import _ from 'lodash';
import { IPayloadAction } from 'interfaces/payloadAction';
import { LANG_DICTIONARY, ADMIN_ROLE_TYPES } from 'consts';
import {
  GET_ADMINISTRATORS,
  GET_ADMIN_CARD,
  RESET_CHANGES,
  CLEAR_FORM,
  CHANGE_FIELD,
  DELETE_ADMIN,
  GET_CURRENT_CARD,
  SAVE_CHANGES,
  SET_HAVE_CARD,
} from '../types';
import { IAdministratorsReducer, IAdmin } from './interfaces';

const {
  LK_ADM_1,
  LK_ADM_2,
  LK_ADM_3,
  LK_ADM_4,
} = ADMIN_ROLE_TYPES;
const {
  MANAGMENT_LK,
  USERS,
  ADMINS,
  TEMPLATES_OF_PRINTED_FORMS,
} = LANG_DICTIONARY;

const INITIAL_STATE: IAdministratorsReducer = {
  initialList: [],
  administrators: [],
  totalPages: 1,
  currentPage: 1,
  toDelete: [],
  currentCard: {
    id: 0,
    status: true,
    dateCreate: '',
    passwordChangeDate: '',
    password: '',
    roles: [],
    needUpdatePassword: false,
    errorPassword: '',
    hash: '',
  },
  haveCard: false,
  choosedId: 0,
};

export default (state = INITIAL_STATE, { type, payload }: IPayloadAction) => {
  switch (type) {
    case GET_CURRENT_CARD: {
      const {card, id} = payload;
      return {
        ...state,
        choosedId: id,
        haveCard: true,
        currentCard: card,
      };
    }

    case DELETE_ADMIN: {
      return {
        ...state,
        toDelete: payload,
      };
    }

    case SET_HAVE_CARD:
      return {
        ...state,
        haveCard: payload,
      };

    case CHANGE_FIELD: {
      return {
        ...state,
        administrators: payload,
      };
    }

    case RESET_CHANGES: {
      const { initialList } = state;
      return {
        ...state,
        administrators: initialList,
        haveCard: false,
        choosedId: 0,
        toDelete: [],
      };
    }

    case CLEAR_FORM: {
      return {
        ...INITIAL_STATE,
      };
    }

    case GET_ADMINISTRATORS.success: {
      const { administrators: oldAdmins, initialList } = state;
      const { administrators, currentPage, totalPages } = payload;
      const rebuildData = administrators.map((item: IAdmin) => ({
        ...item,
        sendStatus: 'none',
        canUpdateName: false,
        canUpdateEmail: false,
        canUpdatePassword: false,
      }));
      return {
        ...state,
        administrators: _.uniqBy([...oldAdmins, ...rebuildData], 'id'),
        currentPage,
        totalPages,
        initialList: _.uniqBy([...initialList, ...rebuildData], 'id'),
      };
    }

    case GET_ADMIN_CARD.success: {
      const { data, adminId } = payload;
      const { administrators, initialList } = state;
      const getRoles = (roles: any[]) => {
        const rolesList = [
          {
            id: 1,
            name: MANAGMENT_LK,
            code: LK_ADM_1,
            checked: false,
          },
          {
            id: 2,
            name: USERS,
            checked: false,
            code: LK_ADM_2,
          },
          {
            id: 3,
            name: ADMINS,
            checked: false,
            code: LK_ADM_3,
          },
          {
            id: 4,
            name: TEMPLATES_OF_PRINTED_FORMS,
            checked: false,
            code: LK_ADM_4,
          },
        ];
        return rolesList.map((item) => {
          const foundedId = roles.find((role) => role.id === item.id);
          if (foundedId) {
            return {
              ...item,
              checked: true,
            };
          }
          return item;
        });
      };
      const newRoles = getRoles(data.roles);
      const newAdministrators: IAdmin[] = administrators.map((item) => {
        if (item.id === adminId) {
          return {
            ...item,
            personalCard: {
              ...data,
              roles: newRoles,
            },
          };
        }
        return {
          ...item,
        };
      });
      const newInitial: IAdmin[] = initialList.map((item) => {
        if (item.id === adminId) {
          return {
            ...item,
            personalCard: {
              ...data,
              roles: newRoles,
            },
          };
        }
        return {
          ...item,
        };
      });
      return {
        ...state,
        administrators: newAdministrators,
        initialList: newInitial,
        haveCard: true,
        currentCard: {
          ...data,
          roles: newRoles,
        },
        choosedId: adminId,
      };
    }

    case SAVE_CHANGES.success:
      return {
        ...state,
        administrators: [],
        initialList: [],
        haveCard: false,
      };

    default:
      return state;
  }
};
