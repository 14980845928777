import { IPayloadAction } from 'interfaces/payloadAction';
import { rebuildDescriptions } from 'helpers';
import { GET_CLIENT_DESCRIPTIONS } from '../types';

const INITIAL_STATE = {
  descriptions: {},
};

export default (state = INITIAL_STATE, { type, payload }: IPayloadAction) => {
  switch (type) {
    case GET_CLIENT_DESCRIPTIONS.success: {
      const rebuildedDescriptions = rebuildDescriptions(payload);
      return {
        ...state,
        descriptions: rebuildedDescriptions,
      };
    }

    default:
      return state;
  }
};
