const REGEXP = {
  // eslint-disable-next-line
  PHONE_HOME: /^\8[\d\(\)\ -]{9,11}\d$/,
  // eslint-disable-next-line
  PHONEMOBILE: /^\+(?![8])[\d\(\)\ -]{10,12}\d$/,
  NUMBERS_REG: /[0-9]/,
  LATINIC_LOW: /[a-z]/,
  LATINIC_UP: /[A-Z]/,
  CYRILLIC: /[а-яА-ЯёЁ]/,
  EMAIL_CHECK: /[^\-_+=@0-9a-zA-Z.]/,
  CURRENCY_REG: /(\d)(?=(\d\d\d)+([^\d]|$))/g,
  PHONE_MASK: /[\s()]/g,
  MOBILE_PHONE: /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
  PASSWORD_CHECK: /^(?=.*[a-z]{1,})(?=.*[A-Z]{1,})(?=.*\d{1,}).{8,}$/,
  PLUS_NUMBER_CHECK: /\+/,
  ONLY_DIGITS_CHECK: /\d/,
  NOT_DIGITS_CHECK: /\D/,
  AT_CHECK: '@',
  NOT_DIGITS_CHECK_GREEDY: /\D/g,
};

export default REGEXP;
