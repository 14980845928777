import './styles.scss';

import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import {
  AdminSectionTitle,
  AdminMainButton,
  FlexTablesAdmins,
} from 'components';
import React from 'react';
import { IAdmin, IAdminPersonalCard } from 'redux/adminAdministrators/reducers/interfaces';
import PersonalCard from './PersonalCard';

const b = block('admin-card-form');

const {
  ADMINS_WORD,
  REVOKE_WORD,
  SAVE_WORD,
} = LANG_DICTIONARY;

interface IProps {
  handleChangeValue: (name: string, value: any, id: any) => void;
  addAction: () => void;
  deleteAction: (id: number | string) => void;
  administrators: IAdmin[];
  resetChanges: () => void;
  saveChanges: () => void;
  updateList: () => any;
  getAdminPersonalCard: (id: number | string, haveCard: boolean) => void;
  choosedId: number | string;
  currentCard: IAdminPersonalCard;
  haveCard: boolean
  handleChangeValuePersonalCard: (name: string, value: any) => void;
  checkDisabled: (id: any) => boolean;
  handleChangeCheckbox: (id: number | string, roleId: number) => void;
  checkPasswordValidation: (password: string) => void;
}

class AdminCardForm extends React.Component<IProps> {
  componentDidMount = () => {
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount = () => {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const { updateList } = this.props;
    const wrappedElement: any = document.getElementById('adminTable');
    const bodyHeight = wrappedElement.offsetHeight;
    const scrollHeight = wrappedElement.scrollTop;
    const bodyScrollHeight = wrappedElement.scrollHeight;
    const isNeedUpdateList = bodyScrollHeight <= bodyHeight + scrollHeight;

    if (isNeedUpdateList) {
      updateList();
    }
  }

  checkForUpdatePassword = () => {
    const { administrators, choosedId } = this.props;
    const currentAdmins: any = administrators.find((item) => item.id === choosedId);
    return currentAdmins && currentAdmins.canUpdatePassword;
  }

  checkboxPassword = (id: number | string) => {
    const { handleChangeValue, administrators } = this.props;
    const currentAdmins: any = administrators.find((item) => item.id === id);
    if (currentAdmins) {
      handleChangeValue('canUpdatePassword', !currentAdmins.canUpdatePassword, id);
    }
  }

  render() {
    const {
      administrators,
      handleChangeValue,
      addAction,
      deleteAction,
      saveChanges,
      resetChanges,
      getAdminPersonalCard,
      choosedId,
      currentCard,
      haveCard,
      handleChangeValuePersonalCard,
      checkDisabled,
      handleChangeCheckbox,
      checkPasswordValidation,
    } = this.props;

    return (
      <div className={b()}>
        <AdminSectionTitle title={ADMINS_WORD} />
        <div className={b('content')}>
          <div className={b('table', { max: !haveCard })} id="adminTable" onScroll={this.trackScrolling}>
            <FlexTablesAdmins
              administrators={administrators}
              handleChangeValue={handleChangeValue}
              addAction={addAction}
              deleteAction={deleteAction}
              choosedId={choosedId}
              getAdminPersonalCard={getAdminPersonalCard}
            />
          </div>
          {haveCard && (
          <PersonalCard
            handleChangeCheckbox={handleChangeCheckbox}
            personalCard={currentCard}
            handleChangeValue={handleChangeValuePersonalCard}
            canUpdatePassword={this.checkForUpdatePassword()}
            checkDisabled={checkDisabled}
            checkboxPassword={this.checkboxPassword}
            checkPasswordValidation={checkPasswordValidation}
          />
          )}
        </div>
        <div className={b('footer')}>
          <AdminMainButton text={REVOKE_WORD} onClick={resetChanges} className={b('footer-btn')} />
          <AdminMainButton text={SAVE_WORD} onClick={saveChanges} className={b('footer-btn')} />
        </div>
      </div>
    );
  }
}

export default AdminCardForm;
