import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';
import { AdminCurrentTime } from 'components';

const b = block('admin-footer-panel');

const { IP_ADDRESS } = LANG_DICTIONARY;

interface IProps {
  ip: string,
}

const AdminFooterPanel: React.FC<IProps> = ({
  ip,
}) => (
  <div className={b()}>
    <p className={b('ip')}>{`${IP_ADDRESS}: ${ip}`}</p>
    <AdminCurrentTime />
  </div>
);

export default AdminFooterPanel;
