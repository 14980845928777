import { API } from 'consts';
import { apiCall, getError } from 'helpers';
import { put, takeLatest } from 'redux-saga/effects';
import { GET_IP, GET_ADMIN_URL } from '../types';

function* getIp() {
  try {
    yield put({ type: GET_IP.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_IP,
    });
    yield put({ type: GET_IP.success, payload: data });
  } catch (e) {
    yield put({ type: GET_IP.fail, payload: getError(e) });
  }
}

function* getAdminUrl() {
  try {
    yield put({ type: GET_ADMIN_URL.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_ADMIN_URL,
    });
    const { host } = document.location;
    const adminUrl = data.replace(host, '');
    yield put({ type: GET_ADMIN_URL.success, payload: adminUrl });
  } catch (e) {
    yield put({ type: GET_ADMIN_URL.fail });
  }
}

export default function* commonSaga() {
  yield takeLatest<string, any>(GET_IP.request, getIp);
  yield takeLatest<string, any>(GET_ADMIN_URL.request, getAdminUrl);
}
