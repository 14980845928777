import './styles.scss';
import React from 'react';
import { block } from 'bem-cn';
import { IRiskData, RiskData } from 'redux/contracts/reducers/interface';
import { LANG_DICTIONARY, DATE_FORMAT } from 'consts';
import { rebuildCurrency } from 'helpers';
import moment from 'moment';
import { TransparentTable } from 'components';
import { Icon, Popup } from 'semantic-ui-react';

const {
  INSURANCE_RISK_UNDER_THE_CONTRACT,
  TERM_RISK,
  MAX_INSURANCE_SUM_ON_RISK,
  INSURANCE_RISK,
  ADDITIONAL_RISKS,
} = LANG_DICTIONARY;

const b = block('isz-risks-data');
const slider = block('main-slider');
const contentClass = b('content').mix(slider('content', { right: true }));

interface IProps {
  data: IRiskData,
}

const RisksIszData: React.FC<IProps> = ({ data }) => {
  const FirstColumn = (description: { description: string }) => (
    <div className={b('table-first-column')}>
      <Popup
        popperModifiers={{
          preventOverflow: {
            boundariesElement: 'offsetParent',
          },
        }}
        hideOnScroll
        content={<div style={{width: '400px'}}>{description.description}</div>}
        on="click"
        pinned
        trigger={<Icon style={{cursor: 'pointer'}} name="question circle outline" />}
      />
    </div>
  );

  const getDate = (date: string) => date && moment(date).format(DATE_FORMAT.DATE);

  const headersSurv = [
    <div className={b('header-surv')}>
      <div>{INSURANCE_RISK}</div>
    </div>,
    MAX_INSURANCE_SUM_ON_RISK,
    TERM_RISK];

  const headersNotSurv = [
    <div className={b('header-surv')}>
      <div>{ADDITIONAL_RISKS}</div>
    </div>,
    MAX_INSURANCE_SUM_ON_RISK,
    TERM_RISK];

  const rebuildData = (item: RiskData) => ([
    <div className={b('header-surv')}>
      <div>{item.nameFull}</div>
      {item.description ? <FirstColumn description={item.description} /> : <div />}
    </div>,
    `${rebuildCurrency(String(item.insSumCur))} ${item.currencyName}`,
    `по ${getDate(item.dateEnd)}`]
  );

  const rebuildDataNotSurv = data.notSurv && data.notSurv.map((risk) => (rebuildData(risk)));

  const rebuildDataSurv = data.surv && data.surv.map((risk) => (rebuildData((risk))));

  return (
    <div className={b.mix(slider)}>
      <div className={b('wrapper')}>
        <div className={contentClass}>
          <div className={b('header-form-text')}>{INSURANCE_RISK_UNDER_THE_CONTRACT}</div>
          { rebuildDataSurv
            && rebuildDataSurv.length ? (
              <TransparentTable
                data={rebuildDataSurv}
                headers={headersSurv}
                className={b('table')}
              />
            ) : <div />}
          { rebuildDataNotSurv
          && rebuildDataNotSurv.length ? (
            <TransparentTable
              data={rebuildDataNotSurv}
              headers={headersNotSurv}
              className={b('table-second')}
            />
            ) : <div />}
        </div>
      </div>
    </div>
  );
};

export default RisksIszData;
