const combineRows = (data: any) => {
  const newData: any = [].concat(data);
  for (let i = 0; i < newData.length; i += 1) {
    if (newData[i].row) {
      if (i % 2 === 0) {
        const combine = newData.splice(i + 1, newData[i].row);
        newData.splice(i + 1, 0, combine);
      } else {
        const combine = newData.splice(i + 1, newData[i].row - 1);
        const prevElem = newData[i - 1];
        combine.unshift(prevElem);
        newData[i - 1] = combine;
        i += 1;
      }
    }
  }
  return newData;
};

export default (data: any) => {
  if (data && Array.isArray(data[0])) {
    return data.map((item: any) => (combineRows(item)));
  }
  return combineRows(data);
};
