import { API, ROUTES } from 'consts';
import { apiCall, getGlobalHistory } from 'helpers';
import { ITEM_COUNT_ADMIN_TABLE_USERS } from 'config';
import { put, takeLatest, select } from 'redux-saga/effects';
import { GET_USERS, SIGN_IN_BY_USER } from '../types';

function* getUsersList({payload}: {payload: {page: number, filter: string}}) {
  try {
    const { page, filter } = payload;
    yield put({ type: GET_USERS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_USERS_ADMINISTRATION,
      isAdminToken: true,
      query: {
        offset: ITEM_COUNT_ADMIN_TABLE_USERS * (page - 1),
        limit: ITEM_COUNT_ADMIN_TABLE_USERS,
        filter: filter.length ? filter : undefined,
      },
    });
    yield put({ type: GET_USERS.success, payload: data });
  } catch (e) {
    const { adminUrl } = yield select(({common}) => common);
    getGlobalHistory().push(`${adminUrl}${ROUTES.adminAuthorization}`);
    yield put({ type: GET_USERS.fail });
  }
}

function* signInByUser({payload}: {payload: number}) {
  try {
    yield put({ type: SIGN_IN_BY_USER.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.SIGN_IN_BY_USER,
      isAdminToken: true,
      body: {
        id: payload,
      },
    });
    yield put({ type: SIGN_IN_BY_USER.success, payload: data });
    getGlobalHistory().push(ROUTES.home);
  } catch (e) {
    yield put({ type: SIGN_IN_BY_USER.fail });
  }
}

export default function* adminUsersSaga() {
  yield takeLatest<string, any>(GET_USERS.request, getUsersList);
  yield takeLatest<string, any>(SIGN_IN_BY_USER.request, signInByUser);
}
