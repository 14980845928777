import { Avatar } from '@material-ui/core';
import { block } from 'bem-cn';
import {
  ChangePasswordForm,
  ChangeContactForm,
  PersonalInfoPopUp,
  AcceptedRulesPopup,
} from 'components';
import { getPasswordErrors } from 'helpers';
import { LANG_DICTIONARY, REGEXP } from 'consts';
import React from 'react';
import InputMask from 'react-input-mask';
import avatarDownloadIcon from 'assets/svg/avatarDownloadIcon.svg';
import emailIcong from 'assets/svg/emailIcong.svg';
import passwordChangeIcon from 'assets/svg/passwordChangeIcon.svg';
import personalPhone from 'assets/svg/personalPhone.svg';
import userIcon from 'assets/svg/userIcon.svg';
import schema from './schema';
import './styles.scss';

const b = block('personal-info');

const {
  PERSONAL_DATA_TEXT,
  CHANGE_PASSWORD_TEXT,
  ADD_CONTACT,
  RS_WORDS,
  IN_ORGANIZATION,
  BIC_WORD,
  PHONE_HOME,
  PHONE_MOBILE,
  EMAIL,
} = LANG_DICTIONARY;

interface IContacts {
  id?: number;
  isDefault: boolean;
  type: string;
  value: string;
  isDelete: boolean;
  code: string;
}

interface IProps {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phoneNumber: string;
  rs: string;
  organization: string;
  bic: string;
  setAvatar: (e: React.ChangeEvent<HTMLInputElement>) => void;
  avatarLink: string;
  deleteContact: (id: number) => void;
  changePassword: (
    oldPassword: string,
    newPassword: string,
    passwordConfirmation: string,
  ) => void;
  changeContact: (contact: string) => void;
  removeCodeType: () => void;
  changePhone: (contact: string, smsCode: string) => void;
  changeEmail: (contact: string, smsCode: string) => void;
  contactVerify: (contact: string) => void;
  infoContact: string;
  infoPassword: string;
  adrFactFullText: string;
  adrRegFullText: string;
  birthDate: string;
  birthPlaceFull: string;
  contactsEmails: IContacts[],
  contactsPhones: IContacts[],
  docAuthCode: string;
  docCitizenship: string;
  docDateIssue: string;
  docNumber: string;
  docSerial: string;
  fullName: string;
  infoPersonalData: string;
  passwordErrorMessage: string;
  passwordChangeOpen: boolean;
  openPopUpPassword: (type: string) => void;
  contactChangeOpen: boolean;
  contactErrorMessage: string;
  changeField: (name: string, value: any) => void;
  docAuth: string;
  codeType: string;
  isAdmin: boolean;
  isAcceptedAgreement: boolean;
}

interface IState {
  contact: string;
  errorPassword: string;
  oldPassword: string;
  smsCode: string;
  isPhone: boolean;
  password: string;
  passwordConfirm: string;
  personalInfoPopUpOpen: boolean;
  errorField: string;
}

const CONTACT_FIELD_NAME = 'contact';

class PersonalInfo extends React.Component <IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      personalInfoPopUpOpen: false,
      contact: '',
      errorPassword: '',
      isPhone: false,
      smsCode: '',
      oldPassword: '',
      password: '',
      passwordConfirm: '',
      errorField: '',
    };
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { isAdmin } = this.props;
    if (isAdmin) {
      return null;
    }
    const { name } = e.target;
    const { value } = e.target;

    if (name === CONTACT_FIELD_NAME) {
      this.getTypeOfContact(value);
    }

    const state: object = {
      [name]: value,
    };
    this.setState(state);
    return null;
  }

  public changeContact = () => {
    const { contactVerify, isAdmin } = this.props;
    const { contact } = this.state;
    if (isAdmin) {
      return null;
    }
    contactVerify(contact);
    return null;
  }

  public verifyPhone = () => {
    const { contactVerify, isAdmin } = this.props;
    const { contact } = this.state;
    if (isAdmin) {
      return null;
    }
    contactVerify(contact);
    return null;
  }

  public changePassword = () => {
    const { changePassword, isAdmin } = this.props;
    if (isAdmin) {
      return null;
    }
    const { oldPassword, password, passwordConfirm } = this.state;
    const passwordSchema = true;
    const supportSchema = false;
    try {
      schema(passwordSchema, supportSchema, password).validateSync({
        oldPassword,
        password,
        passwordConfirm,
      });
      changePassword(oldPassword, password, passwordConfirm);
      this.setState({
        errorPassword: '',
        errorField: '',
      });
    } catch (e) {
      const { error, path } = getPasswordErrors(e);
      this.setState({
        errorPassword: error,
        errorField: path,
      });
    }
    return null;
  }

  public popUpControl = () => {
    const { openPopUpPassword, changeField } = this.props;
    this.setState({
      oldPassword: '',
      password: '',
      passwordConfirm: '',
      errorPassword: '',
      errorField: '',
    });
    openPopUpPassword('password');
    changeField('passwordErrorMessage', '');
  }

  public contactPopUpControl = () => {
    const { openPopUpPassword, changeField } = this.props;
    this.setState({
      contact: '',
    });
    openPopUpPassword('login');
    changeField('contactErrorMessage', '');
  }

  public deletePersonalContact = (id: number) => {
    const { deleteContact, isAdmin } = this.props;
    if (isAdmin) {
      return null;
    }
    deleteContact(id);
    return null;
  }

  public personalInfoPopUpControl = () => {
    const { personalInfoPopUpOpen } = this.state;
    this.setState({
      personalInfoPopUpOpen: !personalInfoPopUpOpen,
    });
  }

  private isEmailLoginCheck = (contact: string): Boolean => (
    Boolean(
      contact.match(REGEXP.AT_CHECK)
      || (contact.match(REGEXP.ONLY_DIGITS_CHECK)
        && contact.match(REGEXP.NOT_DIGITS_CHECK)),
    )
  )

  private getTypeOfContact = (contact: string): string => {
    const { removeCodeType } = this.props;
    const { PLUS_NUMBER_CHECK, ONLY_DIGITS_CHECK } = REGEXP;

    removeCodeType();
    if (contact.match(PLUS_NUMBER_CHECK)) {
      this.setState({ isPhone: true });
      return PHONE_MOBILE;
    }
    if (this.isEmailLoginCheck(contact)) {
      this.setState({ isPhone: false, smsCode: '' });

      return EMAIL;
    }
    if (contact.match(ONLY_DIGITS_CHECK)) {
      this.setState({ isPhone: true });
      return PHONE_HOME;
    }
    this.setState({ isPhone: false, smsCode: '' });
    return EMAIL;
  }

  public render() {
    const {
      firstName,
      lastName,
      middleName,
      rs,
      organization,
      bic,
      phoneNumber,
      email,
      setAvatar,
      avatarLink,
      infoContact,
      infoPassword,
      fullName,
      docSerial,
      docNumber,
      docDateIssue,
      docAuthCode,
      docCitizenship,
      changeEmail,
      birthDate,
      birthPlaceFull,
      contactsEmails,
      contactsPhones,
      infoPersonalData,
      adrFactFullText,
      adrRegFullText,
      passwordErrorMessage,
      passwordChangeOpen,
      contactChangeOpen,
      contactErrorMessage,
      changePhone,
      docAuth,
      codeType,
      removeCodeType,
      isAcceptedAgreement,
    } = this.props;
    const {
      errorPassword,
      oldPassword,
      password,
      passwordConfirm,
      contact,
      isPhone,
      personalInfoPopUpOpen,
      smsCode,
      errorField,
    } = this.state;
    return (
      <div className={b()}>
        <PersonalInfoPopUp
          fullName={fullName}
          serial={docSerial}
          passportNumber={docNumber}
          passportDate={docDateIssue}
          passportGetBy={docAuth}
          subdivisionCode={docAuthCode}
          country={docCitizenship}
          birthDate={birthDate}
          birthPlace={birthPlaceFull}
          addressLiving={adrRegFullText}
          currentAddress={adrFactFullText}
          contactsEmails={contactsEmails}
          contactsPhones={contactsPhones}
          deleteContact={this.deletePersonalContact}
          isOpen={personalInfoPopUpOpen}
          closePopUp={this.personalInfoPopUpControl}
          info={infoPersonalData}
        />
        <ChangePasswordForm
          password={password}
          passwordConfirm={passwordConfirm}
          sendData={this.changePassword}
          errorPassword={errorPassword}
          info={infoPassword}
          handleChange={this.handleChange}
          popUpOpen={passwordChangeOpen}
          oldPassword={oldPassword}
          closePopUp={this.popUpControl}
          errorField={errorField}
          passwordErrorMessage={passwordErrorMessage}
        />
        <ChangeContactForm
          contact={contact}
          changeEmail={changeEmail}
          codeType={codeType}
          removeCodeType={removeCodeType}
          smsCode={smsCode}
          changePhone={changePhone}
          isPhone={isPhone}
          getTypeOfContact={this.getTypeOfContact}
          verifyPhone={this.verifyPhone}
          isOpen={contactChangeOpen}
          sendData={this.changeContact}
          handleChange={this.handleChange}
          closePopUp={this.contactPopUpControl}
          info={infoContact}
          errorMessage={contactErrorMessage}
        />
        {!isAcceptedAgreement
        && <AcceptedRulesPopup email={email} isAcceptedAgreement={isAcceptedAgreement} />}
        <div className={b('first-row')}>
          <div className={b('left-side')}>
            <div className={b('avatar-control')}>
              <Avatar src={avatarLink} className={b('avatar').toString()} />
              <div className={b('test')}>
                <div>
                  <label htmlFor="embedpollfileinput" className={b('avatar-upload')}>
                    <img src={avatarDownloadIcon} alt="upload" />
                    <input
                      type="file"
                      className={b('inputfile')}
                      id="embedpollfileinput"
                      onChange={(e) => setAvatar(e)}
                      accept=".png, .jpg, .jpeg, .svg, .bmp"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className={b('personal-name')}>
              {`${lastName} ${firstName} ${middleName}`}
            </div>
          </div>
          <div className={b('right-side')}>
            <div
              className={b('personal-data-right-full-info')}
              onClick={this.personalInfoPopUpControl}
              role="button"
              tabIndex={-1}
            >
              <img src={userIcon} className={b('user-icon')} alt="userIcon" />
              {PERSONAL_DATA_TEXT}
            </div>
            <div className={b('change-data')} onClick={this.popUpControl} role="button" tabIndex={-1}>
              <img src={passwordChangeIcon} className={b('user-icon')} alt="userIcon" />
              {CHANGE_PASSWORD_TEXT}
            </div>
          </div>
        </div>
        <div className={b('second-row-wrapper')}>
          <div className={b('second-row')}>
            <div className={b('left-side-bottom')}>
              <div className={b('avatar-control')} />
              {Boolean(rs) && (
                <div className={b('personal-data-bank')}>
                  <div className={b('bank-data')}>{`${RS_WORDS} ${rs}`}</div>
                  <div className={b('bank-data')}>{`${IN_ORGANIZATION} ${organization}`}</div>
                  <div className={b('bank-data')}>{`${BIC_WORD}: ${bic}`}</div>
                </div>
              )}
            </div>
            <div className={b('right-side-bottom')}>
              <div className={b('personal-contacts')}>
                <div className={b('personal-data-right')}>
                  <img src={personalPhone} className={b('user-icon')} alt="phone" />
                  <InputMask value={phoneNumber} mask="+9 999 999 9999" disabled className={b('main-number')} />
                </div>
                <div className={b('personal-data-right')}>
                  <img src={emailIcong} className={b('user-icon')} alt="email" />
                  <p className={b('user-email')}>{email}</p>
                </div>
              </div>
              <div className={b('personal-contacts')}>
                <div
                  className={b('change-data-bottom')}
                  onClick={this.contactPopUpControl}
                  role="button"
                  tabIndex={-1}
                >
                  {ADD_CONTACT}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalInfo;
