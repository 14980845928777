import React, {Component} from 'react';
import moment from 'moment';
import 'moment-timezone';
import { LANG_DICTIONARY, DATE_FORMAT } from 'consts';
import withTimeout from 'react-timeout';

interface IState {
  currentTime: string;
}

const { ADMIN_FOOTER_TIME_FORMAT } = DATE_FORMAT;

class CurrentTime extends Component<any, IState> {
  constructor(props: any) {
    super(props);
    const { setInterval: setIntervalFunc } = props;

    this.state = { currentTime: moment().format(ADMIN_FOOTER_TIME_FORMAT) };

    setIntervalFunc(() => {
      this.setState({
        currentTime: moment().format(ADMIN_FOOTER_TIME_FORMAT),
      });
    }, 1000);
  }

  getDate = () => {
    const { currentTime } = this.state;
    const timeZoneOffset = new Date().getTimezoneOffset();

    return ` ${currentTime} GMT +${-timeZoneOffset / 60}`;
  };

  render() {
    const { CURRENT_TIME } = LANG_DICTIONARY;

    return (
      <div>
        {`${CURRENT_TIME}: ${this.getDate()}`}
      </div>
    );
  }
}

export default withTimeout(CurrentTime);
