import { REGEXP, LANG_DICTIONARY } from 'consts';
import { MINIMUM_PASSWORD_LENGTH } from 'config';
import { object, string } from 'yup';

const {
  PASSWORD_CHECK,
  CYRILLIC,
} = REGEXP;

const {
  PASSWORD_LANGUAGE,
  PASSWORD_RULES,
} = LANG_DICTIONARY;

const checkPasswordSchema = (password: string) => ({
  password: password.length
    ? string()
      .min(MINIMUM_PASSWORD_LENGTH, PASSWORD_RULES)
      .matches(PASSWORD_CHECK, PASSWORD_RULES)
      .test('', PASSWORD_LANGUAGE, (value) => !value.match(CYRILLIC))
    : string(),
}
);

const schema = (password: string) => object().shape({
  ...checkPasswordSchema(password),
});

export default schema;
