import './styles.scss';

import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import {
  AdminSectionTitle,
  FlexTableContacts,
} from 'components';
import React from 'react';
import {
  IContactSelectvalue,
  IContactsUserCard,
  ISelectItem,
} from 'redux/adminUserCard/reducers/interface';

const {
  ID_WORD,
  DATE_CREATE,
  DATE_REG,
  CONTACT_DATA,
} = LANG_DICTIONARY;
const b = block('user-card-contacts');

type TAction = 'contacts' | 'contragents' | 'contracts';

interface IProps {
  userId: number;
  regDateCreate: string;
  registrationFinishDate: string;
  contacts: IContactsUserCard[];
  addAction: (type: TAction) => void;
  deleteAction: (type: TAction, id: number | string) => void;
  onChangeCheckbox: (id: number | string) => void;
  selectData: ISelectItem[];
  handleChangeContact: (id: number | string, value: IContactSelectvalue) => void;
  handleChangeContactName: (id: number | string, value: string) => void;
}

const ContactsForm: React.FC<IProps> = ({
  userId,
  regDateCreate,
  registrationFinishDate,
  contacts,
  addAction,
  deleteAction,
  onChangeCheckbox,
  selectData,
  handleChangeContact,
  handleChangeContactName,
}) => {
  const firstRowData = [
    {
      title: ID_WORD,
      value: userId,
    },
    {
      title: DATE_CREATE,
      value: regDateCreate,
    },
    {
      title: DATE_REG,
      value: registrationFinishDate,
    },
  ];
  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('first-row')}>
          {firstRowData.map(({title, value}) => (
            <div className={b('personal-block')} key={title}>
              <div className={b('title')}>{title}</div>
              <div className={b('value')}>{value}</div>
            </div>
          ))}
        </div>
        <AdminSectionTitle title={CONTACT_DATA} />
      </div>
      <div className={b('table')}>
        <FlexTableContacts
          addAction={addAction}
          deleteAction={deleteAction}
          onChangeCheckbox={onChangeCheckbox}
          selectData={selectData}
          contacts={contacts}
          handleChangeContact={handleChangeContact}
          handleChangeContactName={handleChangeContactName}
        />
      </div>
    </div>
  );
};

export default ContactsForm;
