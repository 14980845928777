import { block } from 'bem-cn';
import {
  FormInput,
  GreenButton,
  InfoBlock,
  PopupContainer,
  CaptchaBlock,
} from 'components';
import {
  LANG_DICTIONARY,
  MASKS,
  REGEXP,
  ROUTES,
} from 'consts';
import React from 'react';
import classNames from 'classnames';
import schema from './schema';
import './styles.scss';
import FormInputWithButton from '../FormInputWithButton';
import FormInputMiniLabel from '../FormInputMiniLabel';
import { IAddPhoneLoginPayload, IAddPhoneLoginVerifyPayload } from '../../../../interfaces/addPhoneLoginPayload';

const b = block('sign-in-form');

const {
  LOGIN_EMAIL_AUTH,
  LOGIN_PHONE_AUTH,
  PASSWORD_WORD,
  SIGN_IN_INFO,
  SIGN_INF_WORD,
  EL_MAIL,
  PHONE_NUMBER,
  ENTER_PHONE_NUMBER,
  REGISTRATION_WORD,
  MISSING_PASSWORD,
  RETURN_ACCESS,
  CHECK_LOGIN,
  REG_TOOLTIP_TEXT,
  FORGET_PASSWORD,
  ACCEPT_WORD,
  SMS_CODE,
  CAPTCHA_CODE,
  RESET_PASSWORD,
  ENTER_LK,
} = LANG_DICTIONARY;

const {
  phoneNumberMask,
  smsCodeMask,
} = MASKS;

const {
  PHONE_MASK,
} = REGEXP;

interface IProps {
  authorization: (login: string, password: string, captchaId: string, captchaKey: string) => void;
  addPhoneLogin: (payload:IAddPhoneLoginPayload) => void;
  addPhoneLoginVerify: (payload:IAddPhoneLoginVerifyPayload) => void;
  history: any;
  errorMessage: string;
  clearStore: () => void;
  recaptcha: (captchaId: string) => void,
  captcha: string,
  captchaId: string,
  isNeedReset: boolean,
  isPhone: boolean,
  changeAuthLogin: (isPhone: boolean) => void,
}

interface IState {
  login: string;
  isSmsActive: boolean;
  messageError: string;
  open: boolean;
  password: string;
  phoneNumber: string;
  smsCode: string;
  currentTypePassword: string;
  typePassword: string;
  typeText: string;
  captchaKey: string;
}

class SignIn extends React.Component <IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isSmsActive: false,
      login: '',
      messageError: '',
      phoneNumber: '',
      smsCode: '',
      open: true,
      password: '',
      currentTypePassword: 'password',
      typePassword: 'password',
      typeText: 'text',
      captchaKey: '',
    };
  }

  public handleChangeLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({login: value});
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const { value } = e.target;
    const state: object = {
      [name]: value,
    };
    this.setState(state);
  }

  public authorizationAction = () => {
    const {
      login,
      password,
      captchaKey,
      phoneNumber,
      smsCode,
    } = this.state;
    const {
      authorization,
      captchaId,
      isPhone,
      addPhoneLogin,
    } = this.props;
    if (!isPhone) {
      addPhoneLogin({
        phone: phoneNumber.replace(PHONE_MASK, ''),
        email: login,
        password,
        captchaId,
        captchaKey,
        smsCode: smsCode.replace(PHONE_MASK, ''),
      });
      return;
    }
    schema(isPhone).isValid({
      login,
    }).then((valid) => {
      if (valid) {
        this.setState({
          messageError: '',
        });
        authorization(login.replace(PHONE_MASK, ''), password, captchaId, captchaKey);
      } else {
        this.setState({
          messageError: CHECK_LOGIN,
        });
      }
    });
  }

  public passwordProtectControl = () => {
    const { typePassword, typeText, currentTypePassword } = this.state;
    if (currentTypePassword === typePassword) {
      this.setState({
        currentTypePassword: typeText,
      });
    } else {
      this.setState({
        currentTypePassword: typePassword,
      });
    }
  }

  public componentWillUnmount = () => {
    const { clearStore } = this.props;
    clearStore();
  }

  public handleClosePopUp = () => {
    document.location.href = 'https://bcslife.ru/';
  }

  public handleChangeIsPhoneTab = (isPhone:boolean) => {
    const { changeAuthLogin, clearStore, errorMessage} = this.props;
    const { messageError } = this.state;
    changeAuthLogin(isPhone);
    clearStore();
    this.setState({ login: '', messageError: '' });
  }

  public generateSignInForm = () => {
    const {
      history,
      errorMessage,
      recaptcha,
      addPhoneLoginVerify,
      captcha,
      captchaId,
      isNeedReset,
      isPhone,
    } = this.props;
    const {
      login,
      phoneNumber,
      smsCode,
      isSmsActive,
      password,
      messageError,
      currentTypePassword,
      captchaKey,
    } = this.state;

    const notDisableButton = () => {
      if (isPhone) {
        return Boolean(login.length && password.length);
      }
      return Boolean(login.length && password.length
          && (captchaId ? captchaKey.length : true))
        && isSmsActive && !smsCode.includes('_') && smsCode.length !== 0;
    };

    return (
      <div className={b()}>
        <div className={b('container')}>
          <div className={b('header')}>
            {ENTER_LK}
            <br />
            {SIGN_IN_INFO}
          </div>
          <div className={b('tabs')}>
            <div>
              <button
                onClick={() => this.handleChangeIsPhoneTab(true)}
                className={classNames({
                  [b('tab-button')]: true,
                  [b('tab-button-active')]: isPhone,
                })}
              >
                {PHONE_NUMBER}
              </button>
            </div>
            <div>
              <button
                onClick={() => this.handleChangeIsPhoneTab(false)}
                className={classNames({
                  [b('tab-button')]: true,
                  [b('tab-button-active')]: !isPhone,
                })}
              >
                {EL_MAIL}
              </button>
            </div>
          </div>
          {isPhone
            ? (
              <FormInput
                placeholder={LOGIN_PHONE_AUTH}
                value={login}
                onChange={this.handleChangeLogin}
                name="phone"
                mask={phoneNumberMask}
              />
            )
            : (
              <FormInput
                placeholder={LOGIN_EMAIL_AUTH}
                value={login}
                onChange={this.handleChangeLogin}
                name="email"
                mask=""
              />
            )}
          <div className={b('password-field')}>
            <FormInput
              placeholder={PASSWORD_WORD}
              value={password}
              onChange={this.handleChange}
              name="password"
              type={currentTypePassword}
              mask=""
              onClicIcon={this.passwordProtectControl}
              className={b('password-wrapper')}
              password
            />
          </div>
          {!isPhone && (
            <div>
              <FormInputWithButton
                placeholder={ENTER_PHONE_NUMBER}
                value={phoneNumber}
                onChange={this.handleChange}
                isButtonDisabled={phoneNumber === '' || phoneNumber.includes('_')}
                buttonLabel={ACCEPT_WORD}
                tooltipText={REG_TOOLTIP_TEXT}
                onClick={() => {
                  this.setState({isSmsActive: true});
                  return addPhoneLoginVerify({
                    email: login,
                    phone: phoneNumber.replace(PHONE_MASK, ''),
                    password,
                  });
                }}
                name="phoneNumber"
                mask={phoneNumberMask}
              />
              {isSmsActive && (
                <div className={b('sms-wrapper')}>
                  <FormInputMiniLabel
                    className={b('sms-code')}
                    placeholder={SMS_CODE}
                    value={smsCode}
                    onChange={this.handleChange}
                    name="smsCode"
                    mask={smsCodeMask}
                  />
                </div>
              )}
            </div>
          )}
          <div
            role="button"
            tabIndex={-1}
            className={b('reset-password')}
            onClick={() => history.push(ROUTES.resetPassword)}
          >
            {FORGET_PASSWORD}
          </div>
          {captcha && (
          <>
            <CaptchaBlock
              captcha={captcha}
              handleRecaptcha={() => recaptcha(captchaId)}
            />
            <div className={b('captcha-wrapper')}>
              <div className={b('captcha-input-wrapper')}>
                <FormInput
                  placeholder={CAPTCHA_CODE}
                  value={captchaKey}
                  onChange={this.handleChange}
                  name="captchaKey"
                  mask=""
                />
              </div>
            </div>
          </>
          )}
          <div className={b('control-buttons')}>
            <GreenButton
              onClick={() => this.authorizationAction()}
              text={SIGN_INF_WORD}
              className={b('sign-in-button')}
              disabled={!notDisableButton()}
            />
            <GreenButton
              onClick={() => history.push(ROUTES.registration)}
              text={REGISTRATION_WORD}
              className={b('sign-up-button')}
            />
          </div>
          <div className={b('additional-info')}>
            <InfoBlock message={messageError || errorMessage} error />
            <div className={b('description')}>
              <div className={b('missing-password')}>{MISSING_PASSWORD}</div>
              <div className={b('return-access')}>{RETURN_ACCESS}</div>
            </div>
            {isNeedReset && (
            <div
              role="button"
              tabIndex={-1}
              className={b('reset-password', { reset: true })}
              onClick={() => history.push(ROUTES.resetPassword)}
            >
              {RESET_PASSWORD}
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  public render() {
    const { open } = this.state;
    return (
      <PopupContainer
        isOpen={open}
        children={this.generateSignInForm()}
        close={this.handleClosePopUp}
        isCloseButton
      />
    );
  }
}

export default SignIn;
