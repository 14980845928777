import React, { useEffect } from 'react';
import block from 'bem-cn';
import {
  RefPaymentTerm,
} from 'redux/profitability/reducers/interfaces';
import { dateToQuarter } from 'helpers/dateUtils';
import { LANG_DICTIONARY } from 'consts/dictionary';
import { Loader } from 'semantic-ui-react';
import DateLineSelect from 'components/_client/_shared/DateLineSelect';
import 'components/_client/_shared/Profitability/styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getProfitabilityPayments } from '../../../../../redux/profitability/actions';
import {
  getContractInfo,
  getIsPaymentsLoading,
  getSelectedPeriodPayments,
  getSelectedStrategy,
} from '../../../../../redux/profitability/selectors';

const b = block('profitability');

interface IProps {
  selectedYear: number;
  isMarkedYear?: boolean;
}

const SelectDate: React.FC<IProps> = ({
  selectedYear,
  isMarkedYear,
}) => {
  const dispatch = useDispatch();
  const selectedPeriodPayments = useSelector(getSelectedPeriodPayments);
  const selectedStrategy = useSelector(getSelectedStrategy);
  const isPaymentsLoading = useSelector(getIsPaymentsLoading);
  const contractInfo = useSelector(getContractInfo);
  const OnceAHalfYearEndDateQ = 2;

  useEffect(() => {
    dispatch(getProfitabilityPayments(selectedStrategy?.id!, selectedYear));
  }, [selectedYear]);


  const dateSelectItems = selectedPeriodPayments?.map((x, i) => ({
    date: x?.date,
    isDisabled: x.isDisabled,
    quartalNumber: x.quartalNumber,
  })) || [];

  const selectItemExists = dateSelectItems.length > 0;
  switch (selectedStrategy?.refPaymentTerm) {
    case RefPaymentTerm.OnceAMonth:
      return (
        <div className={b()}>
          <div>
            <div className={b('block-header')}>
              {LANG_DICTIONARY.CHOOSE_WATCH_PERIOD}
            </div>
            {isPaymentsLoading ? (
              <div>
                <Loader active={isPaymentsLoading} inline="centered" />
              </div>
            )
              : (
                <>
                  <div className={b('quart-tabs')}>
                    {selectedPeriodPayments && (
                      selectedPeriodPayments.map((item, index) => {
                        if (item.quartalNumber !== -1) {
                          return <div key={item.date}>{`${index} месяц`}</div>;
                        }
                        return null;
                      })
                    )}
                  </div>
                  {selectItemExists && (
                    <DateLineSelect
                      isMarkedYear={isMarkedYear}
                      dates={dateSelectItems}
                    />
                  )}
                </>
              )}
          </div>
        </div>
      );
    case RefPaymentTerm.OnceAQuarter:
      return (
        <div className={b()}>
          <div>
            <div className={b('block-header')}>
              {LANG_DICTIONARY.CHOOSE_WATCH_PERIOD}
            </div>
            {isPaymentsLoading ? (
              <div>
                <Loader active={isPaymentsLoading} inline="centered" />
              </div>
            )
              : (
                <>
                  <div className={b('quart-tabs')}>
                    {
                      dateSelectItems.map((item) => {
                        if (item.quartalNumber !== -1 && item.quartalNumber !== -2) {
                          return (
                            <div
                              key={item.quartalNumber}
                            >
                              {`${dateToQuarter(item.quartalNumber)} ${LANG_DICTIONARY.QUARTER_SHORT}`}
                            </div>
                          );
                        }
                        return null;
                      })
                    }
                  </div>
                  {selectItemExists && (
                    <DateLineSelect
                      isMarkedYear={isMarkedYear}
                      dates={dateSelectItems}
                    />
                  )}
                </>
              )}
          </div>
        </div>
      );
    case RefPaymentTerm.OnceAHalfYear:
      return (
        <div className={b()}>
          <div className={b('block-header')}>
            {LANG_DICTIONARY.CHOOSE_WATCH_PERIOD}
          </div>
          {isPaymentsLoading ? (
            <div>
              <Loader active={isPaymentsLoading} inline="centered" />
            </div>
          )
            : (
              <div className={b('scale')}>
                {selectItemExists && (
                  <DateLineSelect
                    isMarkedYear={isMarkedYear}
                    dates={dateSelectItems}
                  />
                )}
              </div>
            )}
        </div>
      );
    case RefPaymentTerm.OnceAYear:
      return (
        <div className={b()}>
          <div className={b('block-header')}>
            {LANG_DICTIONARY.CHOOSE_WATCH_PERIOD}
          </div>
          {isPaymentsLoading ? (
            <div>
              <Loader active={isPaymentsLoading} inline="centered" />
            </div>
          )
            : (
              <div className={b('scale')}>
                {selectItemExists && (
                  <DateLineSelect
                    isMarkedYear={isMarkedYear}
                    dates={dateSelectItems}
                  />
                )}
              </div>
            )}
        </div>
      );
    case RefPaymentTerm.AtTheEndOfTheTerm:
      return (
        <div className={b()}>
          <div className={b('block-header')}>
            {LANG_DICTIONARY.CHOOSE_WATCH_PERIOD}
          </div>
          {isPaymentsLoading ? (
            <div>
              <Loader active={isPaymentsLoading} inline="centered" />
            </div>
          )
            : (
              <div className={b('scale')}>
                {selectItemExists && (
                  <DateLineSelect
                    isMarkedYear={isMarkedYear}
                    dates={[...dateSelectItems, {
                      date: contractInfo.endDate,
                      isDisabled: true,
                      quartalNumber: OnceAHalfYearEndDateQ,
                    }]}
                  />
                )}
              </div>
            )}
        </div>
      );
    default: return null;
  }
};

export default SelectDate;
