import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { ENVIRONMENT_ENUM } from 'consts';
import rootReducer from 'redux/rootRedusers';
import rootSaga from 'redux/rootSaga';

const sagaMiddleware = createSagaMiddleware();
const env = process.env.NODE_ENV;

const init = (store: any) => {
  sagaMiddleware.run(rootSaga);
  return store;
};

const configureStore = (initialState = {}) => {
  if (env === ENVIRONMENT_ENUM.development) {
    return import('redux-devtools-extension').then(({composeWithDevTools}) => {
      const createdStore = createStore(
        rootReducer(),
        initialState,
        composeWithDevTools(
          applyMiddleware(
            sagaMiddleware,
          ),
        ),
      );

      return init(createdStore);
    });
  }

  const store = createStore(
    rootReducer(),
    initialState,
    compose(
      applyMiddleware(
        sagaMiddleware,
      ),
    ),
  );

  return Promise.resolve(init(store));
};

export default configureStore;
