import { DATE_FORMAT } from 'consts';
import moment from 'moment';

const { SYSTEM_DATE_TO } = DATE_FORMAT;

const simpleDate = (date: Date | string, dateFormat?: string) => {
  if (!date) {
    return '';
  }
  if (dateFormat) {
    return moment(date).format(dateFormat);
  }
  return moment(date).format(SYSTEM_DATE_TO);
};

const dateToQuarter = (val: number) => {
  switch (val) {
    case 1: return 'I';
    case 2: return 'II';
    case 3: return 'III';
    case 0: return 'IV';
    default: return '';
  }
};

const yearDiffrence = (startDate: Date | string, endDate: Date | string) => moment(endDate).diff(moment(startDate), 'years');

export {
  simpleDate,
  dateToQuarter,
  yearDiffrence,
};
