import './styles.scss';

import { block } from 'bem-cn';
import React from 'react';

interface IProps {
  isActive: boolean,
}

const b = block('lds-ring');

const Loader: React.FC<IProps> = ({isActive}) => (
  <>
    {isActive && (
      <div className={b()}>
        <div />
        <div />
        <div />
        <div />
      </div>
    )}
  </>
);

export default Loader;
