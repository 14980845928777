import React from 'react';
import { block } from 'bem-cn';
import './styles.scss';
import { AdminCheckbox } from 'components';
import { LANG_DICTIONARY } from 'consts';

const b = block('details');

interface IProps {
    checkboxLabel: string;
    fullName: {
        firstName: string;
        lastName: string;
        middleName: string;
    };
    isApplicant: boolean;
    isNotAdmin: boolean;
}

const ApplicantsDetails: React.FC<IProps> = (props) => {
  const {
    checkboxLabel,
    fullName,
    isApplicant,
    isNotAdmin,
  } = props;

  return (
    <>
      <div className={b('details-checkbox')}>
        <p className={b('text')}>{LANG_DICTIONARY.APPLICANT}</p>
        <div>
          <div>
            <AdminCheckbox
              isNotAdmin={isNotAdmin}
              name="insuredPerson"
              checked={isApplicant}
              text={checkboxLabel}
              isCursorDefault
            />
          </div>
        </div>
      </div>
      <div className={b('fio')}>
        <input disabled value={fullName.lastName} className={b('field')} />
        <input disabled value={fullName.firstName} className={b('field')} />
        <input disabled value={fullName.middleName} className={b('field')} />
      </div>
    </>
  );
};

export default ApplicantsDetails;
