import React from 'react';
import 'components/_client/_shared/Profitability/styles.scss';
import classNames from 'classnames';
import block from 'bem-cn';
import { rebuildCurrency } from 'helpers';
import { couponFormatter } from 'helpers/countFormatter';
import { LANG_DICTIONARY, NUMBER_CONSTANTS } from 'consts';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';
import { getSelectedPayment } from '../../../../../redux/profitability/selectors';

const b = block('profitability');

const refOptionTypesExpectedStrategy = [3, 7, 8, 9, 13, 14, 15, 18];
const refOptionTypesStrategyRate = [1, 2, 10, 11, 12, 16];

interface IProps {
    refOptionType: number,
    isLoading:boolean,
}

const StrategyResult: React.FC<IProps> = (props) => {
  const {
    refOptionType,
    isLoading,
  } = props;

  const {
    earnedByMoney,
    expectedStrategyYield,
    earnedByPiecesBarrierOption,
    memoryEffectEarnedByMoney,
    memoryEffectEarnedByPieces,
    date,
    participationStrategyRate,
  } = useSelector(getSelectedPayment);

  return (
    <div className={b('row')}>
      <div className={`${b('block')}`}>
        <div className={b('blocks-header')}>
          {LANG_DICTIONARY.STRATEGY_RESULT}
        </div>
        {!isLoading && (
          <>
            <div className={b('current-date')}>
              {LANG_DICTIONARY.FOR_DATE(moment(date).locale('ru').format('DD MMMM YYYY'))}
            </div>
            <div className={b('coupon')}>
              <div className={`${b('coupon-info')}`}>
                <div className={b('coupon-total')}>
                  <div>
                    <span className={b('coupon-value')}>
                      {`${rebuildCurrency(String(earnedByMoney))} р.`}
                    </span>
                    {
                      refOptionTypesExpectedStrategy.includes(refOptionType)
                        ? `(${couponFormatter(earnedByPiecesBarrierOption)})`
                        : ''
                    }
                  </div>
                  <div>
                    {LANG_DICTIONARY.TOTAL_PERIOD_PROFIT}
                  </div>
                </div>
                {
                  refOptionTypesExpectedStrategy.includes(refOptionType)
                  && (
                    <div className={classNames({
                      [b('coupon-total')]: true,
                      transparent: true,
                    })}
                    >
                      <div>
                        <span className={b('coupon-value')}>
                          {`${rebuildCurrency(String(memoryEffectEarnedByMoney))} р.`}
                        </span>
                        {`(${couponFormatter(memoryEffectEarnedByPieces)})`}
                      </div>
                      <div>
                        {LANG_DICTIONARY.MEMORY_EFFECT_COUPONS}
                      </div>
                      <p className={b('common-text')}>
                        {LANG_DICTIONARY.MEMORY_EFFECT_COUPONS_DESCRIPTION}
                      </p>
                    </div>
                  )
                }
                {
                  (refOptionType === NUMBER_CONSTANTS.STRIP_OF_CALLS_OPTION
                    || NUMBER_CONSTANTS.JUST_EUROPE_OPTION)
                  && (
                    <p className={b('common-text')}>
                      {LANG_DICTIONARY.EUROPE_OR_STRIP_OPTION}
                    </p>
                  )
                }
              </div>
              <div className={b('barrier-description')}>
                <div className={b('barrier-border')} />
                <div className={b('barrier-description-text')}>
                  {
                    refOptionTypesExpectedStrategy.includes(refOptionType)
                    && (
                      <>
                        <div className={b('coupon-description')}>{LANG_DICTIONARY.EXPECTED_STRATEGY_YIELD}</div>
                        <div className={b('coupon-value')}>{`${expectedStrategyYield}%`}</div>
                      </>
                    )
                  }
                  {
                    refOptionTypesStrategyRate.includes(refOptionType)
                    && (
                      <>
                        <div className={b('coupon-description')}>{LANG_DICTIONARY.PARTICIPATION_STRATEGY_RATE}</div>
                        <div className={b('coupon-value')}>{`${participationStrategyRate}%`}</div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {isLoading && (
        <div className={b('loader')}>
          <Loader active={isLoading} className={b('loader')} inline="centered" />
        </div>
      )}
    </div>
  );
};

export default StrategyResult;
