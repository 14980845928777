import React from 'react';
import './styles.scss';
import block from 'bem-cn';
import classNames from 'classnames';
import { LANG_DICTIONARY } from 'consts';

const b = block('tab-select');

export interface ITabSelectItem {
  label: string;
  value: any;
  active: boolean;
  marked: boolean;
  barrier?: number;
  disabled?: boolean;
}

interface IProps {
  value?: number;
  items: ITabSelectItem[];
  markTitle: string;
  onSelect: (value: any) => void;
  isMonthSelect?: boolean;
}

const TabSelect: React.FC<IProps> = ({
  value,
  items,
  markTitle,
  onSelect,
  isMonthSelect = false,
}) => (
  <div className={b('wrapper', { 'is-month': isMonthSelect})}>
    {
      items.map((x) => {
        const selected = x.value === value;
        const className = classNames({
          [b('item', { 'is-month': isMonthSelect})]: true,
          [b('item-disabled')]: !x.active || x.disabled,
          [b('item-selected', { 'is-month': isMonthSelect })]: selected,
        });

        const tabSelected = classNames({
          [b('tab-selected')]: selected,
        });

        const period = classNames({
          [b('selected-period')]: selected,
          [b('period')]: !selected,
        });

        const border = classNames({
          [b('selected-border')]: selected && x.barrier,
          [b('border')]: !selected,
          [b('border-past')]: x.active && !selected,
        });

        const barrier = classNames({
          [b('barrier-selected')]: selected,
        });

        return (
          <button
            key={`${x.value}_tab`}
            className={className}
            onClick={() => onSelect(x.value)}
          >
            <div className={tabSelected}>
              <div>{x.label}</div>
              <div className={period}>
                {x.marked ? markTitle : ''}
              </div>
              <div className={border} />
              {selected
                ? (
                  <div className={barrier}>
                    <div className={b('barrier-percent')}>
                      {Boolean(x.barrier) && `${x.barrier}%`}
                    </div>
                    <div>{Boolean(x.barrier) && LANG_DICTIONARY.BARRIER}</div>
                  </div>
                )
                : (
                  <div className={b('barrier')}>
                    {Boolean(x.barrier) && `${LANG_DICTIONARY.BARRIER} ${x.barrier} %`}
                  </div>
                )}
            </div>
          </button>
        );
      })
    }
  </div>
);

export default TabSelect;
