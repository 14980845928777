import {
  SET_IS_PAYMENT_END,
  GET_USER_CONTRACT,
  SET_CHECKBOX_VALUE,
  SET_PASSED_FIRST_FORM,
  TYPE_COMMENT,
  CLEAR_STATE,
  CHANGE_FIELD,
  SEND_APPLICATION,
  SAVE_FORM_DATA,
  DELETE_IMAGE,
  SEND_REQ_DADATA,
  SET_FOUND_BANK,
  SET_PIC_ERR,
  SEND_REQ_FOR_SMS_CODE,
  CHECK_CODE_IS_VALID,
  GET_USER_BANKS_INN,
} from '../types';
import { IBankData, IGetSmsCode } from '../reducers/interface';

const setIsPaymentEnd = (isPaymentEnd: boolean) => ({
  payload: isPaymentEnd,
  type: SET_IS_PAYMENT_END,
});

const getUserContractIsz = (id: number) => ({
  payload: id,
  type: GET_USER_CONTRACT.request,
});

const setCheckboxValue = (value: boolean, name: string) => ({
  payload: { value, name },
  type: SET_CHECKBOX_VALUE,
});

const setPassedFirstForm = (value: boolean) => ({
  payload: value,
  type: SET_PASSED_FIRST_FORM,
});

const typeComment = (value: string) => ({
  payload: value,
  type: TYPE_COMMENT,
});

const clearState = () => ({
  type: CLEAR_STATE,
});

const changeField = (value: string, name: string) => ({
  payload: { value, name },
  type: CHANGE_FIELD,
});

const sendApplication = (
  payload: {
    userId: number,
    formData: FormData,
  },
) => ({
  payload,
  type: SEND_APPLICATION.request,
});

const saveFormData = (name: string, file: File) => ({
  payload: { name, file },
  type: SAVE_FORM_DATA,
});

const deleteImage = (name: string) => ({
  payload: { name },
  type: DELETE_IMAGE,
});

const sendReqDaData = (value: string) => ({
  bic: value,
  type: SEND_REQ_DADATA.request,
});

const setFoundBank = (data: IBankData) => ({
  payload: data,
  type: SET_FOUND_BANK,
});

const setPicErr = (name: string, value: boolean) => ({
  payload: { name, value },
  type: SET_PIC_ERR,
});

const sendReqForSmsCode = (body: IGetSmsCode) => ({
  payload: body,
  type: SEND_REQ_FOR_SMS_CODE.request,
});

const checkCodeIsValid = (
  code: string, phone: string, userId: number, selectedTypeId: number, contractNumFull: string,
) => ({
  payload: {
    code, phone, userId, selectedTypeId, contractNumFull,
  },
  type: CHECK_CODE_IS_VALID.request,
});

const getBankInn = () => ({
  type: GET_USER_BANKS_INN.request,
});

export {
  setIsPaymentEnd,
  getUserContractIsz,
  setCheckboxValue,
  setPassedFirstForm,
  typeComment,
  clearState,
  changeField,
  sendApplication,
  saveFormData,
  deleteImage,
  sendReqDaData,
  setFoundBank,
  setPicErr,
  sendReqForSmsCode,
  checkCodeIsValid,
  getBankInn,
};
