import { adminSignin } from 'redux/rootActions';
import { LANG_DICTIONARY } from 'consts';
import * as yup from 'yup';

interface IPropsSubmit {
  props: {
    signIn: typeof adminSignin,
    captchaId: string,
  }
}

const { FIELD_IS_REQUIRED } = LANG_DICTIONARY;

export interface FormikValues {
  login: string;
  password: string;
  captchaKey: string;
}

const schema = yup.object().shape({
  login: yup.string().required(FIELD_IS_REQUIRED),
  password: yup.string().required(FIELD_IS_REQUIRED),
  captchaKey: yup.string(),
});

const formSettings = {
  mapPropsToValues: () => ({
    password: '',
    login: '',
    captchaKey: '',
  }),
  validationSchema: schema,
  handleSubmit: (values: FormikValues, { props }: IPropsSubmit) => {
    const { signIn, captchaId } = props;
    const { login, password, captchaKey } = values;
    signIn(login, password, captchaId, captchaKey);
  },
};

export { formSettings };
