import {block} from 'bem-cn';
import React from 'react';
import './styles.scss';
import classNames from 'classnames';
import GreenButton from '../GreenButton';

const b = block('green-button');

interface IProps {
    onClick: () => void,
    text: string,
    disabled?: boolean,
    hasBorder?: boolean,
    type?: 'submit' | 'button',
}

const WhiteButton: React.FC<IProps> = (props) => {
  const {
    hasBorder,
  } = props;

  return (
    <GreenButton
      {...props}
      className={classNames({
        whiteButton: true,
        hasBorder,
      })}
    />
  );
};

export default WhiteButton;
