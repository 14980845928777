import { REGEXP, LANG_DICTIONARY } from 'consts';
import { object, string } from 'yup';
import { MAX_LENGTH_INPUT } from 'config';

const { PHONEMOBILE, PHONE_HOME } = REGEXP;
const { EMAIL, PHONE_MOBILE } = LANG_DICTIONARY;

const checkLogin = (check: string) => {
  switch (check) {
    case EMAIL:
      return {
        login: string().email().max(MAX_LENGTH_INPUT),
      };
    case PHONE_MOBILE:
      return {
        login: string().matches(PHONEMOBILE).max(MAX_LENGTH_INPUT),
      };
    default:
      return {
        login: string().matches(PHONE_HOME).max(MAX_LENGTH_INPUT),
      };
  }
};

const schema = (check: string) => object().shape(checkLogin(check));

export default schema;
