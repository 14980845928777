import { block } from 'bem-cn';
import { HeaderImage, PersonalInfo } from 'components';
import React from 'react';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { IRedux } from 'redux/interface';
import { IMainDataReducer } from 'redux/mainData/reducers/interfaces';
import {
  changeContact,
  changeContactPhone,
  contactVerify,
  changeFieldSupport,
  changePassword,
  deletePersonalContact,
  getAgreement,
  getAvatarUser,
  getInfoContacts,
  getInfoPassowrd,
  getMainData,
  getPersonalData,
  openPasswordPopUp,
  sendSupportMessage,
  setAvatarUser,
  getDescriptions,
  changeContactEmail,
  getCurrentYear,
  clearCodeType,
} from 'redux/rootActions';

const b = block('main-page');

type IMapStateToPropsTypes = (state: IRedux) => IMainDataReducer;

interface IMapDispatchToPropsTypes {
  getAvatar: typeof getAvatarUser;
  getUserData: typeof getMainData;
  setAvatar: typeof setAvatarUser;
  contactChange: typeof changeContact;
  removeCodeType: typeof clearCodeType;
  changePhone: typeof changeContactPhone;
  changeEmail: typeof changeContactEmail;
  contactVerifyPhone: typeof contactVerify;
  passwordChange: typeof changePassword;
  getContactInfo: typeof getInfoContacts;
  getPasswordInfo: typeof getInfoPassowrd;
  getDataPersonal: typeof getPersonalData;
  deleteContact: typeof deletePersonalContact;
  openPopUpPassword: typeof openPasswordPopUp;
  changeField: typeof changeFieldSupport;
  sendMessageSupport: typeof sendSupportMessage;
  getAgreementLink: typeof getAgreement;
  getClientDescriptions: typeof getDescriptions;
  getFooterYear: typeof getCurrentYear;
}

interface IProps {
  children: React.ReactNode,
}

type Props = IProps & IMapDispatchToPropsTypes & IMainDataReducer;

class MainPageContainer extends React.Component<Props> {
  public componentDidMount = () => {
    const {
      getUserData,
      getAvatar,
      getContactInfo,
      getPasswordInfo,
      getDataPersonal,
      getAgreementLink,
      getClientDescriptions,
      getFooterYear,
    } = this.props;
    getUserData();
    getAvatar();
    getContactInfo();
    getPasswordInfo();
    getDataPersonal();
    getAgreementLink();
    getClientDescriptions();
    getFooterYear();
  }

  public downloadFile = (e: any) => {
    const { target: { files }} = e;
    const { setAvatar, isAdmin } = this.props;
    if (isAdmin) {
      return null;
    }
    const data = new FormData();
    data.append('file', files[0]);
    setAvatar(data);
    return null;
  }

  public render() {
    const {
      firstName,
      lastName,
      middleName,
      mobile,
      email,
      bic,
      bankName,
      codeType,
      accountNumber,
      passwordChange,
      avatarLink,
      contactVerifyPhone,
      contactChange,
      changePhone,
      changeEmail,
      infoContact,
      infoPassword,
      adrFactFullText,
      adrRegFullText,
      birthDate,
      birthPlaceFull,
      contactsEmails,
      contactsPhones,
      docAuthCode,
      docCitizenship,
      docDateIssue,
      docNumber,
      docSerial,
      fullName,
      infoPersonalData,
      deleteContact,
      passwordErrorMessage,
      openPopUpPassword,
      passwordChangeOpen,
      contactChangeOpen,
      contactErrorMessage,
      removeCodeType,
      changeField,
      docAuth,
      isAdmin,
      children,
      isAccepted,
    } = this.props;

    return (
      <div className={b()}>
        <MetaTags>
          <meta name="description" content="личный кабинет БКС Страхование жизни." />
          <meta name="keywords" content="личный кабинет,БКС,страхование жизни,страхователь,вход,авторизация,договор страхования,ИСЖ,НСЖ,НС" />
        </MetaTags>
        <HeaderImage
          name={firstName}
        />
        <PersonalInfo
          isAdmin={isAdmin}
          changeEmail={changeEmail}
          removeCodeType={removeCodeType}
          changeField={changeField}
          contactChangeOpen={contactChangeOpen}
          changePhone={changePhone}
          contactErrorMessage={contactErrorMessage}
          passwordChangeOpen={passwordChangeOpen}
          openPopUpPassword={openPopUpPassword}
          passwordErrorMessage={passwordErrorMessage}
          infoContact={infoContact}
          infoPassword={infoPassword}
          avatarLink={avatarLink}
          firstName={firstName}
          lastName={lastName}
          middleName={middleName}
          email={email}
          phoneNumber={mobile}
          bic={bic}
          rs={accountNumber}
          organization={bankName}
          setAvatar={this.downloadFile}
          changePassword={passwordChange}
          changeContact={contactChange}
          codeType={codeType}
          contactVerify={contactVerifyPhone}
          adrFactFullText={adrFactFullText}
          adrRegFullText={adrRegFullText}
          birthDate={birthDate}
          birthPlaceFull={birthPlaceFull}
          contactsEmails={contactsEmails}
          contactsPhones={contactsPhones}
          docAuthCode={docAuthCode}
          docAuth={docAuth}
          docCitizenship={docCitizenship}
          docDateIssue={docDateIssue}
          docNumber={docNumber}
          docSerial={docSerial}
          fullName={fullName}
          infoPersonalData={infoPersonalData}
          deleteContact={deleteContact}
          isAcceptedAgreement={isAccepted}
        />
        {
          children
        }
      </div>
    );
  }
}

const mapStateToProps: IMapStateToPropsTypes = ({
  mainDataReducer,
}) => ({
  ...mainDataReducer,
});

const mapDispatchToProps: IMapDispatchToPropsTypes = {
  contactChange: changeContact,
  changePhone: changeContactPhone,
  contactVerifyPhone: contactVerify,
  removeCodeType: clearCodeType,
  changeEmail: changeContactEmail,
  getAvatar: getAvatarUser,
  getContactInfo: getInfoContacts,
  getDataPersonal: getPersonalData,
  getPasswordInfo: getInfoPassowrd,
  getUserData: getMainData,
  passwordChange: changePassword,
  setAvatar: setAvatarUser,
  deleteContact: deletePersonalContact,
  openPopUpPassword: openPasswordPopUp,
  changeField: changeFieldSupport,
  sendMessageSupport: sendSupportMessage,
  getAgreementLink: getAgreement,
  getClientDescriptions: getDescriptions,
  getFooterYear: getCurrentYear,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainPageContainer);
