import { generateContacts, simpleDate } from 'helpers';
import { DATE_FORMAT } from 'consts';
import {
  GET_AVATAR_USER_SUCCESS,
  GET_CONTACTS_INFO_SUCCESS,
  GET_MAIN_DATA_USER_SUCCESS,
  GET_PASSWORD_INFO_SUCCESS,
  GET_PERSONAL_DATA_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  OPEN_PASSWORD_POPUP,
  CHANGE_CONTACTS_FAIL,
  CHANGE_CONTACTS_SUCCESS,
  GET_AGREEMENT_SUCCESS,
  CHANGE_FIELD_MAINDATA,
  GET_CURRENT_YEAR,
  CONTACTS_VERIFY_SUCCESS,
  CONTACTS_VERIFY_FAIL,
  CHANGE_CONTACTS_PHONE_SUCCESS,
  CHANGE_CONTACTS_EMAIL_SUCCESS,
  CLEAR_CODE_TYPE,
  CHANGE_CONTACTS_EMAIL_FAIL,
  SET_IS_ACCEPTED_AGREEMENT,
} from '../types';

const INITIAL_STATE = {
  accountNumber: '',
  adrFactFullText: '',
  adrRegFullText: '',
  avatarLink: '',
  bankName: '',
  bic: '',
  birthDate: '',
  birthPlaceFull: '',
  contactsEmails: '',
  contactsPhones: '',
  ctgtsId: 0,
  docAuthCode: '',
  docAuth: '',
  docCitizenship: '',
  docDateIssue: '',
  docNumber: '',
  docSerial: '',
  codeType: '',
  email: '',
  firstName: '',
  fullName: '',
  infoContact: '',
  infoPassword: '',
  infoPersonalData: '',
  lastName: '',
  middleName: '',
  mobile: '',
  sex: '',
  usersCtgtsId: 0,
  passwordErrorMessage: '',
  passwordChangeOpen: false,
  contactChangeOpen: false,
  contactErrorMessage: '',
  emailForSupport: '',
  acceptAgreement: true,
  agreementLink: '',
  messageForSupport: '',
  currentYear: '',
  isAccepted: true,
};

export default (state = INITIAL_STATE, { type, payload }: {type: string, payload: any}) => {
  switch (type) {
    case GET_CURRENT_YEAR.success: {
      return {
        ...state,
        currentYear: payload,
      };
    }

    case CHANGE_FIELD_MAINDATA: {
      const { name, value } = payload;
      return {
        ...state,
        [name]: value,
      };
    }

    case GET_AGREEMENT_SUCCESS: {
      return {
        ...state,
        agreementLink: payload,
      };
    }

    case CHANGE_CONTACTS_PHONE_SUCCESS: {
      return {
        ...state,
        contactChangeOpen: false,
        contactErrorMessage: '',
      };
    }

    case CHANGE_CONTACTS_EMAIL_SUCCESS: {
      return {
        ...state,
        contactChangeOpen: false,
        contactErrorMessage: '',
      };
    }

    case CHANGE_CONTACTS_SUCCESS: {
      return {
        ...state,
        contactChangeOpen: false,
        contactErrorMessage: '',
      };
    }

    case CONTACTS_VERIFY_SUCCESS: {
      const { message, codeType } = payload;
      return {
        ...state,
        contactErrorMessage: message,
        codeType,
      };
    }

    case CLEAR_CODE_TYPE: {
      return {
        ...state,
        codeType: '',
        contactErrorMessage: '',
      };
    }

    case CONTACTS_VERIFY_FAIL: {
      return {
        ...state,
        contactErrorMessage: payload,
      };
    }

    case CHANGE_CONTACTS_EMAIL_FAIL: {
      return {
        ...state,
        contactErrorMessage: payload,
      };
    }

    case OPEN_PASSWORD_POPUP: {
      const { passwordChangeOpen, contactChangeOpen } = state;
      if (payload === 'password') {
        return {
          ...state,
          passwordChangeOpen: !passwordChangeOpen,
        };
      }
      return {
        ...state,
        contactChangeOpen: !contactChangeOpen,
      };
    }

    case CHANGE_CONTACTS_FAIL: {
      return {
        ...state,
        contactErrorMessage: payload,
      };
    }

    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        passwordErrorMessage: '',
        passwordChangeOpen: false,
      };
    }

    case CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        passwordErrorMessage: payload,
      };
    }

    case GET_PERSONAL_DATA_SUCCESS: {
      const {
        lastName,
        firstName,
        middleName,
        docSerial,
        docNumber,
        docDateIssue,
        docAuth,
        docAuthCode,
        docCitizenship,
        birthDate,
        birthPlaceFull,
        adrRegFullText,
        adrFactFullText,
        infoMessage,
        contactsData,
      } = payload;
      const newContacts = generateContacts(contactsData);
      const { emails, phones} = newContacts;
      return {
        ...state,
        adrFactFullText,
        adrRegFullText,
        birthDate: birthDate ? simpleDate(birthDate, DATE_FORMAT.DATE) : '',
        birthPlaceFull,
        contactsEmails: emails,
        contactsPhones: phones,
        docAuth,
        docAuthCode,
        docCitizenship,
        docDateIssue,
        docNumber,
        docSerial,
        fullName: `${lastName} ${firstName} ${middleName}`,
        infoPersonalData: infoMessage,
      };
    }

    case GET_PASSWORD_INFO_SUCCESS: {
      return {
        ...state,
        infoPassword: payload.message,
      };
    }

    case GET_CONTACTS_INFO_SUCCESS: {
      return {
        ...state,
        infoContact: payload.message,
      };
    }

    case GET_MAIN_DATA_USER_SUCCESS: {
      return {
        ...state,
        accountNumber: payload.accountNumber,
        bankName: payload.bankName,
        bic: payload.bic,
        ctgtsId: payload.ctgtsId,
        email: payload.email,
        emailForSupport: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        middleName: payload.middleName,
        mobile: payload.mobile,
        sex: payload.sex,
        usersCtgtsId: payload.usersCtgtsId,
        isAccepted: payload.isAccepted,
      };
    }

    case GET_AVATAR_USER_SUCCESS: {
      return {
        ...state,
        avatarLink: payload,
      };
    }

    case SET_IS_ACCEPTED_AGREEMENT: {
      return {
        ...state,
        isAccepted: payload,
      };
    }

    default:
      return state;
  }
};
