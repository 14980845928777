import { TOKEN_TYPE } from 'consts';
import { IAdminAuthorization } from './interfaces';
import {
  CHECK_ADMIN,
  GET_CAPTCHA_ADMIN,
  SIGN_IN,
  SIGN_OUT,
} from '../types';

const INITIAL_STATE: IAdminAuthorization = {
  errorMessage: '',
  token: '',
  fullName: '',
  login: '',
  actions: [],
  id: 0,
  captcha: '',
  captchaId: '',
};

interface IPayloadAction {
  type: string,
  payload: any,
}

export default (state = INITIAL_STATE, { type, payload }: IPayloadAction) => {
  switch (type) {
    case GET_CAPTCHA_ADMIN.success: {
      const { captcha, captchaId } = payload;

      return {
        ...state,
        captcha,
        captchaId,
      };
    }

    case CHECK_ADMIN.success: {
      return {
        ...state,
        ...payload,
        errorMessage: '',
      };
    }

    case SIGN_IN.success: {
      localStorage.setItem(TOKEN_TYPE.admin, payload.token);

      return {
        ...state,
        ...payload,
      };
    }

    case SIGN_IN.fail: {
      localStorage.removeItem(TOKEN_TYPE.admin);

      return {
        ...state,
        errorMessage: payload,
      };
    }

    case SIGN_OUT: {
      localStorage.removeItem(TOKEN_TYPE.admin);

      return INITIAL_STATE;
    }

    default:
      return state;
  }
};
