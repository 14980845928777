import { API, DATE_FORMAT, LANG_DICTIONARY } from 'consts';
import {
  apiCall,
  generateFio,
  simpleDate,
  getError,
} from 'helpers';
import { put, takeLatest } from 'redux-saga/effects';
import { getUserInfo as updateUserCard } from 'redux/rootActions';
import { ITEM_COUNT_ADMIN_USER_CARD } from 'config';
import { toastr } from 'react-redux-toastr';
import {
  GET_USER_INFO,
  GET_CONTACTS_CODES,
  GET_CONTR_AGENTS_LIST,
  GET_CONTR_AGENT_CARD,
  UPDATE_CONTR_AGENTS_LIST,
  GET_CONTRACT_CARD,
  GET_CONTRACTS_LIST,
  UPDATE_CONTRACTS_LIST,
  SAVE_CHANGES,
} from '../types';

const { SAVED_SUCCESS } = LANG_DICTIONARY;

function* saveChanges({payload}: { payload: { body: any, userId: string } }) {
  try {
    yield put({ type: SAVE_CHANGES.start });
    const { userId } = payload;
    yield apiCall({
      type: 'PUT',
      body: payload.body,
      url: API.SAVE_CHANGES(payload.userId),
      isAdminToken: true,
    });
    yield put({ type: SAVE_CHANGES.success });
    yield put(updateUserCard(userId));
    toastr.success('', SAVED_SUCCESS);
  } catch (e) {
    yield put({ type: SAVE_CHANGES.fail, payload: getError(e, true) });
  }
}

function* getContractCard({payload}: { payload: {userId: string, ctsId: number } }) {
  try {
    const { userId, ctsId } = payload;
    yield put({ type: GET_CONTRACT_CARD.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_USER_CONTRACT_INFO(userId, ctsId),
      isAdminToken: true,
    });
    yield put({ type: GET_CONTRACT_CARD.success, payload: { data, ctsId } });
  } catch (e) {
    yield put({ type: GET_CONTRACT_CARD.fail, payload: getError(e, true) });
  }
}

function* getContractsList({payload}: { payload: {
  page: number,
  userId: string,
  filter: string,
  ctgtId: number,
}}) {
  try {
    const {
      page,
      userId,
      filter,
      ctgtId,
    } = payload;
    yield put({ type: GET_CONTRACTS_LIST.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CONTRACTS_LIST(userId, ctgtId),
      isAdminToken: true,
      query: {
        offset: ITEM_COUNT_ADMIN_USER_CARD * (page - 1),
        limit: ITEM_COUNT_ADMIN_USER_CARD,
        filter: filter.length ? filter : undefined,
      },
    });
    const { cts } = data;
    const rebuildData = cts.map((item: any) => ({
      text: item.numFull,
      key: item.ctsId,
      value: {...item},
    }));
    yield put({ type: GET_CONTRACTS_LIST.success, payload: { data, rebuildData } });
  } catch (e) {
    yield put({ type: GET_CONTRACTS_LIST.fail });
  }
}

function* updateContractsList({payload}: { payload: {
  page: number,
  userId: string,
  filter: string,
  ctgtId: number,
}}) {
  try {
    const {
      page,
      userId,
      filter,
      ctgtId,
    } = payload;
    yield put({ type: UPDATE_CONTRACTS_LIST.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CONTRACTS_LIST(userId, ctgtId),
      isAdminToken: true,
      query: {
        offset: ITEM_COUNT_ADMIN_USER_CARD * (page - 1),
        limit: ITEM_COUNT_ADMIN_USER_CARD,
        filter: filter.length ? filter : undefined,
      },
    });
    const { cts } = data;
    const rebuildData = cts.map((item: any) => ({
      text: item.numFull,
      key: item.ctsId,
      value: {...item},
    }));
    yield put({ type: UPDATE_CONTRACTS_LIST.success, payload: { data, rebuildData } });
  } catch (e) {
    yield put({ type: UPDATE_CONTRACTS_LIST.fail });
  }
}

function* updateContrAgents(
  {payload}: { payload: { currentpage: number, filter: string, id: number } },
) {
  try {
    const { currentpage, filter, id} = payload;
    yield put({ type: UPDATE_CONTR_AGENTS_LIST.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CONTR_AGENTS(id),
      isAdminToken: true,
      query: {
        offset: ITEM_COUNT_ADMIN_USER_CARD * (currentpage - 1),
        limit: ITEM_COUNT_ADMIN_USER_CARD,
        filter: filter.length ? filter : undefined,
      },
    });
    const { ctgts } = data;
    const rebuildData = ctgts.map((item: any) => ({
      text: `${generateFio(item.lastName, item.firstName, item.middleName)} ${simpleDate(item.birthDate, DATE_FORMAT.DATE)}`,
      key: item.ctgtsId,
      value: {...item},
    }));
    yield put({ type: UPDATE_CONTR_AGENTS_LIST.success, payload: { rebuildData, data }});
  } catch (e) {
    yield put({ type: UPDATE_CONTR_AGENTS_LIST.fail });
  }
}

function* getContrAgentCard({payload}: { payload: {userId: string, ctgtId: number } }) {
  try {
    const { userId, ctgtId } = payload;
    yield put({ type: GET_CONTR_AGENT_CARD.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_USER_CONTR_AGENT_INFO(userId, ctgtId),
      isAdminToken: true,
    });
    yield put({ type: GET_CONTR_AGENT_CARD.success, payload: {data, ctgtId} });
  } catch (e) {
    yield put({ type: GET_CONTR_AGENT_CARD.fail, payload: getError(e, true) });
  }
}

function* getContrAgents({payload}: {payload: {
  currentpage: number,
    filter: string,
    id: number
}}) {
  try {
    const { currentpage, filter, id} = payload;
    yield put({ type: GET_CONTR_AGENTS_LIST.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CONTR_AGENTS(id),
      isAdminToken: true,
      query: {
        offset: ITEM_COUNT_ADMIN_USER_CARD * (currentpage - 1),
        limit: ITEM_COUNT_ADMIN_USER_CARD,
        filter: filter.length ? filter : undefined,
      },
    });
    const { ctgts } = data;
    const rebuildData = ctgts.map((item: any) => ({
      text: `${generateFio(item.lastName, item.firstName, item.middleName)} ${simpleDate(item.birthDate, DATE_FORMAT.DATE)}`,
      key: item.ctgtsId,
      value: {...item},
    }));
    yield put({ type: GET_CONTR_AGENTS_LIST.success, payload: { rebuildData, data }});
  } catch (e) {
    yield put({ type: GET_CONTR_AGENTS_LIST.fail });
  }
}

function* getCodes() {
  try {
    yield put({ type: GET_CONTACTS_CODES.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CONTACT_CODES,
      isAdminToken: true,
    });
    const rebuildData = data.map((item: any) => ({
      text: item.name,
      key: item.code,
      value: {code: item.code, name: item.name},
    }));
    yield put({ type: GET_CONTACTS_CODES.success, payload: rebuildData });
  } catch (e) {
    yield put({ type: GET_CONTACTS_CODES.fail });
  }
}

function* getUserInfo({payload}: { payload: number | string }) {
  try {
    yield put({ type: GET_USER_INFO.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_USER_PERSONAL_CARD(payload),
      isAdminToken: true,
    });
    yield put({ type: GET_USER_INFO.success, payload: data });
  } catch (e) {
    yield put({ type: GET_USER_INFO.fail });
  }
}

export default function* adminUserCardSaga() {
  yield takeLatest<string, any>(SAVE_CHANGES.request, saveChanges);
  yield takeLatest<string, any>(GET_CONTRACT_CARD.request, getContractCard);
  yield takeLatest<string, any>(GET_CONTRACTS_LIST.request, getContractsList);
  yield takeLatest<string, any>(UPDATE_CONTRACTS_LIST.request, updateContractsList);
  yield takeLatest<string, any>(GET_CONTR_AGENTS_LIST.request, getContrAgents);
  yield takeLatest<string, any>(UPDATE_CONTR_AGENTS_LIST.request, updateContrAgents);
  yield takeLatest<string, any>(GET_CONTR_AGENT_CARD.request, getContrAgentCard);
  yield takeLatest<string, any>(GET_USER_INFO.request, getUserInfo);
  yield takeLatest<string, any>(GET_CONTACTS_CODES.request, getCodes);
}
