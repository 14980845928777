const DATE_FORMAT = {
  DATE: 'DD.MM.YYYY',
  DATE_PICKER_DATE: 'dd.MM.yyyy',
  SYSTEM_DATE_TO: 'YYYY-MM-DD',
  ADMIN_FOOTER_TIME_FORMAT: 'HH:mm',
  DATE_WITH_HOURS: 'DD.MM.YYYY HH:MM',
  DATE_WITH_HOURS_MINUTES_SECOND: 'DD.MM.YYYY HH:mm:ss',
};

export default DATE_FORMAT;
