import {ActionFactory} from 'helpers';

const factory = new ActionFactory('mainData');

const GET_MAIN_DATA_USER_REQUEST = 'mainData/GET_MAIN_DATA_USER_REQUEST';
const GET_MAIN_DATA_USER_SUCCESS = 'mainData/GET_MAIN_DATA_USER_SUCCESS';
const GET_MAIN_DATA_USER_START = 'mainData/GET_MAIN_DATA_USER_START';
const GET_MAIN_DATA_USER_FAIL = 'mainData/GET_MAIN_DATA_USER_FAIL';

const GET_AVATAR_USER_REQUEST = 'mainData/GET_AVATAR_USER_REQUEST';
const GET_AVATAR_USER_SUCCESS = 'mainData/GET_AVATAR_USER_SUCCESS';
const GET_AVATAR_USER_START = 'mainData/GET_AVATAR_USER_START';
const GET_AVATAR_USER_FAIL = 'mainData/GET_AVATAR_USER_FAIL';

const SET_AVATAR_USER_REQUEST = 'mainData/SET_AVATAR_USER_REQUEST';
const SET_AVATAR_USER_SUCCESS = 'mainData/SET_AVATAR_USER_SUCCESS';
const SET_AVATAR_USER_START = 'mainData/SET_AVATAR_USER_START';
const SET_AVATAR_USER_FAIL = 'mainData/SET_AVATAR_USER_FAIL';

const GET_PASSWORD_INFO_REQUEST = 'mainData/GET_PASSWORD_INFO_REQUEST';
const GET_PASSWORD_INFO_START = 'mainData/GET_PASSWORD_INFO_START';
const GET_PASSWORD_INFO_FAIL = 'mainData/GET_PASSWORD_INFO_FAIL';
const GET_PASSWORD_INFO_SUCCESS = 'mainData/GET_PASSWORD_INFO_SUCCESS';

const CHANGE_PASSWORD_REQUEST = 'mainData/CHANGE_PASSWORD_REQUEST';
const CHANGE_PASSWORD_START = 'mainData/CHANGE_PASSWORD_START';
const CHANGE_PASSWORD_FAIL = 'mainData/CHANGE_PASSWORD_FAIL';
const CHANGE_PASSWORD_SUCCESS = 'mainData/CHANGE_PASSWORD_SUCCESS';

const GET_CONTACTS_INFO_REQUEST = 'mainData/GET_CONTACTS_INFO_REQUEST';
const GET_CONTACTS_INFO_START = 'mainData/GET_CONTACTS_INFO_START';
const GET_CONTACTS_INFO_FAIL = 'mainData/GET_CONTACTS_INFO_FAIL';
const GET_CONTACTS_INFO_SUCCESS = 'mainData/GET_CONTACTS_INFO_SUCCESS';

const CHANGE_CONTACTS_PHONE_REQUEST = 'mainData/CHANGE_CONTACTS_PHONE_REQUEST';
const CHANGE_CONTACTS_PHONE_START = 'mainData/CHANGE_CONTACTS_PHONE_START';
const CHANGE_CONTACTS_PHONE_FAIL = 'mainData/CHANGE_CONTACTS_PHONE_FAIL';
const CHANGE_CONTACTS_PHONE_SUCCESS = 'mainData/CHANGE_CONTACTS_PHONE_SUCCESS';

const CHANGE_CONTACTS_EMAIL_REQUEST = 'mainData/CHANGE_CONTACTS_EMAIL_REQUEST';
const CHANGE_CONTACTS_EMAIL_START = 'mainData/CHANGE_CONTACTS_EMAIL_START';
const CHANGE_CONTACTS_EMAIL_FAIL = 'mainData/CHANGE_CONTACTS_EMAIL_FAIL';
const CHANGE_CONTACTS_EMAIL_SUCCESS = 'mainData/CHANGE_CONTACTS_EMAIL_SUCCESS';


const CHANGE_CONTACTS_REQUEST = 'mainData/CHANGE_CONTACTS_REQUEST';
const CHANGE_CONTACTS_START = 'mainData/CHANGE_CONTACTS_START';
const CHANGE_CONTACTS_FAIL = 'mainData/CHANGE_CONTACTS_FAIL';
const CHANGE_CONTACTS_SUCCESS = 'mainData/CHANGE_CONTACTS_SUCCESS';

const CONTACTS_VERIFY_REQUEST = 'mainData/CONTACTS_VERIFY_REQUEST';
const CONTACTS_VERIFY_START = 'mainData/CONTACTS_VERIFY_START';
const CONTACTS_VERIFY_FAIL = 'mainData/CONTACTS_VERIFY_FAIL';
const CONTACTS_VERIFY_SUCCESS = 'mainData/CONTACTS_VERIFY_SUCCESS';

const CLEAR_CODE_TYPE = 'mainData/CLEAR_CODE_TYPE';

const GET_PERSONAL_DATA_FAIL = 'mainData/GET_PERSONAL_DATA_FAIL';
const GET_PERSONAL_DATA_START = 'mainData/GET_PERSONAL_DATA_START';
const GET_PERSONAL_DATA_SUCCESS = 'mainData/GET_PERSONAL_DATA_SUCCESS';
const GET_PERSONAL_DATA_REQUEST = 'mainData/GET_PERSONAL_DATA_REQUEST';

const DELETE_PERSONAL_CONTACT_REQUEST = 'mainData/DELETE_PERSONAL_CONTACT_REQUEST';
const DELETE_PERSONAL_CONTACT_START = 'mainData/DELETE_PERSONAL_CONTACT_START';
const DELETE_PERSONAL_CONTACT_FAIL = 'mainData/DELETE_PERSONAL_CONTACT_FAIL';
const DELETE_PERSONAL_CONTACT_SUCCESS = 'mainData/DELETE_PERSONAL_CONTACT_SUCCESS';

const OPEN_PASSWORD_POPUP = 'mainData/OPEN_PASSWORD_POPUP';

const GET_AGREEMENT_REQUEST = 'mainData/GET_AGREEMENT_REQUEST';
const GET_AGREEMENT_START = 'mainData/GET_AGREEMENT_START';
const GET_AGREEMENT_SUCCESS = 'mainData/GET_AGREEMENT_SUCCESS';
const GET_AGREEMENT_FAIL = 'mainData/GET_AGREEMENT_FAIL';

const SEND_SUPPORT_MESSAGE_REQUEST = 'mainData/SEND_SUPPORT_MESSAGE_REQUEST';
const SEND_SUPPORT_MESSAGE_SUCCESS = 'mainData/SEND_SUPPORT_MESSAGE_SUCCESS';
const SEND_SUPPORT_MESSAGE_START = 'mainData/SEND_SUPPORT_MESSAGE_START';
const SEND_SUPPORT_MESSAGE_FAIL = 'mainData/SEND_SUPPORT_MESSAGE_FAIL';

const CHANGE_FIELD_MAINDATA = 'mainData/CHANGE_FIELD_MAINDATA';

export const GET_CURRENT_YEAR = factory.createAsync('GET_CURRENT_YEAR');

const SET_IS_ACCEPTED_AGREEMENT = 'mainData/SET_IS_ACCEPTED_AGREEMENT';

export {
  GET_AGREEMENT_FAIL,
  GET_AGREEMENT_REQUEST,
  GET_AGREEMENT_START,
  GET_AGREEMENT_SUCCESS,
  SEND_SUPPORT_MESSAGE_FAIL,
  SEND_SUPPORT_MESSAGE_REQUEST,
  SEND_SUPPORT_MESSAGE_START,
  SEND_SUPPORT_MESSAGE_SUCCESS,
  CHANGE_FIELD_MAINDATA,
  OPEN_PASSWORD_POPUP,
  DELETE_PERSONAL_CONTACT_FAIL,
  DELETE_PERSONAL_CONTACT_REQUEST,
  DELETE_PERSONAL_CONTACT_START,
  DELETE_PERSONAL_CONTACT_SUCCESS,
  GET_PERSONAL_DATA_REQUEST,
  GET_PERSONAL_DATA_SUCCESS,
  GET_PERSONAL_DATA_START,
  GET_PERSONAL_DATA_FAIL,
  CHANGE_CONTACTS_FAIL,
  CHANGE_CONTACTS_REQUEST,
  CHANGE_CONTACTS_START,
  CHANGE_CONTACTS_SUCCESS,
  GET_CONTACTS_INFO_FAIL,
  GET_CONTACTS_INFO_REQUEST,
  GET_CONTACTS_INFO_START,
  GET_CONTACTS_INFO_SUCCESS,
  CHANGE_CONTACTS_PHONE_REQUEST,
  CHANGE_CONTACTS_PHONE_START,
  CHANGE_CONTACTS_PHONE_FAIL,
  CHANGE_CONTACTS_PHONE_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  GET_PASSWORD_INFO_FAIL,
  GET_PASSWORD_INFO_REQUEST,
  GET_PASSWORD_INFO_START,
  GET_PASSWORD_INFO_SUCCESS,
  GET_AVATAR_USER_FAIL,
  GET_AVATAR_USER_REQUEST,
  GET_AVATAR_USER_START,
  GET_AVATAR_USER_SUCCESS,
  GET_MAIN_DATA_USER_FAIL,
  GET_MAIN_DATA_USER_REQUEST,
  GET_MAIN_DATA_USER_START,
  GET_MAIN_DATA_USER_SUCCESS,
  SET_AVATAR_USER_FAIL,
  SET_AVATAR_USER_REQUEST,
  SET_AVATAR_USER_START,
  SET_AVATAR_USER_SUCCESS,
  CONTACTS_VERIFY_FAIL,
  CONTACTS_VERIFY_REQUEST,
  CONTACTS_VERIFY_START,
  CONTACTS_VERIFY_SUCCESS,
  CHANGE_CONTACTS_EMAIL_REQUEST,
  CHANGE_CONTACTS_EMAIL_START,
  CHANGE_CONTACTS_EMAIL_FAIL,
  CHANGE_CONTACTS_EMAIL_SUCCESS,
  CLEAR_CODE_TYPE,
  SET_IS_ACCEPTED_AGREEMENT,
};
