import React from 'react';
import { block } from 'bem-cn';
import { ContractData, IPrintFormOptions } from 'redux/contracts/reducers/interface';
import {
  dateFormatter,
  rebuildCurrency,
  simpleDate,
  monthFormatter,
} from 'helpers';
import { DATE_FORMAT, LANG_DICTIONARY } from 'consts';
import contractSuccessStatusBlack from 'assets/images/contract-success-status-black.png';
import './styles.scss';
import DropdownButton from '../../../_buttons/DropdownButton';

const b = block('contract-card');
const {
  CTS_NUMBER,
  FROM,
  DATE,
  CONTRIBUTION_SUM,
  CURRENCY_TYPE_RUBLES_SHORT,
} = LANG_DICTIONARY;

interface IProps {
  contract: ContractData;
  onCardClick: () => void;
  isCardClicked: boolean;
  printFormsOptions: IPrintFormOptions[],
}

const ContractCard: React.FC<IProps> = ({
  contract,
  onCardClick,
  isCardClicked,
  printFormsOptions,
}) => {
  const {
    dateCreate,
    numFull,
    productName,
    ctsStatusName,
    totalIncome,
    contractTerm: {
      years,
      months,
    },
  } = contract;

  return (
    <div
      className={b({ active: isCardClicked })}
      onClick={onCardClick}
      role="button"
      tabIndex={0}
    >
      <div className={b('upper-block')}>
        <div className={b('year')}>
          { years ? dateFormatter(Number(years)) : <div />}
          { months
            ? <div className={!years ? b('big-month') : b('month')}>{monthFormatter(Number(months))}</div>
            : <div className={b('month')} />}
        </div>
        <div className={b('name-status-block')}>
          <div className={b('bold-text')}>{productName}</div>
          <span className={b('status-block')}>
            <img
              src={contractSuccessStatusBlack}
              className={b('img')}
              alt="contract-success-status"
            />
            <span className={b('status-name')}>{ctsStatusName}</span>
          </span>
        </div>
      </div>
      <div className={b('lower-block')}>
        <div>
          <span className={b('bold-text')}>
            {`${CTS_NUMBER}: `}
          </span>
          <span>{numFull}</span>
        </div>
        <div>
          <span className={b('bold-text')}>
            {`${DATE}: `}
          </span>
          <span>{`${FROM.toLowerCase()} `}</span>
          <span>{simpleDate(dateCreate, DATE_FORMAT.DATE)}</span>
        </div>
        <div>
          <span className={b('bold-text')}>
            {`${CONTRIBUTION_SUM}: `}
          </span>
          <span>
            {`${totalIncome ? rebuildCurrency(String(parseFloat(totalIncome))) : 0} ${CURRENCY_TYPE_RUBLES_SHORT}`}
          </span>
        </div>
        <div className={b('actions-wrapper')}>
          <DropdownButton contract={contract} actions={printFormsOptions} />
        </div>
      </div>
    </div>
  );
};

export default ContractCard;
