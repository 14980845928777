import './styles.scss';

import React from 'react';
import { TableGenerator } from 'components';
import { block } from 'bem-cn';

const b = block('reverse-white-table');

interface IProps {
  data: any[][],
  headers?: (string | number)[],
  className?: string,
}

const ReverseWhiteTable: React.FC<IProps> = ({
  data,
  headers = [],
  className = '',
}) => (
  <TableGenerator
    className={b.mix(className)}
    classNameTr={b('row')}
    classNameTd={b('td')}
    data={data}
    headers={headers}
  />
);

export default ReverseWhiteTable;
