import { block } from 'bem-cn';
import React from 'react';
import InputMask from 'react-input-mask';
import { EyeIconSvg, GreenButton } from 'components';
import './styles.scss';
import InfoPopUp from '../../_popups/InfoPopUp';
import InputButton from '../../../_shared/InputButton';

const b = block('form-input-with-button');

interface IProps {
  placeholder?: string,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  buttonLabel: string,
  isButtonDisabled: boolean,
  onClick: () => void,
  tooltipText?: string,
  name: string,
  type?: string,
  mask?: string,
  maskChar?: string,
  className?: string,
  password?: boolean,
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onClicIcon?: () => void,
}

const PASSWORD_TYPE = 'password';

const FormInputWithButton: React.FC<IProps> = (
  {
    placeholder,
    value,
    onChange,
    buttonLabel,
    isButtonDisabled,
    onClick,
    tooltipText,
    name,
    type,
    mask,
    maskChar,
    className,
    password,
    onBlur,
    onClicIcon,
  },
) => {
  const hidePassword = type === PASSWORD_TYPE;

  return (
    <div className={`${b('wrapper')} ${className}`}>
      <InputMask
        className={b.mix(className)}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        type={type}
        mask={mask || ''}
        maskChar={maskChar}
        onBlur={onBlur}
      />
      {tooltipText && (
        <span className={b('tooltip')}>
          <InfoPopUp description={tooltipText} />
        </span>
      )}
      <InputButton
        className={b('button')}
        text={buttonLabel}
        onClick={onClick}
        disabled={isButtonDisabled}
      />
      {password && (
      <div
        className={b('icon')}
        onClick={onClicIcon}
        role="button"
        tabIndex={-1}
      >
        <EyeIconSvg hidePassword={hidePassword} />
      </div>
      )}
    </div>
  );
};

export default FormInputWithButton;
