import { block } from 'bem-cn';
import {
  FormInput, GreenButton, InfoBlock, PopupContainer,
} from 'components';
import { LANG_DICTIONARY } from 'consts';
import React from 'react';
import './styles.scss';

const {
  MIND_THE_PASSWORD_NEW,
  SAVE_NEW_PASSWORD,
  ENTER_CURRENT_PASSWORD,
  ENTER_NEW_PASSWORD,
  CONFIRM_NEW_PASSWORD,
  ACCEPT_WORD,
} = LANG_DICTIONARY;

const b = block('change-password-form');

interface IProps {
  password: string;
  passwordConfirm: string;
  sendData: () => void;
  info: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorPassword: string;
  popUpOpen: boolean;
  oldPassword: string;
  closePopUp: () => void;
  errorField: string;
  passwordErrorMessage: string;
  isResetPasswordForm?: boolean;
}

const disableButton = (
  firstCondition: string,
  secondCondition: string,
  thirdCondition: string,
) => Boolean(firstCondition.length)
    && Boolean(secondCondition.length)
    && Boolean(thirdCondition.length);

interface IState {
  currentTypeOldPassword: string;
  currentTypePassword: string;
  currentTypePasswordConfirm: string;
  typeOldPassword: string;
  typePassword: string;
  typePasswordConfirm: string;
  typeText: string;
}

class ChangePasswordForm extends React.Component <IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentTypeOldPassword: 'password',
      currentTypePassword: 'password',
      currentTypePasswordConfirm: 'password',
      typeOldPassword: 'oldPassword',
      typePassword: 'password',
      typePasswordConfirm: 'passwordConfirm',
      typeText: 'text',
    };
  }

  public passwordProtectControl = () => {
    const { typePassword, typeText, currentTypePassword } = this.state;
    if (currentTypePassword === typePassword) {
      this.setState({
        currentTypePassword: typeText,
      });
    } else {
      this.setState({
        currentTypePassword: typePassword,
      });
    }
  }

  public passwordConfirmProtectControl = () => {
    const { typePassword, typeText, currentTypePasswordConfirm } = this.state;
    if (currentTypePasswordConfirm === typePassword) {
      this.setState({
        currentTypePasswordConfirm: typeText,
      });
    } else {
      this.setState({
        currentTypePasswordConfirm: typePassword,
      });
    }
  }

  public passwordOldProtectControl = () => {
    const { typePassword, typeText, currentTypeOldPassword } = this.state;
    if (currentTypeOldPassword === typePassword) {
      this.setState({
        currentTypeOldPassword: typeText,
      });
    } else {
      this.setState({
        currentTypeOldPassword: typePassword,
      });
    }
  }

  public onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { handleChange } = this.props;
    handleChange(e);
  }

  public generateResetPasswordForm = () => {
    const {
      password,
      passwordConfirm,
      errorPassword,
      sendData,
      oldPassword,
      errorField,
      passwordErrorMessage,
    } = this.props;
    const {
      typePassword,
      typePasswordConfirm,
      currentTypePassword,
      currentTypePasswordConfirm,
    } = this.state;
    return (
      <div className={b()}>
        <div className={b('small-header')}>
          {ENTER_NEW_PASSWORD}
        </div>
        <div className={b('input-field-password', {error: errorField === 'password'})}>
          <FormInput
            value={password}
            onChange={this.onChange}
            name={typePassword}
            type={currentTypePassword}
            onClicIcon={this.passwordProtectControl}
            mask=""
            password
            className={b('password-wrapper')}
          />
        </div>
        <div className={b('small-header')}>
          {CONFIRM_NEW_PASSWORD}
        </div>
        <div className={b('input-field-password', {error: errorField === 'passwordConfirm'})}>
          <FormInput
            value={passwordConfirm}
            onChange={this.onChange}
            onClicIcon={this.passwordConfirmProtectControl}
            name={typePasswordConfirm}
            type={currentTypePasswordConfirm}
            mask=""
            password
            className={b('password-wrapper')}
          />
        </div>
        <div className={b('info-block')}>
          {(passwordErrorMessage || errorPassword) && (
            <div>
              <InfoBlock
                error
                message={passwordErrorMessage || errorPassword}
              />
            </div>
          )}
        </div>
        <div className={b('control-btn')}>
          <GreenButton
            text={ACCEPT_WORD}
            onClick={sendData}
            disabled={!disableButton(password, passwordConfirm, oldPassword)}
          />
        </div>
      </div>
    );
  }

  public generatePasswordsForm = () => {
    const {
      info,
      password,
      passwordConfirm,
      errorPassword,
      sendData,
      oldPassword,
      errorField,
      passwordErrorMessage,
    } = this.props;
    const {
      typeOldPassword,
      typePassword,
      typePasswordConfirm,
      currentTypePassword,
      currentTypePasswordConfirm,
      currentTypeOldPassword,
    } = this.state;

    return (
      <div className={b()}>
        <div className={b('header')}>
          {ENTER_CURRENT_PASSWORD}
        </div>
        <div className={b('input-field-password', {error: errorField === 'oldPassword'})}>
          <FormInput
            value={oldPassword}
            onChange={this.onChange}
            onClicIcon={this.passwordOldProtectControl}
            name={typeOldPassword}
            type={currentTypeOldPassword}
            mask=""
            password
            className={b('password-wrapper')}
          />
        </div>
        <div className={b('header-second')}>
          {MIND_THE_PASSWORD_NEW}
        </div>
        <div className={b('input-field-password', {error: errorField === 'password'})}>
          <FormInput
            value={password}
            onChange={this.onChange}
            name={typePassword}
            type={currentTypePassword}
            onClicIcon={this.passwordProtectControl}
            mask=""
            password
            className={b('password-wrapper')}
          />
        </div>
        <div className={b('input-field-password', {error: errorField === 'passwordConfirm'})}>
          <FormInput
            value={passwordConfirm}
            onChange={this.onChange}
            onClicIcon={this.passwordConfirmProtectControl}
            name={typePasswordConfirm}
            type={currentTypePasswordConfirm}
            mask=""
            password
            className={b('password-wrapper')}
          />
        </div>
        <div className={b('info-block')}>
          {(passwordErrorMessage || errorPassword) && (
            <div>
              <InfoBlock
                error
                message={passwordErrorMessage || errorPassword}
              />
            </div>
          )}
          <InfoBlock message={info} />
        </div>
        <div className={b('control-btn')}>
          <GreenButton
            text={SAVE_NEW_PASSWORD}
            onClick={sendData}
            disabled={!disableButton(password, passwordConfirm, oldPassword)}
          />
        </div>
      </div>
    );
  }

  public generateForm = () => {
    const { isResetPasswordForm } = this.props;
    if (isResetPasswordForm) {
      return this.generateResetPasswordForm();
    }

    return this.generatePasswordsForm();
  }

  public render() {
    const {
      popUpOpen,
      closePopUp,
    } = this.props;
    return (
      <PopupContainer
        isOpen={popUpOpen}
        close={closePopUp}
        isCloseButton
        children={this.generateForm()}
      />
    );
  }
}

export default ChangePasswordForm;
