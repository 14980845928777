import React from 'react';
import { Popup } from 'semantic-ui-react';
import block from 'bem-cn';
import './styles.scss';
import { LANG_DICTIONARY } from 'consts';

const b = block('attention-popup');

const AttentionPopUp: React.FC = () => (
  <Popup
    content={(
      <div className={b('popup')}>
        <p className={b('popup-attention-icon')}>
          !
        </p>
        <div className={b('popup-border')} />
        <div className={b('popup-text')}>
          <div>
            <p>{`${LANG_DICTIONARY.AUTOCALL_OCCURRED}:`}</p>
            <p>{LANG_DICTIONARY.STRATEGY_FINISHED}</p>
          </div>
        </div>
      </div>
    )}
    on="click"
    hideOnScroll
    trigger={(
      <div className={b('popup-trigger')}>
        <div className={b('popup-trigger-icon')}>
          <p className={b('popup-trigger-text')}>!</p>
        </div>
        {`${LANG_DICTIONARY.ATTENTION}! ${LANG_DICTIONARY.AUTOCALL_OCCURRED}`}
      </div>
    )}
  />
);

export default AttentionPopUp;
