import React from 'react';
import block from 'bem-cn';
import { LANG_DICTIONARY } from 'consts/dictionary';

const b = block('profitability');

interface IAutocallBarrier {
  autocallBarrier: number,
  bonusCoupon: number,
}

const AutocallBarrier = (props: IAutocallBarrier) => {
  const {
    autocallBarrier,
    bonusCoupon,
  } = props;

  return (
    <div className={b('barrier-block')}>
      <div className={b('barrier-block-content')}>
        <div className={b('barrier-block-left')}>
          <div className={b('barrier-block-left-autocall')}>
            <div className={b('barrier-block-left-header')}>{LANG_DICTIONARY.AUTOCALL_BARRIER}</div>
            <div className={b('barrier-bloc-left-percents')}>{`${autocallBarrier}%`}</div>
          </div>
          <div className={b('barrier-block-right')}>
            <div className={b('barrier-block-left-header')}>{LANG_DICTIONARY.BONUS_COUPON}</div>
            <div className={b('barrier-bloc-right-percents')}>{`${bonusCoupon}%`}</div>
          </div>
        </div>
        <div className={b('barrier-description')}>
          <div className={b('barrier-border')} />
          <div className={b('barrier-description-text')}>
            {LANG_DICTIONARY.EXCEEDING_BARRIER}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutocallBarrier;
