import { AuthForm } from 'components';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IAuthreducer } from 'redux/authorization/reducers/interfaces';
import { IRedux } from 'redux/interface';
import {
  clearAuthStore,
  AddPhoneLoginVerify,
  SignIn,
  getRecaptcha,
  changeLogin,
} from 'redux/rootActions';
import { withUserInterface } from 'HOC';
import { IAddPhoneLoginPayload, IAddPhoneLoginVerifyPayload } from '../../../interfaces/addPhoneLoginPayload';
import { AddPhoneLogin } from '../../../redux/authorization/actions';

type TMapStateToPropsTypes = (reducer: IRedux) => IAuthreducer;

interface IMapDispatchToPropsTypes {
    authorization: (login: string, password: string, captchaId: string, captchaKey: string) => void;
    addPhoneLoginVerify: (payload: IAddPhoneLoginVerifyPayload) => void;
    addPhoneLogin: (payload: IAddPhoneLoginPayload) => void;
    clearStore: () => void;
    recaptcha: typeof getRecaptcha;
    changeAuthLogin: typeof changeLogin;
}

interface IProps {
    authorization: (login: string, password: string, captchaId: string, captchaKey: string) => void;
    addPhoneLoginVerify: (payload: IAddPhoneLoginVerifyPayload) => void,
    addPhoneLogin: (payload: IAddPhoneLoginPayload) => void,
    clearStore: () => void;
    history: any;
    errorMessage: string;
    recaptcha: (captchaId: string) => void;
    captcha: string,
    captchaId: string,
    isNeedReset: boolean,
    isPhone: boolean,
    changeAuthLogin: (isPhone: boolean) => void,
}

const AuthorizationContainer: React.FC<IProps> = ({
  addPhoneLoginVerify,
  addPhoneLogin,
  authorization,
  clearStore,
  history,
  errorMessage,
  recaptcha,
  captcha,
  captchaId,
  isNeedReset,
  isPhone,
  changeAuthLogin,
}) => (
  <AuthForm
    authorization={authorization}
    addPhoneLogin={addPhoneLogin}
    addPhoneLoginVerify={addPhoneLoginVerify}
    history={history}
    clearStore={clearStore}
    errorMessage={errorMessage}
    recaptcha={recaptcha}
    captcha={captcha}
    captchaId={captchaId}
    isNeedReset={isNeedReset}
    isPhone={isPhone}
    changeAuthLogin={changeAuthLogin}
  />
);

const mapStateToProps: TMapStateToPropsTypes = ({
  authReducer,
  registartionReducer: {
    isPhone,
  },
}: IRedux) => ({
  ...authReducer,
  isPhone,
});

const mapDispatchToProps: IMapDispatchToPropsTypes = {
  authorization: SignIn,
  addPhoneLoginVerify: AddPhoneLoginVerify,
  addPhoneLogin: AddPhoneLogin,
  clearStore: clearAuthStore,
  recaptcha: getRecaptcha,
  changeAuthLogin: changeLogin,
};

export default withUserInterface(withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AuthorizationContainer),
));
