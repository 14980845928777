import { TOKEN_TYPE, ROUTES } from 'consts';
import { getGlobalHistory } from 'helpers';
import { SIGN_IN_BY_USER } from 'redux/adminUsers/types';
import {
  CLEAR_STORE_AUTH,
  LOG_OUT,
  SET_STORE_TOKEN,
  SIGN_IN,
  CHECK_USER,
  ADD_PHONE_LOGIN_VERIFY,
  REQUEST_RESET_PASSWORD,
  VERIFY_RESET_PASSWORD_TOKEN,
  RESET_PASSWORD_CHANGE_PASSWORD,
  CLEAR_RESET_PASSWORD_STORE,
  GET_CAPTCHA,
  ADD_PHONE_LOGIN,
  ACCEPT_RULES,
  SET_IS_ACCEPTED_AGREEMENT,
} from '../types';
import { IAuthreducer } from './interfaces';
import { CHANGE_LOGIN } from '../../registration/types';

const INITIAL_STATE: IAuthreducer = {
  errorMessage: '',
  token: '',
  isAdmin: false,
  resetPasswordErrorMessage: '',
  loginType: '',
  loginForReset: '',
  passwordIsChanged: false,
  captcha: '',
  captchaId: '',
  isNeedReset: false,
  isPhone: true,
  userId: 0,
  isAccepted: false,
};

export default (state = INITIAL_STATE, { type, payload }: {type: string, payload: any}) => {
  switch (type) {
    case CHANGE_LOGIN: {
      return {
        ...state,
        isPhone: payload,
      };
    }

    case GET_CAPTCHA.success: {
      const { captcha, captchaId } = payload;
      return {
        ...state,
        captcha,
        captchaId,
      };
    }

    case CLEAR_RESET_PASSWORD_STORE: {
      return {
        ...INITIAL_STATE,
      };
    }

    case REQUEST_RESET_PASSWORD.fail:
    case VERIFY_RESET_PASSWORD_TOKEN.fail:
    case RESET_PASSWORD_CHANGE_PASSWORD.fail: {
      return {
        ...state,
        resetPasswordErrorMessage: payload,
      };
    }

    case RESET_PASSWORD_CHANGE_PASSWORD.success: {
      return {
        ...state,
        passwordIsChanged: true,
      };
    }

    case VERIFY_RESET_PASSWORD_TOKEN.success: {
      const { login } = payload;
      return {
        ...state,
        loginForReset: login,
      };
    }

    case ADD_PHONE_LOGIN_VERIFY.success: {
      const { login } = payload;
      return {
        ...state,
        errorMessage: '',
        loginForReset: login,
      };
    }

    case ADD_PHONE_LOGIN_VERIFY.fail: {
      const {
        isNeedReset,
        error,
      } = payload;
      localStorage.removeItem(TOKEN_TYPE.client);
      return {
        ...INITIAL_STATE,
        errorMessage: error,
        isNeedReset,
      };
    }

    case ADD_PHONE_LOGIN.success: {
      localStorage.setItem(TOKEN_TYPE.client, payload.token);
      return {
        ...state,
        token: payload.token,
      };
    }

    case ADD_PHONE_LOGIN.fail: {
      const {
        isNeedReset,
        error,
      } = payload;
      localStorage.removeItem(TOKEN_TYPE.client);
      return {
        ...INITIAL_STATE,
        errorMessage: error,
        isNeedReset,
      };
    }


    case REQUEST_RESET_PASSWORD.success: {
      const { loginType } = payload;
      return {
        ...state,
        loginType,
      };
    }

    case SIGN_IN_BY_USER.success: {
      localStorage.setItem(TOKEN_TYPE.client, payload.token);
      return {
        ...state,
        isAdmin: true,
        token: payload.token,
        userId: payload.id,
      };
    }

    case CLEAR_STORE_AUTH: {
      return {
        ...state,
        errorMessage: '',
      };
    }

    case SIGN_IN.success: {
      localStorage.setItem(TOKEN_TYPE.client, payload.token);
      return {
        ...state,
        userId: payload.id,
        token: payload.token,
        isAccepted: payload.isAccepted,
      };
    }

    case SIGN_IN.fail: {
      const {
        isNeedReset,
        error,
      } = payload;
      localStorage.removeItem(TOKEN_TYPE.client);
      return {
        ...INITIAL_STATE,
        errorMessage: error,
        isNeedReset,
      };
    }

    case LOG_OUT: {
      localStorage.removeItem('user_token');
      getGlobalHistory().push(ROUTES.authorization);
      return {
        ...INITIAL_STATE,
      };
    }

    case SET_STORE_TOKEN: {
      return {
        ...state,
        token: payload,
      };
    }

    case CHECK_USER.success: {
      return {
        ...state,
        token: payload.payload,
        userId: payload.userId,
      };
    }

    case ACCEPT_RULES.success: {
      return {
        ...state,
      };
    }

    case SET_IS_ACCEPTED_AGREEMENT: {
      return {
        ...state,
        isAccepted: payload,
      };
    }

    default:
      return state;
  }
};
