import { block } from 'bem-cn';
import {
  FormInput, GreenButton, InfoBlock, PopupContainer,
} from 'components';
import { LANG_DICTIONARY, MASKS, REGEXP } from 'consts';
import { gerErrorFromSchema } from 'helpers';
import React from 'react';
import schema from './schema';
import './styles.scss';
import FormInputMiniLabel from '../FormInputMiniLabel';

const b = block('change-contact-form');

const {
  ENTER_NEW_LOGIN,
  ENTER_LOGIN,
  SEND_DATA_WORD,
  PHONE_MOBILE,
  PHONE,
  PHONE_HOME,
  EMAIL,
  SMS_CODE,
  EMAIL_CODE,
} = LANG_DICTIONARY;

const {
  PHONE_MASK,
} = REGEXP;

const {
  smsCodeMask,
  emailCodeMask,
} = MASKS;

const PHONE_TYPE = 'phone';
const EMAIL_TYPE = 'email';

interface IProps {
  contact: string;
  smsCode: string;
  sendData: () => void;
  verifyPhone: () => void;
  changePhone: (contact:string, smsCode: string) => void;
  changeEmail: (contact:string, code: string) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isOpen: boolean;
  closePopUp: () => void;
  info: string;
  codeType: string;
  removeCodeType: () => void;
  isPhone: boolean;
  getTypeOfContact: (contact:string) => string
  errorMessage: string;
}

interface IState {
  errorLogin: string;
  isSmsCodeActive: boolean;
  isEmail: boolean;
}
class ChangeContactForm extends React.Component <IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      errorLogin: '',
      isSmsCodeActive: false,
      isEmail: false,
    };
  }

  public executeForm = () => {
    const {
      sendData,
      contact,
      verifyPhone,
      getTypeOfContact,
      errorMessage,
      changePhone,
      codeType,
      changeEmail,
      smsCode,
    } = this.props;

    const check = getTypeOfContact(contact);
    try {
      schema(check).validateSync({
        contact,
      });
      this.setState({
        errorLogin: '',
      });

      if (codeType === PHONE) {
        changePhone(
          contact.replace(PHONE_MASK, ''),
          smsCode.replace(PHONE_MASK, ''),
        );
        return;
      }
      if (codeType === EMAIL) {
        changeEmail(
          contact,
          smsCode.replace(PHONE_MASK, ''),
        );
        return;
      }

      if ([PHONE_MOBILE, PHONE_HOME].includes(check)) {
        verifyPhone();
        this.setState({isSmsCodeActive: true});
        return;
      }
      this.setState({isSmsCodeActive: false});

      sendData();
    } catch (e) {
      const error = gerErrorFromSchema(e);
      this.setState({
        errorLogin: error,
      });
    }
  }

  public handleClosePopUp = () => {
    const { closePopUp, removeCodeType } = this.props;
    this.setState({
      errorLogin: '',
    });
    removeCodeType();
    closePopUp();
  }

  public generateForm = () => {
    const {
      contact,
      handleChange,
      info,
      errorMessage,
      smsCode,
      codeType,
      isPhone,
    } = this.props;

    const {
      errorLogin,
      isEmail,
      isSmsCodeActive,
    } = this.state;

    return (
      <div className={b()}>
        <div className={b('header')}>
          {ENTER_NEW_LOGIN}
        </div>
        <div className={b('input-field')}>
          <FormInput
            value={contact}
            mask=""
            placeholder={ENTER_LOGIN}
            onChange={handleChange}
            name="contact"
            className={errorLogin ? b('error-input') : ''}
          />
        </div>
        {codeType === PHONE_TYPE && (
          <div className={b('input-field')}>
            <FormInputMiniLabel
              className={b('sms-code')}
              placeholder={SMS_CODE}
              value={smsCode}
              onChange={handleChange}
              name="smsCode"
              mask={smsCodeMask}
            />
          </div>
        )}
        {codeType === EMAIL_TYPE && (
          <div className={b('input-field')}>
            <FormInputMiniLabel
              className={b('sms-code')}
              placeholder={EMAIL_CODE}
              value={smsCode}
              onChange={handleChange}
              name="smsCode"
              mask={emailCodeMask}
            />
          </div>
        )}
        <div className={b('description')}>
          {(errorMessage || errorLogin) && <InfoBlock message={errorMessage || errorLogin} error />}
        </div>
        <InfoBlock message={info} />
        <div className={b('control-btn')}>
          <GreenButton text={SEND_DATA_WORD} onClick={this.executeForm} />
        </div>
      </div>
    );
  }

  public render() {
    const { isOpen } = this.props;
    return (
      <PopupContainer
        isOpen={isOpen}
        isCloseButton
        close={this.handleClosePopUp}
        children={this.generateForm()}
      />
    );
  }
}

export default ChangeContactForm;
