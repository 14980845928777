import './styles.scss';
import React from 'react';
import { block } from 'bem-cn';
import { IPayment } from 'redux/contracts/reducers/interface';
import { LANG_DICTIONARY, DATE_FORMAT } from 'consts';
import { rebuildCurrency } from 'helpers';
import { connect } from 'react-redux';
import { WhiteTable, InfoBlock } from 'components';
import moment from 'moment';

const {
  PAYMENT_NUMBER,
  PLAN_DATE_PAYMENT,
  REAL_DATE_PAYMENT,
  PLAN_SUM_PAYMENT,
  REAL_SUM_PAYMENT,
  STATUS_OF_PAYMENT,
  OVER_PAID,
  NOT_ENOUGH_PAIDED,
  CURRENCY_TYPE_RUBLES,
  PAYMENT_SCHEDULE,
  FUTURE,
  NOT_PAID,
  PAID_FULL,
} = LANG_DICTIONARY;

interface IProps {
  data: IPayment[],
  descriptions: any;
}

const length = 1;

const b = block('isz-payments-data');
const slider = block('main-slider');
const contentClass = b('content').mix(slider('content', { right: true }));

const PaymentsTable: React.FC<IProps> = ({data, descriptions}) => {
  const getDate = (date: string) => date && moment(date).format(DATE_FORMAT.DATE);

  const isNumber = data.some((item) => !item.paymentOrder);

  const dataLength = data.length;

  const RebuildHeaders = () => (
    isNumber
      ? (
        <div className={b('header')}>
          <div className={b('header-item')}>{PLAN_DATE_PAYMENT}</div>
          <div className={b('header-item')}>{REAL_DATE_PAYMENT}</div>
          <div className={b('header-item')}>{PLAN_SUM_PAYMENT}</div>
          <div className={b('header-item')}>{REAL_SUM_PAYMENT}</div>
          <div className={b('header-item')}>{STATUS_OF_PAYMENT}</div>
        </div>
      )
      : (
        <div className={b('header')}>
          <div className={b('header-item')}>{PAYMENT_NUMBER}</div>
          <div className={b('header-item')}>{PLAN_DATE_PAYMENT}</div>
          <div className={b('header-item')}>{REAL_DATE_PAYMENT}</div>
          <div className={b('header-item')}>{PLAN_SUM_PAYMENT}</div>
          <div className={b('header-item')}>{REAL_SUM_PAYMENT}</div>
          <div className={b('header-item')}>{STATUS_OF_PAYMENT}</div>
        </div>
      )
  );

  const getPaidedBlock = () => {
    const result = data[0]?.overpaymentSum ? data[0].overpaymentSum : 0;

    if (result > 0) {
      return (
        <div className={b('paided-block')}>
          <div className={b('good')}>{OVER_PAID}</div>
          <div className={b('sum')}>{`${rebuildCurrency(String(parseFloat(result.toFixed(2))))} ${CURRENCY_TYPE_RUBLES}`}</div>
        </div>
      );
    }

    if (result < 0) {
      return (
        <div className={b('paided-block')}>
          <div className={b('bad')}>{NOT_ENOUGH_PAIDED}</div>
          <div className={b('sum')}>{`${rebuildCurrency(String(Math.abs(Number(parseFloat(result.toFixed(2))))))} ${CURRENCY_TYPE_RUBLES}`}</div>
        </div>
      );
    }

    return null;
  };

  const getStatusBlock = (item: IPayment) => {
    if (item.paymentConditionName === PAID_FULL) {
      return (
        <div className={b('payment-status-current-paid')}>
          <div className={b('payment-status')}>
            {item.paymentConditionName}
          </div>
        </div>
      );
    }

    if (item.paymentConditionName === null && item.paymentPlanDate > item.sysDate) {
      return (
        <div className={b('payment-status-current-future')}>
          <div className={b('payment-status')}>
            {FUTURE}
          </div>
        </div>
      );
    }

    if (item.paymentConditionName !== PAID_FULL && item.paymentPlanDate > item.sysDate) {
      return (
        <div className={b('payment-status-current-future')}>
          <div className={b('payment-status')}>
            {item.paymentConditionName}
          </div>
        </div>
      );
    }

    if (item.paymentConditionName !== PAID_FULL && item.paymentPlanDate <= item.sysDate) {
      return (
        <div className={b('payment-status-current-not-paid')}>
          <div className={b('payment-status')}>
            {item.paymentConditionName}
          </div>
        </div>
      );
    }

    if (item.paymentPlanDate <= item.sysDate && item.paymentConditionName === null) {
      return (
        <div className={b('payment-status-current-not-paid')}>
          <div className={b('payment-status')}>
            {NOT_PAID}
          </div>
        </div>
      );
    }

    return <div />;
  };

  const rebuildData = isNumber ? data.map((item) => [
    getDate(item.paymentPlanDate),
    getDate(item.paymentFactDate),
    `${rebuildCurrency(String(parseFloat(String(item.premiumCur))))} ${item.premiumCurrencyName}`,
    `${rebuildCurrency(String(parseFloat(String(item.totalPaymentCur))))} ${item.premiumCurrencyName}`,
    getStatusBlock(item),
  ])
    : data.map((item) => [
      item.paymentOrder,
      getDate(item.paymentPlanDate),
      getDate(item.paymentFactDate),
      `${rebuildCurrency(String(parseFloat(String(item.premiumCur))))} ${item.premiumCurrencyName}`,
      `${rebuildCurrency(String(parseFloat(String(item.totalPaymentCur))))} ${item.premiumCurrencyName}`,
      getStatusBlock(item),
    ]);
  return (
    <div className={b.mix(slider)}>
      <div className={contentClass}>
        <div className={b('wrapper')}>
          <div className={b('header-form-text')}>{PAYMENT_SCHEDULE}</div>
          <RebuildHeaders />
          <div className={dataLength === length ? b('simple-dimple') : b('simple')}>
            <WhiteTable data={rebuildData} />
          </div>
          <InfoBlock message={descriptions['form-lk-isz-2']} />
        </div>
        <div>{getPaidedBlock()}</div>
      </div>
    </div>
  );
};

const mapStateToProps: any = ({
  descriptions: {
    descriptions,
  },
}: {descriptions: any}) => ({
  descriptions,
});

export default connect(mapStateToProps, null)(PaymentsTable);
