import { LANG_DICTIONARY, DATE_FORMAT } from 'consts';
import React from 'react';
import { simpleDate } from 'helpers';
import { FlexTable, AdminCheckbox, CustomSelect } from 'components';
import { block } from 'bem-cn';
import addButton from 'assets/images/addButton.png';
import trashButton from 'assets/images/trashButton.png';
import {
  IContactSelectvalue,
  IContactsUserCard,
  ISelectItem,
} from 'redux/adminUserCard/reducers/interface';
import template from './template';

import './styles.scss';

const b = block('flex-table-contacts');

const {
  USER_NAME_WORD,
  TYPE_WORD,
  IS_DEFAULT_WORD,
  MANUAL_ADD,
} = LANG_DICTIONARY;

type TAction = 'contacts' | 'contragents' | 'contracts';

interface IProps {
  contacts: IContactsUserCard[];
  addAction: (type: TAction) => void;
  deleteAction: (type: TAction, id: number | string) => void;
  onChangeCheckbox: (id: number | string) => any;
  selectData: ISelectItem[];
  handleChangeContact: (id: number | string, value: IContactSelectvalue) => void;
  handleChangeContactName: (id: number | string, value: string) => void;
}

const headers = [
  USER_NAME_WORD,
  TYPE_WORD,
  IS_DEFAULT_WORD,
  MANUAL_ADD,
  <img
    src={addButton}
    alt="add"
    className={b('control-btn')}
  />,
];

const FlexTableContacts: React.FC<IProps> = ({
  contacts,
  addAction,
  deleteAction,
  onChangeCheckbox,
  selectData,
  handleChangeContact,
  handleChangeContactName,
}) => {
  const controlButtons = (id: number | string, disabled: boolean) => (
    <div className={b('control-btns')}>
      <div
        onClick={() => (disabled ? null : deleteAction('contacts', id))}
        role="button"
        tabIndex={0}
      >
        <img
          src={trashButton}
          alt="delete"
          className={b('control-btn')}
        />
      </div>
      <div
        onClick={() => addAction('contacts')}
        role="button"
        tabIndex={0}
      >
        <img
          src={addButton}
          alt="add"
          className={b('control-btn')}
        />
      </div>
    </div>
  );

  const idFromBase = (id: number | string) => typeof (id) === 'number';

  const rebuildData = contacts.map(({
    id,
    value,
    isDefault,
    manualAddDate,
    contactTypeCode,
    contactTypeName,
    popUpOpen,
  }) => template(headers, [
    id,
    idFromBase(id) ? value
      : (
        <input
          value={value}
          className={b('contact-input')}
          name="value"
          disabled={!contactTypeCode.length}
          onChange={(e) => {
            const { value: inputValue} = e.target;
            handleChangeContactName(id, inputValue);
          }}
        />
      ),
    typeof (id) === 'number' ? contactTypeName
      : (
        <CustomSelect
          options={selectData}
          onChange={() => null}
          value={contactTypeName}
          onSelect={(val) => handleChangeContact(id, val)}
          onScroll={() => null}
          button
        />
      ),
    <AdminCheckbox
      checked={isDefault}
      name={String(id)}
      onChange={(e) => onChangeCheckbox(e.target.name)}
      disabled={isDefault}
    />,
    simpleDate(manualAddDate, DATE_FORMAT.DATE_WITH_HOURS),
    controlButtons(id, isDefault),
  ]));
  return (<div className={b()}><FlexTable data={rebuildData} minHeight={30} /></div>);
};

export default FlexTableContacts;
