import './styles.scss';

import React from 'react';
import { block } from 'bem-cn';
import { TableGenerator } from 'components';

const b = block('white-table');

interface IProps {
  data: any[][],
  headers?: any[],
  className?: string,
}

const WhiteTable: React.FC<IProps> = ({
  data,
  headers = [],
  className,
}) => (
  <TableGenerator
    data={data}
    headers={headers}
    className={b.mix(className)}
    classNameTh={b('header')}
    classNameTd={b('value')}
    classNameTr={b('row')}
  />
);

export default WhiteTable;
