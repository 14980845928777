import {
  SET_IS_PAYMENT_END,
  GET_USER_CONTRACT,
  SET_CHECKBOX_VALUE,
  SET_PASSED_FIRST_FORM,
  TYPE_COMMENT,
  CLEAR_STATE,
  CHANGE_FIELD,
  SAVE_FORM_DATA,
  DELETE_IMAGE,
  SEND_REQ_DADATA,
  SET_FOUND_BANK,
  SET_PIC_ERR,
  CHECK_CODE_IS_VALID,
  GET_USER_BANKS_INN,
} from '../types';

const INITIAL_STATE = {
  isPaymentEnd: false,
  selectedContract: {},
  isLoading: false,
  isSameData: false,
  isTelNumber: false,
  isPassedFirstForm: false,
  comment: '',
  banksData: [],
};

export default (state = INITIAL_STATE, { type, payload }: { type: string, payload: any }) => {
  switch (type) {
    case SET_IS_PAYMENT_END: {
      return {
        ...INITIAL_STATE,
        isPaymentEnd: payload,
      };
    }

    case GET_USER_BANKS_INN.success: {
      return {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          inn: payload.inn,
          corAcc: payload.corAcc,
          bankName: payload.bankName.payment,
        },
      };
    }

    case GET_USER_CONTRACT.success: {
      const { contract, sysDate } = payload;

      const fio = contract.lastName ? `${contract.lastName} ${contract.firstName} ${contract.middleName}` : '';

      return {
        ...state,
        fio,
        sysDate,
        isLoading: false,
        selectedContract: {
          ...state.selectedContract,
          ...contract,
          recipient: fio,
        },
      };
    }

    case GET_USER_CONTRACT.start: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_USER_CONTRACT.fail: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case SET_CHECKBOX_VALUE: {
      const {
        value,
        name,
      } = payload;

      return {
        ...state,
        [name]: value,
      };
    }

    case SET_PASSED_FIRST_FORM: {
      return {
        ...state,
        isPassedFirstForm: payload,
      };
    }

    case TYPE_COMMENT: {
      return {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          comment: payload,
        },
      };
    }

    case CLEAR_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }

    case CHANGE_FIELD: {
      const {
        value,
        name,
      } = payload;

      return {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          [name]: value,
        },
      };
    }

    case SAVE_FORM_DATA: {
      const {
        name,
        file,
      } = payload;
      return {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          [name]: file,
        },
      };
    }

    case DELETE_IMAGE: {
      const {
        name,
      } = payload;
      return {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          [name]: null,
        },
      };
    }

    case SEND_REQ_DADATA.success: {
      return {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          banksData: payload,
        },
      };
    }

    case SET_FOUND_BANK: {
      return {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          ...payload,
        },
      };
    }

    case SET_PIC_ERR: {
      const {
        name,
        value,
      } = payload;

      return {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          [name]: value,
        },
      };
    }

    case CHECK_CODE_IS_VALID.success: {
      return {
        ...state,
        selectedContract: {
          ...state.selectedContract,
          isValid: payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};
