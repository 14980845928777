import './styles.scss';

import React from 'react';

interface IProps {
    isOpen: boolean,
    children: React.ReactNode,
    isCloseButton?: boolean,
    isCloseClickVeil?: boolean,
    className?: string,
    classNameVeil?: string,
    close: () => void,
}

const PopupContainer: React.FC<IProps> = ({
  close,
  isOpen,
  children,
  className = '',
  classNameVeil = '',
  isCloseClickVeil = true,
}) => {
  const veil = React.createRef<HTMLDivElement>();
  const closePopup = (e: any) => {
    if (e.target === veil.current) {
      close();
    }
  };

  return isOpen
    ? (
      <>
        <div
          className={`veil ${classNameVeil}`}
          ref={veil}
          role="button"
          tabIndex={0}
          onClick={(e) => isCloseClickVeil && closePopup(e)}
        >
          {}
        </div>
        <div className={className}>
          {children}
        </div>
      </>
    )
    : null;
};

export default PopupContainer;
