import React from 'react';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import { IRoute } from 'router/interfaces/route';
import HomePageContainer from '../../containers/_client/HomePage';
import ProfitabilityPageContainer from '../../containers/_client/ProfitabilityPage';
import ApplicationPaymentEnd from '../../containers/_client/ApplicationPaymentEnd';
import ApplicationPaymentUploads from '../../containers/_client/ApplicationPaymentUploads';
import ApplicationFormed from '../../containers/_client/ApplicationFormed';
import ApplicationForTermination from '../../containers/_client/ApplicationForTermination';

const { PERSONAL_AREA } = LANG_DICTIONARY;

const pathes: IRoute[] = [
  {
    component: HomePageContainer,
    path: ROUTES.home,
    title: PERSONAL_AREA,
    type: 'client',
  },
  {
    component: ProfitabilityPageContainer,
    path: `${ROUTES.profitability}/:contractNumber`,
    title: PERSONAL_AREA,
    type: 'client',
  },
  {
    component: ApplicationPaymentEnd,
    path: `${ROUTES.payoutEnd}/:id`,
    title: PERSONAL_AREA,
    type: 'client',
  },
  {
    component: ApplicationForTermination,
    path: `${ROUTES.payoutCancel}/:id`,
    title: PERSONAL_AREA,
    type: 'client',
  },
  {
    component: ApplicationPaymentUploads,
    path: `${ROUTES.payoutEndUpload}/:id`,
    title: PERSONAL_AREA,
    type: 'client',
  },
  {
    component: ApplicationFormed,
    path: ROUTES.payoutSuccess,
    title: PERSONAL_AREA,
    type: 'client',
  },
];

export default pathes;
