import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import { Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';
import './styles.scss';

const { FIELD_IS_REQUIRED } = LANG_DICTIONARY;
const b = block('warning-error-message');

const RequiredMessage = ({ value }: { value: string }) => (
  <>
    {!value.length && (
      <div className={b()}>
        <Icon name="warning sign" className={b('icon').toString()} />
        <p className={b('message')}>{FIELD_IS_REQUIRED}</p>
      </div>
    )}
  </>
);

export default RequiredMessage;
