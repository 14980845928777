import { LANG_DICTIONARY, DATE_FORMAT } from 'consts';
import React from 'react';
import { simpleDate } from 'helpers';
import { block } from 'bem-cn';
import { IContractUserCard } from 'redux/adminUserCard/reducers/interface';
import { FlexTable, CustomSelect } from 'components';
import addButton from 'assets/images/addButton.png';
import findIcong from 'assets/images/findIcong.png';
import trashButton from 'assets/images/trashButton.png';
import { ENUMS_SEND_STATUSES } from 'redux/adminUserCard/reducers';
import template from './template';
import './styles.scss';

const b = block('flex-table-contracts');

const {
  WORD_1C,
  CTS_NUMBER,
  CTS_DATE,
  STATUS,
} = LANG_DICTIONARY;

type TAction = 'contacts' | 'contragents' | 'contracts';

interface IProps {
  addAction: (type: TAction) => void;
  deleteAction: (type: TAction, id: number | string) => void;
  contracts: IContractUserCard[];
  getContractCard: (id: number | string) => void;
  updateContractList: (filter: string) => void;
  listOfContracts: any[];
  clearCtsList: () => void;
  handleChangeValueContract: (id: number | string, name: string, value: string) => void;
  handleSetContract: (id: number | string, value: any) => void;
}

const FlexTableContracts: React.FC<IProps> = ({
  addAction,
  deleteAction,
  contracts,
  getContractCard,
  updateContractList,
  listOfContracts,
  clearCtsList,
  handleChangeValueContract,
  handleSetContract,
}) => {
  const headers = [
    '',
    `${WORD_1C}`,
    CTS_NUMBER,
    CTS_DATE,
    STATUS,
    <img
      src={addButton}
      alt="add"
      className={b('control-btn')}
    />,
  ];

  const controlButtons = (id: number | string) => (
    <div
      role="button"
      tabIndex={0}
      className={b('control-btns')}
    >
      <div
        onClick={() => deleteAction('contracts', id)}
        role="button"
        tabIndex={0}
      >
        <img
          src={trashButton}
          alt="delete"
          className={b('control-btn')}
        />
      </div>
      <div
        onClick={() => addAction('contracts')}
        role="button"
        tabIndex={0}
      >
        <img
          src={addButton}
          alt="add"
          className={b('control-btn')}
        />
      </div>
    </div>
  );

  const rebuildData = contracts.map(({
    ctsId,
    guid1c,
    numFull,
    dateCreate,
    statusName,
    sendStatus,
  }) => template(headers, [
    ctsId,
    <div
      className={b('find-button')}
      onClick={() => getContractCard(ctsId)}
      role="button"
      tabIndex={0}
    >
      <img src={findIcong} alt="find" />
    </div>,
    guid1c,
    sendStatus !== ENUMS_SEND_STATUSES.toCreate ? numFull
      : (
        <CustomSelect
          options={listOfContracts}
          name="numFull"
          onChange={(value, name) => handleChangeValueContract(ctsId, name, value)}
          value={numFull}
          onScroll={() => updateContractList(numFull)}
          onSelect={(value) => handleSetContract(ctsId, value)}
          onBlur={clearCtsList}
        />
      ),
    simpleDate(dateCreate, DATE_FORMAT.DATE),
    statusName,
    controlButtons(ctsId),
  ]));

  return (
    <div className={b()}>
      <FlexTable data={rebuildData} minHeight={45} />
    </div>
  );
};

export default FlexTableContracts;
