import { RegiStrationForm } from 'components';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { IRedux } from 'redux/interface';
import { IRegistartionReducer } from 'redux/registration/reducers/interfaces';
import {
  changeField,
  clearStore,
  getInfoAccount,
  getInfoContract,
  getInfoPersonal,
  setDataFirstForm,
  setDataSecondForm,
  setDataThirdForm,
  changeLogin,
  clearLogin,
} from 'redux/rootActions';
import { withUserInterface } from 'HOC';

interface IMapDispatchToPropsTypes {
  initialStore: typeof clearStore;
  fieldChange: typeof changeField;
  sendFirstForm: typeof setDataFirstForm;
  sendSecondForm: typeof setDataSecondForm;
  sendThirdForm: typeof setDataThirdForm;
  getAccountInfo: typeof getInfoAccount;
  getContractInfo: typeof getInfoContract;
  getPersonalInfo: typeof getInfoPersonal;
  changeRegLogin: typeof changeLogin;
  clearRegLogin: typeof clearLogin;
}

type Props = IMapDispatchToPropsTypes & IRegistartionReducer & RouteComponentProps;

const RegistrationContainer: React.FC<Props> = ({
  infoContract,
  infoPersonal,
  infoAccount,
  login,
  password,
  passwordConfirm,
  dateSign,
  ctsNumber,
  firstName,
  lastName,
  middleName,
  passportSerial,
  passportNumber,
  activeStage,
  getAccountInfo,
  getPersonalInfo,
  getContractInfo,
  sendThirdForm,
  sendSecondForm,
  sendFirstForm,
  fieldChange,
  initialStore,
  errorMessage,
  usersRegsId,
  prevStage,
  ctgtsFlId,
  infoMessage,
  redirectLink,
  history,
  isPhone,
  changeRegLogin,
  clearRegLogin,
}) => (
  <div>
    <MetaTags>
      <meta name="description" content="Вход в личный кабинет БКС Страхование жизни.Все договора под рукой. Мы стали еще ближе и удобнее." />
      <meta name="keywords" content="личный кабинет,БКС,страхование жизни,страхователь,вход,авторизация,договор страхования,ИСЖ,НСЖ,НС" />
    </MetaTags>

    <RegiStrationForm
      history={history}
      infoMessage={infoMessage}
      redirectLink={redirectLink}
      prevStage={prevStage}
      infoContract={infoContract}
      infoPersonal={infoPersonal}
      infoAccount={infoAccount}
      login={login}
      password={password}
      passwordConfirm={passwordConfirm}
      dateSign={dateSign}
      ctsNumber={ctsNumber}
      firstName={firstName}
      lastName={lastName}
      middleName={middleName}
      passportSerial={passportSerial}
      passportNumber={passportNumber}
      activeStage={activeStage}
      getAccountInfo={getAccountInfo}
      getPersonalInfo={getPersonalInfo}
      getContractInfo={getContractInfo}
      sendThirdForm={sendThirdForm}
      sendSecondForm={sendSecondForm}
      sendFirstForm={sendFirstForm}
      fieldChange={fieldChange}
      initialStore={initialStore}
      errorMessage={errorMessage}
      usersRegsId={usersRegsId}
      ctgtsFlId={ctgtsFlId}
      isPhone={isPhone}
      changeRegLogin={changeRegLogin}
      clearRegLogin={clearRegLogin}
    />
  </div>
);

const mapStateToProps = ({
  registartionReducer: {
    infoContract,
    infoPersonal,
    infoAccount,
    login,
    password,
    passwordConfirm,
    dateSign,
    ctsNumber,
    firstName,
    lastName,
    middleName,
    passportSerial,
    passportNumber,
    activeStage,
    errorMessage,
    usersRegsId,
    prevStage,
    ctgtsFlId,
    infoMessage,
    redirectLink,
    isPhone,
  },
}: IRedux) => ({
  activeStage,
  ctgtsFlId,
  ctsNumber,
  dateSign,
  errorMessage,
  firstName,
  infoAccount,
  infoContract,
  infoMessage,
  infoPersonal,
  lastName,
  login,
  middleName,
  passportNumber,
  passportSerial,
  password,
  passwordConfirm,
  prevStage,
  redirectLink,
  usersRegsId,
  isPhone,
});

const mapDispatchToProps: IMapDispatchToPropsTypes = {
  fieldChange: changeField,
  getAccountInfo: getInfoAccount,
  getContractInfo: getInfoContract,
  getPersonalInfo: getInfoPersonal,
  initialStore: clearStore,
  sendFirstForm: setDataFirstForm,
  sendSecondForm: setDataSecondForm,
  sendThirdForm: setDataThirdForm,
  changeRegLogin: changeLogin,
  clearRegLogin: clearLogin,
};

export default withUserInterface(withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RegistrationContainer),
));
