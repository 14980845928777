import { block } from 'bem-cn';
import { FormInput, GreenButton, InfoBlock } from 'components';
import { LANG_DICTIONARY } from 'consts';
import React from 'react';
import './styles.scss';

const {
  MIND_THE_PASSWORD,
  CREATE_CABINET,
} = LANG_DICTIONARY;

const b = block('stage-third-form');

interface IProps {
  password: string;
  passwordConfirm: string;
  sendData: () => void;
  info: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorPassword: string;
  errorField: string;
}

const disableButton = (
  firstCondition: string,
  secondCondition: string,
) => Boolean(firstCondition.length && secondCondition.length);

interface IState {
  currentTypePassword: string;
  currentTypePasswordConfirm: string;
  typePassword: string;
  typePasswordConfirm: string;
  typeText: string;
}

class StageThird extends React.Component <IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentTypePassword: 'password',
      currentTypePasswordConfirm: 'password',
      typePassword: 'password',
      typePasswordConfirm: 'passwordConfirm',
      typeText: 'text',
    };
  }

  public passwordProtectControl = () => {
    const { typePassword, typeText, currentTypePassword } = this.state;
    if (currentTypePassword === typePassword) {
      this.setState({
        currentTypePassword: typeText,
      });
    } else {
      this.setState({
        currentTypePassword: typePassword,
      });
    }
  }

  public passwordConfirmProtectControl = () => {
    const { typePassword, typeText, currentTypePasswordConfirm } = this.state;
    if (currentTypePasswordConfirm === typePassword) {
      this.setState({
        currentTypePasswordConfirm: typeText,
      });
    } else {
      this.setState({
        currentTypePasswordConfirm: typePassword,
      });
    }
  }

  public onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { handleChange } = this.props;
    handleChange(e);
  }

  public render() {
    const {
      info,
      password,
      passwordConfirm,
      errorPassword,
      sendData,
      errorField,
    } = this.props;
    const {
      typePassword,
      typePasswordConfirm,
      currentTypePassword,
      currentTypePasswordConfirm,
    } = this.state;
    return (
      <div className={b()}>
        <div className={b('header')}>
          {MIND_THE_PASSWORD}
        </div>
        <div className={b('input-field-password')}>
          <FormInput
            value={password}
            onChange={this.onChange}
            onClicIcon={this.passwordProtectControl}
            name={typePassword}
            type={currentTypePassword}
            mask=""
            password
            className={errorField === 'password' ? b('error-input') : b('password-wrapper')}
          />
        </div>
        <div className={b('input-field-password')}>
          <FormInput
            value={passwordConfirm}
            onChange={this.onChange}
            onClicIcon={this.passwordConfirmProtectControl}
            name={typePasswordConfirm}
            type={currentTypePasswordConfirm}
            mask=""
            password
            className={errorField === 'passwordConfirm' ? b('error-input') : b('password-wrapper')}
          />
        </div>
        <div className={b('info-block')}>
          {errorPassword && (
          <div>
            <InfoBlock
              error
              message={errorPassword}
            />
          </div>
          )}
          <InfoBlock message={info} />
        </div>
        <div className={b('control-btn')}>
          <GreenButton
            text={CREATE_CABINET}
            onClick={sendData}
            disabled={!disableButton(password, passwordConfirm)}
          />
        </div>
      </div>
    );
  }
}

export default StageThird;
