import { block } from 'bem-cn';
import React from 'react';
import './styles.scss';

const b = block('header-image');

const getLocalTime = (name: string) => {
  const currentDate = new Date();
  const hours = currentDate.getHours();
  if (hours >= 5 && hours <= 11) {
    return `Доброе утро, ${name}!`;
  }
  if (hours >= 12 && hours <= 17) {
    return `Добрый день, ${name}!`;
  }
  if (hours >= 17 && hours <= 23) {
    return `Добрый вечер, ${name}!`;
  }
  if (hours >= 23 && hours <= 5) {
    return `Добрый ночи, ${name}!`;
  }
  return '';
};

interface IProps {
  name: string;
}

const HeaderImage: React.FC<IProps> = ({name}) => (
  <div className={b()}>
    <div className={b('hello-wrapper')}>
      <div className={b('hello-name')}>{getLocalTime(name)}</div>
    </div>
  </div>
);

export default HeaderImage;
