import {
  GET_USER_INFO,
  CLEAR_STORE,
  CONTROL_ACTION,
  GET_CONTACTS_CODES,
  GET_CONTR_AGENTS_LIST,
  DELETE_ACTION,
  GET_CONTR_AGENT_CARD,
  CLEAR_CTGT_CARD,
  UPDATE_CONTR_AGENTS_LIST,
  SHOW_TABLES_CONTROL,
  CHOOSE_CTGT,
  GET_CONTRACT_CARD,
  GET_CONTRACTS_LIST,
  UPDATE_CONTRACTS_LIST,
  CLEAR_CTS_CARD,
  SAVE_CHANGES,
  RESET_CHANGES,
  CLEAR_SEARCH_CTS,
} from '../types';
import {
  IPersonalCard,
  ISendBody,
  IShowTables,
  IToDelete,
} from '../reducers/interface';

export const clearSearchCts = () => ({
  type: CLEAR_SEARCH_CTS,
});

export const resetChangesUserCard = () => ({
  type: RESET_CHANGES,
});

export const saveChanges = (userId: string, body: ISendBody) => ({
  type: SAVE_CHANGES.request,
  payload: { userId, body },
});

export const chooseCtgt = (id: number | string) => ({
  type: CHOOSE_CTGT,
  payload: id,
});

export const getContractCard = (userId: string, ctsId: number) => ({
  type: GET_CONTRACT_CARD.request,
  payload: { userId, ctsId },
});

export const getContractsList = (
  page: number,
  userId: string,
  filter: string,
  ctgtId: number | string | null,
) => ({
  type: GET_CONTRACTS_LIST.request,
  payload: {
    page,
    userId,
    filter,
    ctgtId,
  },
});

export const updateList = (
  page: number,
  userId: string,
  filter: string,
  ctgtId: number | string | null,
) => ({
  type: UPDATE_CONTRACTS_LIST.request,
  payload: {
    page,
    userId,
    filter,
    ctgtId,
  },
});

export const clearCtsCard = () => ({
  type: CLEAR_CTS_CARD,
});

export const showTablesControl = (showTables: IShowTables) => ({
  type: SHOW_TABLES_CONTROL,
  payload: showTables,
});

export const clearCtgtCard = () => ({
  type: CLEAR_CTGT_CARD,
});

export const getContrAgentCard = (userId: string, ctgtId: number) => ({
  type: GET_CONTR_AGENT_CARD.request,
  payload: { userId, ctgtId },
});

export const deleteAction = (newDeleteArray: IToDelete) => ({
  type: DELETE_ACTION,
  payload: newDeleteArray,
});

export const getContragents = (currentpage: number, filter: string, id: number) => ({
  type: GET_CONTR_AGENTS_LIST.request,
  payload: { currentpage, filter, id },
});

export const updatecontrAgents = (currentpage: number, filter: string, id: number) => ({
  type: UPDATE_CONTR_AGENTS_LIST.request,
  payload: { currentpage, filter, id },
});

export const getCodes = () => ({
  type: GET_CONTACTS_CODES.request,
});

export const getUserInfo = (id: string) => ({
  type: GET_USER_INFO.request,
  payload: id,
});

export const clearStore = () => ({
  type: CLEAR_STORE,
});

export const controlAction = (
  renderData: IPersonalCard,
) => ({
  type: CONTROL_ACTION,
  payload: renderData,
});
