import { API } from 'consts';
import { apiCall } from 'helpers';
import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import _ from 'lodash';
import { ContractData } from '../reducers/interface';
import {
  GET_PAYMENTS,
  GET_REDEMPTION,
  GET_RISKS,
  GET_ADDITIONAL_SERVICES,
  GET_STRATEGY,
  GET_USERS_CONTRACTS_ISZ,
  GET_CONTRAGENTS,
  GET_ASSETS,
  GET_INVSTMENT_INCOME,
  GET_USERS_CONTRACTS_NSZ,
  GET_EARNED,
} from '../types';

function* getContractsIsz() {
  try {
    yield put({ type: GET_USERS_CONTRACTS_ISZ.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.USER_CONTRACTS,
    });
    yield put({ type: GET_USERS_CONTRACTS_ISZ.success, payload: data });
    const generators = data.isz.map(
      (item: ContractData, index: number) => call(getContractData, item.id, index, 'isz'),
    );
    yield all(generators);
  } catch (e) {
    yield put({ type: GET_USERS_CONTRACTS_ISZ.fail });
  }
}

function* getContractsNsz() {
  try {
    yield put({ type: GET_USERS_CONTRACTS_NSZ.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.USER_NSZ_CONTRACTS,
    });
    yield put({ type: GET_USERS_CONTRACTS_NSZ.success, payload: data });
    const generatorsNsz = data.nsz.map(
      (item: ContractData, index: number) => call(getContractData, item.id, index, 'nsz'),
    );
    yield all(generatorsNsz);
  } catch (e) {
    yield put({ type: GET_USERS_CONTRACTS_NSZ.fail });
  }
}

function* getContractData(id: number, index: number, contractType: string) {
  yield all([
    call(getEarned, id, index, contractType),
    call(getRisks, id, index, contractType),
    call(getRedemptions, id, index, contractType),
    call(getPayments, id, index, contractType),
    call(getStrategy, id, index, contractType),
    call(getContragents, id, index, contractType),
    call(getAssets, id, index, contractType),
    call(getInvestmentIncome, id, index, contractType),
    call(getAdditionalServices, id, index, contractType),
  ]);
}

function* getRisks(id: number, index: number, contractType: string) {
  try {
    yield put({ type: GET_RISKS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.CONTRACT_RISK(id),
    });
    yield put({ type: GET_RISKS.success, payload: { data, index, contractType } });
  } catch (e) {
    yield put({ type: GET_RISKS.fail });
  }
}

function* getEarned(id: number, index: number, contractType: string) {
  try {
    yield put({ type: GET_EARNED.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_EARNED_CONTRACT(id),
    });
    yield put({ type: GET_EARNED.success, payload: { data, index, contractType } });
  } catch (e) {
    yield put({ type: GET_EARNED.fail });
  }
}

function* getAdditionalServices(id: number, index: number, contractType: string) {
  try {
    yield put({type: GET_ADDITIONAL_SERVICES.start});
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_ADDITIONAL_SERVICES(id),
    });
    yield put({ type: GET_ADDITIONAL_SERVICES.success, payload: { data, index, contractType }});
  } catch (e) {
    yield put({ type: GET_ADDITIONAL_SERVICES.fail });
  }
}

function* getRedemptions(id: number, index: number, contractType: string) {
  try {
    yield put({ type: GET_REDEMPTION.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_REDEMPTIONS(id),
    });
    const sortData = _.sortBy(data, 'redemptionOrder');
    yield put({ type: GET_REDEMPTION.success, payload: { data: sortData, index, contractType } });
  } catch (e) {
    yield put({ type: GET_REDEMPTION.fail });
  }
}

function* getPayments(id: number, index: number, contractType: string) {
  try {
    yield put({ type: GET_PAYMENTS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_PAYMENTS(id),
    });
    yield put({ type: GET_PAYMENTS.success, payload: { data, index, contractType } });
  } catch (e) {
    yield put({ type: GET_PAYMENTS.fail });
  }
}

function* getStrategy(id: number, index: number, contractType: string) {
  try {
    yield put({ type: GET_STRATEGY.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_STRATEGY(id),
    });
    yield put({ type: GET_STRATEGY.success, payload: { data, index, contractType } });
  } catch (e) {
    yield put({ type: GET_STRATEGY.fail });
  }
}

function* getContragents(id: number, index: number, contractType: string) {
  try {
    yield put({ type: GET_CONTRAGENTS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CONTRAGENTS(id),
    });
    yield put({ type: GET_CONTRAGENTS.success, payload: { data, index, contractType } });
  } catch (e) {
    yield put({ type: GET_CONTRAGENTS.fail });
  }
}

function* getAssets(id: number, index: number, contractType: string) {
  try {
    yield put({ type: GET_ASSETS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_ASSETS(id),
    });
    yield put({ type: GET_ASSETS.success, payload: { data, index, contractType } });
  } catch (e) {
    yield put({ type: GET_ASSETS.fail });
  }
}

function* getInvestmentIncome(id: number, index: number, contractType: string) {
  try {
    yield put({ type: GET_INVSTMENT_INCOME.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_INVESTMENT_INCOME(id),
    });
    yield put({ type: GET_INVSTMENT_INCOME.success, payload: { data, index, contractType } });
  } catch (e) {
    yield put({ type: GET_INVSTMENT_INCOME.fail });
  }
}

export default function* mainDataSaga() {
  yield takeLatest(GET_USERS_CONTRACTS_ISZ.request, getContractsIsz);
  yield takeLatest(GET_USERS_CONTRACTS_NSZ.request, getContractsNsz);
}
