import { block } from 'bem-cn';
import { GreenButton } from 'components';
import { LANG_DICTIONARY } from 'consts';
import React from 'react';
import './styles.scss';

const b = block('final-stage-form');
const { GO_TO_BCS_ONLINE } = LANG_DICTIONARY;

interface IProps {
  infoMessage: string;
  onClick: () => void;
}

const StageFinal: React.FC<IProps> = ({infoMessage, onClick}) => (
  <div className={b()}>
    <div className={b('info-message')}>{infoMessage}</div>
    <div className={b('control-btn')}>
      <GreenButton text={GO_TO_BCS_ONLINE} onClick={onClick} />
    </div>
  </div>
);

export default StageFinal;
