import './styles.scss';

import { block } from 'bem-cn';
import { AdminErrorImportPopup, AdminSuccsessImportPopup } from 'components';
import { Location } from 'history';
import React from 'react';
import {
  changeGlobalSettings,
  clearConnectionUsersToCtgts,
  createConnectionUsersToCtgts,
  getLastImportStatus,
  import1c,
  sendPrintForm,
  setInitStoreForImport,
} from 'redux/rootActions';
import { RouteComponentProps } from 'react-router-dom';
import ConnectionUsers from './connectionUsers';
import GlobalSettings from './globalSettings';
import Import from './import';
import PrintedForms from './printedForms';

interface IProps {
  history: RouteComponentProps['history'],
  location: Location,
  isProcessImport: boolean,
  isProcessCreatingConnection: boolean,
  isProcessCleaningConnection: boolean,
  fileName: string,
  statusImport: boolean,
  isFinishImport: boolean,
  errors: string[],
  isAuthorization: boolean,
  isRegistration: boolean,
  isService: boolean,
  importFrom1c: typeof import1c,
  createConnection: typeof createConnectionUsersToCtgts,
  clearStoreForImport: typeof setInitStoreForImport,
  changeSettings: typeof changeGlobalSettings,
  clearConnection: typeof clearConnectionUsersToCtgts,
  getLastImport: typeof getLastImportStatus,
  printFormsOptions: [],
  sendChosePrintForm: typeof sendPrintForm,
    adminId: number,
}

const b = block('managment-admin-form');

const ManagmentForm: React.FC<IProps> = ({
  importFrom1c,
  isProcessImport,
  isProcessCreatingConnection,
  isProcessCleaningConnection,
  createConnection,
  fileName,
  statusImport,
  clearStoreForImport,
  isFinishImport,
  errors,
  isAuthorization,
  isRegistration,
  isService,
  changeSettings,
  clearConnection,
  getLastImport,
  printFormsOptions,
  sendChosePrintForm,
  adminId,
}) => (
  <div className={b()}>
    <AdminErrorImportPopup
      name={fileName}
      errors={errors}
      isOpen={isFinishImport && !statusImport}
      close={clearStoreForImport}
    />
    <AdminSuccsessImportPopup
      name={fileName}
      isOpen={isFinishImport && statusImport}
      close={clearStoreForImport}
    />
    <GlobalSettings
      isAuthorization={isAuthorization}
      isRegistration={isRegistration}
      isService={isService}
      changeSettings={changeSettings}
    />
    <ConnectionUsers
      isProcessCreatingConnection={isProcessCreatingConnection}
      isProcessCleaningConnection={isProcessCleaningConnection}
      clearConnection={clearConnection}
      createConnection={createConnection}
    />
    <Import
      isProcessImport={isProcessImport}
      importFrom1c={importFrom1c}
      getLastImport={getLastImport}
    />
    <PrintedForms
      printFormsOptions={printFormsOptions}
      sendChosePrintForm={sendChosePrintForm}
      adminId={adminId}
    />
  </div>
);

export default ManagmentForm;
