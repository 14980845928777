import {
  GET_ADMINISTRATORS,
  GET_ADMIN_CARD,
  SAVE_CHANGES,
  RESET_CHANGES,
  CLEAR_FORM,
  CHANGE_FIELD,
  DELETE_ADMIN,
  GET_CURRENT_CARD,
  SET_HAVE_CARD,
} from '../types';
import {IAdminPersonalCard, IDelete} from '../reducers/interfaces';

export const getCurrentCard = (card: IAdminPersonalCard, id: number | string) => ({
  type: GET_CURRENT_CARD,
  payload: { card, id },
});

export const deleteAdmin = (deleteArray: IDelete[]) => ({
  type: DELETE_ADMIN,
  payload: deleteArray,
});

export const getAdminsList = (page: number) => ({
  type: GET_ADMINISTRATORS.request,
  payload: page,
});

export const getAdminCard = (id: number) => ({
  type: GET_ADMIN_CARD.request,
  payload: id,
});

export const saveChanges = (body: any) => ({
  type: SAVE_CHANGES.request,
  payload: body,
});

export const resetChanges = () => ({
  type: RESET_CHANGES,
});

export const clearStoreAdministrators = () => ({
  type: CLEAR_FORM,
});

export const changeFieldAdministrators = (newList: any) => ({
  type: CHANGE_FIELD,
  payload: newList,
});

export const setHaveCardAdministrators = (value: boolean) => ({
  type: SET_HAVE_CARD,
  payload: value,
});
