import React from 'react';

const BcsLogoSvg = () => (
  <svg width="153" height="52" viewBox="0 0 153 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.8023 3.08789V7.93807H6.34168V15.6347H13.0343C16.316 15.6347 19.7346 15.4986 22.516 17.3261C24.1092 18.3806 26.5258 20.808 26.5258 25.3845C26.5258 29.7353 24.477 32.1627 22.516 33.3981C19.6438 35.2287 15.7249 35.1374 13.3098 35.1374H0V3.08789H22.8023ZM6.34168 30.1883H13.0836C14.7767 30.1883 16.6392 30.1883 17.9614 29.4152C19.7346 28.3592 20.0102 26.575 20.0102 25.2469C20.0102 23.9188 19.7346 22.1315 17.9583 21.0724C16.6361 20.2993 14.769 20.2993 13.0328 20.2993H6.34168V30.1883Z" fill="white" />
    <path d="M46.5929 3.08789V14.857H50.989L59.0716 3.08789H66.4168L56.4795 18.1038L66.5092 35.1328H59.0346L50.989 21.2084H46.5929V35.1328H40.2559V3.08789H46.5929Z" fill="white" />
    <path d="M103.298 25.8998C101.515 28.4246 98.6955 30.0465 95.2245 30.0465C89.1691 30.0465 85.0655 25.1144 85.0655 19.0319C85.0655 12.9495 89.1629 8.01583 95.2245 8.01583C98.6862 8.01583 101.5 9.62534 103.282 12.1393L111.003 13.251C109.65 9.52187 107.032 6.39088 103.609 4.40799C100.187 2.4251 96.1775 1.71714 92.2865 2.40846C88.3955 3.09979 84.8715 5.14619 82.3334 8.18819C79.7953 11.2302 78.4056 15.0732 78.4082 19.0423C78.4109 23.0113 79.8058 26.8525 82.3479 29.8911C84.89 32.9297 88.4168 34.9713 92.3087 35.6574C96.2006 36.3435 100.209 35.6301 103.629 33.6427C107.049 31.6552 109.663 28.5206 111.011 24.7897L103.298 25.8998Z" fill="white" />
    <path d="M32.9826 0H31.9883V38.2217H32.9826V0Z" fill="white" />
    <path d="M74.4666 0H73.4707V38.2217H74.4666V0Z" fill="white" />
    <path d="M115.949 0H114.959V38.2217H115.949V0Z" fill="white" />
    <path d="M133.234 33.0754C133.366 33.5037 133.361 33.6738 133.488 33.7356C133.552 33.765 135.307 34.2845 135.358 33.8469C135.917 29.139 148.903 24.261 149.779 28.301C150.94 26.5833 152.139 24.8748 152.6 22.7535C152.592 22.6136 152.633 22.4752 152.716 22.3623C151.568 18.6841 129.958 22.3623 133.234 33.0754ZM152.936 17.2694C152.746 15.1407 152.148 13.069 151.175 11.1684C144.818 3.60789 120.39 11.388 128.742 30.9201C128.996 31.5154 129.346 31.8571 129.512 31.7427C129.678 31.6282 130.446 31.5602 130.361 30.9789C128.139 15.697 147.507 12.0497 152.944 17.2756L152.936 17.2694ZM145.996 5.77091C146.136 5.82348 147.307 6.35071 147.03 6.02138C146.74 5.68117 146.395 5.39352 146.008 5.17102C126.361 -5.96105 110.975 20.4915 125.962 32.4987C126.532 32.9548 126.926 33.2888 127.06 33.2965C127.238 33.2965 130.583 35.6791 128.13 31.8509C118.829 17.3668 129.88 -1.99216 145.996 5.77091Z" fill="#A7AAAD" />
    <path d="M151.144 11.1365C149.792 15.0976 137.238 8.28234 135.34 4.52062C135.14 4.12173 134.973 4.02277 134.906 4.04597C134.771 4.0939 134.665 4.23768 134.571 4.67833C133.406 10.2444 145.898 20.3174 152.999 17.7291C153.007 17.5689 152.994 17.4083 152.96 17.2514C152.749 15.1152 152.133 13.0395 151.144 11.1365ZM130.78 6.09767C130.889 5.51787 130.96 5.27359 130.809 5.13753C130.658 5.00147 130.339 5.35089 129.945 5.87812C122.169 16.4598 139.161 32.5255 150.214 28.3603C151.412 26.5611 152.254 24.5469 152.694 22.4278C146.626 27.0492 128.871 16.217 130.783 6.10076L130.78 6.09767ZM128.723 4.32736C129.21 3.89135 129.682 3.48472 129.501 3.45225C129.368 3.4337 128.894 3.6548 128.252 4.01814C116.143 10.8303 118.994 32.0138 134.765 35.8435V35.8621C135.227 35.9007 135.688 35.9239 136.15 35.9239C137.994 35.9244 139.826 35.6209 141.573 35.0256C142.921 34.5399 144.211 33.9063 145.421 33.1362C145.583 33.0311 145.738 32.9213 145.893 32.8116C132.134 37.0418 116.285 16.0346 128.716 4.32736" fill="white" />
    <path d="M6.08959 50.1398L6.57753 51.1014C6.30282 51.3762 5.96662 51.5808 5.59703 51.6982C5.16363 51.8526 4.70694 51.9305 4.24711 51.9286C3.13168 51.9286 2.22609 51.5833 1.53035 50.8927C0.834613 50.2021 0.487257 49.2894 0.488284 48.1545C0.488284 47.0599 0.818708 46.1569 1.47956 45.4457C2.1404 44.7345 3.05266 44.3789 4.21633 44.3789C5.15219 44.3789 5.89103 44.6263 6.43284 45.121L5.86024 46.0719C5.37316 45.7478 4.80059 45.5776 4.21633 45.5833C3.90122 45.575 3.58786 45.6329 3.2963 45.7532C3.00474 45.8736 2.74141 46.0537 2.52316 46.2822C2.07268 46.7501 1.84743 47.3743 1.84743 48.1545C1.83403 48.4953 1.88929 48.8353 2.0099 49.1542C2.13051 49.473 2.31399 49.764 2.54933 50.0099C2.7861 50.2482 3.06907 50.4351 3.38071 50.559C3.69235 50.683 4.02602 50.7413 4.36102 50.7304C4.9867 50.7376 5.59625 50.531 6.08959 50.1444" fill="white" />
    <path d="M9.02539 45.699V44.4961H15.03V45.699H12.6688V51.8139H11.3881V45.699H9.02539Z" fill="white" />
    <path d="M19.5072 51.8135H18.2266V44.4941C18.3512 44.4941 18.6883 44.4941 19.2194 44.4833C19.7504 44.4725 20.1168 44.4833 20.2968 44.4833C20.7502 44.474 21.2021 44.5393 21.6344 44.6766C21.9676 44.7771 22.2704 44.9599 22.5149 45.2085C22.7158 45.4236 22.8727 45.6763 22.9767 45.9521C23.0758 46.2351 23.1248 46.5334 23.1214 46.8334C23.1267 47.1482 23.0665 47.4605 22.9446 47.7505C22.8226 48.0405 22.6417 48.3016 22.4133 48.5172C21.9423 48.9779 21.2907 49.2083 20.4585 49.2083C20.0336 49.2083 19.7176 49.1975 19.5103 49.1758L19.5072 51.8135ZM20.4308 45.6785C20.2768 45.6785 19.969 45.6785 19.5072 45.7001V47.9482C19.8083 47.9816 20.1109 47.9992 20.4138 48.0008C20.7639 48.016 21.1088 47.9107 21.3912 47.7024C21.5182 47.5954 21.6183 47.4598 21.6833 47.3067C21.7483 47.1536 21.7764 46.9872 21.7653 46.8211C21.7653 46.0604 21.3179 45.68 20.4231 45.68" fill="white" />
    <path d="M25.0742 51.8141L28.1527 44.4746H29.2764L32.3548 51.8141H30.9911L30.2307 49.9432H27.126L26.3656 51.8141H25.0742ZM28.6868 46.1382L27.6094 48.7404H29.7397L28.6868 46.1382Z" fill="white" />
    <path d="M41.2287 44.4961L38.772 48.0815L41.3226 51.8139H39.8218L37.9393 49.0649L36.0645 51.8139H34.5684L37.1173 48.0815L34.6823 44.4961H36.1815L37.9701 47.14L39.7294 44.4961H41.2287Z" fill="white" />
    <path d="M43.5713 48.1545C43.5585 47.6565 43.6464 47.161 43.8298 46.698C44.0131 46.2351 44.2881 45.8143 44.638 45.4612C45.3502 44.7397 46.2578 44.3789 47.3609 44.3789C48.4641 44.3789 49.3717 44.7268 50.0839 45.4225C50.8022 46.1214 51.1613 47.0315 51.1613 48.153C51.1613 49.2466 50.8022 50.149 50.0839 50.8602C49.726 51.2128 49.3008 51.4891 48.834 51.6724C48.3671 51.8557 47.8681 51.9423 47.3671 51.9271C46.266 51.9281 45.3563 51.5807 44.638 50.885C43.9197 50.1892 43.5605 49.2801 43.5605 48.1576L43.5713 48.1545ZM44.9151 48.1576C44.905 48.4948 44.9619 48.8306 45.0824 49.1454C45.2029 49.4602 45.3846 49.7478 45.617 49.9913C45.8386 50.2316 46.1086 50.4218 46.409 50.5492C46.7094 50.6767 47.0334 50.7384 47.3594 50.7304C48.109 50.7304 48.7062 50.4861 49.1465 49.9975C49.5928 49.4922 49.8296 48.8344 49.8083 48.1592C49.8213 47.8182 49.7655 47.4781 49.6443 47.1592C49.5232 46.8404 49.3392 46.5495 49.1034 46.3038C48.6396 45.8422 48.0131 45.5832 47.3602 45.5832C46.7073 45.5832 46.0808 45.8422 45.617 46.3038C45.3844 46.5511 45.2034 46.8427 45.0847 47.1613C44.966 47.48 44.912 47.8192 44.9259 48.1592" fill="white" />
    <path d="M54.7207 51.8043V44.5159C55.3995 44.4803 56.106 44.4648 56.8233 44.4648C57.6468 44.4648 58.2548 44.6287 58.6396 44.9596C58.8275 45.121 58.9769 45.3228 59.0768 45.55C59.1767 45.7772 59.2246 46.024 59.2168 46.2723C59.2356 46.6235 59.1454 46.972 58.9588 47.2697C58.7722 47.5674 58.4983 47.7996 58.1748 47.9343V47.956C58.5556 48.0385 58.8943 48.2555 59.1293 48.5676C59.3643 48.8797 59.48 49.2662 59.4554 49.6567C59.4554 50.3834 59.2061 50.9292 58.7104 51.3002C58.2148 51.6713 57.5621 51.8522 56.7494 51.8522C55.8674 51.8522 55.1902 51.8367 54.7207 51.8012V51.8043ZM56.7987 45.6662C56.4908 45.6662 56.2245 45.677 55.9967 45.6971V47.5524H56.8602C57.1413 47.5649 57.4178 47.4785 57.6422 47.3082C57.7447 47.2186 57.8252 47.1064 57.8773 46.9803C57.9294 46.8542 57.9516 46.7177 57.9423 46.5815C57.9494 46.452 57.9251 46.3227 57.8715 46.2048C57.8179 46.0868 57.7365 45.9837 57.6345 45.9043C57.3889 45.7365 57.0952 45.6545 56.7987 45.6708V45.6662ZM56.9018 48.6703H55.9967V50.606C56.2766 50.6405 56.5583 50.6581 56.8402 50.6586C57.7268 50.6586 58.1707 50.3066 58.1717 49.6026C58.1794 49.4677 58.1527 49.333 58.0943 49.2113C58.0359 49.0896 57.9475 48.9848 57.8377 48.9068C57.5572 48.7336 57.2305 48.651 56.9018 48.6703Z" fill="white" />
    <path d="M62.2852 51.8141L65.3636 44.4746H66.495L69.5735 51.8141H68.2082L67.4493 49.9432H64.34L63.5704 51.8141H62.2852ZM65.8978 46.1382L64.8203 48.7404H66.9568L65.8978 46.1382Z" fill="white" />
    <path d="M77.3596 51.8139V48.6876H74.0272V51.8139H72.748V44.4961H74.0272V47.4847H77.3596V44.4961H78.628V51.8139H77.3596Z" fill="white" />
    <path d="M83.8522 51.8139H82.7285V44.4961H84.0076V49.7034L87.828 44.4961H88.9517V51.8139H87.671V46.6174L83.8522 51.8139Z" fill="white" />
    <path d="M93.0547 51.8139V44.4961H97.2691V45.6974H94.3338V47.5172H96.9582V48.7201H94.3338V50.6141H97.3738V51.8139H93.0547Z" fill="white" />
    <path d="M107.356 47.9981L104.619 44.4961H106.086L108.729 47.9872V44.4961H109.999V47.9872L112.634 44.4961H114.11L111.374 47.9872L114.224 51.8139H112.748L109.999 48.0212V51.8139H108.729V48.0212L105.971 51.8139H104.504L107.356 47.9981Z" fill="white" />
    <path d="M118.544 51.8139H117.42V44.4961H118.699V49.7034L122.521 44.4961H123.646V51.8139H122.364V46.6174L118.544 51.8139Z" fill="white" />
    <path d="M129.604 51.9285C129.073 51.9432 128.546 51.8191 128.077 51.5682C127.688 51.3605 127.355 51.0607 127.107 50.6947L127.803 49.8273C128.034 50.0937 128.313 50.314 128.625 50.4767C128.906 50.6329 129.22 50.7193 129.541 50.7287C129.882 50.7389 130.218 50.6416 130.501 50.4504C130.627 50.3581 130.729 50.2357 130.795 50.0942C130.862 49.9527 130.893 49.7966 130.885 49.6402C130.889 49.5049 130.861 49.3706 130.803 49.2485C130.745 49.1263 130.658 49.02 130.551 48.9383C130.269 48.7474 129.932 48.6555 129.593 48.677H128.834V47.4756H129.541C129.811 47.4812 130.073 47.3884 130.28 47.2143C130.382 47.1297 130.463 47.0223 130.516 46.9007C130.57 46.7791 130.594 46.6467 130.588 46.5139C130.596 46.3841 130.574 46.254 130.524 46.1341C130.474 46.0141 130.397 45.9075 130.298 45.8228C130.078 45.6548 129.805 45.5705 129.529 45.5847C128.939 45.5847 128.451 45.8476 128.049 46.3779L127.314 45.5213C127.571 45.1656 127.915 44.8811 128.311 44.6942C128.736 44.4858 129.203 44.3799 129.675 44.3849C130.334 44.3849 130.863 44.5607 131.262 44.9122C131.662 45.2662 131.859 45.7579 131.859 46.3918C131.856 46.7047 131.771 47.0112 131.613 47.2808C131.435 47.5809 131.183 47.8297 130.882 48.0029V48.0353C131.264 48.1136 131.602 48.3346 131.828 48.6538C132.043 48.9857 132.15 49.3761 132.136 49.7716C132.144 50.0745 132.08 50.3748 131.949 50.6476C131.817 50.9203 131.623 51.1575 131.382 51.3394C130.873 51.733 130.246 51.9408 129.604 51.9285Z" fill="white" />
    <path d="M140.526 51.8139V48.6876H137.197V51.8139H135.916V44.4961H137.197V47.4847H140.526V44.4961H141.794V51.8139H140.526Z" fill="white" />
    <path d="M147.022 51.8139H145.896V44.4961H147.177V49.7034L150.996 44.4961H152.121V51.8139H150.842V46.6174L147.022 51.8139Z" fill="white" />
  </svg>
);

export default BcsLogoSvg;
