import React from 'react';
import block from 'bem-cn';
import { SearchResultData } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import { IBankReq } from 'redux/contracts/reducers/interface';
import InputWithLabel from '../../_forms/InputWithLabel';
import './styles.scss';

const b = block('bank-requisites');

interface IProps {
    bankReq: IBankReq;
    onChange: (value: string, name: string) => void;
    onChangeString: (value: string, name: string) => void;
    onChangeBic: (e: React.MouseEvent<HTMLElement>) => void;
    banksData: any;
    onResultSelect?: (data: SearchResultData) => void;
}

const BankRequisites: React.FC<IProps> = (props) => {
  const {
    bankReq,
    onChange,
    onChangeString,
    onChangeBic,
    banksData,
    onResultSelect,
  } = props;

  const {
    bic,
    curAcc,
    corAcc,
    bankName,
    inn,
    recipient,
  } = bankReq;

  return (
    <div className={b()}>
      <p className={b('text')}>
        {LANG_DICTIONARY.TRANSFER_FUNDS}
      </p>
      <div className={b('block')}>
        <InputWithLabel
          name="recipient"
          label="Получатель"
          value={recipient}
          onChange={onChangeString}
        />
        <InputWithLabel
          name="bic"
          label="БИК"
          value={bic}
          banksData={banksData}
          onSearchChange={onChangeBic}
          onResultSelect={onResultSelect}
          isSearch
        />
        <InputWithLabel
          name="curAcc"
          label="Номер счета"
          value={curAcc}
          maxLength={20}
          onChange={onChange}
        />
        <InputWithLabel
          name="corAcc"
          label="Корреспондентский счет"
          value={corAcc}
          maxLength={20}
          onChange={onChange}
        />
        <InputWithLabel
          name="bankName"
          label="Наименование банка"
          value={bankName}
          onChange={onChangeString}
        />
        <InputWithLabel
          name="inn"
          label="ИНН банка"
          value={inn}
          maxLength={10}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default BankRequisites;
