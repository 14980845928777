import {LANG_DICTIONARY, REGEXP} from 'consts';
import { object, string } from 'yup';
import { MAX_LENGTH_INPUT } from 'config';

const {
  PHONE_HOME,
  MOBILE_PHONE,
  CYRILLIC,
  EMAIL_CHECK,
} = REGEXP;

const {
  PASSWORD_LANGUAGE,
  ACCESS_EMAIL_SYMBOLS,
} = LANG_DICTIONARY;

const checkLogin = (check: boolean) => {
  if (!check) {
    return {
      login: string()
        .email()
        .test('', PASSWORD_LANGUAGE, (value) => !value.match(CYRILLIC))
        .test('', ACCESS_EMAIL_SYMBOLS, (value) => !value.match(EMAIL_CHECK))
        .max(MAX_LENGTH_INPUT),
    };
  }
  if (check) {
    return {
      login: string().matches(MOBILE_PHONE).max(MAX_LENGTH_INPUT),
    };
  }
  return {
    login: string().matches(PHONE_HOME).max(MAX_LENGTH_INPUT),
  };
};

const schema = (check: boolean) => object().shape(checkLogin(check));

export default schema;
