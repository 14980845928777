import './styles.scss';
import { Accordion, Loader } from 'semantic-ui-react';
import block from 'bem-cn';
import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import _ from 'lodash';
import {
  IAssetModel,
  IContractModel,
  IInvestPeriodModel,
  IInvestStrategyModel,
  IPaymentDateModel,
  ISelectedPayment,
  ISelectedStrategy,
  IStrategyModel,
} from 'redux/profitability/reducers/interfaces';
import { getProfitabilityReport } from 'redux/rootActions';
import { LANG_DICTIONARY } from 'consts/dictionary';
import { DATE_FORMAT } from 'consts';
import ContractData from './Contract';
import AutocallBarrier from './AutocallBarrier';
import AccordionTitle from './AccordionTitle';
import ProfitabilityError from './ProfitabilityError';
import AssetsTable from './AssetsTable';
import StrategyResult from './StrategyResult';

const b = block('profitability');

const refOptionTypeAutocall = [7, 9, 13];

interface IProps {
  contract: IContractModel,
  data: any
  getReport: typeof getProfitabilityReport;
  errorMessage: string
  goBack: () => void
  loadProfitabilityStrategy: (strId: number) => void
  isLoading: boolean,
  isStrategyLoading: boolean,
  isPaymentInfoLoading: boolean,
  isPaymentsLoading: boolean,
  isLoadingReport: boolean,
  contractNumber: string,
  selectedStrategy: ISelectedStrategy,
  selectedPeriod?: number,
  selectedPayment?: ISelectedPayment,
}

interface IState {
  selectedYear: number;
  selectedDate: {
    date?: string,
    id?: number,
  };
  activeIndex: number;
}

interface ITitleProps {
  index: number;
}

class ProfitabilityInfo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedYear: 0,
      selectedDate: {
        date: '',
        id: 0,
      },
      activeIndex: 0,
    };
  }

  handleClick = (e : React.MouseEvent, titleProps: ITitleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  }

  checkIsActiveWeight = (refOptionType: number): boolean => refOptionTypeAutocall
    .includes(refOptionType);

  getProfitabilityReport = () => {
    const {
      contract,
      getReport,
      contractNumber,
      selectedPayment,
    } = this.props;

    const body = {
      contractInfo: contract,
      id: contractNumber,
      reportDate: selectedPayment?.date,
    };

    getReport(body);
  }

  getWatchDate = () => {
    const { selectedYear, selectedDate, activeIndex } = this.state;
    const { data } = this.props;
    const selectedStrategy = data.strategies ? data.strategies[activeIndex]?.periods
      .find((x: IInvestStrategyModel) => x?.year === selectedYear) ?? null : null;

    const selectedStrategyDate = selectedStrategy?.paymentDates
      .find((x: IPaymentDateModel) => x.date === selectedDate.date);

    if (!selectedStrategyDate) {
      return moment(_.get(data.contractInfo, 'actualDate', '')).format(DATE_FORMAT.DATE);
    }
    const actualDate = _.get(selectedStrategyDate, 'actualDate', '');

    return moment(actualDate).format(DATE_FORMAT.DATE);
  }

  getNoBarrierCrossingDesc = () => {
    const {
      selectedStrategy,
      selectedPeriod,
      selectedPayment,
    } = this.props;
    if (!selectedPayment?.date || !selectedStrategy?.periods || !selectedPeriod) {
      return null;
    }
    const worstAsset = selectedPayment.assets.reduce((acc: IAssetModel, item: IAssetModel) => {
      if (acc?.assetPriceIncrease && (acc.assetPriceIncrease < item.assetPriceIncrease)) {
        return acc;
      }

      return item;
    }, {} as any);

    const { barrierLevel } = selectedStrategy.periods[selectedPeriod - 1];
    const isCrossedBarrier = barrierLevel ? barrierLevel < worstAsset?.assetPriceIncrease : false;
    if (isCrossedBarrier) {
      return (
        <p className={b('common-text', { memory: true })}>
          {LANG_DICTIONARY.BARRIER_CROSSED}
        </p>
      );
    }
    return (
      <p className={b('common-text', { memory: true })}>
        <div className={b('common-text-no-barrier')}>{LANG_DICTIONARY.NO_BARRIER_CROSSING_TEXT}</div>
        <div>{LANG_DICTIONARY.NEXT_REPORTING_DATE}</div>
      </p>
    );
  };

  render() {
    const {
      contract,
      data,
      errorMessage,
      goBack,
      data: {
        strategies,
      },
      isLoading,
      isStrategyLoading,
      isPaymentInfoLoading,
      isPaymentsLoading,
      isLoadingReport,
      selectedStrategy,
      selectedPayment,
    } = this.props;

    const {
      activeIndex,
    } = this.state;

    const assets = selectedPayment?.assets;

    const isAutocalledStrategy = strategies?.some((item: IInvestPeriodModel) => item.isAutocalled);

    const isContractNotActive = moment().isBefore(contract.dateInvest);

    const isHaveActiveStrategy = Boolean(strategies?.find((item: IStrategyModel) => (
      item.isAllPaymentsExists
    )));

    if (errorMessage) {
      return (
        <div className={b('wrapper')}>
          <ProfitabilityError
            goBack={goBack}
            errorMessage={errorMessage}
          />
        </div>
      );
    }

    if (isContractNotActive) {
      return (
        <div className={b('wrapper')}>
          <ProfitabilityError
            goBack={goBack}
            errorMessage={LANG_DICTIONARY.NO_PROFITABILITY_DATE}
          />
        </div>
      );
    }

    return (
      <div className={b('wrapper')}>
        <div>
          <h1 className={b('page-title')}>{LANG_DICTIONARY.PROGRAM_PROFIT}</h1>
          <div className={b('main-block')}>
            <button
              className={b('back-button')}
              onClick={goBack}
            >
              {LANG_DICTIONARY.BACK_WORD}
            </button>
            {
              !isLoading
                ? (
                  <div className={b('contract-block')}>
                    <ContractData
                      isHaveActiveStrategy={isHaveActiveStrategy}
                      contract={contract}
                      isAutocalledStrategy={isAutocalledStrategy}
                      getProfitabilityReport={this.getProfitabilityReport}
                      isLoadingReport={isLoadingReport}
                    />
                  </div>
                )
                : (<div />)
            }
            {
              !isLoading
                ? (
                  <div className={b('profitability-section')}>
                    <div>
                      <Accordion>
                        {strategies
                          && strategies?.map((item: IInvestPeriodModel, index: number) => (
                            <div key={item.number}>
                              <AccordionTitle
                                activeIndex={activeIndex}
                                index={index}
                                number={item.number}
                                isAutocalled={item.isAutocalled}
                                name={item.name}
                                years={item.term.years}
                                months={item.term.months}
                                description={selectedStrategy.description}
                                handleClick={
                                  (e, titleProps) => this.handleClick(e, titleProps)
                                }
                                item={item}
                                isDisabledStrategy={!item.isAllPaymentsExists}
                              />
                              <Accordion.Content
                                active={Boolean(assets) && index === activeIndex}
                              >
                                {
                                  (this.checkIsActiveWeight(
                                    data.selectedStrategy.refOptionType,
                                  ))
                                  && (
                                    <AutocallBarrier
                                      autocallBarrier={
                                        data.strategies[activeIndex]?.autocallBarrier
                                      }
                                      bonusCoupon={data.strategies[activeIndex]?.bonusCoupon}
                                    />
                                  )
                                }
                                {
                                  assets && assets.length >= 1 ? (
                                    <AssetsTable
                                      isLoading={isPaymentInfoLoading || isPaymentsLoading
                                        || isStrategyLoading}
                                      refOptionType={selectedStrategy.refOptionType}
                                      barrier={selectedStrategy?.periods[0].barrierLevel}
                                      data={assets ?? []}
                                      getNoBarrier={this.getNoBarrierCrossingDesc}
                                    />
                                  ) : null
                                }
                                {selectedPayment && selectedPayment.date && (
                                  <StrategyResult
                                    isLoading={isPaymentInfoLoading || isPaymentsLoading
                                      || isStrategyLoading}
                                    refOptionType={selectedStrategy.refOptionType}
                                  />
                                )}
                              </Accordion.Content>
                            </div>
                          ))}
                      </Accordion>
                    </div>
                  </div>
                )
                : <Loader active={isLoading} />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ProfitabilityInfo;
