export default (headers: string[], data: any[]) => ({
  key: data[0],
  data: [
    {
      md: 1,
      headers: [
        { text: headers[0]},
      ],
      data: [
        { text: data[1]},
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[1]},
      ],
      data: [
        { text: data[2]},
      ],
    },
    {
      md: 5,
      headers: [
        { text: headers[2]},
        { text: headers[3]},
      ],
      data: [
        { text: data[3]},
        { text: data[4]},
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[4]},
        { text: headers[5]},
      ],
      data: [
        { text: data[5]},
        { text: data[6]},
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[6]},
      ],
      data: [
        { text: data[7]},
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[7]},
      ],
      data: [
        { text: data[8]},
      ],
    },
  ],
});
