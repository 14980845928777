import { ActionFactory } from 'helpers';

const factory = new ActionFactory('adminManagmnet');

export const IMPORT_1C = factory.createAsync('IMPORT_1C');
export const CREATE_CONNECTION_USERS_TO_CTGTS = factory.createAsync('CREATE_CONNECTION_USERS_TO_CTGTS');
export const CLEAR_CONNECTION_USERS_TO_CTGTS = factory.createAsync('CLEAR_CONNECTION_USERS_TO_CTGTS');
export const SET_INIT_STORE_FOR_IMPORT = factory.create('SET_INIT_STORE_FOR_IMPORT');
export const CHANGE_GLOBAL_SETTINGS = factory.createAsync('CHANGE_GLOBAL_SETTINGS');
export const GET_GLOBAL_SETTINGS = factory.createAsync('GET_GLOBAL_SETTINGS');
export const GET_LAST_IMPORT_RESULT = factory.createAsync('GET_LAST_IMPORT_RESULT');
export const CHECK_IMPORT = 'CHECK_IMPORT';
export const GET_PRINTED_FORMS_TYPES = factory.createAsync('GET_PRINTED_FORMS_TYPES');
export const SEND_PRINT_FORMS = factory.createAsync('SEND_PRINT_FORMS');
