const API = {
  GET_AGREEMENT_LINK: '/support/agreement',
  SEND_SUPPORT_MESSAGE: '/support/send-message',
  DELETE_USER_CONTACT: '/users/personal-data/contacts/id',
  GET_USER_AVATAR: '/users/avatar',
  GET_USER_MAIN_DATA: '/users/main-data',
  PASSWORD_CONTROL: '/users/account/change-password',
  PERSONAL_CONTACTS_CONTROL: '/users/personal-data/contacts',
  PERSONAL_CONTACTS_CONTROL_VERIFY: '/users/personal-data/contacts-verify',
  CHANGE_CONTACTS_PHONE: '/users/personal-data/contacts-phone',
  CHANGE_CONTACTS_EMAIL: '/users/personal-data/contacts-email',
  PERSONAL_DATA_USER: '/users/personal-data',
  SIGN_IN: '/auth/signin',
  SIGN_UP_ACCOUNT: '/auth/signup/check-data/account',
  SIGN_UP_CONTRACT: '/auth/signup/check-data/contract',
  SIGN_UP_PERSONAL: '/auth/signup/check-data/personal',
  CHECK_USER: '/auth/user',
  GET_IP: '/auth/ip',
  CHECK_ADMIN: '/administration/auth/user',
  SIGN_IN_ADMIN: '/administration/auth/signin',
  GET_ADMIN_URL: '/common/admin-url',
  IMPORT_1C: '/administration/import/1c',
  IMPORT_1C_CHECK: '/administration/import/1c/check',
  LAST_IMPORT_RESULT: '/administration/import/1c/last',
  CONNECTION_USERS_TO_CTGTS: '/administration/managment/matching-users-to-ctgts',
  GLOBAL_SETTINGS: '/administration/managment/global-settings',
  USER_CONTRACTS: '/contracts/isz/user/',
  USER_NSZ_CONTRACTS: '/contracts/nsz/user',
  GET_EARNED_CONTRACT: (id: number) => `/contracts/isz/earned/id${id}`,
  CONTRACT_RISK: (id: number) => `/contracts/id${id}/risks`,
  GET_ADDITIONAL_SERVICES: (id: number) => `/contracts/id${id}/additional-services`,
  GET_REDEMPTIONS: (id: number) => `/contracts/id${id}/redemption`,
  GET_PAYMENTS: (id: number) => `/contracts/id${id}/payments`,
  GET_STRATEGY: (id: number) => `/contracts/id${id}/strategy`,
  GET_CONTRAGENTS: (id: number) => `/contracts/id${id}/contragents`,
  GET_ASSETS: (id: number) => `/contracts/id${id}/assets`,
  GET_INVESTMENT_INCOME: (id: number) => `/contracts/id${id}/investment-income`,
  GET_USERS_ADMINISTRATION: '/administration/personal-card/list',
  SIGN_IN_BY_USER: '/administration/auth/signin-as-user',
  GET_USER_PERSONAL_CARD: (id: number | string) => `/administration/personal-card/id${id}/main`,
  GET_USER_CONTR_AGENT_INFO: (id: string, ctgtsId: number) => `/administration/personal-card/id${id}/contragents/id${ctgtsId}`,
  GET_USER_CONTRACT_INFO: (id: string, ctsId: number) => `/administration/personal-card/id${id}/contracts/id${ctsId}`,
  GET_CONTR_AGENTS: (id: number) => `/administration/personal-card/id${id}/contragents/search`,
  GET_CONTACT_CODES: '/references/contacts/main',
  GET_CONTRACTS_LIST: (id: string, ctgtsId: number) => `/administration/personal-card/id${id}/contragents/id${ctgtsId}/contracts/search`,
  GET_FORM_DESCRIPTIONS: '/references/form-descriptions/main',
  GET_ADMINISTRATORS_LIST: '/administration/administrators',
  GET_ADMINISTRATOR_CARD: (id: number) => `/administration/administrators/${id}`,
  GET_CURRENT_YEAR: '/tools/datetime',
  SAVE_CHANGES: (userId: string) => `/administration/personal-card/id${userId}`,
  GET_PROFITABILITY: (id: string) => `/profitability/id${id}/strategy-profitability/`,
  GET_PROFITABILITY_CONTRACT_INFO: (id: string) => `/profitability/id${id}/contract-info/`,
  GET_PROFITABILITY_STRATEGIES: (id: string) => `/profitability/id${id}/strategies/`,
  GET_PROFITABILITY_STRATEGY: (id: string) => `/profitability/strategy/id${id}`,
  GET_PROFITABILITY_PAYMENTS: (id: string, year: string) => `/profitability/payments/id${id}&year${year}`,
  GET_PROFITABILITY_PAYMENT_INFO: (id: string, date: string) => `/profitability/payment-info/id${id}&date${date}`,
  GET_PROFITABILITY_REPORT: (id: string) => `/contracts/id${id}/investation-strategy-report/`,
  START_PASSWORD_RESET: '/auth/reset-password',
  ADD_PHONE: '/auth/add-phone',
  ADD_PHONE_LOGIN: '/auth/add-phone/login',
  VERIFY_PASSWORD_RESET: '/auth/reset-password/verify-token',
  CHANGE_PASSWORD_RESET_PASSWORD: '/auth/reset-password/change-password',
  CAPTCHA: '/auth/captcha',
  RECAPTCHA: '/auth/recaptcha',
  USER_CONTRACT: '/contracts/isz/contract',
  ACCEPT_RULES: '/auth/accept-rules',
  SEND_APPLICATION: (userId: number) => `/contracts/application/id${userId}`,
  GET_PRINTED_FORMS_TYPES: '/administration/print-forms/print-form-types',
  SEND_PRINT_FORM: '/administration/print-forms',
  SEND_REQ_DADATA: '/contracts/application/send-req-dadata',
  SEND_REQ_FOR_SMS_CODE: '/contracts/application/send-req-for-sms-code',
  CHECK_CODE_IS_VALID: '/contracts/application/check-code-is-valid',
  GET_INN: '/contracts/application/get-inn',
};

export default API;
