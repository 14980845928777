import { block } from 'bem-cn';
import {
  ChangePasswordForm,
  FormInput,
  GreenButton,
  InfoBlock,
  PopupContainer,
} from 'components';
import React, { useState } from 'react';
import './styles.scss';
import {
  changePasswordReset,
  startResetPassword,
  verifyResetPasswordToken,
} from 'redux/rootActions';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import schema from './schema';
import ErrorForm from '../RegiStrationForm/StageFour';
import passwordValidationSchema from '../../_shared/PersonalInfo/schema';
import { getPasswordErrors } from '../../../../helpers';

const {
  LOGIN_AUTH,
  ENTER_LOGIN_FOR_RESET,
  RESET_PASSWORD,
  CHECK_LOGIN,
  EMAIL,
  PHONE_MOBILE,
  PHONE_HOME,
  CHECK_EMAIL_FOR_RESET_PASSWORD,
  PASSWORD_RESETED,
  ENTER_LK,
  ENTER_SMS_CODE,
  SMS_CODE,
} = LANG_DICTIONARY;

const b = block('reset-password-form');

interface IProps {
  startReset: typeof startResetPassword,
  verifyToken: typeof verifyResetPasswordToken
  resetPassword: typeof changePasswordReset
  history: any;
  resetPasswordErrorMessage: string;
  loginType: string;
  loginForReset: string;
  resetToken: string;
  passwordIsChanged: boolean;
}

const ResetPasswordForm: React.FC<IProps> = ({
  history,
  resetPasswordErrorMessage,
  startReset,
  verifyToken,
  loginType,
  loginForReset,
  resetToken,
  resetPassword,
  passwordIsChanged,
}) => {
  const [state, changeState] = useState({
    login: '',
    password: '',
    passwordConfirm: '',
    smsCode: '',
  });
  const [errors, changeErrors] = useState({
    loginError: '',
    errorPassword: '',
    errorField: '',
  });

  const {
    login,
    password,
    passwordConfirm,
    smsCode,
  } = state;

  const { loginError, errorField, errorPassword } = errors;

  const handleSubmitPassword = () => {
    const passwordSchema = true;
    const supportSchema = false;
    try {
      passwordValidationSchema(passwordSchema, supportSchema, password).validateSync({
        oldPassword: password,
        password,
        passwordConfirm,
      });
      const restCode = loginType === 'phone' ? smsCode : resetToken;
      resetPassword(restCode, loginForReset, password, passwordConfirm);
      changeErrors({
        ...errors,
        errorPassword: '',
        errorField: '',
      });
    } catch (e) {
      const { error, path } = getPasswordErrors(e);
      changeErrors({
        ...errors,
        errorPassword: error,
        errorField: path,
      });
    }
  };

  const handleSubmit = () => {
    const check = () => {
      if (login.match('@')) {
        return EMAIL;
      }
      if (login.match(/\+/)) {
        return PHONE_MOBILE;
      }
      return PHONE_HOME;
    };
    schema(check()).isValid({
      login,
    }).then((valid) => {
      if (valid) {
        changeErrors({
          ...errors,
          loginError: '',
        });
        startReset(login);
      } else {
        changeErrors({
          ...errors,
          loginError: CHECK_LOGIN,
        });
      }
    });
  };

  const handleChange = ({target: {name, value}}: React.ChangeEvent<HTMLInputElement>) => {
    changeState({
      ...state,
      [name]: value,
    });
  };

  const resetPasswordButtonDisabled = !login.length;

  const handleClosePopUp = () => {
    history.push(ROUTES.authorization);
  };

  const backToAuthorization = () => {
    history.push(ROUTES.authorization);
  };

  const generateErrorForm = () => (
    <ErrorForm
      errorMessage={resetPasswordErrorMessage}
      handleBack={handleClosePopUp}
    />
  );

  const generateComponent = () => {
    if (resetPasswordErrorMessage) {
      return generateErrorForm();
    }

    if (passwordIsChanged) {
      return generatePasswordChangedForm();
    }

    if (loginForReset) {
      return generateEnterPasswordForm();
    }

    return generateResetPasswordForm();
  };

  const generateEnterPasswordForm = () => (
    <ChangePasswordForm
      info=""
      password={password}
      handleChange={handleChange}
      passwordConfirm={passwordConfirm}
      errorPassword={errorPassword}
      popUpOpen
      closePopUp={handleClosePopUp}
      sendData={handleSubmitPassword}
      oldPassword={password}
      errorField={errorField}
      passwordErrorMessage=""
      isResetPasswordForm
    />
  );

  const generatePasswordChangedForm = () => (
    <div className={b('form')}>
      <div className={b('header')}>{PASSWORD_RESETED}</div>
      <GreenButton
        onClick={backToAuthorization}
        text={ENTER_LK}
        className={b('reset-button')}
      />
    </div>
  );

  const generateResetPasswordForm = () => {
    switch (loginType) {
      case 'phone': {
        return (
          <div className={b('form')}>
            <div className={b('title')}>{ENTER_SMS_CODE}</div>
            <FormInput
              placeholder={SMS_CODE}
              value={smsCode}
              onChange={handleChange}
              name="smsCode"
              mask=""
            />
            <GreenButton
              onClick={() => verifyToken(smsCode)}
              text={RESET_PASSWORD}
              className={b('reset-button')}
              disabled={!smsCode.length}
            />
          </div>
        );
      }

      case 'email': {
        return (
          <div className={b('form')}>
            <div className={b('header')}>{CHECK_EMAIL_FOR_RESET_PASSWORD}</div>
          </div>
        );
      }

      default: {
        return (
          <div className={b('form')}>
            <div className={b('title')}>{ENTER_LOGIN_FOR_RESET}</div>
            <FormInput
              placeholder={LOGIN_AUTH}
              value={login}
              onChange={handleChange}
              name="login"
              mask=""
            />
            <InfoBlock message={loginError} error />
            <GreenButton
              onClick={handleSubmit}
              text={RESET_PASSWORD}
              className={b('reset-button')}
              disabled={resetPasswordButtonDisabled}
            />
          </div>
        );
      }
    }
  };

  return (
    <div className={b()}>
      <PopupContainer
        isOpen
        children={generateComponent()}
        close={handleClosePopUp}
        isCloseButton
      />
    </div>
  );
};

export default ResetPasswordForm;
