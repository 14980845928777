import { block } from 'bem-cn';
import React from 'react';
import InputMask from 'react-input-mask';
import { EyeIconSvg } from 'components';
import './styles.scss';

const b = block('form-input');

interface IProps {
  placeholder?: string,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  name: string,
  type?: string,
  mask?: string,
  maskChar?: string,
  className?: string,
  password?: boolean,
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onClicIcon?: () => void,
}

const FormInput: React.FC<IProps> = (
  {
    placeholder,
    value,
    onChange,
    name,
    type,
    mask,
    maskChar,
    className,
    password,
    onBlur,
    onClicIcon,
  },
) => {
  const hidePassword = type === 'password';

  return (
    <div className={`${b('wrapper')} ${className}`}>
      <InputMask
        className={b.mix(className)}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        type={type}
        mask={mask || ''}
        maskChar={maskChar}
        onBlur={onBlur}
      />
      {password && (
      <div
        className={b('icon')}
        onClick={onClicIcon}
        role="button"
        tabIndex={-1}
      >
        <EyeIconSvg hidePassword={hidePassword} />
      </div>
      )}
    </div>
  );
};

export default FormInput;
