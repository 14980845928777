import { block } from 'bem-cn';
import { PopupContainer } from 'components';
import { LANG_DICTIONARY } from 'consts';
import React from 'react';
import './styles.scss';

const b = block('final-stage-form');
const { YOUR_MESSAGE_SENDED } = LANG_DICTIONARY;

interface IProps {
  closePopUp: () => void;
  isOpen: boolean;
}

const FeedBackMessageSended: React.FC<IProps> = ({
  isOpen = false,
  closePopUp,
}) => {
  const messageAnswer = () => (
    <div className={b()}>
      <div className={b('info-message')}>{YOUR_MESSAGE_SENDED}</div>
    </div>
  );

  return (
    <PopupContainer
      isCloseButton
      close={closePopUp}
      children={messageAnswer()}
      isOpen={isOpen}
    />
  );
};

export default FeedBackMessageSended;
