import { ActionFactory } from 'helpers';

const factory = new ActionFactory('contracts');
export const GET_USERS_CONTRACTS_ISZ = factory.createAsync('GET_USERS_CONTRACTS_ISZ');
export const GET_USERS_CONTRACTS_NSZ = factory.createAsync('GET_USERS_CONTRACTS_NSZ');
export const GET_EARNED = factory.createAsync('GET_EARNED');
export const GET_RISKS = factory.createAsync('GET_RISKS');
export const GET_RISKS_NSZ = factory.createAsync('GET_RISKS_NSZ');
export const GET_ADDITIONAL_SERVICES = factory.createAsync('GET_ADDITIONAL_SERVICES');
export const GET_REDEMPTION = factory.createAsync('GET_REDEMPTION');
export const GET_PAYMENTS = factory.createAsync('GET_PAYMENTS');
export const GET_STRATEGY = factory.createAsync('GET_STRATEGY');
export const GET_CONTRAGENTS = factory.createAsync('GET_CONTRAGENTS');
export const GET_ASSETS = factory.createAsync('GET_ASSETS');
export const GET_INVSTMENT_INCOME = factory.createAsync('GET_INVSTMENT_INCOME');
export const SET_CONTRACT_TYPE = 'SET_CONTRACT_TYPE';
export const SET_SELECTED_TYPE_ID = 'SET_SELECTED_TYPE_ID';
export const GET_CONTRACT_ACTIONS = factory.createAsync('GET_CONTRACT_ACTIONS');
