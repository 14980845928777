const ENVIRONMENT_ENUM = {
  development: 'development',
  production: 'production',
};

const ROUTE_TYPE = {
  public: 'public',
  client: 'client',
  admin: 'admin',
  main: 'main',
};

const TOKEN_TYPE = {
  admin: 'admin_token',
  client: 'user_token',
};

const ISZ_TYPE = {
  first: 1,
  index: 2,
  coupon: 3,
};

export {
  ENVIRONMENT_ENUM,
  ROUTE_TYPE,
  TOKEN_TYPE,
  ISZ_TYPE,
};
