import React from 'react';
import { block } from 'bem-cn';
import './styles.scss';

interface IProps {
  value: string | number,
  error: undefined | string,
  touch: undefined | boolean,
  name: string,
  onChange: (...args: any[]) => any,
  onBlur: (...args: any[]) => any,
  type?: 'password' | 'search',
  placeholder?: string,
  autoComplete?: string,
}

const b = block('admin-preview-input');

const PreviewInput: React.FC<IProps> = ({
  value,
  error,
  touch,
  name,
  onChange,
  onBlur,
  type = 'text',
  placeholder,
  autoComplete,
}) => (
  <div className={b()}>
    <input
      value={value}
      name={name}
      id={name}
      onChange={onChange}
      onBlur={onBlur}
      type={type}
      placeholder={placeholder}
      className={b('input', { error: Boolean(touch && error)})}
      autoComplete={autoComplete}
    />
    <p className={b('error')}>{touch && error}</p>
  </div>
);

export default PreviewInput;
