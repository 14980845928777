import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {sendSupportMessage, getAgreement, changeFieldSupport} from 'redux/rootActions';
import { connect } from 'react-redux';
import { withUserInterface } from 'HOC';
import { HomeInfo } from '../../../components';
import { IMainDataReducer } from '../../../redux/mainData/reducers/interfaces';
import { IRedux } from '../../../redux/interface';
import MainPageContainer from '../MainPage';

type IMapStateToPropsTypes = (state: IRedux) => IMainDataReducer;

interface IMapDispatchToPropsTypes {
  sendMessageSupport: typeof sendSupportMessage;
  changeField: typeof changeFieldSupport;
}

type Props = IMapDispatchToPropsTypes & IMainDataReducer & RouteComponentProps;


const HomePageContainer: React.FC<Props> = (props) => {
  const {
    sendMessageSupport,
    emailForSupport,
    acceptAgreement,
    agreementLink,
    isAdmin,
    messageForSupport,
    changeField,
  } = props;

  return (
    <MainPageContainer>
      <HomeInfo
        messageForSupport={messageForSupport}
        emailForSupport={emailForSupport}
        acceptAgreement={acceptAgreement}
        agreementLink={agreementLink}
        changeField={changeField}
        sendMessageSupport={sendMessageSupport}
        isAdmin={isAdmin}
      />
    </MainPageContainer>
  );
};

const mapStateToProps: IMapStateToPropsTypes = ({ mainDataReducer}) => ({
  ...mainDataReducer,
});

const mapDispatchToProps: IMapDispatchToPropsTypes = {
  sendMessageSupport: sendSupportMessage,
  changeField: changeFieldSupport,
};

export default withUserInterface(withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(HomePageContainer),
));
