import { LANG_DICTIONARY, ROUTES } from 'consts';
import { AuthorizationContainer, ResetPasswordContainer } from 'containers';
import { IRoute } from 'router/interfaces/route';

const { AUTHORIZATION } = LANG_DICTIONARY;

const pathes: IRoute[] = [
  {
    component: AuthorizationContainer,
    path: ROUTES.authorization,
    title: AUTHORIZATION,
    type: 'public',
  },
  {
    component: ResetPasswordContainer,
    path: ROUTES.resetPassword,
    title: AUTHORIZATION,
    type: 'public',
  },
  {
    component: ResetPasswordContainer,
    path: ROUTES.resetPasswordWithToken,
    title: AUTHORIZATION,
    type: 'public',
  },
];

export default pathes;
