import { block } from 'bem-cn';
import { SignIn } from 'components';
import React from 'react';
import './styles.scss';
import { RouteComponentProps } from 'react-router-dom';
import { IAddPhoneLoginPayload, IAddPhoneLoginVerifyPayload } from '../../../../interfaces/addPhoneLoginPayload';

const b = block('auth-form');

interface IProps {
    authorization: (login: string, password: string, captchaId: string, captchaKey: string) => void;
    addPhoneLogin: (payload: IAddPhoneLoginPayload) => void;
    addPhoneLoginVerify: (payload: IAddPhoneLoginVerifyPayload) => void;
    history: RouteComponentProps['history']
    errorMessage: string;
    clearStore: () => void;
    recaptcha: (captchaId: string) => void,
    captcha: string,
    captchaId: string,
    isNeedReset: boolean,
    isPhone: boolean,
    changeAuthLogin: (isPhone: boolean) => void,
}

const AuthForm: React.FC<IProps> = ({
  authorization,
  addPhoneLoginVerify,
  addPhoneLogin,
  history,
  errorMessage,
  clearStore,
  recaptcha,
  captcha,
  captchaId,
  isNeedReset,
  isPhone,
  changeAuthLogin,
}) => (
  <div className={b()}>
    <SignIn
      authorization={authorization}
      addPhoneLogin={addPhoneLogin}
      addPhoneLoginVerify={addPhoneLoginVerify}
      history={history}
      errorMessage={errorMessage}
      clearStore={clearStore}
      recaptcha={recaptcha}
      captcha={captcha}
      captchaId={captchaId}
      isNeedReset={isNeedReset}
      isPhone={isPhone}
      changeAuthLogin={changeAuthLogin}
    />
  </div>
);

export default AuthForm;
