import './styles.scss';

import closeIcon from 'assets/svg/closeIcon.svg';
import block from 'bem-cn';
import React from 'react';
import ReactDom from 'react-dom';

const popup = block('popup-container');

interface IProps {
  isOpen: boolean,
  children: React.ReactNode,
  isCloseButton?: boolean,
  isCloseClickVeil?: boolean,
  className?: string,
  classNameVeil?: string,
  close: () => void,
}

const PopupContainer: React.FC<IProps> = ({
  close,
  isOpen,
  children,
  isCloseButton,
  className = '',
  classNameVeil = '',
  isCloseClickVeil = true,
}) => {
  const veil = React.createRef<HTMLDivElement>();
  const closePopup = (e: any) => {
    if (e.target === veil.current) {
      close();
    }
  };

  const rootRender: any = document.getElementsByClassName('App')[0];

  return isOpen ? ReactDom.createPortal(
    <>
      <div
        className={`veil ${classNameVeil}`}
        ref={veil}
        role="button"
        tabIndex={0}
        onClick={(e) => isCloseClickVeil && closePopup(e)}
      >
        <div className={`${popup()} ${className}`}>
          {isCloseButton && (
          <div
            className={popup('close-btn')}
            onClick={close}
            role="button"
            tabIndex={-1}
          >
            <img src={closeIcon} alt="close" />
          </div>
          )}
          {children}
        </div>
      </div>
    </>, rootRender,
  ) : null;
};

export default PopupContainer;
