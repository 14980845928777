import './styles.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { block } from 'bem-cn';
import {
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import React from 'react';
import { IData } from 'redux/contracts/reducers/interface';
import { SliderControl } from 'components';
import { LANG_DICTIONARY } from 'consts';
import Contract from './Contract';
import Risks from './Risks';
import RedemptionSums from './RedemptionSums';
import PaymentsTable from './Payments';
import ContrAgents from './ContrAgents';
import AdditionalServices from './AdditionalServices';

const b = block('contract-info-slider');
const {
  YOUR_CONTRACT,
  REDEMPTION_SUM_SCHEDULE,
  ADDITIONAL_SERVICES,
  RISKS,
  PAYMENT_SCHEDULE,
  INSURED_INFORMATION,
} = LANG_DICTIONARY;

interface ISlide {
  component: React.FC<any>,
  name: string,
  slideData: any,
}
interface IProps {
  data: IData,
  isIsz: boolean,
}

const IszcContractCarousel: React.FC<IProps> = ({
  data, isIsz,
}) => {
  const {
    contract,
    risks,
    redemptions,
    payments,
    contragents,
    services,
  } = data;

  const slides: ISlide[] = services.additionalServices && services.additionalServices.length ? [
    {
      component: Contract,
      slideData: { contract, isIsz },
      name: YOUR_CONTRACT,
    },
    {
      component: Risks,
      slideData: risks,
      name: RISKS,
    },
    {
      component: AdditionalServices,
      slideData: services,
      name: ADDITIONAL_SERVICES,
    },
    {
      component: RedemptionSums,
      slideData: { redemptions, contract },
      name: REDEMPTION_SUM_SCHEDULE,
    },
    {
      component: PaymentsTable,
      slideData: payments,
      name: PAYMENT_SCHEDULE,
    },
    {
      component: ContrAgents,
      slideData: contragents,
      name: INSURED_INFORMATION,
    },
  ]
    : [
      {
        component: Contract,
        slideData: { contract, isIsz },
        name: YOUR_CONTRACT,
      },
      {
        component: Risks,
        slideData: risks,
        name: RISKS,
      },
      {
        component: RedemptionSums,
        slideData: { redemptions, contract },
        name: REDEMPTION_SUM_SCHEDULE,
      },
      {
        component: PaymentsTable,
        slideData: payments,
        name: PAYMENT_SCHEDULE,
      },
      {
        component: ContrAgents,
        slideData: contragents,
        name: INSURED_INFORMATION,
      },
    ];


  const slidesText = slides.map(({name}) => name);

  return (
    <div className={b()}>
      <div className={b('slider-content')}>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={slides.length}
          infinite
        >
          <Slider className={b('slider')}>
            {slides.map(({
              component: Component,
              name,
              slideData,
            },
            index) => (
              <Slide
                index={index}
                key={name}
              >
                <Component data={slideData} />
              </Slide>
            ))}
          </Slider>
          <SliderControl tabs={slidesText} />
        </CarouselProvider>
      </div>
    </div>
  );
};

export default IszcContractCarousel;
