import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { block } from 'bem-cn';
import './styles.scss';
import { LANG_DICTIONARY } from 'consts';

const b = block('sms-block');

interface IProps {
  isSmsSent: boolean,
  isAllFieldsFilled: boolean | File,
  submitApplication: () => void,
  sendSms: (e: React.MouseEvent<HTMLButtonElement>) => void,
  mobilePhone: string,
  typeCode: (name: string, value: string) => void,
  code: string,
  isValid: boolean,
  setCheckboxValue: (value: boolean, name: string) => void,
}

const {
  INVALID_CODE_ENTERED,
  ENTER_SMS_CODE,
  SEND_CODE_AGAIN,
  SEND_CODE_AGAIN_VIA,
  SUBMIT_APPLICATION,
  CONFIRM_SENDING_APPLICATION,
  GET_CODE_FROM_SMS,
  SMS_TEXT_SENT,
} = LANG_DICTIONARY;

const timerLimit = 120;

const SmsBlock: React.FC<IProps> = (props) => {
  const {
    isSmsSent,
    isAllFieldsFilled,
    submitApplication,
    sendSms,
    mobilePhone,
    typeCode,
    code,
    isValid,
    setCheckboxValue,
  } = props;

  // eslint-disable-next-line prefer-const
  let [timer, setTimer] = useState(timerLimit);
  const [isTimeEnd, setIsTimeEnd] = useState(false);

  const dispatch = useDispatch();

  const timerFunc = () => {
    setTimer(timer = timerLimit);
    const int = setInterval(() => {
      if (timer === 0) {
        setIsTimeEnd(true);
        clearInterval(int);
        return;
      }
      setTimer(timer -= 1);
    }, 1000);
  };

  useEffect(() => () => {
    dispatch(setCheckboxValue(false, 'isSmsSent'));
  }, []);

  const sentCodeAgain = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    sendSms(e);
    setIsTimeEnd(false);
    timerFunc();
  };

  const isCodeLengthLess = code?.split('').length < 6;

  const isDisabledSendSms = !isAllFieldsFilled || !code || isCodeLengthLess;

  return (
    <div>
      { isSmsSent
        ? (
          <div>
            <p className={b('agreement')}>
              {ENTER_SMS_CODE}
            </p>
            <div className={b('sms-code-block')}>
              <div>
                <input
                  className={b('sms-code')}
                  maxLength={6}
                  name="code"
                  onChange={(e) => typeCode(e.target.value, e.target.name)}
                  value={code}
                />
                { isValid === false
                  && (
                    <div className={b('error')}>{INVALID_CODE_ENTERED}</div>
                  )}
              </div>
              <p className={b('sms-code-text')}>
                <div>
                  { isTimeEnd
                    ? (
                      <button
                        className={b('dis-btn')}
                        name="isSmsSentAgain"
                        onClick={(e) => sentCodeAgain(e)}
                      >
                        {SEND_CODE_AGAIN}
                      </button>
                    )
                    : SEND_CODE_AGAIN_VIA(timer)}
                </div>
              </p>
            </div>
            <button
              className={b('button', { disabled: isDisabledSendSms })}
              disabled={isDisabledSendSms}
              onClick={submitApplication}
            >
              {SUBMIT_APPLICATION}
            </button>
          </div>
        )
        : (
          <div>
            <p className={b('agreement')}>{CONFIRM_SENDING_APPLICATION}</p>
            <div className={b('sms-block')}>
              <button
                className={b('sms-button-send', { disabled: !isAllFieldsFilled })}
                name="isSmsSent"
                disabled={!isAllFieldsFilled}
                onClick={(e) => {
                  sendSms(e);
                  timerFunc();
                }}
              >
                {GET_CODE_FROM_SMS}
              </button>
              <p className={b('sms-text-send')}>
                {SMS_TEXT_SENT(mobilePhone)}
              </p>
            </div>
          </div>
        )}
    </div>
  );
};

export default SmsBlock;
