import { HeadPanel, Footer } from 'components';
import { connect } from 'react-redux';
import { logOut } from 'redux/rootActions';
import React from 'react';

export default (Component) => {
  const UserInterface = ({
    token, exit, currentYear,
  }) => (
    <>
      <HeadPanel token={token} exit={exit} />
      <Component />
      {token && <Footer currentYear={currentYear} />}
    </>
  );
  const mapStateToProps = ({
    authReducer: {
      token,
    },
    mainDataReducer: {
      currentYear,
    },
  }) => ({
    token,
    currentYear,
  });

  const mapDispatchToProps = {
    exit: logOut,
  };

  return connect(mapStateToProps, mapDispatchToProps)(UserInterface);
};
