import React from 'react';
import {block} from 'bem-cn';
import { MyIszDataContainer, MyNszDataContainer } from 'containers';
import {
  ContractsTabs,
  FeedBackMessageSended,
  SupportBlock,
} from 'components';
import { IRedux } from 'redux/interface';
import { setContractType } from 'redux/contracts/actions';
import {gerErrorFromSchema} from 'helpers';
import {connect} from 'react-redux';
import schema from '../PersonalInfo/schema';

const b = block('personal-info');

interface IProps {
  messageForSupport: string;
  emailForSupport: string;
  acceptAgreement: boolean;
  agreementLink: string;
  changeField: (name: string, value: any) => void;
  sendMessageSupport: (data: ISendMessage) => void;
  isAdmin: boolean;
  setType: (value: boolean) => void;
  isIsz: boolean;
}

interface IState {
  errorSupport: string;
  feedBackPopUpOpen: boolean;
}

interface ISendMessage {
  email: string;
  message: string;
}

class HomeInfo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      errorSupport: '',
      feedBackPopUpOpen: false,
    };
  }

  public sendSupportMessage = () => {
    const {
      sendMessageSupport,
      emailForSupport,
      messageForSupport,
      isAdmin,
    } = this.props;
    if (isAdmin) {
      return null;
    }
    const passwordSchema = false;
    const supportSchema = true;
    try {
      schema(passwordSchema, supportSchema).validateSync({
        email: emailForSupport,
        message: messageForSupport,
      });
      sendMessageSupport({email: emailForSupport, message: messageForSupport});
      this.setState({
        feedBackPopUpOpen: true,
      });
    } catch (e) {
      const errorMessage = gerErrorFromSchema(e);
      this.setState({
        errorSupport: errorMessage,
      });
    }
    return null;
  }

  public changeFieldSupport = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {isAdmin} = this.props;
    if (isAdmin) {
      return null;
    }
    const {changeField} = this.props;
    const {name} = e.target;
    const {value} = e.target;
    changeField(name, value);
    this.setState({
      errorSupport: '',
    });
    return null;
  }

  public chekcBoxSupportControl = () => {
    const {changeField, acceptAgreement, isAdmin} = this.props;
    changeField('acceptAgreement', !acceptAgreement);
    if (isAdmin) {
      return null;
    }
    return null;
  }

  public feedBackMessagePopUpControl = () => {
    const { feedBackPopUpOpen } = this.state;
    this.setState({
      feedBackPopUpOpen: !feedBackPopUpOpen,
    });
  }

  render() {
    const {
      errorSupport,
      feedBackPopUpOpen,
    } = this.state;

    const {
      messageForSupport,
      acceptAgreement,
      agreementLink,
      emailForSupport,
      isIsz,
      setType,
    } = this.props;

    return (
      <div>
        <FeedBackMessageSended
          isOpen={feedBackPopUpOpen}
          closePopUp={this.feedBackMessagePopUpControl}
        />
        <ContractsTabs isIsz={isIsz} setType={setType} />
        {isIsz
          ? <MyIszDataContainer />
          : <MyNszDataContainer />}
        <SupportBlock
          email={emailForSupport}
          message={messageForSupport}
          onChange={this.changeFieldSupport}
          sendMessage={this.sendSupportMessage}
          link={agreementLink}
          isActiveChekbox={acceptAgreement}
          checkboxClick={this.chekcBoxSupportControl}
          errorSupport={errorSupport}
        />
      </div>
    );
  }
}

const mapStateToProps = ({contracts: isIsz}: IRedux) => (isIsz);

const mapDispatchToProps = {
  setType: setContractType,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeInfo);
