import { block } from 'bem-cn';
import {
  FormInput,
  GreenButton,
  InfoBlock,
} from 'components';
import { DATE_FORMAT, LANG_DICTIONARY, MASKS } from 'consts';
import { ctsNumberFullLenght } from 'config';
import ru from 'date-fns/locale/ru';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import InputMask from 'react-input-mask';
import contract from 'assets/images/contract.png';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';
import classNames from 'classnames';
import infoButton from 'assets/svg/infoButton.svg';

registerLocale('ru', ru);

const b = block('stage-one-form');
const { ctsNumberMask, dateWithDots } = MASKS;
const { DATE_PICKER_DATE } = DATE_FORMAT;
const {
  REGISTRATION_WORD_FORM,
  PHONE_NUMBER,
  EL_MAIL,
  LOGIN_EMAIL_AUTH,
  LOGIN_PHONE_AUTH,
  ENTER_CTS_NUMBER,
  ENTER_DATE_SIGN,
  SEND_DATA_WORD,
  REG_INFO,
} = LANG_DICTIONARY;

const {
  phoneNumberMask,
} = MASKS;

interface IProps {
  login: string;
  dateSign: any;
  ctsNumber: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sendData: () => void;
  info: string;
  handleChangeDate: (date: Date) => void;
  errorLogin: string;
}

const disableButton = (firstCondition: string, secondCondition: string, thirdCondition: string) => {
  const checkCtsNumber = thirdCondition.trim();
  return Boolean(firstCondition.length)
  && Boolean(secondCondition)
  && checkCtsNumber.length === ctsNumberFullLenght;
};

const StageOne: React.FC<IProps> = (props) => {
  const {
    dateSign,
    ctsNumber,
    handleChange,
    sendData,
    info,
    handleChangeDate,
    errorLogin,
    login,
  } = props;

  return (
    <div className={b()}>
      <div className={b('header')}>{REGISTRATION_WORD_FORM}</div>
      <FormInput
        placeholder={LOGIN_PHONE_AUTH}
        value={login}
        onChange={handleChange}
        name="login"
        mask={phoneNumberMask}
      />
      <div className={b('input-field')}>
        <DatePicker
          selected={dateSign}
          onChange={(date: Date) => handleChangeDate(date)}
          maxDate={new Date()}
          placeholderText={ENTER_DATE_SIGN}
          locale="ru"
          showYearDropdown
          showPopperArrow={false}
          dateFormat={DATE_PICKER_DATE}
          customInput={(
            <InputMask value={dateSign} mask={dateWithDots} />
          )}
        />
        <div className={b('icon')}>
          <div className={b('info')}>
            <img className={b('info-button')} src={infoButton} alt="info-icon" />
            <div className={b('tooltip')}>
              <div className={b('icon-description')}>
                {REG_INFO}
              </div>
              <img src={contract} alt="Пример шапки договора" />
            </div>
          </div>
        </div>
        <div />
      </div>
      <div className={b('input-field')}>
        <FormInput
          value={ctsNumber}
          mask={ctsNumberMask}
          placeholder={ENTER_CTS_NUMBER}
          onChange={handleChange}
          name="ctsNumber"
          maskChar=" "
        />
      </div>
      <div className={b('control-btn')}>
        <GreenButton
          disabled={!disableButton(login, dateSign, ctsNumber)}
          text={SEND_DATA_WORD}
          onClick={() => sendData()}
        />
      </div>
      {errorLogin && <InfoBlock message={errorLogin} error />}
      <InfoBlock message={info} />
    </div>
  );
};

export default StageOne;
