import { API } from 'consts';
import { apiCall } from 'helpers';
import { put, takeLatest, debounce } from 'redux-saga/effects';
import {
  CHECK_CODE_IS_VALID,
  GET_USER_BANKS_INN,
  GET_USER_CONTRACT,
  SEND_APPLICATION,
  SEND_REQ_DADATA,
  SEND_REQ_FOR_SMS_CODE,
} from '../types';
import { IApplicationData, IGetSmsCode } from '../reducers/interface';

function* getContract({ payload }: { payload: number }) {
  try {
    yield put({ type: GET_USER_CONTRACT.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.USER_CONTRACT,
      query: {
        id: payload,
      },
    });
    yield put({ type: GET_USER_CONTRACT.success, payload: data });
  } catch (e) {
    yield put({ type: GET_USER_CONTRACT.fail });
  }
}

function* sendApplication({ payload }: { payload: IApplicationData }) {
  try {
    const {
      userId,
      formData,
    } = payload;

    yield put({ type: SEND_APPLICATION.start });
    const data = yield apiCall({
      type: 'POST',
      url: API.SEND_APPLICATION(userId),
      body: formData,
    });
    yield put({ type: SEND_APPLICATION.success, payload: data });
  } catch (e) {
    yield put({ type: SEND_APPLICATION.fail });
  }
}

function* sendReqDaData({ bic }: { bic: string, type: string }) {
  try {
    yield put({ type: SEND_REQ_DADATA.start });
    const data = yield apiCall({
      type: 'POST',
      url: API.SEND_REQ_DADATA,
      body: { bicNumber: bic },
    });
    const rebuildData = data.data.suggestions.map((item: any) => ({
      description: item.value,
      data: item.data,
    }));
    yield put({ type: SEND_REQ_DADATA.success, payload: rebuildData });
  } catch (e) {
    yield put({ type: SEND_REQ_DADATA.fail });
  }
}

function* sendReqForSmsCode(body: { payload: IGetSmsCode }) {
  try {
    yield put({ type: SEND_REQ_FOR_SMS_CODE.start });
    const data = yield apiCall({
      type: 'POST',
      url: API.SEND_REQ_FOR_SMS_CODE,
      body: body.payload,
    });
    yield put({ type: SEND_REQ_FOR_SMS_CODE.success, payload: data });
  } catch (e) {
    yield put({ type: SEND_REQ_FOR_SMS_CODE.fail });
  }
}

function* checkCodeIsValid(body: { payload :
      { phone: string,
        code: string, userId: number, selectedTypeId: number, contractNumFull: string } }) {
  try {
    yield put({ type: CHECK_CODE_IS_VALID.start });
    const data = yield apiCall({
      type: 'POST',
      url: API.CHECK_CODE_IS_VALID,
      body: body.payload,
    });
    yield put({ type: CHECK_CODE_IS_VALID.success, payload: data.data });
  } catch (e) {
    yield put({ type: CHECK_CODE_IS_VALID.fail });
  }
}

function* getBankInn() {
  try {
    yield put({ type: GET_USER_BANKS_INN.start });
    const data = yield apiCall({
      type: 'GET',
      url: API.GET_INN,
    });
    yield put({ type: GET_USER_BANKS_INN.success, payload: data.data });
  } catch (e) {
    yield put({ type: GET_USER_BANKS_INN.fail });
  }
}

export default function* applicationSaga() {
  yield takeLatest<string, any>(GET_USER_CONTRACT.request, getContract);
  yield takeLatest<string, any>(SEND_APPLICATION.request, sendApplication);
  yield debounce(1000, SEND_REQ_DADATA.request, sendReqDaData);
  yield takeLatest<string, any>(SEND_REQ_FOR_SMS_CODE.request, sendReqForSmsCode);
  yield takeLatest<string, any>(CHECK_CODE_IS_VALID.request, checkCodeIsValid);
  yield takeLatest(GET_USER_BANKS_INN.request, getBankInn);
}
