import { IContractTerm } from '../../contracts/reducers/interface';

// eslint-disable-next-line no-shadow
export enum RefPaymentTerm {
  OnceAMonth = 1,
  OnceAQuarter = 2,
  OnceAHalfYear = 3,
  OnceAYear = 4,
  AtTheEndOfTheTerm = 5,
}

export interface IContractModel {
  totalIncome: string;
  actualDate: string;
  ctsNumber: string;
  startDate: string;
  endDate: string;
  productName: string;
  insuranceSum: string;
  watchDate: string;
  expectedProfitability: string;
  allYears: number;
  premiumCur: string;
  garanteeLevel: string;
  regularCoupon: string;
  dateOfPrice: string;
  partCoef: string;
  refOptionType: number;
  dateInvest: string;
  isAutocalled: boolean,
  nameShort: string,
  refPaymentTerm: number,
}

export interface IAssetModel {
  name: string;
  ticker: string;
  assetPriceChange: number;
  assetPriceIncrease: number;
  weight: number;
  assetIsCrossBarrier: boolean;
}

export interface ISelectedPayment {
  date: string;
  assets: IAssetModel[];
  earnedByMoney: number;
  earnedByPiecesBarrierOption: number;
  memoryEffectEarnedByMoney: number;
  expectedStrategyYield?: number;
  participationStrategyRate?: number,
  memoryEffectEarnedByPieces: number;
}

export interface IPaymentDateModel {
  date: string;
  actualDate: string;
  quartalNumber: number;
  assets: IAssetModel[];
  disabledDate: boolean;
  isAutoCreated: boolean;
}

export interface IInvestStrategyModel {
  year: number;
  barrierLevel: number;
  paymentDates: IPaymentDateModel[];
  isAllPaymentsExists: boolean;
}

export interface IStrategyModel {
  id: number
  number: string,
  name: string,
  term: {
    years: number,
    months: number,
  },
  isAutocalled: boolean,
  isAllPaymentsExists: boolean,
}

export interface ISelectedPeriodPayments {
  date: string;
  isDisabled: boolean;
  quartalNumber: number;
}

export interface ISelectedPeriod {
  date: string;
  isDisabled: boolean;
  barrierLevel: number;
}

export interface ISelectedStrategy {
  id: number;
  number: number;
  description: string;
  name: string;
  term: IContractTerm;
  isAutocalled: boolean;
  isAllPaymentsExists: boolean;
  defaultPeriod: number;
  periods: ISelectedPeriod[];
  refPaymentTerm: number;
  refOptionType: number;
  dateOfPrice: string;
}

export interface IInvestPeriodModel {
  id: number;
  isAutocalled: boolean;
  isHaveAssetWithBadDate: boolean;
  isAllPaymentsExists: boolean;
  name: string;
  term: IContractTerm;
  number: number;
  periods: IInvestStrategyModel[];
  dateInvestPayments: IPaymentDateModel[];
  refPaymentTerm: number;
  refOptionType: number;
  dateOfPrice: string;
}

export interface IProfitabilityModel {
  contract: IContractModel;
  strategies: IInvestPeriodModel[];
  selectedStrategy: ISelectedStrategy;
  selectedPeriodPayments: ISelectedPeriodPayments[];
  selectedPeriod: number;
  selectedPayment: ISelectedPayment;
}

export interface IProfitabilityReducer {
  profitabilityData: IProfitabilityModel;
  errorMessage: string;
  isLoading: boolean;
  isStrategyLoading: boolean;
  isPaymentsLoading: boolean;
  isPaymentInfoLoading: boolean;
  isLoadingReport: boolean;
}
