interface IError {
  errors: string[];
  path: string;
}
const gerErrorFromSchema = (e: IError) => {
  const { errors } = e;
  return errors && errors[0];
};

const getPasswordErrors = (e: IError) => {
  const { errors } = e;
  const { path } = e;
  return { error: errors[0], path};
};

const validatePersonalInfo = (value: string) => {
  // eslint-disable-next-line no-useless-escape
  const personalNameTest = /^[\sа-яА-ЯёЁ\-]+$/;
  return personalNameTest.test(value);
};

const validatePassword = (value: string, passwordConfirm: string, login: string) => {
  const passwordIncludeLogin = value.match(login);
  const passwordEqualPasswordConfirm = value !== passwordConfirm;
  return passwordEqualPasswordConfirm && passwordIncludeLogin;
};

export {
  gerErrorFromSchema,
  validatePersonalInfo,
  validatePassword,
  getPasswordErrors,
};
