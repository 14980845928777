import './styles.scss';
import React from 'react';
import { block } from 'bem-cn';
import {Redemption, IRedemption} from 'redux/contracts/reducers/interface';
import { connect } from 'react-redux';
import { LANG_DICTIONARY } from 'consts';
import { rebuildCurrency } from 'helpers';
import { WhiteTable, InfoBlock } from 'components';
import moment from 'moment';

interface IProps {
  data: IRedemption,
  descriptions: any;
}

const b = block('isz-redemption-sums-data');
const slider = block('main-slider');
const contentClass = b('content').mix(slider('content', { right: true }));
const {
  NUMBER_OF_YEARS_UNTIL_THE_END_OF_THE_CONTRACT,
  REDEMPTION_SUM,
  PERCENTAGE_OF_SS_ON_SURVIVAL,
  REDEMPTION_SUM_SCHEDULE,
  TODAY,
} = LANG_DICTIONARY;

const rowsNumber = 15;
const daysInYear = 365;

const RedemptionSums: React.FC<IProps> = ({data, descriptions}) => {
  const headers = [
    NUMBER_OF_YEARS_UNTIL_THE_END_OF_THE_CONTRACT,
    `${REDEMPTION_SUM} *`,
    PERCENTAGE_OF_SS_ON_SURVIVAL,
  ];

  const getCurDate = moment();
  const getBegDate = moment(data.contract.dateReg);

  const diffDays = getCurDate.diff(getBegDate, 'days') / daysInYear;

  const dataSmallerRowsNumber = data.redemptions.slice(0, data.redemptions.length / 2);
  const dataBiggerRowsNumber = data.redemptions.slice(data.redemptions.length / 2);

  const activeElementIndex = data.redemptions.findIndex(
    (value) => Number(value.redemptionOrder) >= diffDays,
  );

  const rebuildData = (newData: Redemption[]) => (
    newData.map(
      (item, index) => (index === activeElementIndex ? [
        <div className={b('selected-row')}>
          <div>{item.redemptionOrder}</div>
          <div>{TODAY}</div>
        </div>,
        <div className={b('selected-row')}>
          {rebuildCurrency(String(item.redemptionSumRur))}
          {' '}
          {item.redemptionCurrencyName}
        </div>,
        <div className={b('selected-row')}>
          {item.redemptionPercents}
          {' '}
          %
        </div>,
      ]
        : [
          item.redemptionOrder,
          `${rebuildCurrency(String(item.redemptionSumRur))} ${item.redemptionCurrencyName}`,
          `${item.redemptionPercents}%`,
        ]),
    )
  );

  return (
    <div className={b.mix(slider)}>
      <div className={b('wrapper')}>
        <div className={contentClass}>
          <div className={b('header-form-text')}>{REDEMPTION_SUM_SCHEDULE}</div>
          {data.redemptions.length > rowsNumber
            ? (
              <div className={b('tables')}>
                <div className={b('left-table')}>
                  <WhiteTable data={rebuildData(dataSmallerRowsNumber)} headers={headers} />
                </div>
                <div className={b('right-table')}>
                  <WhiteTable data={rebuildData(dataBiggerRowsNumber)} headers={headers} />
                </div>
              </div>
            )
            : <WhiteTable data={rebuildData(data.redemptions)} headers={headers} />}
          <InfoBlock message={descriptions['form-lk-isz-1']} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps: any = ({
  descriptions: {
    descriptions,
  },
}: {descriptions: any}) => ({
  descriptions,
});

export default connect(mapStateToProps, null)(RedemptionSums);
