import './styles.scss';

import React from 'react';
import { block } from 'bem-cn';
import { TableGenerator } from 'components';

interface IProps {
  data: any[][],
  headers?: any[],
  className?: string,
  isFirstRowColor?: boolean,
}

const b = block('transparent-table');

const TransparentTable: React.FC<IProps> = ({
  headers = [],
  data,
  className = '',
  isFirstRowColor = true,
}) => (
  <TableGenerator
    headers={headers}
    data={data}
    className={b.mix(className)}
    classNameTr={b('row', { first: isFirstRowColor })}
    classNameTd={b('value')}
    classNameTh={b('header')}
  />
);

export default TransparentTable;
