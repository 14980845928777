import {
  GET_PROFITABILITY_REQUEST,
  GET_PROFITABILITY_REPORT_REQUEST,
  CLEAR_PROFITABILITY_PAGE,
  GET_PROFITABILITY_CONTRACT_INFO_REQUEST,
  GET_PROFITABILITY_STRATEGIES_REQUEST,
  GET_PROFITABILITY_STRATEGY_REQUEST,
  GET_PROFITABILITY_PAYMENTS_REQUEST,
  GET_PROFITABILITY_PAYMENT_INFO_REQUEST,
  CHANGE_PROFITABILITY_SELECTED_PERIOD,
} from '../types';

const getProfitability = (contractNumber: string) => ({
  payload: contractNumber,
  type: GET_PROFITABILITY_REQUEST,
});

const getProfitabilityContractInfo = (contractNumber: string) => ({
  payload: contractNumber,
  type: GET_PROFITABILITY_CONTRACT_INFO_REQUEST,
});

const getProfitabilityStrategies = (contractNumber: string) => ({
  payload: contractNumber,
  type: GET_PROFITABILITY_STRATEGIES_REQUEST,
});

const getProfitabilityStrategy = (strId: number) => ({
  payload: strId,
  type: GET_PROFITABILITY_STRATEGY_REQUEST,
});

const getProfitabilityPayments = (strategyId: number, year: number) => ({
  payload: { id: strategyId, year },
  type: GET_PROFITABILITY_PAYMENTS_REQUEST,
});

const getProfitabilityPaymentInfo = (id: number, date:string) => ({
  payload: { id, date },
  type: GET_PROFITABILITY_PAYMENT_INFO_REQUEST,
});

const getProfitabilityReport = (body: any) => ({
  payload: body,
  type: GET_PROFITABILITY_REPORT_REQUEST,
});

const clearProfitabilityPage = () => ({
  type: CLEAR_PROFITABILITY_PAGE,
});

const changeProfitabilitySelectedPeriod = (periodNumber: number) => ({
  payload: periodNumber,
  type: CHANGE_PROFITABILITY_SELECTED_PERIOD,
});

export {
  getProfitability,
  getProfitabilityContractInfo,
  getProfitabilityStrategies,
  getProfitabilityStrategy,
  getProfitabilityPayments,
  getProfitabilityPaymentInfo,
  getProfitabilityReport,
  clearProfitabilityPage,
  changeProfitabilitySelectedPeriod,
};
