import {
  CHANGE_CONTACTS_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  DELETE_PERSONAL_CONTACT_REQUEST,
  GET_AVATAR_USER_REQUEST,
  GET_CONTACTS_INFO_REQUEST,
  GET_MAIN_DATA_USER_REQUEST,
  GET_PASSWORD_INFO_REQUEST,
  GET_PERSONAL_DATA_REQUEST,
  SET_AVATAR_USER_REQUEST,
  OPEN_PASSWORD_POPUP,
  GET_AGREEMENT_REQUEST,
  SEND_SUPPORT_MESSAGE_REQUEST,
  CHANGE_FIELD_MAINDATA,
  GET_CURRENT_YEAR,
  CONTACTS_VERIFY_REQUEST,
  CHANGE_CONTACTS_PHONE_REQUEST,
  CHANGE_CONTACTS_EMAIL_REQUEST,
  CLEAR_CODE_TYPE,
  SET_IS_ACCEPTED_AGREEMENT,
} from '../types';

interface ISendMessage {
  email: string;
  message: string;
}

const getCurrentYear = () => ({
  type: GET_CURRENT_YEAR.request,
});

const getAgreement = () => ({
  type: GET_AGREEMENT_REQUEST,
});

const sendSupportMessage = ({email, message}: ISendMessage) => ({
  type: SEND_SUPPORT_MESSAGE_REQUEST,
  payload: { email, message },
});

const changeFieldSupport = (name: string, value: any) => ({
  type: CHANGE_FIELD_MAINDATA,
  payload: { name, value },
});

const openPasswordPopUp = (type: string) => ({
  type: OPEN_PASSWORD_POPUP,
  payload: type,
});

const getPersonalData = () => ({
  type: GET_PERSONAL_DATA_REQUEST,
});

const deletePersonalContact = (id: number) => ({
  payload: id,
  type: DELETE_PERSONAL_CONTACT_REQUEST,
});

const getMainData = () => ({
  type: GET_MAIN_DATA_USER_REQUEST,
});

const getAvatarUser = () => ({
  type: GET_AVATAR_USER_REQUEST,
});

const setAvatarUser = (data: FormData) => ({
  payload: data,
  type: SET_AVATAR_USER_REQUEST,
});

const getInfoPassowrd = () => ({
  type: GET_PASSWORD_INFO_REQUEST,
});

const getInfoContacts = () => ({
  type: GET_CONTACTS_INFO_REQUEST,
});

const changePassword = (
  oldPassword: string,
  newPassword: string,
  passwordConfirmation: string,
) => ({
  payload: {
    newPassword,
    oldPassword,
    passwordConfirmation,
  },
  type: CHANGE_PASSWORD_REQUEST,
});

const changeContact = (contact: string) => ({
  payload: { contact },
  type: CHANGE_CONTACTS_REQUEST,
});

const changeContactPhone = (contact: string, smsCode: string) => ({
  payload: { contact, smsCode },
  type: CHANGE_CONTACTS_PHONE_REQUEST,
});

const changeContactEmail = (contact: string, code: string) => ({
  payload: { contact, code },
  type: CHANGE_CONTACTS_EMAIL_REQUEST,
});

const contactVerify = (contact: string) => ({
  payload: { contact },
  type: CONTACTS_VERIFY_REQUEST,
});

const clearCodeType = () => ({
  type: CLEAR_CODE_TYPE,
});

export const setIsAcceptedAgreement = (isAcceptedAgreement: boolean) => ({
  payload: isAcceptedAgreement,
  type: SET_IS_ACCEPTED_AGREEMENT,
});

export {
  clearCodeType,
  openPasswordPopUp,
  changeContact,
  contactVerify,
  changeContactPhone,
  changeContactEmail,
  changePassword,
  deletePersonalContact,
  getInfoContacts,
  getAvatarUser,
  getInfoPassowrd,
  getMainData,
  getPersonalData,
  setAvatarUser,
  changeFieldSupport,
  sendSupportMessage,
  getAgreement,
  getCurrentYear,
};
