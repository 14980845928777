import { RouteComponentProps } from 'react-router-dom';
import React from 'react';
import { withRouter } from 'react-router';
import { History, createBrowserHistory } from 'history';

let globalHistory: History = createBrowserHistory();

class HistoryComponent extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);
    globalHistory = props.history;
  }

  componentDidUpdate() {
    const { history } = this.props;
    globalHistory = history;
  }

  render() {
    return null;
  }
}

export const GlobalHistory = withRouter(HistoryComponent);
export const getGlobalHistory = () => globalHistory;
