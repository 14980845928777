import './styles.scss';

import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import {
  FlexTableContrAgents,
  AdminSectionTitle,
  InfoTable,
} from 'components';
import React from 'react';
import { IContrAgents } from 'redux/adminUserCard/reducers/interface';
import { ENUMS } from 'containers/_administration/UserCard';

const { CONTR_AGENTS, CONTR_AGENT_DATA } = LANG_DICTIONARY;

const b = block('user-card-contr-agents');

type TAction = 'contacts' | 'contragents' | 'contracts';

interface IProps {
  contragents: IContrAgents[];
  addAction: (type: TAction) => void;
  deleteAction: (type: TAction, id: number | string) => void;
  getCurrentCtgtCard: (id: number | string) => void;
  listOfCtgts: any[];
  updateListOfCtgts: (filter: string) => void;
  currentContrAgent: any;
  showTablesControl: (type: string) => void;
  showTable: boolean;
  chooseCtgt: (id: number | string) => void;
  choosedCtgt: number | string | null;
  handleChangeValueCtgts: (id: number | string, name: string, value: string) => void;
  hadleSetContrAgent: (id: number | string, value: IContrAgents) => void;
}

const ContrAgentsForm: React.FC<IProps> = ({
  contragents,
  addAction,
  deleteAction,
  getCurrentCtgtCard,
  listOfCtgts,
  updateListOfCtgts,
  currentContrAgent,
  showTablesControl,
  showTable,
  choosedCtgt,
  chooseCtgt,
  handleChangeValueCtgts,
  hadleSetContrAgent,
}) => (
  <div className={b()}>
    <button
      onClick={() => showTablesControl(ENUMS.contragents)}
      type="button"
      tabIndex={0}
      className={b('control-btn')}
    >
      <AdminSectionTitle title={CONTR_AGENTS} addButtonBoolean />
    </button>
    {showTable && (
    <div className={b('contragents-block')}>
      <div className={b('table')}>
        <FlexTableContrAgents
          contragents={contragents}
          addAction={addAction}
          deleteAction={deleteAction}
          getCurrentCtgtCard={getCurrentCtgtCard}
          listOfCtgts={listOfCtgts}
          updateListOfCtgts={updateListOfCtgts}
          choosedCtgt={choosedCtgt}
          chooseCtgt={chooseCtgt}
          handleChangeValueCtgts={handleChangeValueCtgts}
          hadleSetContrAgent={hadleSetContrAgent}
        />
      </div>
      {Boolean(currentContrAgent.length) && <AdminSectionTitle title={CONTR_AGENT_DATA} />}
      <InfoTable
        data={currentContrAgent}
      />
    </div>
    )}
  </div>
);

export default ContrAgentsForm;
