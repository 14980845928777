import './styles.scss';

import React from 'react';
import { block } from 'bem-cn';
import contractSuccessStatus from 'assets/images/contract-success-status.png';

const b = block('contract-status');

interface IProps {
  status: string,
  className?: string,
}

const ContractStatus: React.FC<IProps> = ({
  status,
  className = '',
}) => (
  <div className={b.mix(className)}>
    <img
      src={contractSuccessStatus}
      className={b('img')}
      alt="contract-success-status"
    />
    <p className={b('status')}>{status}</p>
  </div>
);

export default ContractStatus;
