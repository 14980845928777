import './styles.scss';

import React, { useState } from 'react';
import {
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import { block } from 'bem-cn';
import arrowLeft from 'assets/images/arrow-left.png';

const b = block('slider-control');

interface IProps {
  tabs: string[],
}

const SliderControl: React.FC<IProps> = ({ tabs }) => {
  const [tab, setCurrentTab] = useState(0);

  const getNextButtonText = () => {
    if (tab === tabs.length - 1) {
      return tabs[0];
    }

    return tabs[tab + 1];
  };

  const getBackButtonText = () => {
    if (tab === 0) {
      return tabs[tabs.length - 1];
    }

    return tabs[tab - 1];
  };

  return (
    <div className={b()}>
      <div className={b('panel')}>
        <ButtonBack
          className={b('button', { left: true })}
        >
          <div>
            <img
              src={arrowLeft}
              alt="arrow-left"
              className={b('arrow', { left: true })}
            />
            {getBackButtonText()}
          </div>
        </ButtonBack>
        <DotGroup
          renderDots={(props) => {
            setCurrentTab(props.currentSlide as number);
            return (<DotGroup className={b('dots')} />);
          }}
        />
        <ButtonNext
          className={b('button', { right: true })}
        >
          <div>
            {getNextButtonText()}
            <img
              src={arrowLeft}
              alt="arrow-right"
              className={b('arrow', { right: true })}
            />
          </div>
        </ButtonNext>
      </div>
    </div>
  );
};

export default SliderControl;
