import React from 'react';
import { block } from 'bem-cn';
import './styles.scss';
import { DATE_FORMAT, LANG_DICTIONARY } from 'consts';
import { dateFormatter, simpleDate, monthFormatter } from 'helpers';
import { IInsuranceData } from 'redux/applications/reducers/interface';

const b = block('insuranceContract');

interface IProps {
  insuranceData: IInsuranceData
}

const InsuranceContract: React.FC<IProps> = ({
  insuranceData,
}) => {
  const {
    productName,
    numFull,
    dateReg,
    premiumCur,
    contractTerm,
    ctsStatusName,
    symbol,
  } = insuranceData;

  const premCur = premiumCur?.toLocaleString('ru');

  return (
    <>
      <p className={b('header')}>
        {LANG_DICTIONARY.INSURANCE_CONTRACT}
      </p>
      <div className={b()}>
        <div className={b('container')}>
          <div className={b('flex-item')}>{productName}</div>
          <div className={b('flex-item')}>
            <div>{numFull}</div>
            <div>{`от ${simpleDate(dateReg, DATE_FORMAT.DATE)}`}</div>
          </div>
          <div className={b('flex-item')}>
            <div>
              {`${premCur} ${symbol}`}
            </div>
            <div>
              {
                `${contractTerm?.years ? dateFormatter(contractTerm?.years) : ''}
                ${contractTerm?.months ? monthFormatter(contractTerm?.months) : ''}`
              }
            </div>
          </div>
          <div className={b('item-status')}>
            <p className={b('item-status-text')}>{ctsStatusName}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsuranceContract;
