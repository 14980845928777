import { LANG_DICTIONARY, DATE_FORMAT, ROUTES } from 'consts';
import React from 'react';
import { simpleDate, getGlobalHistory } from 'helpers';
import { FlexTable } from 'components';
import pencil from 'assets/svg/pencil.svg';
import userIcon from 'assets/images/userIcon.png';
import { IUsers } from 'redux/adminUsers/reducers/interface';
import { signInByUserId } from 'redux/rootActions';
import template from './template';

const {
  MANAGMENT,
  ID_WORD,
  DATE_LOGIN,
  LAST_IP,
  STATUS,
  ENTER,
  BIRTH_DATE,
  SHORT_FULLNAME,
} = LANG_DICTIONARY;

const STATUSES = {
  LOCKED: 'Заблокирован',
  ACTIVED: 'Активен',
};

interface IProps {
  users: IUsers[]
  signInByUser: typeof signInByUserId;
  adminUrl: string;
}

interface IGetFio {
  lastName: string;
  firstName: string;
  middleName?: string;
}

const FlexTableUsers: React.FC<IProps> = ({users, signInByUser, adminUrl}) => {
  const headers: string[] = [
    MANAGMENT,
    ID_WORD,
    SHORT_FULLNAME,
    BIRTH_DATE,
    DATE_LOGIN,
    LAST_IP,
    STATUS,
    ENTER,
  ];

  const getFio = ({lastName, firstName, middleName}: IGetFio): string => {
    if (middleName !== null) {
      return `${lastName} ${firstName} ${middleName}`;
    }
    return `${lastName} ${firstName}`;
  };

  const getStatus = (isLocked: boolean): string => {
    if (isLocked) {
      return STATUSES.LOCKED;
    }
    return STATUSES.ACTIVED;
  };

  const rebuildData = users.map(({
    userId,
    ctgtsId,
    lastName,
    firstName,
    middleName,
    birthDate,
    isLocked,
    dateLogin,
    ip,
  }) => template(headers, [
    ctgtsId,
    <div
      onClick={() => getGlobalHistory().push(`${adminUrl}${ROUTES.adminUserCard}/${userId}`)}
      role="button"
      tabIndex={-1}
    >
      <img src={pencil} alt="pencil" />
    </div>,
    userId,
    getFio({lastName, firstName, middleName}),
    simpleDate(birthDate, DATE_FORMAT.DATE),
    simpleDate(dateLogin, DATE_FORMAT.DATE),
    ip,
    getStatus(isLocked),
    <div
      onClick={() => signInByUser(userId)}
      role="button"
      tabIndex={0}
    >
      <img src={userIcon} alt="user" />
    </div>,
  ]));

  return (<FlexTable data={rebuildData} minHeight={45} />);
};

export default FlexTableUsers;
