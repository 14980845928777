import { API, ROUTES } from 'consts';
import { apiCall, getError, getGlobalHistory } from 'helpers';
import { put, takeLatest } from 'redux-saga/effects';

import {
  SIGN_IN,
  CHECK_USER,
  REQUEST_RESET_PASSWORD,
  VERIFY_RESET_PASSWORD_TOKEN,
  RESET_PASSWORD_CHANGE_PASSWORD,
  GET_CAPTCHA,
  GET_RECAPTCHA,
  ADD_PHONE_LOGIN_VERIFY,
  ADD_PHONE_LOGIN,
  ACCEPT_RULES,
} from '../types';
import { IResetData } from '../reducers/interfaces';
import { IAddPhoneLoginPayload, IAddPhoneLoginVerifyPayload } from '../../../interfaces/addPhoneLoginPayload';
import checkAuthorizationForReset from '../../../helpers/checkAuthorization';

interface ISignin {
  payload: {
    login: string,
    password: string,
    captchaId: string,
    captchaKey: string,
  }
}

function* signIn({ payload }: ISignin) {
  try {
    const {
      login,
      password,
      captchaId,
      captchaKey,
    } = payload;
    yield put({ type: SIGN_IN.start });
    const body = {
      login,
      password,
      ...(captchaId && { captchaId, captchaKey }),
    };

    const { data } = yield apiCall({
      body,
      type: 'POST',
      url: API.SIGN_IN,
    });
    yield put({ type: SIGN_IN.success, payload: data});
    getGlobalHistory().push(ROUTES.home);
  } catch (e) {
    const { response: { data: { message: { captchaId: id }}}} = e;
    yield put({ type: SIGN_IN.fail, payload: checkAuthorizationForReset(e) });
    if (id) {
      yield getCaptcha({ payload: { captchaId: id }});
    }
  }
}

function* getCaptcha({payload: { captchaId }}: { payload: { captchaId: string } }) {
  const { CAPTCHA } = API;
  try {
    yield put({ type: GET_CAPTCHA.start });
    const { data: { captcha } } = yield apiCall({
      type: 'POST',
      url: CAPTCHA,
      body: {
        captchaId,
      },
    });
    yield put({ type: GET_CAPTCHA.success, payload: { captcha, captchaId } });
  } catch (e) {
    yield put({ type: GET_CAPTCHA.fail, payload: getError(e) });
  }
}

function* recaptcha({payload: {captchaId: id}}: { payload: { captchaId: string } }) {
  try {
    yield put({ type: GET_RECAPTCHA.start });
    const { data: captchaId } = yield apiCall({
      type: 'POST',
      url: API.RECAPTCHA,
      body: {
        captchaId: id,
      },
    });
    yield getCaptcha({payload: { captchaId }});
  } catch (e) {
    yield put({ type: GET_RECAPTCHA.fail, payload: getError(e)});
  }
}

function* checkUser({ payload }: { payload: string }) {
  try {
    yield put({ type: CHECK_USER.start });
    const { data: user } = yield apiCall({
      type: 'GET',
      url: API.CHECK_USER,
    });
    yield put({ type: CHECK_USER.success, payload: { payload, userId: user.id } });
  } catch (e) {
    getGlobalHistory().push(ROUTES.authorization);
    yield put({ type: SIGN_IN.fail, payload: getError(e) });
  }
}

function* startResetPassword({ payload }: { payload: string }) {
  try {
    yield put({ type: REQUEST_RESET_PASSWORD.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.START_PASSWORD_RESET,
      body: {
        login: payload,
      },
    });
    yield put({ type: REQUEST_RESET_PASSWORD.success, payload: data });
  } catch (e) {
    yield put({ type: REQUEST_RESET_PASSWORD.fail, payload: getError(e) });
  }
}

function* addPhoneLoginVerify({payload}: { payload: IAddPhoneLoginVerifyPayload }) {
  try {
    yield put({type: ADD_PHONE_LOGIN_VERIFY.start});
    const {
      email,
      phone,
      password,
    } = payload;
    const body = {
      email,
      password,
      phone,
    };
    const {data} = yield apiCall({
      body,
      type: 'POST',
      url: API.ADD_PHONE,
    });
    yield put({ type: ADD_PHONE_LOGIN_VERIFY.success, payload: data });
  } catch (e) {
    yield put({ type: ADD_PHONE_LOGIN_VERIFY.fail, payload: checkAuthorizationForReset(e) });
  }
}

function* addPhoneLogin({payload}: { payload: IAddPhoneLoginPayload }) {
  try {
    yield put({ type: ADD_PHONE_LOGIN.start });
    const {
      email,
      phone,
      password,
      captchaId,
      captchaKey,
      smsCode,
    } = payload;
    const body = {
      email,
      password,
      phone,
      smsCode,
      ...(captchaId && {captchaId, captchaKey}),
    };
    const {data} = yield apiCall({
      body,
      type: 'POST',
      url: API.ADD_PHONE_LOGIN,
    });
    yield put({ type: ADD_PHONE_LOGIN.success, payload: data });
    getGlobalHistory().push(ROUTES.home);
  } catch (e) {
    const { response: { data: { message: { captchaId: id }}}} = e;
    yield put({ type: ADD_PHONE_LOGIN.fail, payload: checkAuthorizationForReset(e) });
    if (id) {
      yield getCaptcha({ payload: { captchaId: id }});
    }
  }
}

function* verifyResetPassword({ payload }: { payload: string }) {
  try {
    yield put({ type: VERIFY_RESET_PASSWORD_TOKEN.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.VERIFY_PASSWORD_RESET,
      body: {
        token: payload,
      },
    });
    yield put({ type: VERIFY_RESET_PASSWORD_TOKEN.success, payload: data });
  } catch (e) {
    yield put({ type: VERIFY_RESET_PASSWORD_TOKEN.fail, payload: getError(e) });
  }
}

function* changePasswordResetPassword({ payload }: { payload: IResetData }) {
  try {
    yield put({ type: RESET_PASSWORD_CHANGE_PASSWORD.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.CHANGE_PASSWORD_RESET_PASSWORD,
      body: payload,
    });
    yield put({ type: RESET_PASSWORD_CHANGE_PASSWORD.success, payload: data });
  } catch (e) {
    yield put({ type: RESET_PASSWORD_CHANGE_PASSWORD.fail, payload: getError(e) });
  }
}

function* acceptRules({ payload }: { payload: number }) {
  try {
    yield put({ type: ACCEPT_RULES.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.ACCEPT_RULES,
      body: { userId: payload },
    });
    yield put({ type: ACCEPT_RULES.success, payload: data });
  } catch (e) {
    yield put({ type: ACCEPT_RULES.fail, payload: getError(e) });
  }
}

export default function* authorizationSaga() {
  yield takeLatest<string, any>(SIGN_IN.request, signIn);
  yield takeLatest<string, any>(CHECK_USER.request, checkUser);
  yield takeLatest<string, any>(REQUEST_RESET_PASSWORD.request, startResetPassword);
  yield takeLatest<string, any>(ADD_PHONE_LOGIN_VERIFY.request, addPhoneLoginVerify);
  yield takeLatest<string, any>(ADD_PHONE_LOGIN.request, addPhoneLogin);
  yield takeLatest<string, any>(VERIFY_RESET_PASSWORD_TOKEN.request, verifyResetPassword);
  yield takeLatest<string, any>(RESET_PASSWORD_CHANGE_PASSWORD.request,
    changePasswordResetPassword);
  yield takeLatest<string, any>(GET_RECAPTCHA.request, recaptcha);
  yield takeLatest<string, any>(GET_CAPTCHA.request, getCaptcha);
  yield takeLatest<string, any>(ACCEPT_RULES.request, acceptRules);
}
