import { ROUTES, TOKEN_TYPE } from 'consts';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRedux } from 'redux/interface';
import { checkCurrentAdmin } from 'redux/rootActions';

interface IProps {
  component: React.FC | React.ComponentClass,
}
interface IStoreProps {
  token: string,
  adminUrl: string,
  checkAdmin: typeof checkCurrentAdmin,
}
type TProps = IProps & IStoreProps & RouteComponentProps;

class AdminContainer extends React.Component<TProps> {
  componentDidMount() {
    const {
      checkAdmin,
      history,
      token: tokenFromStore,
      adminUrl,
    } = this.props;

    if (tokenFromStore) {
      return null;
    }

    const tokenFromLocalStore = localStorage.getItem(TOKEN_TYPE.admin);

    if (tokenFromLocalStore) {
      checkAdmin(tokenFromLocalStore);
    } else {
      history.push(`${adminUrl}${ROUTES.adminAuthorization}`);
    }

    return null;
  }

  render() {
    const { component: Component, token } = this.props;

    if (!token) {
      return null;
    }
    return (<Component />);
  }
}

const mapStateToProps = ({
  adminAuthorization: {
    token,
  },
  common: {
    adminUrl,
  },
}: IRedux) => ({
  token,
  adminUrl,
});

const mapDispatchToProps = {
  checkAdmin: checkCurrentAdmin,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminContainer));
