import {
  CHECK_ADMIN,
  GET_RECAPTCHA_ADMIN,
  SIGN_IN,
  SIGN_OUT,
} from '../types';

const checkCurrentAdmin = (token: string) => ({
  type: CHECK_ADMIN.request,
  payload: token,
});

const adminSignin = (login: string, password: string, captchaId: string, captchaKey: string) => ({
  type: SIGN_IN.request,
  payload: {
    login,
    password,
    captchaId,
    captchaKey,
  },
});

const adminSignOut = () => ({
  type: SIGN_OUT,
});

const getRecaptchaAdmin = (captchaId: string) => ({
  type: GET_RECAPTCHA_ADMIN.request,
  payload: { captchaId },
});

export {
  adminSignOut,
  checkCurrentAdmin,
  adminSignin,
  getRecaptchaAdmin,
};
