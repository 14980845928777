import React from 'react';


const IszIconSvg = () => (
  <svg width="62" height="61" viewBox="0 0 62 61" fill="none">
    <path d="M47.3754 16.75C45.7865 16.75 44.4864 17.9976 44.4864 19.5224C44.4864 19.7996 44.4864 20.2154 44.6309 20.4926L38.9972 26.4531C38.8527 26.4531 38.7084 26.4531 38.4194 26.4531C37.6971 26.4531 37.1193 26.7303 36.5415 27.1462L29.7523 24.6511C29.4634 23.4035 28.3078 22.2946 26.8632 22.2946C25.2742 22.2946 23.9741 23.5422 23.9741 25.067C23.9741 25.2056 23.9741 25.3442 23.9741 25.3442L16.4626 30.7503C16.0293 30.7503 15.7404 30.6116 15.307 30.6116C13.7181 30.6116 12.418 31.8592 12.418 33.384C12.418 34.9088 13.7181 36.1563 15.307 36.1563C16.896 36.1563 18.1961 34.9088 18.1961 33.384C18.1961 33.2453 18.1961 33.1068 18.1961 33.1068L25.7076 27.7007C25.9965 27.8394 26.4298 27.9779 26.8632 27.9779C27.5854 27.9779 28.1633 27.7007 28.741 27.2848L35.6747 29.7799C35.9635 31.0275 37.1191 32.1364 38.5637 32.1364C40.1527 32.1364 41.4528 30.8888 41.4528 29.364C41.4528 29.0868 41.4528 28.671 41.3083 28.3938L46.7975 22.2946C46.942 22.2946 47.0863 22.2946 47.3753 22.2946C48.9643 22.2946 50.2644 21.047 50.2644 19.5222C50.2645 17.9976 48.9644 16.75 47.3754 16.75Z" fill="white" />
    <path d="M57.1975 2.88858H32.6407V1.50234C32.6407 0.670732 32.0629 0.116211 31.1963 0.116211C30.3297 0.116211 29.7518 0.670732 29.7518 1.50234V2.88846H5.19487C2.73917 2.88846 0.861328 4.69044 0.861328 7.04695C0.861328 9.40346 2.73917 11.2054 5.19487 11.2054H56.4927C57.3594 11.2054 57.9371 10.6509 57.9371 9.81932C57.9371 8.98772 57.3593 8.4332 56.4927 8.4332H5.19487C4.32814 8.4332 3.7504 7.87867 3.7504 7.04707C3.7504 6.21547 4.32826 5.66095 5.19487 5.66095H57.1975C58.0643 5.66095 58.642 6.21547 58.642 7.04707C58.642 7.87867 58.0641 8.4332 57.1975 8.4332C56.3308 8.4332 55.7531 8.98772 55.7531 9.81932V40.315C55.7531 41.1467 55.1752 41.7011 54.3086 41.7011H8.08394C7.21721 41.7011 6.63947 41.1466 6.63947 40.315V10.4958C6.63947 9.66411 6.06161 9.10971 5.195 9.10971C4.32839 9.10971 3.7504 9.66411 3.7504 10.4958V40.3151C3.7504 42.6716 5.62824 44.4736 8.08394 44.4736H29.7517V54.3154C28.4516 54.5926 27.4405 55.2856 26.5737 56.256C25.5626 57.5036 24.118 58.1967 22.6735 58.1967H22.5291C21.6623 58.1967 21.0846 58.7512 21.0846 59.5828C21.0846 60.4145 21.6624 60.9689 22.5291 60.9689H22.8179C25.1291 60.9689 27.296 59.86 28.8849 57.9193C30.0405 56.5332 32.3517 56.5332 33.5073 57.9193C35.0963 59.86 37.2632 60.9689 39.5744 60.9689H39.8632C40.73 60.9689 41.3077 60.4144 41.3077 59.5828C41.3077 58.7511 40.7298 58.1967 39.8632 58.1967H39.5744C38.1299 58.1967 36.8298 57.5036 35.6742 56.256C34.8074 55.2856 33.7963 54.5926 32.4962 54.3154V44.4736H54.164C56.6197 44.4736 58.4975 42.6716 58.4975 40.3151V10.9284C60.231 10.3738 61.3866 8.84906 61.3866 7.04707C61.5311 4.69056 59.6532 2.88858 57.1975 2.88858Z" fill="white" />
  </svg>
);

export default IszIconSvg;
