const GET_PROFITABILITY_REQUEST = 'profitability/GET_PROFITABILITY_REQUEST';
const GET_PROFITABILITY_START = 'profitability/GET_PROFITABILITY_START';
const GET_PROFITABILITY_FAIL = 'profitability/GET_PROFITABILITY_FAIL';
const GET_PROFITABILITY_SUCCESS = 'profitability/GET_PROFITABILITY_SUCCESS';

const GET_PROFITABILITY_CONTRACT_INFO_REQUEST = 'profitability/GET_PROFITABILITY_CONTRACT_INFO_REQUEST';
const GET_PROFITABILITY_CONTRACT_INFO_START = 'profitability/GET_PROFITABILITY_CONTRACT_INFO_START';
const GET_PROFITABILITY_CONTRACT_INFO_FAIL = 'profitability/GET_PROFITABILITY_CONTRACT_INFO_FAIL';
const GET_PROFITABILITY_CONTRACT_INFO_SUCCESS = 'profitability/GET_PROFITABILITY_CONTRACT_INFO_SUCCESS';

const GET_PROFITABILITY_STRATEGIES_REQUEST = 'profitability/GET_PROFITABILITY_STRATEGIES_REQUEST';
const GET_PROFITABILITY_STRATEGIES_START = 'profitability/GET_PROFITABILITY_STRATEGIES_START';
const GET_PROFITABILITY_STRATEGIES_FAIL = 'profitability/GET_PROFITABILITY_STRATEGIES_FAIL';
const GET_PROFITABILITY_STRATEGIES_SUCCESS = 'profitability/GET_PROFITABILITY_STRATEGIES_SUCCESS';

const GET_PROFITABILITY_STRATEGY_REQUEST = 'profitability/GET_PROFITABILITY_STRATEGY_REQUEST';
const GET_PROFITABILITY_STRATEGY_START = 'profitability/GET_PROFITABILITY_STRATEGY_START';
const GET_PROFITABILITY_STRATEGY_FAIL = 'profitability/GET_PROFITABILITY_STRATEGY_FAIL';
const GET_PROFITABILITY_STRATEGY_SUCCESS = 'profitability/GET_PROFITABILITY_STRATEGY_SUCCESS';

const GET_PROFITABILITY_PAYMENT_INFO_REQUEST = 'profitability/GET_PROFITABILITY_PAYMENT_INFO_REQUEST';
const GET_PROFITABILITY_PAYMENT_INFO_START = 'profitability/GET_PROFITABILITY_PAYMENT_INFO_START';
const GET_PROFITABILITY_PAYMENT_INFO_FAIL = 'profitability/GET_PROFITABILITY_PAYMENT_INFO_FAIL';
const GET_PROFITABILITY_PAYMENT_INFO_SUCCESS = 'profitability/GET_PROFITABILITY_PAYMENT_INFO_SUCCESS';

const GET_PROFITABILITY_PAYMENTS_REQUEST = 'profitability/GET_PROFITABILITY_PAYMENTS_REQUEST';
const GET_PROFITABILITY_PAYMENTS_START = 'profitability/GET_PROFITABILITY_PAYMENTS_START';
const GET_PROFITABILITY_PAYMENTS_FAIL = 'profitability/GET_PROFITABILITY_PAYMENTS_FAIL';
const GET_PROFITABILITY_PAYMENTS_SUCCESS = 'profitability/GET_PROFITABILITY_PAYMENTS_SUCCESS';

const GET_PROFITABILITY_REPORT_REQUEST = 'profitability/GET_PROFITABILITY_REPORT_REQUEST';
const GET_PROFITABILITY_REPORT_START = 'profitability/GET_PROFITABILITY_REPORT_START';
const GET_PROFITABILITY_REPORT_FAIL = 'profitability/GET_PROFITABILITY_REPORT_FAIL';
const GET_PROFITABILITY_REPORT_SUCCESS = 'profitability/GET_PROFITABILITY_REPORT_SUCCESS';

const CLEAR_PROFITABILITY_PAGE = 'profitability/CLEAR_PROFITABILITY_PAGE';
const CHANGE_PROFITABILITY_SELECTED_PERIOD = 'profitability/CHANGE_PROFITABILITY_SELECTED_PERIOD';

export {
  GET_PROFITABILITY_FAIL,
  GET_PROFITABILITY_REQUEST,
  GET_PROFITABILITY_START,
  GET_PROFITABILITY_SUCCESS,
  GET_PROFITABILITY_CONTRACT_INFO_REQUEST,
  GET_PROFITABILITY_CONTRACT_INFO_START,
  GET_PROFITABILITY_CONTRACT_INFO_FAIL,
  GET_PROFITABILITY_CONTRACT_INFO_SUCCESS,
  GET_PROFITABILITY_PAYMENT_INFO_REQUEST,
  GET_PROFITABILITY_PAYMENT_INFO_START,
  GET_PROFITABILITY_PAYMENT_INFO_FAIL,
  GET_PROFITABILITY_PAYMENT_INFO_SUCCESS,
  GET_PROFITABILITY_STRATEGIES_REQUEST,
  GET_PROFITABILITY_STRATEGIES_START,
  GET_PROFITABILITY_STRATEGIES_FAIL,
  GET_PROFITABILITY_STRATEGIES_SUCCESS,
  GET_PROFITABILITY_REPORT_FAIL,
  GET_PROFITABILITY_REPORT_SUCCESS,
  GET_PROFITABILITY_REPORT_START,
  GET_PROFITABILITY_REPORT_REQUEST,
  GET_PROFITABILITY_PAYMENTS_REQUEST,
  GET_PROFITABILITY_PAYMENTS_START,
  GET_PROFITABILITY_PAYMENTS_FAIL,
  GET_PROFITABILITY_PAYMENTS_SUCCESS,
  GET_PROFITABILITY_STRATEGY_REQUEST,
  GET_PROFITABILITY_STRATEGY_START,
  GET_PROFITABILITY_STRATEGY_FAIL,
  GET_PROFITABILITY_STRATEGY_SUCCESS,
  CHANGE_PROFITABILITY_SELECTED_PERIOD,
  CLEAR_PROFITABILITY_PAGE,
};
