export default (headers: any[], data: any[]) => ({
  key: data[0],
  data: [
    {
      md: 1,
      headers: [
        { text: headers[0]},
      ],
      data: [
        { text: data[1]},
      ],
    },
    {
      md: 3,
      headers: [
        { text: headers[1]},
      ],
      data: [
        { text: data[2]},
      ],
    },
    {
      md: 3,
      headers: [
        { text: headers[2]},
      ],
      data: [
        { text: data[3]},
      ],
    },
    {
      md: 3,
      headers: [
        { text: headers[3]},
      ],
      data: [
        { text: data[4]},
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[4]},
      ],
      data: [
        { text: data[5]},
      ],
    },
  ],
});
