import { LANG_DICTIONARY } from 'consts';
import React from 'react';
import { block } from 'bem-cn';
import { FlexTable, EmailValidation, RequiredMessage } from 'components';
import addButton from 'assets/svg/addButton.svg';
import trashButton from 'assets/svg/trashButton.svg';
import notActiveX from 'assets/svg/notActiveX.svg';
import activeX from 'assets/svg/activeX.svg';
import pencil from 'assets/svg/pencil.svg';
import {IAdmin, IAdminPersonalCard} from 'redux/adminAdministrators/reducers/interfaces';
import { ENUMS_SEND_STATUSES } from 'redux/adminUserCard/reducers';
import template from './template';
import './styles.scss';

const b = block('flex-table-administrators');

const {
  ID_WORD,
  SHORT_FULLNAME,
  EMAIL_WORD,
  ACTIVITY_WORD,
} = LANG_DICTIONARY;

interface IProps {
  handleChangeValue: (name: string, value: any, id: any) => void;
  addAction: () => void;
  deleteAction: (id: number | string) => void;
  administrators: IAdmin[];
  getAdminPersonalCard: (id: number | string, haveCard: boolean) => void;
  choosedId: number | string;
}

const FlexTablesAdmins: React.FC<IProps> = ({
  administrators,
  handleChangeValue,
  addAction,
  deleteAction,
  getAdminPersonalCard,
  choosedId,
}) => {
  const headers: string[] = [
    ID_WORD,
    SHORT_FULLNAME,
    EMAIL_WORD,
    ACTIVITY_WORD,
    '',
  ];

  const controlButtons = (
    id: number | string,
    personalCard: IAdminPersonalCard,
    sendStatus: string | undefined,
  ) => (
    <div
      role="button"
      tabIndex={0}
      className={b('control-btns', {
        choosed: id === choosedId,
        error: (id !== choosedId
            && Boolean(personalCard && personalCard.errorPassword))
            || Boolean(sendStatus === ENUMS_SEND_STATUSES.toCreate && !personalCard.password),
      })}
    >
      <div
        onClick={() => deleteAction(id)}
        role="button"
        tabIndex={0}
      >
        <img
          src={trashButton}
          alt="delete"
          className={b('control-btn')}
        />
      </div>
      <div
        onClick={addAction}
        role="button"
        tabIndex={0}
      >
        <img
          src={addButton}
          alt="add"
          className={b('control-btn')}
        />
      </div>
    </div>
  );

  const getActivityStatus = (status: boolean) => <img src={status ? notActiveX : activeX} alt="img" className={b('control-btn')} />;

  const getWrapperClassName = (
    id: number | string,
    personalCard: IAdminPersonalCard,
    sendStatus: string | undefined,
  ) => b('wrapper', {
    choosed: id === choosedId,
    error: (id !== choosedId
        && Boolean(personalCard && personalCard.errorPassword))
        || Boolean(sendStatus === ENUMS_SEND_STATUSES.toCreate && !personalCard.password),
  });

  const rebuildData = administrators.map(({
    id,
    fullName,
    email,
    canUpdateEmail,
    canUpdateName,
    personalCard,
    sendStatus,
    status,
  }) => template(headers, [
    id,
    <div
      className={getWrapperClassName(id, personalCard, sendStatus)}
      onClick={() => getAdminPersonalCard(id, Boolean(personalCard))}
      role="button"
      tabIndex={0}
    >
      {sendStatus === ENUMS_SEND_STATUSES.toCreate ? '' : id}
    </div>,
    <div
      className={getWrapperClassName(id, personalCard, sendStatus)}
      onClick={() => getAdminPersonalCard(id, Boolean(personalCard))}
      role="button"
      tabIndex={0}
    >
      <div className={b('input-wrapper')}>
        <input
          value={fullName}
          onChange={({target: {name, value}}) => handleChangeValue(name, value, id)}
          name="fullName"
          disabled={!canUpdateName}
          id={`nameInput${id}`}
          className={b('input')}
        />
        <RequiredMessage value={fullName} />
      </div>
      <div
        onClick={() => handleChangeValue('canUpdateName', !canUpdateName, id)}
        role="button"
        tabIndex={0}
      >
        <img
          src={pencil}
          alt="pencil"
          className={b('control-btn')}
        />
      </div>
    </div>,
    <div
      className={getWrapperClassName(id, personalCard, sendStatus)}
      onClick={() => getAdminPersonalCard(id, Boolean(personalCard))}
      role="button"
      tabIndex={0}
    >
      <div className={b('input-wrapper')}>
        <input
          value={email}
          onChange={({target: {name, value}}) => handleChangeValue(name, value, id)}
          name="email"
          disabled={!canUpdateEmail}
          className={b('input')}
          id={`emailInput${id}`}
        />
        <EmailValidation email={email} />
      </div>
      <div
        onClick={() => handleChangeValue('canUpdateEmail', !canUpdateEmail, id)}
        role="button"
        tabIndex={0}
      >
        <img
          src={pencil}
          alt="pencil"
          className={b('control-btn')}
        />
      </div>
    </div>,
    <div
      className={getWrapperClassName(id, personalCard, sendStatus)}
      onClick={() => getAdminPersonalCard(id, Boolean(personalCard))}
      role="button"
      tabIndex={0}
    >
      {getActivityStatus(status)}
    </div>,
    controlButtons(id, personalCard, sendStatus),
  ]));

  return (<FlexTable data={rebuildData} minHeight={45} />);
};

export default FlexTablesAdmins;
