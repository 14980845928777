import { ActionFactory } from 'helpers';

const factory = new ActionFactory('common');
const GET_IP = factory.createAsync('GET_IP');
const GET_ADMIN_URL = factory.createAsync('GET_ADMIN_URL');
const TOGGLE_ADMIN_BURGER_MENU = factory.create('TOGGLE_ADMIN_BURGER_MENU');

export {
  TOGGLE_ADMIN_BURGER_MENU,
  GET_IP,
  GET_ADMIN_URL,
};
