import React, { useState, useRef, useEffect } from 'react';
import './styles.scss';
import block from 'bem-cn';
import classNames from 'classnames';
import { simpleDate } from 'helpers/dateUtils';
import dateFormats from 'consts/dateFormats';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getProfitabilityPaymentInfo } from '../../../../redux/profitability/actions';
import {
  getSelectedPayment,
  getSelectedPeriodPayments,
  getSelectedStrategy,
} from '../../../../redux/profitability/selectors';
import { RefPaymentTerm } from '../../../../redux/profitability/reducers/interfaces';

const b = block('date-line-select');

const MARGIN_PX_ROUNDING = 15;
const MARGIN_PX = 34;
const DIFF_DAYS_FOR_MARGIN = 15;

const TOTAL_NUMBERS_PERIODS = 1;
const TOTAL_NUMBERS_PERCENTAGES = 100;

interface IProps {
  dates: {
    date: string,
    isDisabled: boolean,
  }[];
  isMarkedYear?: boolean;
}

interface IGetMarginLeftByDelta {
  marginLeft? : string;
  position? : 'absolute';
}

const DateLineSelect: React.FC<IProps> = ({
  dates,
  isMarkedYear,
}) => {
  const DECREMENT_CURRENT_PERIOD = 2;
  const OVERSIZE_PIXELS = 20;
  const lineRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(lineRef.current?.offsetWidth || 1);

  const dispatch = useDispatch();
  const selectedStrategy = useSelector(getSelectedStrategy);
  const selectedPayment = useSelector(getSelectedPayment);
  const selectedPeriodPayments = useSelector(getSelectedPeriodPayments);
  const { dateOfPrice } = selectedStrategy;

  useEffect(() => {
    onSelect({ date: selectedStrategy.dateOfPrice, id: selectedStrategy.id });
  }, []);

  useEffect(() => {
    const lastPaymentIndex = selectedPeriodPayments
      .map((payment) => payment.isDisabled)
      .lastIndexOf(false);

    onSelect({
      date: selectedPeriodPayments[lastPaymentIndex].date,
      id: selectedStrategy.id,
    });
  }, [selectedPeriodPayments]);

  const disabledDatesCount = dates.filter((item) => item.isDisabled).length;
  const disabledDatesPart = (disabledDatesCount) / (dates.length);
  const disabledDatesPercent = disabledDatesPart * TOTAL_NUMBERS_PERCENTAGES;
  const currentPeriod = (TOTAL_NUMBERS_PERIODS / (dates.length - DECREMENT_CURRENT_PERIOD))
    * TOTAL_NUMBERS_PERCENTAGES;

  const dateSelectItem = {
    date: dateOfPrice,
    id: -1,
  };
  const diffDays = dates.find((item) => Math.abs(moment(item.date)
    .diff(dateSelectItem.date, 'days')) < DIFF_DAYS_FOR_MARGIN) || undefined;

  const getDelta = () => {
    if (diffDays) {
      return moment(dateSelectItem.date).startOf('day')
        .diff(moment(diffDays?.date).startOf('day'), 'days');
    }
    return 0;
  };

  const delta = getDelta();

  const onSelect = ({ date, id }: { date: string, id: number }) => {
    dispatch(getProfitabilityPaymentInfo(id, date.split('T')[0]));
  };

  useEffect(
    () => {
      setWidth(lineRef.current?.offsetWidth || 1);
    },
    [lineRef.current],
  );

  const diffDaysActual = moment(dateSelectItem.date).diff(dates[0]?.date, 'days');
  const diffDaysAllPeriod = moment(dates[dates.length - 1]?.date).diff(dates[0]?.date, 'days');

  const marginPercent = (diffDaysActual * 100) / diffDaysAllPeriod;

  const indentPlusMargin = ((diffDaysActual / diffDaysAllPeriod) * width)
      + MARGIN_PX_ROUNDING + MARGIN_PX;
  const indentMinusMargin = ((diffDaysActual / diffDaysAllPeriod) * width)
      - MARGIN_PX_ROUNDING + MARGIN_PX;

  const getMarginLeftByDelta = (): IGetMarginLeftByDelta => {
    if (delta < 0) {
      return { marginLeft: `-${indentMinusMargin}px` };
    }
    if (delta > 0) {
      return { marginLeft: `-${indentPlusMargin}px` };
    }
    if (delta === 0) {
      return { marginLeft: `calc(${marginPercent}% - ${OVERSIZE_PIXELS}px)`, position: 'absolute' };
    }

    return {};
  };

  return (
    <div className={b()}>
      <div id="line" ref={lineRef} className={b('line')}>
        <div className={b('line-disabled')} style={{width: `${disabledDatesPercent}%`}} />
        <div className={isMarkedYear ? b('current-period') : b()} style={{width: `${currentPeriod}%`, marginRight: `${disabledDatesPercent}%`}} />
      </div>
      <div className={b('wrapper')}>
        {
          dates.map((x, index) => (
            <button
              className={classNames({
                [b('item-selected')]: x.date === selectedPayment?.date,
                [b('item')]: true,
                [b('item-passed')]: !x.isDisabled,
                [b('item-not-passed')]: x.isDisabled,
              })}
              style={x.date === dateOfPrice ? getMarginLeftByDelta() : undefined}
              key={x.date}
              onClick={() => onSelect({
                date: x.date,
                id: selectedStrategy?.id!,
              })}
              disabled={x.isDisabled}
            >
              {
                selectedPayment?.date === x.date.split('T')[0] && (
                  <div className={b('item-selected-indicator')} />
                )
              }
              {x.date !== dateOfPrice && <div>{simpleDate(x.date, dateFormats.DATE)}</div>}
            </button>
          ))
        }
      </div>
    </div>
  );
};

export default DateLineSelect;
