import './styles.scss';

import React from 'react';
import { block } from 'bem-cn';

interface IProps {
  title: string,
  image: any,
  className?: string,
}

const b = block('contract-header-form');

const LogoForm: React.FC<IProps> = ({
  title,
  image,
  className = '',
}) => (
  <div className={b.mix(className)}>
    <div className={b('icon')}>
      <img
        src={image}
        alt="logo-form-icon"
        className={b('img')}
      />
    </div>
    <p className={b('header-form-text')}>{title}</p>
  </div>
);

export default LogoForm;
