import './styles.scss';

import adminLogoImage from 'assets/images/admin-logo-bcs.png';
import burgerIcon from 'assets/images/burger-menu-icon.png';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import React from 'react';

interface IProps {
  login: string,
  token: string,
  fullName: string,
  toggleBurgerMenu: (state: boolean) => void,
  isOpenBurgerMenu: boolean,
}

const b = block('admin-head-panel');
const { ADMIN } = LANG_DICTIONARY;

const HeadPanel: React.FC<IProps> = ({
  login,
  token,
  fullName,
  toggleBurgerMenu,
}) => (
  <div className={b()}>
    <img src={adminLogoImage} alt="bcs-logo" className={b('logo')} />
    {token && (
      <div className={b('right-panel-wrapper')}>
        <p className={b('info')}>{`${ADMIN}: ${fullName} (${login})`}</p>
        <button
          type="button"
          className={b('burger-button')}
          onClick={() => toggleBurgerMenu(true)}
        >
          <img
            src={burgerIcon}
            alt="burger-icon"
            className={b('burger-icon')}
          />
        </button>
      </div>
    )}
  </div>
);

export default HeadPanel;
