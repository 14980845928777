import { ActionFactory } from 'helpers';

const factory = new ActionFactory('adminUserCard');

export const GET_USER_INFO = factory.createAsync('GET_USER_INFO');
export const CLEAR_STORE = factory.create('CLEAR_STORE');
export const CONTROL_ACTION = factory.create('CONTROL_ACTION');
export const GET_CONTACTS_CODES = factory.createAsync('GET_CONTACTS_CODES');
export const GET_CONTR_AGENTS_LIST = factory.createAsync('GET_CONTR_AGENTS_LIST');
export const DELETE_ACTION = factory.create('DELETE_ACTION');
export const GET_CONTR_AGENT_CARD = factory.createAsync('GET_CONTR_AGENT_CARD');
export const CLEAR_CTGT_CARD = factory.create('CLEAR_CTGT_CARD');
export const UPDATE_CONTR_AGENTS_LIST = factory.createAsync('UPDATE_CONTR_AGENTS_LIST');
export const SHOW_TABLES_CONTROL = factory.create('SHOW_TABLES_CONTROL');
export const CHOOSE_CTGT = factory.create('CHOOSE_CTGT');
export const GET_CONTRACT_CARD = factory.createAsync('GET_CONTRACT_CARD');
export const GET_CONTRACTS_LIST = factory.createAsync('GET_CONTRACTS_LIST');
export const UPDATE_CONTRACTS_LIST = factory.createAsync('UPDATE_CONTRACTS_LIST');
export const CLEAR_CTS_CARD = factory.create('CLEAR_CTS_CARD');
export const SAVE_CHANGES = factory.createAsync('SAVE_CHANGES');
export const RESET_CHANGES = factory.create('RESET_CHANGES');
export const CLEAR_SEARCH_CTS = factory.create('CLEAR_SEARCH_CTS');
