import { LANG_DICTIONARY } from '../consts';

const { IS_NEED_RESET_ERROR } = LANG_DICTIONARY;
const ERROR_FOR_RESET = 'Need to reset password';

const checkAuthorizationForReset = (error: any) => {
  const data = error.response && error.response.data;
  const { message: { EN: errorMessage, RU } } = data;
  const isNeedReset = errorMessage === ERROR_FOR_RESET;
  const message = isNeedReset ? IS_NEED_RESET_ERROR : RU;

  return {
    isNeedReset,
    error: message,
  };
};

export default checkAuthorizationForReset;
