import React from 'react';
import block from 'bem-cn';
import './styles.scss';
import {Search, SearchResultData} from 'semantic-ui-react';

const b = block('input-wrapper');

interface IProps {
    label: string;
    value?: string;
    name: string;
    maxLength?: number;
    onChange?: (value: string, name: string) => void;
    isSearch?: boolean;
    onSearchChange?: (e: React.MouseEvent<HTMLElement>) => void;
    banksData?: any,
    onResultSelect?: (data: SearchResultData) => void;
}

const InputWithLabel: React.FC<IProps> = (props) => {
  const {
    label,
    value,
    name,
    maxLength,
    onChange,
    isSearch,
    onSearchChange,
    banksData,
    onResultSelect,
  } = props;

  return (
    <div className={b()}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={b('label')}>
        {label}
      </label>
      {
          isSearch
            ? (
              <Search
                icon={false}
                name="bic"
                value={value}
                results={banksData}
                onSearchChange={(e) => (onSearchChange ? onSearchChange(e) : () => {})}
                onResultSelect={(e, data) => (onResultSelect ? onResultSelect(data) : () => {})}
              />
            )
            : (
              <input
                className={b('input')}
                name={name}
                value={value}
                maxLength={maxLength}
                onChange={(e) => (onChange ? onChange(e.target.value, name) : () => {})}
              />
            )
        }
    </div>
  );
};

export default InputWithLabel;
