import compose from '@shopify/react-compose';
import { ADMINISTRATION_STATUSES } from 'consts';
import { gerErrorFromSchema } from 'helpers';
import { withAdminFormInterface, withAdministrationInterface } from 'HOC';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import randomstring from 'randomstring';
import { IRedux } from 'redux/interface';
import _ from 'lodash';
import { AdminCardForm } from 'components';
import {
  getAdminsList,
  getAdminCard,
  saveChanges,
  resetChanges,
  clearStoreAdministrators,
  changeFieldAdministrators,
  deleteAdmin,
  getCurrentCard,
  setHaveCardAdministrators,
} from 'redux/rootActions';
import {
  IAdministratorsReducer,
  IAdmin,
  IAdminPersonalCard,
  IAdminRoles,
} from 'redux/adminAdministrators/reducers/interfaces';
import schema from './schema';

interface IActionsProps {
  getListAdmin: typeof getAdminsList;
  getCardADmin: typeof getAdminCard;
  saveChangesAdmins: typeof saveChanges;
  changesReset: typeof resetChanges;
  clearStore: typeof clearStoreAdministrators;
  changeField: typeof changeFieldAdministrators;
  deleteAdminAction: typeof deleteAdmin;
  getCurrentAdminCard: typeof getCurrentCard;
  setHaveCard: typeof setHaveCardAdministrators;
}
interface IAdminId {
  myId: number;
}

type TProps = IActionsProps & RouteComponentProps & IAdministratorsReducer & IAdminId;

class AdminCard extends React.Component <TProps> {
  componentDidMount = () => {
    const { getListAdmin, currentPage } = this.props;
    getListAdmin(currentPage);
  }

  checkFormHimself = (id: any) => {
    const { myId } = this.props;
    return id === myId;
  }

  handleChangeCheckbox = async (adminId: number | string, rolesId: number) => {
    const { administrators, changeField, initialList } = this.props;
    const checkForOldValues: any = initialList.find((item: IAdmin) => item.id === adminId);
    const checkForDontUpdate = (currentAdmin: IAdmin, oldAdmin: IAdmin) => {
      const newAdmin = {
        ...currentAdmin,
        canUpdateName: false,
        canUpdateEmail: false,
        sendStatus: ADMINISTRATION_STATUSES.dontSend,
        canUpdatePassword: false,
      };
      return _.isEqual(newAdmin, oldAdmin);
    };
    const checkDontUpdatePersonalcard = (
      currentCard: any,
      oldCard: IAdminPersonalCard,
    ) => {
      const newCard = {
        ...currentCard,
        roles: currentCard.roles.map((role: any) => {
          if (role.id === rolesId) {
            return {
              ...role,
              checked: !role.checked,
            };
          }
          return role;
        }),
      };
      return _.isEqual(newCard, oldCard);
    };
    const newList = administrators.map((item: any) => {
      if (item.id === adminId && typeof (adminId) === 'number') {
        return {
          ...item,
          personalCard: {
            ...item.personalCard,
            roles: item.personalCard.roles.map((role: any) => {
              if (role.id === rolesId) {
                return {
                  ...role,
                  checked: !role.checked,
                };
              }
              return role;
            }),
          },
          sendStatus: checkForDontUpdate(item, checkForOldValues)
           && checkDontUpdatePersonalcard(item.personalCard, checkForOldValues.personalCard)
            ? ADMINISTRATION_STATUSES.dontSend
            : ADMINISTRATION_STATUSES.toUpdate,
        };
      }
      if (item.id === adminId && typeof (adminId) === 'string') {
        return {
          ...item,
          personalCard: {
            ...item.personalCard,
            roles: item.personalCard.roles.map((role: any) => {
              if (role.id === rolesId) {
                return {
                  ...role,
                  checked: !role.checked,
                };
              }
              return role;
            }),
          },
        };
      }
      return {
        ...item,
      };
    });
    await changeField(newList);
    this.getAdminPersonalCard(adminId, true);
  }

  checkPasswordValidation = async (password: string) => {
    const {
      administrators,
      changeField,
      choosedId: id,
    } = this.props;

    try {
      schema(password).validateSync({
        password,
      });
      const newList = administrators.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            personalCard: {
              ...item.personalCard,
              errorPassword: '',
            },
          };
        }
        return item;
      });
      await changeField(newList);
      this.getAdminPersonalCard(id, true);
    } catch (e) {
      const errorMessage = gerErrorFromSchema(e);
      const newList = administrators.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            personalCard: {
              ...item.personalCard,
              errorPassword: errorMessage,
            },
          };
        }
        return item;
      });
      await changeField(newList);
      this.getAdminPersonalCard(id, true);
    }
  }

  handleChangeValuePersonalCard = async (name: string, value: any) => {
    const {
      administrators,
      changeField,
      initialList,
      choosedId: id,
    } = this.props;
    const newField = {
      [name]: value,
    };
    const checkForOldValues: any = initialList.find((item: IAdmin) => item.id === id);
    const checkForDontUpdate = (currentAdmin: IAdmin, oldAdmin: IAdmin) => {
      const newAdmin = {
        ...currentAdmin,
        canUpdateName: false,
        canUpdateEmail: false,
        sendStatus: ADMINISTRATION_STATUSES.dontSend,
        canUpdatePassword: false,
      };
      return _.isEqual(newAdmin, oldAdmin);
    };
    const checkDontUpdatePersonalcard = (
      currentCard: any,
      oldCard: IAdminPersonalCard,
    ) => {
      const newCard = {
        ...currentCard,
        ...newField,
      };
      return _.isEqual(newCard, oldCard);
    };
    const newList = administrators.map((item) => {
      if (item.id === id && typeof (id) === 'number') {
        if (checkForDontUpdate(item, checkForOldValues)
        && checkDontUpdatePersonalcard(item.personalCard, checkForOldValues.personalCard)) {
          return {
            ...item,
            personalCard: {
              ...item.personalCard,
              ...newField,
            },
            sendStatus: ADMINISTRATION_STATUSES.dontSend,
          };
        }
        return {
          ...item,
          personalCard: {
            ...item.personalCard,
            ...newField,
            needUpdatePassword: true,
          },
          sendStatus: ADMINISTRATION_STATUSES.toUpdate,
        };
      }
      if (item.id === id && typeof (id) === 'string') {
        if (name === 'password') {
          return {
            ...item,
            personalCard: {
              ...item.personalCard,
              ...newField,
              needUpdatePassword: true,
            },
          };
        }
        return {
          ...item,
          personalCard: {
            ...item.personalCard,
            ...newField,
          },
        };
      }
      return {
        ...item,
      };
    });
    await changeField(newList);
    this.getAdminPersonalCard(id, true);
  }

  handleChangeValue = (name: string, value: any, cId: number) => {
    const {
      administrators,
      initialList,
      changeField,
      choosedId: id,
    } = this.props;

    const newField = {
      [name]: value,
    };

    const checkForOldValues: any = initialList.find((item: IAdmin) => item.id === id);
    const checkForDontUpdate = (currentAdmin: IAdmin, oldAdmin: IAdmin) => {
      const newAdmin = {
        ...currentAdmin,
        ...newField,
        canUpdateName: false,
        canUpdateEmail: false,
        sendStatus: ADMINISTRATION_STATUSES.dontSend,
        canUpdatePassword: false,
      };
      return _.isEqual(newAdmin, oldAdmin);
    };
    const newList = administrators.map((item) => {
      if (item.id === id && typeof (id) === 'number') {
        if (checkForDontUpdate(item, checkForOldValues)) {
          return {
            ...item,
            ...newField,
            sendStatus: ADMINISTRATION_STATUSES.dontSend,
          };
        }
        return {
          ...item,
          ...newField,
          sendStatus: ADMINISTRATION_STATUSES.toUpdate,
        };
      }
      if (item.id === id && typeof (id) === 'string') {
        return {
          ...item,
          ...newField,
        };
      }
      return {
        ...item,
      };
    });

    changeField(newList);
    const prefix = this.getPrefixInput(name);
    const currentInput: any = prefix
      ? document.getElementById(`${prefix}${cId}`)
      : null;
    if (currentInput) {
      currentInput.disabled = false;
      const val = currentInput.value;
      currentInput.value = '';
      currentInput.value = val;
      currentInput.focus();

      currentInput.onblur = () => {
        currentInput.disabled = true;
      };
    }
  }

  getPrefixInput = (name: string) => {
    switch (name) {
      case 'canUpdateEmail':
        return 'emailInput';
      case 'canUpdateName':
        return 'nameInput';
      default:
        return '';
    }
  }

  createAdmin = () => {
    const { administrators, changeField } = this.props;
    const newId = randomstring.generate({
      length: 10,
      charset: 'alphabetic',
    });
    const newPersonalCard: IAdminPersonalCard = {
      id: newId,
      status: false,
      dateCreate: '',
      password: '',
      needUpdatePassword: true,
      passwordChangeDate: '',
      hash: '',
      roles: [
        {
          id: 1,
          name: 'Управление ЛК',
          code: 'lk-adm-1',
          checked: false,
        },
        {
          id: 2,
          name: 'Пользователи',
          checked: false,
          code: 'lk-adm-2',
        },
        {
          id: 3,
          name: 'Администраторы',
          checked: false,
          code: 'lk-adm-3',
        },
        {
          id: 4,
          name: 'Загрузка печатных форм',
          checked: false,
          code: 'lk-adm-4',
        },
      ],
      errorPassword: '',
    };
    const newAdmin: IAdmin = {
      id: newId,
      fullName: '',
      email: '',
      status: false,
      personalCard: newPersonalCard,
      sendStatus: 'toCreate',
      canUpdateEmail: false,
      canUpdateName: false,
      canUpdatePassword: false,
    };
    const newList = [...administrators, newAdmin];
    changeField(newList);
  }

  deleteAdmin = (id: number | string) => {
    const {
      administrators,
      changeField,
      toDelete,
      deleteAdminAction,
      currentCard,
      setHaveCard,
    } = this.props;
    if (currentCard.id === id) {
      setHaveCard(false);
    }

    if (!this.checkFormHimself(id)) {
      const { dontSend, toUpdate } = ADMINISTRATION_STATUSES;
      const needToDelete = (status: any) => status === dontSend || status === toUpdate;
      const deletedArray: any = administrators.find((item) => (
        item.id === id && needToDelete(item.sendStatus)
      ));
      const newList = administrators.filter((item) => item.id !== id);
      const newDeleted = [...toDelete, deletedArray && {id: deletedArray.id}];
      changeField(newList);
      deleteAdminAction(newDeleted);
    }
  }

  resetAllChanges = () => {
    const { changesReset } = this.props;
    changesReset();
  }

  componentWillUnmount = () => {
    const { clearStore } = this.props;
    clearStore();
  }

  getAdminPersonalCard = (id: number | string, haveCard: boolean) => {
    const { getCardADmin, administrators, getCurrentAdminCard } = this.props;
    if (!haveCard) {
      getCardADmin(Number(id));
    } else {
      const currentUserWithCard: any = administrators.find((item) => item.id === id);
      getCurrentAdminCard(currentUserWithCard.personalCard, id);
    }
  }

  updateList = () => {
    const { currentPage, totalPages, getListAdmin } = this.props;
    if (currentPage !== totalPages) {
      getListAdmin(currentPage + 1);
    }
  }

  saveChanges = () => {
    const {
      saveChangesAdmins,
      administrators,
      toDelete,
    } = this.props;
    const rebuildRoles = (roles: IAdminRoles[]) => {
      const filteredRoles = roles.filter((role: IAdminRoles) => role.checked);
      return filteredRoles.map((role: IAdminRoles) => ({
        id: role.id,
      }));
    };
    const toCreateArray = administrators.map((
      {
        fullName,
        email,
        personalCard,
        sendStatus,
      }: any,
    ) => {
      if (sendStatus === ADMINISTRATION_STATUSES.toCreate) {
        return {
          fullName,
          email,
          status: personalCard.status,
          roles: rebuildRoles(personalCard.roles),
          password: personalCard.needUpdatePassword ? personalCard.password : undefined,
        };
      }
      return null;
    });
    const rebuildedCreatedArray = toCreateArray.filter((item) => item !== null);
    const toUpdateArray = administrators.map((
      {
        id,
        fullName,
        email,
        personalCard,
        sendStatus,
      }: any,
    ) => {
      if (sendStatus === ADMINISTRATION_STATUSES.toUpdate) {
        return {
          id,
          fullName,
          email,
          status: personalCard.status,
          password: personalCard.needUpdatePassword ? personalCard.password : undefined,
          roles: rebuildRoles(personalCard.roles),
        };
      }
      return null;
    });
    const rebuildedUpdateArray = toUpdateArray.filter((item) => item);
    const rebuildDelete = toDelete.filter((item) => item);
    const sendBody = {
      toCreate: rebuildedCreatedArray,
      toUpdate: rebuildedUpdateArray,
      toDelete: rebuildDelete,
    };
    saveChangesAdmins(sendBody);
  }

  render() {
    const {
      administrators,
      choosedId,
      currentCard,
      haveCard,
    } = this.props;
    return (
      <AdminCardForm
        choosedId={choosedId}
        addAction={this.createAdmin}
        administrators={administrators}
        deleteAction={this.deleteAdmin}
        handleChangeValue={this.handleChangeValue}
        saveChanges={this.saveChanges}
        resetChanges={this.resetAllChanges}
        updateList={this.updateList}
        getAdminPersonalCard={this.getAdminPersonalCard}
        currentCard={currentCard}
        haveCard={haveCard}
        handleChangeValuePersonalCard={this.handleChangeValuePersonalCard}
        checkDisabled={this.checkFormHimself}
        handleChangeCheckbox={this.handleChangeCheckbox}
        checkPasswordValidation={this.checkPasswordValidation}
      />
    );
  }
}

const mapStateToProps = ({
  adminCard,
  adminAuthorization: {
    id,
  },
}: IRedux) => ({
  ...adminCard,
  myId: id,
});

const mapDispatchToProps = {
  getListAdmin: getAdminsList,
  getCardADmin: getAdminCard,
  saveChangesAdmins: saveChanges,
  changesReset: resetChanges,
  clearStore: clearStoreAdministrators,
  changeField: changeFieldAdministrators,
  deleteAdminAction: deleteAdmin,
  getCurrentAdminCard: getCurrentCard,
  setHaveCard: setHaveCardAdministrators,
};

export default compose(
  withAdministrationInterface,
  withAdminFormInterface({ maxWidth: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AdminCard);
