import compose from '@shopify/react-compose';
import { UserCardForm } from 'components';
import { withAdminFormInterface, withAdministrationInterface } from 'HOC';
import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import { connect } from 'react-redux';
import randomstring from 'randomstring';
import { toastr } from 'react-redux-toastr';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRedux } from 'redux/interface';
import {
  getUserInfo,
  clearStoreAdminUserCard,
  controlAction,
  getCodes,
  getContragents,
  deleteAction,
  getContrAgentCard,
  clearCtgtCard,
  updatecontrAgents,
  showTablesControl,
  chooseCtgt,
  getContractCard,
  getContractsList,
  updateList as updateListCts,
  clearCtsCard,
  saveChangesUserCard,
  resetChangesUserCard,
  clearSearchCts,
} from 'redux/rootActions';
import {
  IUserCard,
  IContactsUserCard,
  IPersonalCard,
  IContrAgents,
  IContractUserCard,
  IContactSelectvalue,
} from 'redux/adminUserCard/reducers/interface';
import { ENUMS_SEND_STATUSES } from 'redux/adminUserCard/reducers';
import { generateFio } from 'helpers';
import { ICommonReducer } from 'redux/common/reducers/interface';
import schema from './schema';

const {
  CHOOSE_CTGT,
  FILL_CTGT,
  FILL_CTS,
  ALREADY_HAVE_CTGTS,
} = LANG_DICTIONARY;

export const ENUMS = {
  contact: 'contacts',
  contragents: 'contragents',
  contracts: 'contracts',
  setContrAget: 'setContrAgent',
  setContract: 'setContract',
};

type TAction = 'contacts' | 'contragents' | 'contracts';

interface IStoreProps {
  getUserCard: typeof getUserInfo;
  clearStore: typeof clearStoreAdminUserCard;
  actionControl: typeof controlAction;
  getContactCodes: typeof getCodes;
  getListCtgts: typeof getContragents;
  actionDelete: typeof deleteAction;
  getCtgtCard: typeof getContrAgentCard;
  clearCardCtgt: typeof clearCtgtCard;
  updateList: typeof updatecontrAgents;
  tablesControl: typeof showTablesControl;
  chooseCtgtsId: typeof chooseCtgt;
  getContacrt: typeof getContractCard;
  getLisContracts: typeof getContractsList;
  updateContractsList: typeof updateListCts;
  clearCts: typeof clearCtsCard;
  saveChanges: typeof saveChangesUserCard,
  resetChanges: typeof resetChangesUserCard,
  clearCtsList: typeof clearSearchCts,
}

interface IParamsUrl {
  id: string;
}
type TProps = IStoreProps & RouteComponentProps<IParamsUrl> & IUserCard & ICommonReducer;

class AdminUserCard extends React.Component <TProps> {
  componentDidMount = () => {
    const {
      getUserCard,
      match: {params: { id }},
      getContactCodes,
    } = this.props;
    getUserCard(id);
    getContactCodes();
  }

  componentWillUnmount = () => {
    const { clearStore } = this.props;
    clearStore();
  }

  handleChangeCanUpdatePassword = () => {
    const { userPersonalCard, actionControl } = this.props;
    const newData = {
      ...userPersonalCard,
      canUpdatePassword: !userPersonalCard.canUpdatePassword,
    };
    actionControl(newData);
  }

  handleSetContact = (id: number | string, value: IContactSelectvalue) => {
    const { userPersonalCard, actionControl } = this.props;
    const newContact = userPersonalCard.contacts.map((item) => {
      if (id === item.id) {
        return {
          ...item,
          contactTypeCode: value.code,
          contactTypeName: value.name,
        };
      }
      return item;
    });
    const newData = {
      ...userPersonalCard,
      contacts: newContact,
    };
    actionControl(newData);
  }

  handleChangeContactName = (id: number | string, value: string) => {
    const { userPersonalCard, actionControl } = this.props;
    const newContactname = userPersonalCard.contacts.map((item) => {
      if (id === item.id) {
        return {
          ...item,
          value,
        };
      }
      return item;
    });
    const newData = {
      ...userPersonalCard,
      contacts: newContactname,
    };
    actionControl(newData);
  }

  handleChange = (name: string, value: string | boolean) => {
    const { userPersonalCard, actionControl} = this.props;
    if (name === 'userPassword') {
      const newData = {
        ...userPersonalCard,
        [name as string]: value,
        needUpdatePassword: true,
      };
      actionControl(newData);
    } else {
      const newData = {
        ...userPersonalCard,
        [name]: value,
      };
      actionControl(newData);
    }
  }

  handleChangeValueCtgts = (id: number | string, name: string, value: string) => {
    const {
      userPersonalCard,
      actionControl,
      getListCtgts,
      match: {params: { id: userId }},
    } = this.props;
    const { contragents } = userPersonalCard;
    const newValueContacts = contragents.map((item) => {
      if (item.ctgtsId === id) {
        return {
          ...item,
          [name]: value,
        };
      }
      return item;
    });
    const newData: IPersonalCard = {
      ...userPersonalCard,
      contragents: newValueContacts,
    };
    actionControl(newData);
    getListCtgts(1, value, Number(userId));
  }

  handleChangeValueContract = (id: number | string, name: string, value: string) => {
    const {
      userPersonalCard,
      actionControl,
      getLisContracts,
      match: {params: { id: userId }},
      choosedCtgt,
    } = this.props;
    const { contracts } = userPersonalCard;
    const idFromBase = Boolean(Number(choosedCtgt));
    const newValueContracts = contracts.map((item) => {
      if (item.ctsId === id) {
        return {
          ...item,
          [name]: value,
        };
      }
      return item;
    });
    const newData: IPersonalCard = {
      ...userPersonalCard,
      contracts: newValueContracts,
    };

    if (!choosedCtgt) {
      toastr.error('', CHOOSE_CTGT);
    } else if (!idFromBase) {
      toastr.error('', FILL_CTGT);
    } else {
      getLisContracts(1, userId, value, choosedCtgt);
    }
    actionControl(newData);
  }

  hadleSetContrAgent = (id: number | string, value: IContrAgents) => {
    const { userPersonalCard, actionControl } = this.props;
    const { contragents } = userPersonalCard;
    const checkCtgt = userPersonalCard.ctgtsIdArray.find((item) => item === value.ctgtsId);
    if (checkCtgt) {
      toastr.error('', ALREADY_HAVE_CTGTS);
    } else {
      const setContrAgent = contragents.map((item) => {
        if (item.ctgtsId === id) {
          return {
            ...value,
            fio: generateFio(value.lastName, value.firstName, value.middleName),
            sendStatus: ENUMS_SEND_STATUSES.toCreate,
          };
        }
        return item;
      });
      const newData: IPersonalCard = {
        ...userPersonalCard,
        contragents: setContrAgent,
        ctgtsIdArray: [...userPersonalCard.ctgtsIdArray, Number(value.ctgtsId)],
      };
      actionControl(newData);
    }
  }

  handleSetContract = (id: number | string, value: IContractUserCard) => {
    const { userPersonalCard, actionControl, choosedCtgt: ctgtsId } = this.props;
    const { contracts } = userPersonalCard;
    const setContract = contracts.map((item) => {
      if (item.ctsId === id) {
        return {
          ...value,
          sendStatus: ENUMS_SEND_STATUSES.toCreate,
          ctgtsId,
        };
      }
      return item;
    });
    const newData = {...userPersonalCard, contracts: setContract};
    actionControl(newData);
  }

  handleChangeCheckBox = (
    id: number | string,
  ) => {
    const idFromBase = Boolean(Number(id));
    const { userPersonalCard, actionControl } = this.props;
    const { contacts } = userPersonalCard;
    const checkedContacts = contacts.map((item) => {
      if (item.id === Number(id)) {
        if (idFromBase) {
          return {
            ...item,
            isDefault: true,
            sendStatus: ENUMS_SEND_STATUSES.toUpdate,
          };
        }
        return {
          ...item,
          isDefault: true,
        };
      }
      return item;
    });
    const newData: IPersonalCard = {
      ...userPersonalCard,
      contacts: checkedContacts,
    };
    actionControl(newData);
  }

  createAction = (type: TAction) => {
    if (type === ENUMS.contact) {
      this.createContact();
    }
    if (type === ENUMS.contragents) {
      this.createCtgt();
    }
    if (type === ENUMS.contracts) {
      this.createContract();
    }
  }

  createCtgt = () => {
    const { userPersonalCard, actionControl } = this.props;
    const { contragents } = userPersonalCard;
    const newCtgt: IContrAgents = {
      ctgtsId: randomstring.generate({length: 7, charset: 'alphabetic'}),
      fio: '',
      guid1c: '',
      birthDate: '',
      sendStatus: ENUMS_SEND_STATUSES.toCreate,
      firstName: '',
      middleName: '',
      lastName: '',
    };
    const newCtgts = [...contragents, newCtgt];
    const newData = {...userPersonalCard, contragents: newCtgts};
    actionControl(newData);
  }

  createContact = () => {
    const { userPersonalCard, actionControl } = this.props;
    const { contacts } = userPersonalCard;
    const newContact: IContactsUserCard = {
      id: randomstring.generate({length: 7, charset: 'alphabetic'}),
      value: '',
      contactTypeCode: '',
      isDefault: false,
      contactTypeName: '',
      manualAddDate: '',
      sendStatus: ENUMS_SEND_STATUSES.toCreate,
      popUpOpen: false,
    };
    const newData: IPersonalCard = {
      ...userPersonalCard,
      contacts: [...contacts, newContact],
    };
    actionControl(newData);
  }

  createContract = () => {
    const { userPersonalCard, actionControl } = this.props;
    const { contracts } = userPersonalCard;
    const newContract: IContractUserCard = {
      ctsId: randomstring.generate({length: 7, charset: 'alphabetic'}),
      guid1c: '',
      numFull: '',
      dateCreate: '',
      statusName: '',
      sendStatus: ENUMS_SEND_STATUSES.toCreate,
    };
    const newData: IPersonalCard = {
      ...userPersonalCard,
      contracts: [...contracts, newContract],
    };
    actionControl(newData);
  }

  deleteContract = (id: number | string) => {
    const {
      actionDelete: deleteFromStore,
      userPersonalCard,
      actionControl,
      toDelete,
    } = this.props;
    const { contracts } = userPersonalCard;
    const contragentsWithoutDeleted = contracts.filter((item) => item.ctsId !== id);
    const findCurrentAdmin: any = contracts.find((item) => item.ctsId === id);
    if (findCurrentAdmin.sendStatus !== ENUMS_SEND_STATUSES.toCreate) {
      const newDeleted = {
        ...toDelete,
        contracts: [...toDelete.contracts, {ctsId: Number(id), ctgtsId: findCurrentAdmin.ctgtsId}],
      };
      deleteFromStore(newDeleted);
    }
    const newData: IPersonalCard = {
      ...userPersonalCard,
      contracts: contragentsWithoutDeleted,
    };
    actionControl(newData);
  }

  deleteContact = (id: number | string) => {
    const {
      actionDelete: deleteFromStore,
      userPersonalCard,
      actionControl,
      toDelete,
    } = this.props;
    const { contacts } = userPersonalCard;
    const contactFromBase = Boolean(Number(id));
    const contactsWithoutDeleted = contacts.filter((item) => item.id !== id);
    if (contactFromBase) {
      const newDeleted = {...toDelete, contacts: [...toDelete.contacts, {id}]};
      deleteFromStore(newDeleted);
    }
    const newData: IPersonalCard = {
      ...userPersonalCard,
      contacts: contactsWithoutDeleted,
    };
    actionControl(newData);
  }

  deleteCtgt = (id: number | string) => {
    const {
      actionDelete: deleteFromStore,
      userPersonalCard,
      actionControl,
      toDelete,
    } = this.props;
    const { contragents } = userPersonalCard;
    const contragentsWithoutDeleted = contragents.filter((item) => item.ctgtsId !== id);
    const findCurrentAdmin: any = contragents.find((item) => item.ctgtsId === id);
    if (findCurrentAdmin.sendStatus !== ENUMS_SEND_STATUSES.toCreate) {
      const newDeleted = {...toDelete, contrAgents: [...toDelete.contrAgents, {id}]};
      deleteFromStore(newDeleted);
    }
    const newData: IPersonalCard = {
      ...userPersonalCard,
      contragents: contragentsWithoutDeleted,
    };
    actionControl(newData);
  }

  deleteAction = (type: string, id: number | string) => {
    if (type === ENUMS.contact) {
      this.deleteContact(id);
    }
    if (type === ENUMS.contragents) {
      this.deleteCtgt(id);
    }
    if (type === ENUMS.contracts) {
      this.deleteContract(id);
    }
  }

  updateListOfCtgts = (filter: string) => {
    const {
      match: {params: { id }},
      updateList,
      currentPageCtgts,
      totalPagesCtgts,
    } = this.props;
    if (currentPageCtgts !== totalPagesCtgts) {
      updateList(currentPageCtgts + 1, filter, Number(id));
    }
  }

  showTablesControl = (type: string) => {
    const { showTables, tablesControl } = this.props;
    const { showContracts, showContragents } = showTables;
    if (type === ENUMS.contragents) {
      const newShowTables = {...showTables, showContragents: !showContragents};
      tablesControl(newShowTables);
    } else {
      const newShowTables = {...showTables, showContracts: !showContracts};
      tablesControl(newShowTables);
    }
  }

  getCurrentCtgtCard = (ctgtId: number | string) => {
    const {
      getCtgtCard,
      match: {params: { id }},
      choosedCtgt,
      clearCardCtgt,
    } = this.props;
    const idFromBase = Boolean(Number(ctgtId));
    if (choosedCtgt !== ctgtId && idFromBase) {
      getCtgtCard(id, Number(ctgtId));
    }
    if (!idFromBase) {
      toastr.error('', FILL_CTGT);
      clearCardCtgt();
    }
  }

  updateListContracts = (filter: string) => {
    const {
      match: {params: { id }},
      updateContractsList,
      currentPageCts,
      totalPagesCts,
      choosedCtgt,
    } = this.props;
    const idFromBase = Boolean(Number(choosedCtgt));
    if (!idFromBase) {
      toastr.error('', FILL_CTGT);
    } else if (currentPageCts !== totalPagesCts) {
      updateContractsList(currentPageCts + 1, id, filter, choosedCtgt);
    }
  }

  getContractCard = (ctsId: number | string) => {
    const {
      match: {params: { id }},
      choosedCts,
      clearCts,
      getContacrt,
    } = this.props;
    const idFromBase = Boolean(Number(ctsId));
    if (choosedCts !== ctsId && idFromBase) {
      getContacrt(id, Number(ctsId));
    }
    if (!idFromBase) {
      clearCts();
      toastr.error('', FILL_CTS);
    }
  }

  chooseCtgt = (id: number | string) => {
    const { chooseCtgtsId } = this.props;
    chooseCtgtsId(id);
  }

  saveChanges = () => {
    const {
      saveChanges,
      userPersonalCard,
      toDelete,
      match: {params: { id }},
    } = this.props;
    const createdContacts = userPersonalCard.contacts.map((item) => {
      if (item.sendStatus === ENUMS_SEND_STATUSES.toCreate) {
        return {
          value: item.value,
          code: item.contactTypeCode,
          isDefault: item.isDefault,
        };
      }
      return null;
    });
    const updatedContacts = userPersonalCard.contacts.map((item) => {
      if (item.sendStatus === ENUMS_SEND_STATUSES.toUpdate) {
        return {
          id: item.id,
          isDefault: item.isDefault,
        };
      }
      return null;
    });
    const createdContragents = userPersonalCard.contragents.map((item) => {
      if (item.sendStatus === ENUMS_SEND_STATUSES.toCreate) {
        return {
          id: item.ctgtsId,
        };
      }
      return null;
    });

    const createdContracts = userPersonalCard.contracts.map((item) => {
      if (item.sendStatus === ENUMS_SEND_STATUSES.toCreate) {
        return {
          ctsId: item.ctsId,
          ctgtsId: item.ctgtsId,
        };
      }
      return null;
    });

    const sendBody: any = {
      contacts: {
        toCreate: createdContacts.filter((item) => item !== null),
        toUpdate: updatedContacts.filter((item) => item !== null),
        toDelete: toDelete.contacts.filter((item) => item !== null),
      },
      contragents: {
        toCreate: createdContragents.filter((item) => item !== null),
        toDelete: toDelete.contrAgents.filter((item) => item !== null),
      },
      contracts: {
        toCreate: createdContracts.filter((item) => item !== null),
        toDelete: toDelete.contracts.filter((item) => item !== null),
      },
      password: userPersonalCard.needUpdatePassword ? userPersonalCard.userPassword : undefined,
      isTempBan: Boolean(userPersonalCard.tempBan),
      isLocked: userPersonalCard.isLocked,
    };
    saveChanges(id, sendBody);
  }

  validatePassword = (value: string) => {
    try {
      schema(value).validateSync({password: value});
      this.handleChange('errorPassword', '');
    } catch (e) {
      this.handleChange('errorPassword', e.message);
    }
  }

  render() {
    const {
      userPersonalCard,
      userPersonalCard: {
        userId,
        regDateCreate,
        registrationFinishDate,
        contacts,
        contragents,
      },
      selectData,
      listOfCtgts,
      currentContrAgent,
      showTables,
      currentContractCard,
      listOfContracts,
      choosedCtgt,
      resetChanges,
      clearCtsList,
      history,
      adminUrl,
    } = this.props;

    return (
      <UserCardForm
        resetChanges={resetChanges}
        choosedCtgt={choosedCtgt}
        chooseCtgt={this.chooseCtgt}
        userId={userId}
        contragents={contragents}
        regDateCreate={regDateCreate}
        registrationFinishDate={registrationFinishDate}
        addAction={this.createAction}
        deleteAction={this.deleteAction}
        onChangeCheckbox={this.handleChangeCheckBox}
        selectData={selectData}
        contacts={contacts}
        currentContrAgent={currentContrAgent}
        getCurrentCtgtCard={this.getCurrentCtgtCard}
        listOfCtgts={listOfCtgts}
        updateListOfCtgts={this.updateListOfCtgts}
        showTablesControl={this.showTablesControl}
        showTables={showTables}
        userPersonalCard={userPersonalCard}
        handleChangeInfo={this.handleChange}
        currentContractCard={currentContractCard}
        listOfContracts={listOfContracts}
        updateContractList={this.updateListContracts}
        getContractCard={this.getContractCard}
        saveChanges={this.saveChanges}
        clearCtsList={clearCtsList}
        handleChangeContact={this.handleSetContact}
        handleChangeContactName={this.handleChangeContactName}
        handleChangeValueCtgts={this.handleChangeValueCtgts}
        hadleSetContrAgent={this.hadleSetContrAgent}
        handleChangeValueContract={this.handleChangeValueContract}
        handleSetContract={this.handleSetContract}
        handleChangeCanUpdatePassword={this.handleChangeCanUpdatePassword}
        validatePassword={this.validatePassword}
        history={history}
        adminUrl={adminUrl}
      />
    );
  }
}

const mapStateToProps = ({adminUserCard, common}: IRedux) => ({...adminUserCard, ...common});

const mapDispatchToProps = {
  getUserCard: getUserInfo,
  clearStore: clearStoreAdminUserCard,
  actionControl: controlAction,
  getContactCodes: getCodes,
  getListCtgts: getContragents,
  actionDelete: deleteAction,
  getCtgtCard: getContrAgentCard,
  clearCardCtgt: clearCtgtCard,
  updateList: updatecontrAgents,
  tablesControl: showTablesControl,
  chooseCtgtsId: chooseCtgt,
  getContacrt: getContractCard,
  getLisContracts: getContractsList,
  updateContractsList: updateListCts,
  clearCts: clearCtsCard,
  saveChanges: saveChangesUserCard,
  resetChanges: resetChangesUserCard,
  clearCtsList: clearSearchCts,
};

export default compose(
  withAdministrationInterface,
  withAdminFormInterface({ maxWidth: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AdminUserCard);
