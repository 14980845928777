import { put, takeLatest } from 'redux-saga/effects';

import { API, LANG_DICTIONARY } from '../../../consts';
import { apiCall, downloadFile, getError } from '../../../helpers';
import {
  GET_PROFITABILITY_FAIL,
  GET_PROFITABILITY_REQUEST,
  GET_PROFITABILITY_START,
  GET_PROFITABILITY_SUCCESS,
  GET_PROFITABILITY_CONTRACT_INFO_FAIL,
  GET_PROFITABILITY_CONTRACT_INFO_REQUEST,
  GET_PROFITABILITY_CONTRACT_INFO_START,
  GET_PROFITABILITY_CONTRACT_INFO_SUCCESS,
  GET_PROFITABILITY_REPORT_FAIL,
  GET_PROFITABILITY_REPORT_SUCCESS,
  GET_PROFITABILITY_REPORT_START,
  GET_PROFITABILITY_REPORT_REQUEST,
  GET_PROFITABILITY_STRATEGIES_FAIL,
  GET_PROFITABILITY_STRATEGIES_SUCCESS,
  GET_PROFITABILITY_STRATEGIES_START,
  GET_PROFITABILITY_STRATEGIES_REQUEST,
  GET_PROFITABILITY_STRATEGY_REQUEST,
  GET_PROFITABILITY_STRATEGY_START,
  GET_PROFITABILITY_STRATEGY_SUCCESS,
  GET_PROFITABILITY_STRATEGY_FAIL,
  GET_PROFITABILITY_PAYMENTS_SUCCESS,
  GET_PROFITABILITY_PAYMENTS_FAIL,
  GET_PROFITABILITY_PAYMENTS_START,
  GET_PROFITABILITY_PAYMENTS_REQUEST,
  GET_PROFITABILITY_PAYMENT_INFO_SUCCESS,
  GET_PROFITABILITY_PAYMENT_INFO_FAIL,
  GET_PROFITABILITY_PAYMENT_INFO_START,
  GET_PROFITABILITY_PAYMENT_INFO_REQUEST,
} from '../types';

const { PROFITABILITY_REPORT_TEXT } = LANG_DICTIONARY;

function* getProfitabilityData({ payload }: { payload: string }) {
  try {
    yield put({ type: GET_PROFITABILITY_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_PROFITABILITY(payload),
    });
    yield put({ type: GET_PROFITABILITY_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PROFITABILITY_FAIL, payload: getError(e) });
  }
}

function* getProfitabilityContractInfo({ payload }: { payload: string }) {
  try {
    yield put({ type: GET_PROFITABILITY_CONTRACT_INFO_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_PROFITABILITY_CONTRACT_INFO(payload),
    });
    yield put({ type: GET_PROFITABILITY_CONTRACT_INFO_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PROFITABILITY_CONTRACT_INFO_FAIL, payload: getError(e) });
  }
}

function* getProfitabilityStrategies({ payload }: { payload: string }) {
  try {
    yield put({ type: GET_PROFITABILITY_STRATEGIES_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_PROFITABILITY_STRATEGIES(payload),
    });
    yield put({ type: GET_PROFITABILITY_STRATEGIES_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PROFITABILITY_STRATEGIES_FAIL, payload: getError(e) });
  }
}

function* getProfitabilityStrategy({ payload }: { payload: string }) {
  try {
    yield put({ type: GET_PROFITABILITY_STRATEGY_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_PROFITABILITY_STRATEGY(payload),
    });
    yield put({ type: GET_PROFITABILITY_STRATEGY_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PROFITABILITY_STRATEGY_FAIL, payload: getError(e) });
  }
}

export interface IPaymentsPayload {
  id: string,
  year: string
}
export interface IPaymentPayload {
  id: string,
  date: string
}

function* getProfitabilityPayments({ payload }: { payload: IPaymentsPayload }) {
  try {
    yield put({ type: GET_PROFITABILITY_PAYMENTS_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_PROFITABILITY_PAYMENTS(payload.id, payload.year),
    });
    yield put({ type: GET_PROFITABILITY_PAYMENTS_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PROFITABILITY_PAYMENTS_FAIL, payload: getError(e) });
  }
}

function* getProfitabilityPaymentInfo({ payload }: { payload: IPaymentPayload }) {
  try {
    yield put({ type: GET_PROFITABILITY_PAYMENT_INFO_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_PROFITABILITY_PAYMENT_INFO(payload.id, payload.date),
    });
    yield put({ type: GET_PROFITABILITY_PAYMENT_INFO_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PROFITABILITY_PAYMENT_INFO_FAIL, payload: getError(e) });
  }
}

function* getProfitabilityReport({ payload }: { payload: any }) {
  try {
    yield put({ type: GET_PROFITABILITY_REPORT_START });
    const res = yield apiCall({
      type: 'GET',
      url: API.GET_PROFITABILITY_REPORT(payload.id),
      query: { reportDate: payload.reportDate },
      isBlob: true,
    });
    const fileName = `${PROFITABILITY_REPORT_TEXT(payload.contractInfo.ctsNumber, payload.contractInfo.dateOfPrice)}.pdf`;
    downloadFile(res, fileName);
    yield put({ type: GET_PROFITABILITY_REPORT_SUCCESS });
  } catch (e) {
    yield put({ type: GET_PROFITABILITY_REPORT_FAIL });
  }
}

export default function* profitabilitySaga() {
  yield takeLatest<string, any>(GET_PROFITABILITY_REQUEST, getProfitabilityData);
  yield takeLatest<string, any>(GET_PROFITABILITY_STRATEGIES_REQUEST, getProfitabilityStrategies);
  yield takeLatest<string, any>(GET_PROFITABILITY_STRATEGY_REQUEST, getProfitabilityStrategy);
  yield takeLatest<string, any>(GET_PROFITABILITY_PAYMENTS_REQUEST, getProfitabilityPayments);
  yield takeLatest<string, any>(
    GET_PROFITABILITY_PAYMENT_INFO_REQUEST,
    getProfitabilityPaymentInfo,
  );
  yield takeLatest<string, any>(GET_PROFITABILITY_REPORT_REQUEST, getProfitabilityReport);
  yield takeLatest<string, any>(
    GET_PROFITABILITY_CONTRACT_INFO_REQUEST,
    getProfitabilityContractInfo,
  );
}
