import { API, LANG_DICTIONARY, ROUTES } from 'consts';
import {apiCall, getError, getGlobalHistory} from 'helpers';
import { put, takeLatest, select } from 'redux-saga/effects';
import { ITEM_COUNT_ADMIN_ADMINS } from 'config';
import { toastr } from 'react-redux-toastr';

import {
  GET_ADMINISTRATORS,
  GET_ADMIN_CARD,
  SAVE_CHANGES,
} from '../types';
import { ISendBody } from '../reducers/interfaces';

const { CHECK_DATA_AND_TRY_AGAIN, SAVED_SUCCESS } = LANG_DICTIONARY;

function* getListOfAdmins({payload}: {payload: number}) {
  try {
    yield put({ type: GET_ADMINISTRATORS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_ADMINISTRATORS_LIST,
      isAdminToken: true,
      query: {
        offset: ITEM_COUNT_ADMIN_ADMINS * (payload - 1),
        limit: ITEM_COUNT_ADMIN_ADMINS,
      },
    });

    yield put({ type: GET_ADMINISTRATORS.success, payload: data });
  } catch (e) {
    yield put({ type: GET_ADMINISTRATORS.fail });
    const { adminUrl } = yield select(({common}) => common);
    getGlobalHistory().push(`${adminUrl}${ROUTES.adminAuthorization}`);
  }
}

function* getAdminCard({payload}: {payload: number}) {
  try {
    yield put({ type: GET_ADMIN_CARD.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_ADMINISTRATOR_CARD(payload),
      isAdminToken: true,
    });
    yield put({
      type: GET_ADMIN_CARD.success,
      payload: {
        data: {
          ...data,
          hash: data.password,
          password: '',
        },
        adminId: payload,
      },
    });
  } catch (e) {
    yield put({ type: GET_ADMIN_CARD.fail });
  }
}

function* saveChanges({payload}: {payload: ISendBody }) {
  try {
    yield put({ type: SAVE_CHANGES.start });
    const getRebuildData = (admin: any[]) => admin
      .map((item) => ({
        ...item,
        password: item.password || undefined,
      }));
    const rebuildData = {
      toCreate: getRebuildData(payload.toCreate),
      toUpdate: getRebuildData(payload.toUpdate),
      toDelete: getRebuildData(payload.toDelete),
    };
    yield apiCall({
      type: 'PUT',
      url: API.GET_ADMINISTRATORS_LIST,
      isAdminToken: true,
      body: rebuildData,
    });
    yield put({ type: SAVE_CHANGES.success });

    const { currentPage } = yield select(({ adminCard }) => adminCard);
    yield getListOfAdmins({ payload: currentPage });
    toastr.success('', SAVED_SUCCESS);
  } catch (e) {
    getError(
      e,
      true,
      null,
      null,
      CHECK_DATA_AND_TRY_AGAIN,
    );
    yield put({ type: SAVE_CHANGES.fail });
  }
}

export default function* adminAdministratorsSaga() {
  yield takeLatest<string, any>(GET_ADMINISTRATORS.request, getListOfAdmins);
  yield takeLatest<string, any>(GET_ADMIN_CARD.request, getAdminCard);
  yield takeLatest<string, any>(SAVE_CHANGES.request, saveChanges);
}
