import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import {
  FormInput,
  GreenButton,
  CustomCheckBox,
  InfoBlock,
} from 'components';
import { API_BASE_URL } from 'config';
import React from 'react';
import './styles.scss';

const {
  SUPPORT_DESCRIPTION,
  SUPPORT_TITLE,
  ENTER_EMAIL,
  ENTER_YOUR_MESSAGE,
  ACCEPT_WITH_AGREEMENT,
  SEND_REQUEST,
  EMAIL_DESCTRIPTION,
  ENTER_YOUR_QUESTION,
} = LANG_DICTIONARY;
const b = block('support-block');

interface IProps {
  email: string;
  message: string;
  onChange: (e: any) => void;
  sendMessage: () => void;
  isActiveChekbox: boolean;
  link: string;
  checkboxClick: () => void;
  errorSupport: string;
}

const disableButton = (checkbox: boolean, email: string, message: string) => Boolean(
  checkbox
  && email.length
  && message.length,
);
const SupportBlock: React.FC<IProps> = ({
  email,
  message,
  onChange,
  sendMessage,
  isActiveChekbox,
  link,
  checkboxClick,
  errorSupport,
}) => (
  <div className={b('wrapper')}>
    <div className={b()}>
      <div className={b('left-side')}>
        <div className={b('text')}>
          <div className={b('title')}>{SUPPORT_TITLE}</div>
          <div className={b('description')}>{SUPPORT_DESCRIPTION}</div>
        </div>
      </div>
      <div className={b('right-side')}>
        <FormInput
          value={email}
          onChange={onChange}
          mask=""
          name="emailForSupport"
          placeholder={ENTER_EMAIL}
        />
        <div className={b('email-desctription')}>{email.length ? ENTER_YOUR_QUESTION : EMAIL_DESCTRIPTION }</div>
        <textarea
          className={b('message-field')}
          placeholder={ENTER_YOUR_MESSAGE}
          name="messageForSupport"
          value={message}
          onChange={onChange}
        />
        <div className={b('control-form')}>
          <GreenButton
            className={b('send-button')}
            text={SEND_REQUEST}
            onClick={sendMessage}
            disabled={!disableButton(isActiveChekbox, email, message)}
          />
          <CustomCheckBox
            active={isActiveChekbox}
            onClick={checkboxClick}
          />
          <a
            className={b('agreement-text')}
            download
            href={`${API_BASE_URL}/${link}`}
          >
            {ACCEPT_WITH_AGREEMENT}
          </a>
        </div>
        {errorSupport && <InfoBlock message={errorSupport} error />}
      </div>
    </div>
  </div>
);

export default SupportBlock;
