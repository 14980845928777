import React from 'react';
import { block } from 'bem-cn';
import { VK_BCS, INSTAGRAM_BCS, FB_BCS } from 'config';
import './styles.scss';

const b = block('social-network');

const SocialIcons = () => (
  <div className={b()}>
    <a href={VK_BCS} className={b('social-ling')} target="_blank" rel="noreferrer noopener">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <g opacity="0.9">
          <path d="M16.853 0H1.14953C0.514431 0 0 0.514431 0 1.14826V16.8517C0 17.4856 0.514431 18 1.14953 18H16.8517C17.4856 18 18 17.4856 18 16.8517V1.14826C18.0013 0.513161 17.4868 0 16.853 0ZM14.9312 10.9682C15.2602 11.3035 15.974 11.8662 15.8445 12.4391C15.7251 12.9649 14.9401 12.7731 14.178 12.8036C13.3079 12.8404 12.7922 12.8595 12.2689 12.4391C12.0224 12.2396 11.8776 12.0034 11.6414 11.7392C11.4267 11.5004 11.1562 11.0723 10.7878 11.0888C10.126 11.1219 10.3331 12.044 10.0981 12.6728C6.41959 13.252 4.94235 10.9796 3.63912 8.77454C3.00783 7.7063 2.09583 5.41232 2.09583 5.41232L4.6972 5.40343C4.6972 5.40343 5.53172 6.92132 5.75273 7.31254C5.94072 7.64533 6.14777 7.90953 6.36116 8.20676C6.54026 8.45318 6.82351 8.93586 7.13344 8.89648C7.63771 8.8317 7.72917 6.87559 7.4167 6.22017C7.29222 5.9547 6.99372 5.86197 6.68506 5.77179C6.78922 5.11382 9.60271 4.97664 10.0574 5.48726C10.7179 6.22906 9.60017 8.2944 10.5046 8.89648C11.7748 8.23089 12.8595 5.44408 12.8595 5.44408L15.9054 5.46313C15.9054 5.46313 15.4291 6.96959 14.9299 7.63771C14.6391 8.02893 13.6737 8.90029 13.7118 9.54682C13.7423 10.0587 14.5273 10.5566 14.9312 10.9682Z" fill="white" />
        </g>
      </svg>
    </a>
    <a href={FB_BCS} className={b('social-ling')} target="_blank" rel="noreferrer noopener">
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
      >
        <path opacity="0.9" d="M18.2617 1.49241V17.5036C18.2617 18.054 17.8157 18.496 17.2693 18.496H12.6809V11.529H15.0193L15.3689 8.81295H12.6769V7.07723C12.6769 6.28973 12.8939 5.75536 14.0229 5.75536H15.4613V3.32455C15.2122 3.29241 14.3604 3.21607 13.364 3.21607C11.2907 3.21607 9.86841 4.4817 9.86841 6.80804V8.81295H7.52199V11.529H9.86841V18.5H1.25413C0.707701 18.5 0.261719 18.054 0.261719 17.5076V1.49241C0.261719 0.945982 0.707701 0.5 1.25413 0.5H17.2653C17.8157 0.5 18.2617 0.945982 18.2617 1.49241Z" fill="#FCFCFC" />
      </svg>
    </a>
    <a href={INSTAGRAM_BCS} className={b('social-ling')} target="_blank" rel="noreferrer noopener">
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
      >
        <path opacity="0.9" d="M9.26373 4.88405C6.70979 4.88405 4.64978 6.94406 4.64978 9.49799C4.64978 12.0519 6.70979 14.1119 9.26373 14.1119C11.8177 14.1119 13.8777 12.0519 13.8777 9.49799C13.8777 6.94406 11.8177 4.88405 9.26373 4.88405ZM9.26373 12.4977C7.61331 12.4977 6.26406 11.1524 6.26406 9.49799C6.26406 7.84356 7.60929 6.49833 9.26373 6.49833C10.9182 6.49833 12.2634 7.84356 12.2634 9.49799C12.2634 11.1524 10.9141 12.4977 9.26373 12.4977ZM15.1426 4.69531C15.1426 5.29364 14.6607 5.7715 14.0664 5.7715C13.4681 5.7715 12.9902 5.28963 12.9902 4.69531C12.9902 4.101 13.4721 3.61913 14.0664 3.61913C14.6607 3.61913 15.1426 4.101 15.1426 4.69531ZM18.1985 5.78756C18.1302 4.34596 17.8009 3.06899 16.7448 2.0169C15.6927 0.964807 14.4158 0.635527 12.9742 0.563246C11.4884 0.478918 7.03506 0.478918 5.54928 0.563246C4.11169 0.631511 2.83472 0.960792 1.77862 2.01288C0.722511 3.06498 0.397246 4.34194 0.324965 5.78355C0.240637 7.26933 0.240637 11.7226 0.324965 13.2084C0.39323 14.65 0.722511 15.927 1.77862 16.9791C2.83472 18.0312 4.10768 18.3605 5.54928 18.4327C7.03506 18.5171 11.4884 18.5171 12.9742 18.4327C14.4158 18.3645 15.6927 18.0352 16.7448 16.9791C17.7969 15.927 18.1262 14.65 18.1985 13.2084C18.2828 11.7226 18.2828 7.27334 18.1985 5.78756ZM16.279 14.8026C15.9658 15.5897 15.3594 16.196 14.5684 16.5133C13.3837 16.9831 10.5728 16.8747 9.26373 16.8747C7.95464 16.8747 5.13969 16.9791 3.9591 16.5133C3.17204 16.2001 2.56568 15.5937 2.24845 14.8026C1.77862 13.618 1.88704 10.8071 1.88704 9.49799C1.88704 8.1889 1.78263 5.37395 2.24845 4.19336C2.56166 3.4063 3.16802 2.79994 3.9591 2.48271C5.1437 2.01288 7.95464 2.1213 9.26373 2.1213C10.5728 2.1213 13.3878 2.0169 14.5684 2.48271C15.3554 2.79593 15.9618 3.40229 16.279 4.19336C16.7488 5.37797 16.6404 8.1889 16.6404 9.49799C16.6404 10.8071 16.7488 13.622 16.279 14.8026Z" fill="#FCFCFC" />
      </svg>
    </a>
  </div>
);

export default SocialIcons;
