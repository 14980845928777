import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import {
  IszIconSvg,
  HealthIconSvg,
  NszIconSvg,
} from 'components';
import React from 'react';
import './styles.scss';

interface IProps {
  isIsz: boolean,
  setType: (value: boolean) => void,
}

const {
  INVESTATION,
  INSURANCE,
  NSJ,
  BCS_WORD,
  HEALTH_PRIORITY,
} = LANG_DICTIONARY;

const b = block('contracts-tabs');

const ContractsTabs: React.FC<IProps> = ({
  setType,
  isIsz,
}) => (
  <div className={b()}>
    <div className={b('row')}>
      <button onClick={() => setType(true)} className={b('tab', {active: isIsz})}>
        <div className={b('content')}>
          <IszIconSvg />
          <div className={b('text')}>
            <div>{INVESTATION}</div>
            <div>{INSURANCE}</div>
          </div>
        </div>
        {
          isIsz ? <div className={b('choosed')} /> : <div />
        }
      </button>
      <button onClick={() => setType(false)} className={b('tab', {active: !isIsz})}>
        <div className={b('content')}>
          <NszIconSvg />
          <div className={b('text')}>
            <div>{NSJ}</div>
            <div>{INSURANCE}</div>
          </div>
        </div>
        {
          !isIsz ? <div className={b('choosed')} /> : <div />
        }
      </button>
      <div className={b('tab')}>
        <div className={b('content')}>
          <HealthIconSvg />
          <div className={b('text')}>
            <div>{BCS_WORD}</div>
            <div>{HEALTH_PRIORITY}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ContractsTabs;
