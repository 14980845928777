import React from 'react';
import {block} from 'bem-cn';
import './styles.scss';

const b = block('row-product');

const heightRow = 52;
const padding = 4;

const RowProduct = (props: any) => {
  const {
    row,
    title,
    value,
    html,
  } = props;

  const countHeight = () => row * (heightRow + padding - 1) || heightRow;

  if (html) {
    return (
      <div className={b('description')} style={{height: countHeight()}}>
        <p className={b('text-wrapper')}>{title}</p>
        <div dangerouslySetInnerHTML={{__html: html}} />
      </div>
    );
  }

  return (
    <div className={b()} style={{minHeight: countHeight()}}>
      <p className={b('text-wrapper')}>{title}</p>
      <p className={b('text-wrapper')}>{value}</p>
    </div>
  );
};

export default RowProduct;
