import { API } from 'consts';
import { apiCall, getError } from 'helpers';
import { put, takeLatest} from 'redux-saga/effects';
import {
  CHANGE_CONTACTS_FAIL,
  CHANGE_CONTACTS_REQUEST,
  CHANGE_CONTACTS_START,
  CHANGE_CONTACTS_SUCCESS,
  CONTACTS_VERIFY_FAIL,
  CONTACTS_VERIFY_REQUEST,
  CONTACTS_VERIFY_START,
  CONTACTS_VERIFY_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  DELETE_PERSONAL_CONTACT_FAIL,
  DELETE_PERSONAL_CONTACT_REQUEST,
  DELETE_PERSONAL_CONTACT_START,
  DELETE_PERSONAL_CONTACT_SUCCESS,
  GET_AVATAR_USER_FAIL,
  GET_AVATAR_USER_REQUEST,
  GET_AVATAR_USER_START,
  GET_AVATAR_USER_SUCCESS,
  GET_CONTACTS_INFO_FAIL,
  GET_CONTACTS_INFO_REQUEST,
  GET_CONTACTS_INFO_START,
  GET_CONTACTS_INFO_SUCCESS,
  GET_MAIN_DATA_USER_FAIL,
  GET_MAIN_DATA_USER_REQUEST,
  GET_MAIN_DATA_USER_START,
  GET_MAIN_DATA_USER_SUCCESS,
  GET_PASSWORD_INFO_FAIL,
  GET_PASSWORD_INFO_REQUEST,
  GET_PASSWORD_INFO_START,
  GET_PASSWORD_INFO_SUCCESS,
  GET_PERSONAL_DATA_FAIL,
  GET_PERSONAL_DATA_REQUEST,
  GET_PERSONAL_DATA_START,
  GET_PERSONAL_DATA_SUCCESS,
  SET_AVATAR_USER_FAIL,
  SET_AVATAR_USER_REQUEST,
  SET_AVATAR_USER_START,
  SET_AVATAR_USER_SUCCESS,
  GET_AGREEMENT_FAIL,
  GET_AGREEMENT_REQUEST,
  GET_AGREEMENT_START,
  GET_AGREEMENT_SUCCESS,
  SEND_SUPPORT_MESSAGE_FAIL,
  SEND_SUPPORT_MESSAGE_REQUEST,
  SEND_SUPPORT_MESSAGE_START,
  SEND_SUPPORT_MESSAGE_SUCCESS,
  GET_CURRENT_YEAR,
  CHANGE_CONTACTS_PHONE_SUCCESS,
  CHANGE_CONTACTS_PHONE_START,
  CHANGE_CONTACTS_PHONE_FAIL,
  CHANGE_CONTACTS_PHONE_REQUEST,
  CHANGE_CONTACTS_EMAIL_START,
  CHANGE_CONTACTS_EMAIL_SUCCESS,
  CHANGE_CONTACTS_EMAIL_FAIL,
  CHANGE_CONTACTS_EMAIL_REQUEST,
} from '../types';

function* getCurrentYear() {
  try {
    yield put({type: GET_CURRENT_YEAR.start});
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CURRENT_YEAR,
    });
    const currentYear = new Date(data).getFullYear();
    yield put({ type: GET_CURRENT_YEAR.success, payload: currentYear});
  } catch (e) {
    yield put({type: GET_CURRENT_YEAR.fail});
  }
}

function* getAgreement() {
  try {
    yield put({ type: GET_AGREEMENT_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_AGREEMENT_LINK,
    });
    yield put({ type: GET_AGREEMENT_SUCCESS, payload: data.agreementLink });
  } catch (e) {
    yield put({ type: GET_AGREEMENT_FAIL });
  }
}

function* sendSupportMessage({payload}: {payload: {email: string, message: string}}) {
  try {
    const { email, message } = payload;
    yield put({ type: SEND_SUPPORT_MESSAGE_START });
    yield apiCall({
      type: 'POST',
      url: API.SEND_SUPPORT_MESSAGE,
      body: {
        email,
        message,
      },
    });
    yield put({ type: SEND_SUPPORT_MESSAGE_SUCCESS });
  } catch (e) {
    yield put({ type: SEND_SUPPORT_MESSAGE_FAIL });
  }
}

function* getPersonalData() {
  try {
    yield put({ type: GET_PERSONAL_DATA_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.PERSONAL_DATA_USER,
    });
    yield put({ type: GET_PERSONAL_DATA_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PERSONAL_DATA_FAIL });
  }
}

function* deleteUserContact({payload}: {payload: number}) {
  try {
    yield put({ type: DELETE_PERSONAL_CONTACT_START });
    yield apiCall({
      type: 'DELETE',
      url: `${API.DELETE_USER_CONTACT}${payload}`,
    });
    yield put({ type: DELETE_PERSONAL_CONTACT_SUCCESS });
    yield (getPersonalData());
  } catch (e) {
    yield put({ type: DELETE_PERSONAL_CONTACT_FAIL });
  }
}

function* getPasswordInfo() {
  try {
    yield put({ type: GET_PASSWORD_INFO_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.PASSWORD_CONTROL,
    });
    yield put({ type: GET_PASSWORD_INFO_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PASSWORD_INFO_FAIL });
  }
}

function* changePassword({payload}: {payload: {
  oldPassword: string,
  newPassword: string,
  passwordConfirmation: string,
 }}) {
  try {
    const { oldPassword, newPassword, passwordConfirmation } = payload;
    yield put({ type: CHANGE_PASSWORD_START });
    yield apiCall({
      body: {
        newPassword,
        oldPassword,
        passwordConfirmation,
      },
      type: 'PUT',
      url: API.PASSWORD_CONTROL,
    });
    yield put({ type: CHANGE_PASSWORD_SUCCESS });
  } catch (e) {
    yield put({ type: CHANGE_PASSWORD_FAIL, payload: getError(e) });
  }
}

function* changeContact({payload}: {payload: {contact: string}}) {
  try {
    const { contact } = payload;
    yield put({ type: CHANGE_CONTACTS_START });
    yield apiCall({
      body: {
        contact,
      },
      type: 'POST',
      url: API.PERSONAL_CONTACTS_CONTROL_VERIFY,
    });
    yield put({ type: CHANGE_CONTACTS_SUCCESS });
    yield (getPersonalData());
  } catch (e) {
    yield put({ type: CHANGE_CONTACTS_FAIL, payload: getError(e) });
  }
}

function* changeContactPhone({payload}: {payload: {contact: string, smsCode: string}}) {
  try {
    const { contact, smsCode } = payload;
    yield put({ type: CHANGE_CONTACTS_PHONE_START });
    yield apiCall({
      body: {
        contact,
        smsCode,
      },
      type: 'POST',
      url: API.CHANGE_CONTACTS_PHONE,
    });
    yield put({ type: CHANGE_CONTACTS_PHONE_SUCCESS });
    yield (getPersonalData());
  } catch (e) {
    yield put({ type: CHANGE_CONTACTS_PHONE_FAIL, payload: getError(e) });
  }
}

function* changeContactEmail({payload}: {payload: {contact: string, code: string}}) {
  try {
    const { contact, code } = payload;
    yield put({ type: CHANGE_CONTACTS_EMAIL_START });
    yield apiCall({
      body: {
        contact,
        code,
      },
      type: 'POST',
      url: API.CHANGE_CONTACTS_EMAIL,
    });
    yield put({ type: CHANGE_CONTACTS_EMAIL_SUCCESS });
    yield (getPersonalData());
  } catch (e) {
    yield put({ type: CHANGE_CONTACTS_EMAIL_FAIL, payload: getError(e) });
  }
}

function* contactVerify({payload}: {payload: {contact: string}}) {
  try {
    const { contact } = payload;
    yield put({ type: CONTACTS_VERIFY_START });
    const { data } = yield apiCall({
      body: {
        contact,
      },
      type: 'POST',
      url: API.PERSONAL_CONTACTS_CONTROL_VERIFY,
    });
    yield put({ type: CONTACTS_VERIFY_SUCCESS, payload: data });
    yield (getPersonalData());
  } catch (e) {
    yield put({ type: CONTACTS_VERIFY_FAIL, payload: getError(e) });
  }
}

function* getMainData() {
  try {
    yield put({ type: GET_MAIN_DATA_USER_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_USER_MAIN_DATA,
    });
    yield put({ type: GET_MAIN_DATA_USER_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_MAIN_DATA_USER_FAIL });
  }
}

function* getAvatarUser() {
  try {
    yield put({ type: GET_AVATAR_USER_START });
    const { data } = yield apiCall({
      isBlob: true,
      type: 'GET',
      url: API.GET_USER_AVATAR,
    });
    const newUrl = window.URL.createObjectURL(new Blob([data]));
    yield put({ type: GET_AVATAR_USER_SUCCESS, payload: newUrl});
  } catch (e) {
    yield put({ type: GET_AVATAR_USER_FAIL });
  }
}

function* setAvatarUser({payload}: {payload: FormData}) {
  try {
    yield put({ type: SET_AVATAR_USER_START });
    yield apiCall({
      body: payload,
      type: 'PUT',
      url: API.GET_USER_AVATAR,
    });
    yield put({ type: SET_AVATAR_USER_SUCCESS });
    yield (getAvatarUser());
  } catch (e) {
    yield put({ type: SET_AVATAR_USER_FAIL });
  }
}

export default function* mainDataSaga() {
  yield takeLatest(GET_CURRENT_YEAR.request, getCurrentYear);
  yield takeLatest<string, any>(SEND_SUPPORT_MESSAGE_REQUEST, sendSupportMessage);
  yield takeLatest(GET_AGREEMENT_REQUEST, getAgreement);
  yield takeLatest(GET_PERSONAL_DATA_REQUEST, getPersonalData);
  yield takeLatest<string, any>(DELETE_PERSONAL_CONTACT_REQUEST, deleteUserContact);
  yield takeLatest(GET_PASSWORD_INFO_REQUEST, getPasswordInfo);
  yield takeLatest<string, any>(CHANGE_CONTACTS_REQUEST, changeContact);
  yield takeLatest<string, any>(CHANGE_CONTACTS_PHONE_REQUEST, changeContactPhone);
  yield takeLatest<string, any>(CHANGE_CONTACTS_EMAIL_REQUEST, changeContactEmail);
  yield takeLatest<string, any>(CONTACTS_VERIFY_REQUEST, contactVerify);
  yield takeLatest<string, any>(CHANGE_PASSWORD_REQUEST, changePassword);
  yield takeLatest(GET_AVATAR_USER_REQUEST, getAvatarUser);
  yield takeLatest(GET_MAIN_DATA_USER_REQUEST, getMainData);
  yield takeLatest<string, any>(SET_AVATAR_USER_REQUEST, setAvatarUser);
}
