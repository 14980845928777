import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authReducer from './authorization/reducers';
import mainDataReducer from './mainData/reducers';
import registartionReducer from './registration/reducers';
import adminAuthorization from './adminAuthorization/reducers';
import common from './common/reducers';
import adminManagment from './adminManagment/reducers';
import contracts from './contracts/reducers';
import adminUsers from './adminUsers/reducers';
import adminUserCard from './adminUserCard/reducers';
import descriptions from './clientDescriptions/reducers';
import adminCard from './adminAdministrators/reducers';
import profitability from './profitability/reducers/index';
import applications from './applications/reducers/index';

export default () => combineReducers({
  adminUserCard,
  adminCard,
  descriptions,
  authReducer,
  mainDataReducer,
  registartionReducer,
  adminUsers,
  adminAuthorization,
  adminManagment,
  common,
  contracts,
  profitability,
  toastr: toastrReducer,
  applications,
});
