import React from 'react';
import block from 'bem-cn';
import exclamationMark from '../../../../assets/svg/exclamationMark.svg';
import './styles.scss';

const b = block('warning');

interface IProps {
    warningText: string
}

const WarningInfo: React.FC<IProps> = (props) => {
  const {
    warningText,
  } = props;

  return (
    <div className={b()}>
      <img src={exclamationMark} alt="exclamationMark" />
      <div className={b('border')} />
      <p className={b('text')}>{warningText}</p>
    </div>
  );
};

export default WarningInfo;
