import React from 'react';
import { block } from 'bem-cn';
import './styles.scss';
import { LANG_DICTIONARY } from 'consts';
import uploadFile from 'assets/svg/uploadFile.svg';
import exit from 'assets/svg/exit.svg';

const b = block('saveFile');

interface IProps {
  passportPhoto: { photo: File, reg: File },
  passportPhotoFile: File,
  setScanPhoto: (e: React.ChangeEvent<HTMLInputElement>) => void;
  delImage: (name: string) => void;
  name: string;
  desc: string;
  isPicErr: boolean;
}

const fileSize = 5;

const PhotoUploadDesc: React.FC<IProps> = (props: IProps) => {
  const {
    passportPhotoFile,
    setScanPhoto,
    delImage,
    name,
    desc,
    isPicErr,
  } = props;

  return (
    <div className={b()}>
      <label htmlFor={name} className={b('avatar-upload')}>
        <img src={uploadFile} alt="upload" />
        <input
          type="file"
          className={b('input-file')}
          id={name}
          name={name}
          onChange={(e) => setScanPhoto(e)}
          accept=".pdf, .jpeg, .jpg, .png"
        />
        <p className={b('upload-text')}>{desc}</p>
      </label>
      {
        passportPhotoFile
        && (
        <div className={b('image')}>
          <div className={b('image-desc')}>{passportPhotoFile?.name}</div>
          <div aria-hidden="true" onClick={(e) => delImage(name)}>
            <img className={b('del-icon')} src={exit} alt="del" />
          </div>
        </div>
        )
      }
      {
        isPicErr && (
        <div className={b('error')}>{LANG_DICTIONARY.FILE_SIZE_WARNING(fileSize)}</div>
        )
      }
    </div>
  );
};

export default PhotoUploadDesc;
