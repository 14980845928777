import { ROUTE_TYPE } from 'consts';
import React from 'react';
import { Route as RouteDom } from 'react-router-dom';
import { AdminContainer, ClientContainer } from 'router/components';
import { IRoute } from 'router/interfaces/route';
import { MainPageContainer } from '../../../containers';

const defaultProps = {
  exact: true,
};

const Route: React.FC<IRoute> = ({
  component: Component,
  title,
  path,
  type,
  ...rest
}) => {
  document.title = title;

  const getContainer = () => {
    switch (type) {
      case ROUTE_TYPE.admin:
        return <AdminContainer component={Component} />;

      case ROUTE_TYPE.client:
        return <ClientContainer component={Component} />;

      default:
        return <Component />;
    }
  };

  return (
    <RouteDom
      {...rest}
      exact
      render={() => (
        <>
          {getContainer()}
        </>
      )}
    />
  );
};


Route.defaultProps = defaultProps;
export default Route;
