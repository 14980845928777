import React, { useEffect, useState } from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { IData, IPrintFormOptions } from 'redux/contracts/reducers/interface';
import { IszData } from 'components';
import { Loader } from 'semantic-ui-react';
import ContractCard from '../MyIszBlock/ContractCard';
import '../MyIszBlock/styles.scss';

const b = block('contracts-cards-block');
const {
  MY_NSZ_CONTRACTS,
  NO_CONTRACTS,
} = LANG_DICTIONARY;

interface IProps {
    nsz: IData[];
    isIsz: boolean,
    isLoading: boolean,
    printFormsOptions: IPrintFormOptions[],
}

const MyNszDataBlock: React.FC<IProps> = ({
  nsz,
  isIsz,
  isLoading,
  printFormsOptions,
}) => {
  if (!nsz.length) {
    return (
      <div className={b()}>
        <Loader active={isLoading} />
        {!isLoading
          ? <div className={b('no-contracts')}>{NO_CONTRACTS}</div>
          : <div />}
      </div>
    );
  }

  const [clickedCardId, setClickedCardId] = useState<number | null>(null);

  useEffect(() => {
    setClickedCardId(nsz[0].contract.id);
  }, []);

  const handleCardClick = (id: number) => setClickedCardId(id);

  const checkIsCardClicked = (id: number) => clickedCardId === id;

  const currentContractById = nsz.filter(
    ({ contract }) => contract.id === clickedCardId,
  );

  return (
    <>
      <div className={b()}>
        <div className={b('title')}>{MY_NSZ_CONTRACTS}</div>
        <div className={b('row')}>
          {nsz.map(({ contract, contract: { id } }) => (
            <ContractCard
              printFormsOptions={printFormsOptions}
              key={id}
              contract={contract}
              onCardClick={() => handleCardClick(id)}
              isCardClicked={checkIsCardClicked(id)}
            />
          ))}
        </div>
      </div>
      {currentContractById.length
      && (
      <IszData
        isIsz={isIsz}
        data={currentContractById[0]}
      />
      )}
    </>
  );
};

export default MyNszDataBlock;
