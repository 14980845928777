import { block } from 'bem-cn';
import {
  FormInput,
  GreenButton,
  InfoBlock,
} from 'components';
import { LANG_DICTIONARY, MASKS } from 'consts';
import React from 'react';
import './styles.scss';

const {
  REGISTRATION_WORD_FORM,
  GET_PASSPORT_DATA,
  FIRSTNAME,
  LASTNAME,
  MIDDLENAME,
  SEND_DATA_WORD,
  SERIAL_PASSPORT,
  NUMBER_PASSPORT,
} = LANG_DICTIONARY;

const {
  serialPassportMask,
  numberPassportMask,
} = MASKS;

const b = block('stage-second-form');

interface IProps {
  firstName: string;
  lastName: string;
  middleName: string;
  passportSerial: string;
  passportNumber: string;
  sendDataSecond: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  info: string;
  errorPassportData: string;
}

const StageSecond: React.FC<IProps> = ({
  firstName,
  lastName,
  middleName,
  passportNumber,
  passportSerial,
  sendDataSecond,
  handleChange,
  info,
  errorPassportData,
}) => {
  const firstRowInputs = [
    {
      value: lastName,
      mask: '',
      placeholder: LASTNAME,
      name: 'lastName',
    },
    {
      value: firstName,
      mask: '',
      placeholder: FIRSTNAME,
      name: 'firstName',
    },
    {
      value: middleName,
      mask: '',
      placeholder: MIDDLENAME,
      name: 'middleName',
    },
  ];

  const getFirstRow = () => firstRowInputs.map(({
    value,
    mask,
    placeholder,
    name,
  }) => (
    <FormInput
      value={value}
      mask={mask}
      placeholder={placeholder}
      name={name}
      onChange={handleChange}
      className={b('first-row-input')}
    />
  ));

  return (
    <div className={b()}>
      <div className={b('header')}>
        {REGISTRATION_WORD_FORM}
      </div>
      <div className={b('description')}>{GET_PASSPORT_DATA}</div>
      <div className={b('first-row')}>
        {getFirstRow()}
      </div>
      <div className={b('second-row')}>
        <FormInput
          value={passportSerial}
          mask={serialPassportMask}
          placeholder={SERIAL_PASSPORT}
          name="passportSerial"
          onChange={handleChange}
          className={b('second-row-input')}
        />
        <FormInput
          value={passportNumber}
          mask={numberPassportMask}
          placeholder={NUMBER_PASSPORT}
          name="passportNumber"
          onChange={handleChange}
          className={b('second-row-input')}
        />
      </div>
      <div className={b('control-btn')}>
        <GreenButton
          text={SEND_DATA_WORD}
          onClick={sendDataSecond}
        />
      </div>
      {errorPassportData && <InfoBlock message={errorPassportData} error />}
      <InfoBlock message={info} />
    </div>
  );
};

export default StageSecond;
