import './styles.scss';

import { block } from 'bem-cn';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import {
  AdminSectionTitle,
  AdminMainButton,
} from 'components';
import React from 'react';
import {
  IContactsUserCard,
  ISelectItem,
  IContrAgents,
  IShowTables,
  IPersonalCard,
  IContractUserCard,
  ICommonCard,
  IContactSelectvalue,
} from 'redux/adminUserCard/reducers/interface';
import { RouteComponentProps } from 'react-router-dom';
import ContactsForm from './Contacts';
import ContrAgentsForm from './ContrAgents';
import UserInformation from './UserInformation';
import UserContractsForm from './Contracts';

const b = block('user-card-form');

const {
  USER_CARD,
  SAVE_WORD,
  REVOKE_WORD,
  BACK,
} = LANG_DICTIONARY;

type TAction = 'contacts' | 'contragents' | 'contracts'

interface IProps {
  userId: number;
  regDateCreate: string;
  registrationFinishDate: string;
  contacts: IContactsUserCard[];
  addAction: (type: TAction) => void;
  deleteAction: (type: TAction, id: number | string) => void;
  onChangeCheckbox: (id: number | string) => void;
  selectData: ISelectItem[];
  listOfCtgts: any[];
  contragents: IContrAgents[];
  currentContrAgent: ICommonCard[];
  getCurrentCtgtCard: (id: number | string) => void;
  updateListOfCtgts: (filter: string) => void;
  showTablesControl: (type: string) => void;
  showTables: IShowTables;
  userPersonalCard: IPersonalCard;
  handleChangeInfo: (name: string, value: string | boolean) => void;
  currentContractCard: ICommonCard[],
  listOfContracts: any[],
  updateContractList: (filter: string) => void;
  getContractCard: (id: number | string) => void;
  choosedCtgt: number | string | null;
  chooseCtgt: (id: number | string) => void;
  saveChanges: () => void;
  resetChanges: () => void;
  clearCtsList: () => void;
  handleChangeContact: (id: number | string, value: IContactSelectvalue) => void;
  handleChangeContactName: (id: number | string, value: string) => void;
  handleChangeValueCtgts: (id: number | string, name: string, value: string) => void;
  hadleSetContrAgent: (id: number | string, value: IContrAgents) => void;
  handleChangeValueContract: (id: number | string, name: string, value: string) => void;
  handleSetContract: (id: number | string, value: IContractUserCard) => void;
  handleChangeCanUpdatePassword: () => void;
  validatePassword: (value: string) => void;
  history: RouteComponentProps['history'],
  adminUrl: string | null;
}

const UserCardForm: React.FC<IProps> = ({
  hadleSetContrAgent,
  clearCtsList,
  choosedCtgt,
  chooseCtgt,
  getContractCard,
  currentContractCard,
  listOfContracts,
  updateContractList,
  userId,
  regDateCreate,
  registrationFinishDate,
  contacts,
  addAction,
  deleteAction,
  onChangeCheckbox,
  selectData,
  contragents,
  currentContrAgent,
  getCurrentCtgtCard,
  listOfCtgts,
  updateListOfCtgts,
  showTablesControl,
  showTables,
  userPersonalCard,
  handleChangeInfo,
  saveChanges,
  resetChanges,
  handleChangeContact,
  handleChangeContactName,
  handleChangeValueCtgts,
  handleChangeValueContract,
  handleSetContract,
  handleChangeCanUpdatePassword,
  validatePassword,
  history,
  adminUrl,
}) => (
  <div
    className={b()}
  >
    <AdminSectionTitle title={USER_CARD} />
    <div className={b('content')}>
      <div className={b('left-side')}>
        <ContactsForm
          userId={userId}
          regDateCreate={regDateCreate}
          registrationFinishDate={registrationFinishDate}
          addAction={addAction}
          deleteAction={deleteAction}
          onChangeCheckbox={onChangeCheckbox}
          selectData={selectData}
          contacts={contacts}
          handleChangeContact={handleChangeContact}
          handleChangeContactName={handleChangeContactName}
        />
        <ContrAgentsForm
          addAction={addAction}
          deleteAction={deleteAction}
          contragents={contragents}
          getCurrentCtgtCard={getCurrentCtgtCard}
          listOfCtgts={listOfCtgts}
          updateListOfCtgts={updateListOfCtgts}
          currentContrAgent={currentContrAgent}
          showTablesControl={showTablesControl}
          showTable={showTables.showContragents}
          choosedCtgt={choosedCtgt}
          chooseCtgt={chooseCtgt}
          handleChangeValueCtgts={handleChangeValueCtgts}
          hadleSetContrAgent={hadleSetContrAgent}
        />
      </div>
      <div className={b('right-side')}>
        <UserInformation
          userPersonalCard={userPersonalCard}
          handleChangeValue={handleChangeInfo}
          handleChangeCanUpdatePassword={handleChangeCanUpdatePassword}
          validatePassword={validatePassword}
        />
        <UserContractsForm
          addAction={addAction}
          deleteAction={deleteAction}
          contracts={userPersonalCard.contracts}
          showTablesControl={showTablesControl}
          showTable={showTables.showContracts}
          listOfContracts={listOfContracts}
          updateContractList={updateContractList}
          currentContractCard={currentContractCard}
          getContractCard={getContractCard}
          clearCtsList={clearCtsList}
          handleChangeValueContract={handleChangeValueContract}
          handleSetContract={handleSetContract}
        />
      </div>
    </div>
    <div className={b('footer')}>
      <AdminMainButton
        onClick={() => history.push(`${adminUrl}${ROUTES.adminUsers}`)}
        text={BACK}
        className={b('footer-btn')}
      />
      <div className={b('operation-btn')}>
        <AdminMainButton
          onClick={resetChanges}
          text={REVOKE_WORD}
          className={b('footer-btn', { margin: true })}
        />
        <AdminMainButton
          onClick={saveChanges}
          text={SAVE_WORD}
          className={b('footer-btn')}
          disabled={Boolean(userPersonalCard.errorPassword)}
        />
      </div>
    </div>
  </div>
);

export default UserCardForm;
