import { IProfitabilityReducer } from './interfaces';
import {
  GET_PROFITABILITY_FAIL,
  GET_PROFITABILITY_SUCCESS,
  GET_PROFITABILITY_START,
  CLEAR_PROFITABILITY_PAGE,
  GET_PROFITABILITY_REPORT_START,
  GET_PROFITABILITY_REPORT_SUCCESS,
  GET_PROFITABILITY_REPORT_FAIL,
  GET_PROFITABILITY_CONTRACT_INFO_SUCCESS,
  GET_PROFITABILITY_STRATEGIES_SUCCESS,
  GET_PROFITABILITY_STRATEGY_SUCCESS,
  CHANGE_PROFITABILITY_SELECTED_PERIOD,
  GET_PROFITABILITY_PAYMENTS_SUCCESS,
  GET_PROFITABILITY_PAYMENT_INFO_SUCCESS,
  GET_PROFITABILITY_CONTRACT_INFO_START,
  GET_PROFITABILITY_STRATEGY_START,
  GET_PROFITABILITY_PAYMENTS_START,
  GET_PROFITABILITY_PAYMENT_INFO_START,
} from '../types';

const INITIAL_STATE: IProfitabilityReducer = {
  profitabilityData: {
    contract: {
      totalIncome: '',
      actualDate: '',
      ctsNumber: '',
      startDate: '',
      endDate: '',
      productName: '',
      insuranceSum: '',
      watchDate: '',
      expectedProfitability: '',
      allYears: 0,
      premiumCur: '',
      garanteeLevel: '',
      regularCoupon: '',
      dateOfPrice: '',
      partCoef: '',
      refOptionType: 3,
      dateInvest: '',
      isAutocalled: false,
      nameShort: '',
      refPaymentTerm: 0,
    },
    strategies: [],
    selectedPeriod: 1,
    selectedPayment: {
      date: '',
      assets: [],
      earnedByMoney: 0,
      expectedStrategyYield: 0,
      earnedByPiecesBarrierOption: 0,
      memoryEffectEarnedByMoney: 0,
      memoryEffectEarnedByPieces: 0,
    },
    selectedPeriodPayments: [],
    selectedStrategy: {
      id: 0,
      isAutocalled: false,
      isAllPaymentsExists: false,
      name: '',
      number: 0,
      term: {
        years: 0,
        months: 0,
      },
      description: '',
      periods: [],
      defaultPeriod: 0,
      refPaymentTerm: 0,
      refOptionType: 0,
      dateOfPrice: '',
    },
  },
  isLoading: false,
  isStrategyLoading: false,
  isPaymentsLoading: false,
  isPaymentInfoLoading: false,
  isLoadingReport: false,
  errorMessage: '',
};

export default (state = INITIAL_STATE, { type, payload }: {type: string, payload: any}) => {
  switch (type) {
    case CLEAR_PROFITABILITY_PAGE: {
      return {
        ...INITIAL_STATE,
      };
    }

    case GET_PROFITABILITY_START: {
      return {
        ...INITIAL_STATE,
        isLoading: true,
      };
    }

    case GET_PROFITABILITY_SUCCESS:
      if (payload.isBadDate) {
        return {
          ...INITIAL_STATE,
          errorMessage: payload.errorMessage,
        };
      }

      return {
        ...state,
        profitabilityData: {
          ...payload,
          contract: {
            ...payload.contractInfo,
            insuranceSum: String(payload.contractInfo.insuranceSum),
            productName: payload.contractInfo.productName,
          },
          refOptionType: payload.contractInfo.refOptionType,
          refPaymentTerm: payload.contractInfo.refPaymentTerm,
          earnedByMoney: String(payload.earnedByMoney || 0),
          memoryEffectByMoneyBarrierOption: String(payload.memoryEffectByMoneyBarrierOption || 0),
          earnedByPiecesBarrierOption: String(payload.earnedByPiecesBarrierOption || 0),
          memoryEffectByPiecesBarrierOption: String(payload.memoryEffectByPiecesBarrierOption || 0),
        },
        isLoading: false,
      };

    case GET_PROFITABILITY_CONTRACT_INFO_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_PROFITABILITY_CONTRACT_INFO_SUCCESS:
      if (payload.isBadDate) {
        return {
          ...INITIAL_STATE,
          errorMessage: payload.errorMessage,
        };
      }

      return {
        ...state,
        profitabilityData: {
          ...state.profitabilityData,
          contract: {
            ...payload,
          },
        },
        isLoading: false,
      };

    case GET_PROFITABILITY_STRATEGIES_SUCCESS:
      if (payload.isBadDate) {
        return {
          ...INITIAL_STATE,
          errorMessage: payload.errorMessage,
        };
      }

      return {
        ...state,
        profitabilityData: {
          ...state.profitabilityData,
          strategies: [
            ...payload,
          ],
        },
        isLoading: false,
      };

    case GET_PROFITABILITY_STRATEGY_START: {
      return {
        ...state,
        isStrategyLoading: true,
      };
    }

    case GET_PROFITABILITY_STRATEGY_SUCCESS:
      if (payload.isBadDate) {
        return {
          ...INITIAL_STATE,
          errorMessage: payload.errorMessage,
        };
      }

      return {
        ...state,
        profitabilityData: {
          ...state.profitabilityData,
          selectedStrategy: payload,
        },
        isLoading: false,
        isStrategyLoading: false,
      };

    case GET_PROFITABILITY_PAYMENTS_START: {
      return {
        ...state,
        isPaymentsLoading: true,
      };
    }

    case GET_PROFITABILITY_PAYMENTS_SUCCESS:
      if (payload.isBadDate) {
        return {
          ...INITIAL_STATE,
          errorMessage: payload.errorMessage,
        };
      }

      return {
        ...state,
        profitabilityData: {
          ...state.profitabilityData,
          selectedPeriodPayments: payload,
        },
        isLoading: false,
        isPaymentsLoading: false,
      };

    case GET_PROFITABILITY_PAYMENT_INFO_START: {
      return {
        ...state,
        isPaymentInfoLoading: true,
      };
    }

    case GET_PROFITABILITY_PAYMENT_INFO_SUCCESS:
      if (payload.isBadDate) {
        return {
          ...INITIAL_STATE,
          errorMessage: payload.errorMessage,
        };
      }

      return {
        ...state,
        profitabilityData: {
          ...state.profitabilityData,
          selectedPayment: payload,
        },
        isLoading: false,
        isPaymentInfoLoading: false,
      };

    case GET_PROFITABILITY_FAIL:
      return {
        ...state,
        profitabilityData: INITIAL_STATE.profitabilityData,
        isLoading: false,
      };

    case GET_PROFITABILITY_REPORT_START:
      return {
        ...state,
        isLoadingReport: true,
      };

    case CHANGE_PROFITABILITY_SELECTED_PERIOD:
      return {
        ...state,
        profitabilityData: {
          ...state.profitabilityData,
          selectedPeriod: payload,
        },
      };

    case GET_PROFITABILITY_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingReport: false,
      };

    case GET_PROFITABILITY_REPORT_FAIL:
      return {
        ...state,
        isLoadingReport: false,
      };

    default:
      return state;
  }
};
