import { LANG_DICTIONARY, DATE_FORMAT } from 'consts';
import React from 'react';
import { simpleDate } from 'helpers';
import { FlexTable, CustomSelect } from 'components';
import { block } from 'bem-cn';
import addButton from 'assets/images/addButton.png';
import findIcong from 'assets/images/findIcong.png';
import trashButton from 'assets/images/trashButton.png';
import { IContrAgents } from 'redux/adminUserCard/reducers/interface';
import { ENUMS_SEND_STATUSES } from 'redux/adminUserCard/reducers';
import template from './template';

import './styles.scss';

const b = block('flex-table-contr-agents');

const {
  WORD_1C,
  INSURED,
  BIRTH_DATE,
} = LANG_DICTIONARY;

type TAction = 'contacts' | 'contragents' | 'contracts';

interface IProps {
  contragents: IContrAgents[];
  addAction: (type: TAction) => void;
  deleteAction: (type: TAction, id: number | string) => void;
  getCurrentCtgtCard: (id: number | string) => void;
  listOfCtgts: any[];
  updateListOfCtgts: (filter: string) => void;
  chooseCtgt: (id: number | string) => void;
  choosedCtgt: number | string | null;
  handleChangeValueCtgts: (id: number | string, name: string, value: string) => void;
  hadleSetContrAgent: (id: number | string, value: IContrAgents) => void;
}

const FlexTableContrAgents: React.FC<IProps> = ({
  contragents,
  addAction,
  deleteAction,
  getCurrentCtgtCard,
  listOfCtgts,
  updateListOfCtgts,
  choosedCtgt,
  chooseCtgt,
  handleChangeValueCtgts,
  hadleSetContrAgent,
}) => {
  const headers = [
    '',
    WORD_1C,
    INSURED,
    BIRTH_DATE,
    <img
      src={addButton}
      alt="add"
      className={b('control-btn')}
    />,
  ];

  const cantDeleteLastCtgs = contragents.length === 1;
  const controlButtons = (id: number | string, disabled: boolean) => (
    <div className={b('control-btns')}>
      <div
        onClick={() => (disabled ? null : deleteAction('contragents', id))}
        role="button"
        tabIndex={0}
      >
        <img
          src={trashButton}
          alt="delete"
          className={b('control-btn')}
        />
      </div>
      <div
        onClick={() => addAction('contragents')}
        role="button"
        tabIndex={0}
      >
        <img
          src={addButton}
          alt="add"
          className={b('control-btn')}
        />
      </div>
    </div>
  );

  const rebuildData = contragents.map(({
    ctgtsId,
    guid1c,
    birthDate,
    fio,
    sendStatus,
  }) => template(headers, [
    ctgtsId,
    <div
      className={b('find-button', {choosed: ctgtsId === choosedCtgt })}
      onClick={() => { getCurrentCtgtCard(ctgtsId); }}
      role="button"
      tabIndex={0}
    >
      <img src={findIcong} alt="find" />
    </div>,
    <div
      className={b('wrapper', {choosed: ctgtsId === choosedCtgt })}
      onClick={() => chooseCtgt(ctgtsId)}
      role="button"
      tabIndex={0}
    >
      {guid1c}
    </div>,
    <div
      className={b('wrapper', {choosed: ctgtsId === choosedCtgt })}
      onClick={() => chooseCtgt(ctgtsId)}
      role="button"
      tabIndex={0}
    >
      {sendStatus !== ENUMS_SEND_STATUSES.toCreate ? fio
        : (
          <CustomSelect
            options={listOfCtgts}
            name="fio"
            onChange={(value, name) => handleChangeValueCtgts(ctgtsId, name, value)}
            value={fio}
            onScroll={() => updateListOfCtgts(fio)}
            onSelect={(value) => hadleSetContrAgent(ctgtsId, value)}
          />
        )}
    </div>,
    <div
      className={b('wrapper', {choosed: ctgtsId === choosedCtgt })}
      onClick={() => chooseCtgt(ctgtsId)}
      role="button"
      tabIndex={0}
    >
      {simpleDate(birthDate, DATE_FORMAT.DATE)}
    </div>,
    <div
      className={b('wrapper', {choosed: ctgtsId === choosedCtgt })}
      onClick={() => chooseCtgt(ctgtsId)}
      role="button"
      tabIndex={0}
    >
      {controlButtons(ctgtsId, cantDeleteLastCtgs)}
    </div>,
  ]));
  return (<FlexTable data={rebuildData} minHeight={45} />);
};

export default FlexTableContrAgents;
