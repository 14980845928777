import { API } from 'consts';
import { apiCall, getError } from 'helpers';
import { put, takeLatest} from 'redux-saga/effects';
import {
  GET_ADDTIONAL_INFO_ACCOUNT_FAIL,
  GET_ADDTIONAL_INFO_ACCOUNT_REQUEST,
  GET_ADDTIONAL_INFO_ACCOUNT_START,
  GET_ADDTIONAL_INFO_ACCOUNT_SUCCESS,
  GET_ADDTIONAL_INFO_CONTRACT_FAIL,
  GET_ADDTIONAL_INFO_CONTRACT_REQUEST,
  GET_ADDTIONAL_INFO_CONTRACT_START,
  GET_ADDTIONAL_INFO_CONTRACT_SUCCESS,
  GET_ADDTIONAL_INFO_PERSONAL_FAIL,
  GET_ADDTIONAL_INFO_PERSONAL_REQUEST,
  GET_ADDTIONAL_INFO_PERSONAL_START,
  GET_ADDTIONAL_INFO_PERSONAL_SUCCESS,
  SET_DATA_FIRST_FORM_FAIL,
  SET_DATA_FIRST_FORM_REQUEST,
  SET_DATA_FIRST_FORM_START,
  SET_DATA_FIRST_FORM_SUCCESS,
  SET_DATA_SECOND_FORM_FAIL,
  SET_DATA_SECOND_FORM_REQUEST,
  SET_DATA_SECOND_FORM_START,
  SET_DATA_SECOND_FORM_SUCCESS,
  SET_DATA_THIRD_FORM_FAIL,
  SET_DATA_THIRD_FORM_REQUEST,
  SET_DATA_THIRD_FORM_START,
  SET_DATA_THIRD_FORM_SUCCESS,
} from '../types';

function* getInfoContract() {
  try {
    yield put({ type: GET_ADDTIONAL_INFO_CONTRACT_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.SIGN_UP_CONTRACT,
    });
    yield put({ type: GET_ADDTIONAL_INFO_CONTRACT_SUCCESS, payload: data.message });
  } catch (e) {
    yield put({ type: GET_ADDTIONAL_INFO_CONTRACT_FAIL });
  }
}

function* getInfoPersonal() {
  try {
    yield put({ type: GET_ADDTIONAL_INFO_PERSONAL_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.SIGN_UP_PERSONAL,
    });
    yield put({ type: GET_ADDTIONAL_INFO_PERSONAL_SUCCESS, payload: data.message });
  } catch (e) {
    yield put({ type: GET_ADDTIONAL_INFO_PERSONAL_FAIL });
  }
}

function* getInfoAccount() {
  try {
    yield put({ type: GET_ADDTIONAL_INFO_ACCOUNT_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.SIGN_UP_ACCOUNT,
    });
    yield put({ type: GET_ADDTIONAL_INFO_ACCOUNT_SUCCESS, payload: data.message });
  } catch (e) {
    yield put({ type: GET_ADDTIONAL_INFO_ACCOUNT_FAIL });
  }
}

function* setDataFirstForm({payload}: {payload: {
    login: string,
    dateSign: string,
    ctsNumber: string,
}}) {
  try {
    const { login, dateSign, ctsNumber } = payload;
    yield put({ type: SET_DATA_FIRST_FORM_START });
    const { data } = yield apiCall({
      body: {
        ctsNumber,
        dateSign,
        login,
      },
      type: 'POST',
      url: API.SIGN_UP_CONTRACT,
    });
    yield put({ type: SET_DATA_FIRST_FORM_SUCCESS, payload: data});
  } catch (e) {
    yield put({ type: SET_DATA_FIRST_FORM_FAIL, payload: getError(e) });
  }
}

function* setDataSecondForm({payload}: {payload: {
    usersRegsId: number,
    firstName: string,
    lastName: string,
    middleName: string,
    passportSerial: string,
    passportNumber: string,
}}) {
  try {
    const {
      usersRegsId,
      firstName,
      lastName,
      middleName,
      passportNumber,
      passportSerial,
    } = payload;
    yield put({ type: SET_DATA_SECOND_FORM_START });
    const { data } = yield apiCall({
      body: {
        firstName,
        lastName,
        middleName: middleName || undefined,
        passportNumber,
        passportSerial,
        usersRegsId,
      },
      type: 'POST',
      url: API.SIGN_UP_PERSONAL,
    });
    yield put({ type: SET_DATA_SECOND_FORM_SUCCESS, payload: data.ctgtsFlId });
  } catch (e) {
    yield put({ type: SET_DATA_SECOND_FORM_FAIL, payload: getError(e) });
  }
}

function* setDataThirdForm({payload}: {payload: {
    usersRegsId: number,
    ctgtsFlId: number,
    password: string,
    passwordConfirmation: string,
    dateSign: string,
    ctsNumber: string,
}}) {
  try {
    const {
      usersRegsId,
      ctgtsFlId,
      password,
      passwordConfirmation,
      dateSign,
      ctsNumber,
    } = payload;
    yield put({ type: SET_DATA_THIRD_FORM_START });
    const { data } = yield apiCall({
      body: {
        ctgtsFlId,
        ctsNumber,
        dateSign,
        password,
        passwordConfirmation,
        usersRegsId,
      },
      type: 'POST',
      url: API.SIGN_UP_ACCOUNT,
    });
    yield put({ type: SET_DATA_THIRD_FORM_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: SET_DATA_THIRD_FORM_FAIL, payload: getError(e) });
  }
}

export default function* authorizationSaga() {
  yield takeLatest(GET_ADDTIONAL_INFO_ACCOUNT_REQUEST, getInfoAccount);
  yield takeLatest(GET_ADDTIONAL_INFO_PERSONAL_REQUEST, getInfoPersonal);
  yield takeLatest(GET_ADDTIONAL_INFO_CONTRACT_REQUEST, getInfoContract);
  yield takeLatest(SET_DATA_FIRST_FORM_REQUEST, setDataFirstForm);
  yield takeLatest(SET_DATA_SECOND_FORM_REQUEST, setDataSecondForm);
  yield takeLatest(SET_DATA_THIRD_FORM_REQUEST, setDataThirdForm);
}
