import { LANG_DICTIONARY, ROUTES } from 'consts';
import { RegistrationContainer } from 'containers';
import { IRoute } from 'router/interfaces/route';

const { registration } = ROUTES;
const { REGISTRATION } = LANG_DICTIONARY;

const pathes: IRoute[] = [
  {
    component: RegistrationContainer,
    path: registration,
    title: REGISTRATION,
    type: 'public',
  },
];

export default pathes;
