import React from 'react';
import { block } from 'bem-cn';
import { useHistory, withRouter } from 'react-router-dom';
import './styles.scss';
import { useDispatch } from 'react-redux';
import exit from 'assets/svg/exit.svg';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import phoneColored from 'assets/svg/phoneColored.svg';
import emailColored from 'assets/svg/emailColored.svg';
import { clearState } from 'redux/applications/actions';
import { SUPPORT_NUMBER, SUPPORT_MAIL } from 'config';
import MainPageContainer from '../MainPage';

const b = block('applicationFormed');

const {
  APPLICATION_FORMED,
  QUESTIONS_CONTACTS,
  BY_PHONE,
  BY_MAIL,
} = LANG_DICTIONARY;

const ApplicationFormed = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(clearState());
    history.push(ROUTES.home);
  };

  return (
    <MainPageContainer>
      <div className={b()}>
        <div className={b('container')}>
          <button className={b('back-button')} onClick={goBack}><img src={exit} alt="exit" /></button>
          <div className={b('inner-container')}>
            <p className={b('formed-text')}>
              {APPLICATION_FORMED}
            </p>
            <div className={b('divider')} />
            <div className={b('questions-block')}>
              <p>{QUESTIONS_CONTACTS}</p>
              <div className={b('phone-block')}>
                <img alt="phone" src={phoneColored} />
                <p className={b('block-text')}>{`${BY_PHONE} ${SUPPORT_NUMBER}`}</p>
              </div>
              <div>
                <img alt="email" src={emailColored} />
                <p className={b('block-text')}>{`${BY_MAIL} ${SUPPORT_MAIL}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainPageContainer>
  );
};

export default withRouter(ApplicationFormed);
