import compose from '@shopify/react-compose';
import { AdminManagmentForm } from 'components';
import { withAdminFormInterface, withAdministrationInterface } from 'HOC';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IAdminManagmetReducer } from 'redux/adminManagment/reducers/interface';
import { IRedux } from 'redux/interface';
import {
  createConnectionUsersToCtgts,
  import1c,
  changeGlobalSettings,
  clearConnectionUsersToCtgts,
  getGlobalSettings,
  setInitStoreForImport,
  getLastImportStatus,
  getPrintedFormsTypes,
  sendPrintForm,
} from 'redux/rootActions';

interface IStoreProps {
  adminUrl: string,
  importFrom1c: typeof import1c,
  createConnection: typeof createConnectionUsersToCtgts,
  clearStoreForImport: typeof setInitStoreForImport,
  changeSettings: typeof changeGlobalSettings,
  getSettings: typeof getGlobalSettings,
  clearConnection: typeof clearConnectionUsersToCtgts,
  getLastImport: typeof getLastImportStatus,
  getPrintedFormsOptions: typeof getPrintedFormsTypes,
  sendChosePrintForm: typeof sendPrintForm,
  adminId: number,
}

type TProps = IStoreProps & RouteComponentProps & IAdminManagmetReducer;

const AdminManagment: React.FC<TProps> = ({
  history,
  location,
  importFrom1c,
  isProcessImport,
  isProcessCreatingConnection,
  isProcessCleaningConnection,
  createConnection,
  fileName,
  statusImport,
  clearStoreForImport,
  isFinishImport,
  errors,
  getSettings,
  changeSettings,
  isService,
  isRegistration,
  isAuthorization,
  clearConnection,
  getLastImport,
  getPrintedFormsOptions,
  printFormsOptions,
  sendChosePrintForm,
  adminId,
}) => {
  useEffect(() => {
    getSettings();
  }, [getSettings]);

  useEffect(() => {
    getPrintedFormsOptions();
  }, []);

  return (
    <AdminManagmentForm
      errors={errors}
      history={history}
      location={location}
      importFrom1c={importFrom1c}
      isProcessImport={isProcessImport}
      createConnection={createConnection}
      isProcessCreatingConnection={isProcessCreatingConnection}
      fileName={fileName}
      statusImport={statusImport}
      clearStoreForImport={clearStoreForImport}
      isFinishImport={isFinishImport}
      isService={isService}
      isRegistration={isRegistration}
      isAuthorization={isAuthorization}
      changeSettings={changeSettings}
      isProcessCleaningConnection={isProcessCleaningConnection}
      clearConnection={clearConnection}
      getLastImport={getLastImport}
      printFormsOptions={printFormsOptions}
      sendChosePrintForm={sendChosePrintForm}
      adminId={adminId}
    />
  );
};

const mapStateToProps = (
  {adminManagment, adminAuthorization}: IRedux,
) => (
  {...adminManagment, adminId: adminAuthorization.id}
);

const mapDispatchToProps = {
  importFrom1c: import1c,
  createConnection: createConnectionUsersToCtgts,
  clearStoreForImport: setInitStoreForImport,
  changeSettings: changeGlobalSettings,
  getSettings: getGlobalSettings,
  clearConnection: clearConnectionUsersToCtgts,
  getLastImport: getLastImportStatus,
  getPrintedFormsOptions: getPrintedFormsTypes,
  sendChosePrintForm: sendPrintForm,
};

export default compose(
  withAdministrationInterface,
  withAdminFormInterface({maxWidth: false}),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AdminManagment);
