export const ADMIN_ROLE_TYPES = {
  LK_ADM_1: 'lk-adm-1',
  LK_ADM_2: 'lk-adm-2',
  LK_ADM_3: 'lk-adm-3',
  LK_ADM_4: 'lk-adm-4',
};

export interface IAdminSection {
  name: string,
  role: string,
  path: string,
}

export const ADMIN_SECTIONS: IAdminSection[] = [
  {
    name: 'Управление ЛК',
    role: ADMIN_ROLE_TYPES.LK_ADM_1,
    path: '/managment',
  },
  {
    name: 'Пользователи',
    role: ADMIN_ROLE_TYPES.LK_ADM_2,
    path: '/users',
  },
  {
    name: 'Администраторы',
    role: ADMIN_ROLE_TYPES.LK_ADM_3,
    path: '/administrators',
  },
];
