import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import block from 'bem-cn';
import { dateFormatter, monthFormatter } from 'helpers';
import { LANG_DICTIONARY } from 'consts';
import AttentionPopUp from '../../../_popups/AttentionPopUp';

const b = block('profitability');

interface ITitleProps {
  index: number;
}

interface IProps {
  activeIndex: number,
  index: number,
  number: number,
  isAutocalled: boolean,
  name: string,
  years: number,
  months: number,
  isDisabledStrategy: boolean,
  handleClick: (e : React.MouseEvent, titleProps: ITitleProps) => void,
}

const AccordionTitleClosed: React.FC<IProps> = (props) => {
  const {
    activeIndex,
    index,
    number,
    isAutocalled,
    name,
    years,
    months,
    handleClick,
    isDisabledStrategy,
  } = props;

  return (
    <Accordion.Title
      active={activeIndex === index}
    >
      <div className={b('accordion-closed', {disabled: isDisabledStrategy})}>
        <div className={b('accordion-strategy')}>{`${LANG_DICTIONARY.STRATEGY_NUMBER}${number}`}</div>
        {isAutocalled && <AttentionPopUp />}
        {isDisabledStrategy && <h5>{LANG_DICTIONARY.NO_STRATEGIES_DATA}</h5>}
        <div className={b('accordion-strategy-info')}>
          <div className={b('accordion-strategy-info-content')}>
            <div>
              <span className={b('info-content-head')}>{`${LANG_DICTIONARY.STRATEGY_TYPE}: `}</span>
              <span>{name}</span>
            </div>
            <div>
              <span className={b('info-content-head')}>{`${LANG_DICTIONARY.STRATEGY_TERM}: `}</span>
              <span>
                { years
                  ? dateFormatter(Number(years))
                  : <div />}
                { months
                  ? (
                    <div>
                      {monthFormatter(Number(months))}
                    </div>
                  )
                  : <div />}
              </span>
            </div>
          </div>
          { !isDisabledStrategy && <Icon index={index} size="large" onClick={handleClick} name="chevron down" /> }
        </div>
      </div>
    </Accordion.Title>
  );
};

export default AccordionTitleClosed;
