import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import { Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';
import { email as hapiEmail } from '@hapi/address';
import './styles.scss';

const { EMAIL_VALIDATION } = LANG_DICTIONARY;
const b = block('email-validation-message');

const EmailValidation = ({ email }: { email: string }) => {
  const validateEmail = () => !hapiEmail.isValid(email);

  return (
    <>
      {validateEmail() && (
      <div className={b()}>
        <Icon name="warning sign" className={b('icon').toString()} />
        <p className={b('message')}>{EMAIL_VALIDATION}</p>
      </div>
      )}
    </>
  );
};

export default EmailValidation;
