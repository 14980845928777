import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';

configureStore({}).then((store) => {
  ReactDOM.render(
    (
      <Provider store={store}>
        <App />
      </Provider>
    ),
    document.getElementById('root'),
  );
});

serviceWorker.unregister();
