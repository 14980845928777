import { IRedux } from '../../interface';

const getUserId = (
  { authReducer }: IRedux,
) => (authReducer.userId);

const getIsAcceptedAgreement = (
  { authReducer }: IRedux,
) => (authReducer.isAccepted);

export {
  getUserId,
  getIsAcceptedAgreement,
};
