import './styles.scss';

import { block } from 'bem-cn';
import { AdminSwitcherForm } from 'components';
import { ADMIN_SECTIONS } from 'consts';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRedux } from 'redux/interface';

interface IProps {
  actions: string[],
  adminUrl: string,
}
type TProps = IProps & RouteComponentProps;

const b = block('admin-form-interface');

const withAdminFormInterface = (
  { maxWidth }: { maxWidth: boolean },
) => (Component: React.FC | React.ComponentClass) => {
  const AdminFormInterface: React.FC<TProps> = ({
    actions,
    location,
    history,
    adminUrl,
  }) => (
    <div className={b()}>
      <div className={b('content', { max: maxWidth })}>
        <AdminSwitcherForm
          tabs={ADMIN_SECTIONS}
          adminUrl={adminUrl}
          actions={actions}
          history={history}
          location={location}
        />
        <Component />
      </div>
    </div>
  );

  const mapStateToProps = ({
    adminAuthorization: {
      actions,
    },
    common: {
      adminUrl,
    },
  }: IRedux) => ({ actions, adminUrl });

  return withRouter(connect(mapStateToProps)(AdminFormInterface));
};

export default withAdminFormInterface;
