import './App.scss';
import 'semantic-ui-css/semantic.min.css';
import 'bootstrap/dist/css/bootstrap.css';

import { GlobalHistory } from 'helpers';
import ReduxToastr from 'react-redux-toastr';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './router';

const App: React.FC = () => (
  <Router>
    <GlobalHistory />
    <div className="App" id="app">
      <Routes />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </div>
  </Router>
);
export default App;
