import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import block from 'bem-cn';
import './styles.scss';
import { getUserId } from 'redux/authorization/selectors';
import { acceptRules } from 'redux/authorization/actions';
import { setIsAcceptedAgreement } from 'redux/mainData/actions';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import { AdminCheckbox, ArrowRecIcon, PopupContainer } from '../../../index';

const b = block('acceptedPopup');
const d = block('html');

interface IProps {
  isAcceptedAgreement: boolean;
  email: string;
}

const {
  EL_DOCUMENTS_REQUIREMENTS,
  ROLL_UP,
  UNWRAP,
  INSURANCE_CONTRACT_REQUIREMENTS,
  CONTINUE,
} = LANG_DICTIONARY;

const AcceptedRulesPopup: React.FC<IProps> = (props) => {
  const {
    isAcceptedAgreement,
    email,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const userId = useSelector(getUserId);

  const submitApplication = () => {
    dispatch(setIsAcceptedAgreement(!isAcceptedAgreement));
    dispatch(acceptRules(userId));
  };

  const handleClose = () => {
    history.push(ROUTES.authorization);
  };

  const [isAcceptedRequirements, setIsAcceptedRequirements] = useState<boolean>(false);

  const acceptedRules = () => {
    const [isActive, setActive] = useState<boolean>(false);

    const handleChangeCheckbox = () => {
      setIsAcceptedRequirements(!isAcceptedRequirements);
    };

    const handleClick = () => {
      setActive((state) => !state);
    };

    return (
      <div className={d()}>
        <div className={b()}>
          <p className={b('text-agreement')}>
            {EL_DOCUMENTS_REQUIREMENTS}
          </p>
          <div>
            <div className={b('checkbox')}>
              <AdminCheckbox
                isNotAdmin
                checked={isAcceptedRequirements}
                onChange={handleChangeCheckbox}
                name="check"
              />
            </div>
            <button
              className={b('button')}
              onClick={handleClick}
            >
              {
              isActive
                ? ROLL_UP
                : UNWRAP
            }
              <ArrowRecIcon className={b('arrow-icon', { opened: isActive })} />
            </button>
            {
            isActive
            && (
            <div className={b('accepted-rules')}>
              <p><b>Соглашение об электронном документообороте.</b></p>
              <div className={b('text-container')}>
                <p className={b('text-attention')}>
                  Внимание! Пожалуйста, ознакомьтесь в полном объеме с условиями настоящего
                  Соглашения.
                </p>
                <p className={b('head-paragraph')}>
                  1. Общие положения
                </p>
                <p className={b('paragraph')}>
                  1.1. Настоящее Соглашение является договором присоединения
                  (статья 428 Гражданского кодекса Российской Федерации)
                  и считается заключенным с момента начала использования
                  Клиентом электронного документооборота предусмотренного настоящим Соглашением.
                </p>
                <p className={b('paragraph')}>
                  1.2. Настоящее Соглашение является исключительно соглашением между Страховщиком
                  и Клиентом об использовании электронного взаимодействия в соответствии с пунктом
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  2 статьи 6 Федерального закона от 06.04.2011 N 63-ФЗ "Об электронной подписи".
                </p>
                <p style={{fontWeight: 'bold', textIndent: '20px'}}>
                  2. Понятия и определения
                </p>
                <p className={b('paragraph')}>
                  <b>Страховщик</b>
                  {' '}
                  - Общество с ограниченной ответственностью Страховая компания
                  «БКС Страхование жизни» (местонахождение: 129110, Российская Федерация,
                  г. Москва, Проспект Мира,
                  69, стр.1, этаж 9, помещ. XV, ком.1; ОГРН: 5177746400607).
                </p>
                <p className={b('paragraph')}>
                  <b>Клиент</b>
                  {' '}
                  – физическое лицо, являющееся страхователем и/или
                  выгодоприобретателем и/или застрахованным лицом в рамках заключенного
                  Страховщиком договора страхования, а равно лицо, обратившееся с намерением
                  заключить договор страхования (потенциальный получатель страховой услуги).
                </p>
                <p className={b('paragraph')}>
                  <b>Простая электронная подпись (ПЭП)</b>
                  {' '}
                  – электронная подпись, сформированная Клиентом для подписания Электронного
                  документа, соответствующая требованиям, предъявляемым к ПЭП Федеральным
                  законом от 06.04.2011 № 63-ФЗ «Об электронной подписи», которая формируется
                  посредством использования одноразовых паролей (SMS-сообщении) и номера
                  мобильного телефона, и которая содержится в самом Электронном документе.
                  Простая электронная подпись формируется при условии успешной аутентификации
                  Клиента и подтверждении правильности введенного кода, полученного в SMS-сообщении
                  и включается в электронный документ.
                </p>
                <p className={b('paragraph')}>
                  <b>Одноразовый пароль</b>
                  {' '}
                  – уникальная последовательность из цифр, направленная на номер мобильного
                  телефона, указанного Клиентом и введенная им в специальной форме для
                  подписания электронного документа.
                </p>
                <p className={b('paragraph')}>
                  <b>Электронный документ</b>
                  {' '}
                  – документ, изображение, предназначенное для использования в электронной форме
                  в целях, предусмотренных настоящим Соглашением и подписанный ПЭП Клиента.
                </p>
                <p className={b('paragraph')}>
                  <b>Электронный документооборот (ЭДО)</b>
                  {' '}
                  – процесс обмена электронными документами в рамках настоящего Соглашения,
                  следствием которого являются создание, изменение, обмен, хранения электронных
                  документов.
                </p>
                <p className={b('paragraph')}>
                  <b>Отправитель электронного документа (Отправитель)</b>
                  {' '}
                  – Участник настоящего Соглашения, который направляет электронный документ.
                </p>
                <p className={b('paragraph')}>
                  <b>Получатель электронного документа (Получатель)</b>
                  {' '}
                  – Участник настоящего Соглашения, которому электронный документ направлен
                  отправителем.
                </p>
                <p className={b('head-paragraph')}>
                  3. Условия и порядок электронного документооборота
                </p>
                <p className={b('paragraph')}>
                  3.1. Электронные документы изготавливаются, передаются и принимаются Сторонами
                  в электронной форме без их последующего обязательного представления на бумажном
                  носителе. Порядок электронного взаимодействия является дополнительной возможностью
                  (сервисом), предлагаемым Страховщиком своим Клиентам. Содержание настоящего
                  Соглашения не ограничивает права Клиента на реализацию своих прав и исполнение
                  обязанностей в порядке бумажного документооборота либо иным способом в
                  соответствии с законодательством Российской Федерации.
                </p>
                <p className={b('paragraph')}>
                  3.2. Клиент признает и соглашается с тем, что на основании электронных документов,
                  подписанных ПЭП Клиента, предусмотренной настоящим Соглашением, у Клиента
                  возникают права и обязательства, закрепленные в подписанном им электронного
                  документе.
                </p>
                <p className={b('paragraph')}>
                  3.3. Клиент признает и соглашается с тем, что фактом подписания электронных
                  документов ПЭП Клиента, предусмотренным настоящим Соглашением, Клиент
                  подтверждает, что до такого подписания он ознакомился с содержанием
                  подписанных им электронных документов, а также иных документов, ссылка на
                  которые содержится в подписанных им электронных документах.
                </p>
                <p className={b('paragraph')}>
                  3.4. В рамках настоящего Соглашения признаются равнозначными документам на
                  бумажном носителе, подписанным собственноручной подписью Клиента следующие
                  электронные документы, подписанные ПЭП Клиента:
                  <p className={b('paragraph')}>- настоящее Соглашение;</p>
                  <p className={b('paragraph')}>- заявление на заключение договора страхования (в т.ч. приложения к нему);</p>
                  <p className={b('paragraph')}>
                    - заявление на присоединение к договору страхования (заявление на
                    присоединение к программам страхования);
                  </p>
                  <p className={b('paragraph')}>-Анкета Страхователя и/или Застрахованного лица;</p>
                  <p className={b('paragraph')}>-Декларация Застрахованного лица;</p>
                  <p className={b('paragraph')}>
                    -Дополнительные сведения и согласия Застрахованного лица
                    и/или Страхователя;
                  </p>
                  <p className={b('paragraph')}>
                    -любые заявления, анкеты, уведомления, письма (информационные письма),
                    предусмотренные договором страхования, правилами страхования;
                  </p>
                  <p className={b('paragraph')}>- любые заявления, направленные на получение Клиентом страховой услуги и т.д.;</p>
                  <p className={b('paragraph')}>
                    -любые согласия Клиента, предоставляемые Клиентом Обществу, получение которых
                    от Клиентов требуется Обществу в простой письменной форме, в частности:
                    согласия на обработку персональных данных Клиента (глава 2 Федерального закона
                    от 27.07.2006 N 152-ФЗ &quot;О персональных данных&quot;);
                    согласия на предоставление
                    (получение) сведений и информации, в том числе составляющих тайну страхования
                    (ст.946 Гражданского кодекса РФ); согласия на предоставление (получение)
                    сведений и информации, составляющих врачебную тайну (ст.13 Федерального закона
                    &quot;Об основах охраны здоровья граждан в Российской Федерации&quot;
                    от 21.11.2011
                    N 323-ФЗ); согласие на получение рекламы по сетям электросвязи, в том числе
                    посредством использования телефонной, факсимильной, подвижной радиотелефонной
                    связи (ст.18 Федерального закона от 13.03.2006 N 38-ФЗ &quot;О рекламе&quot;);
                    согласие
                    на передачу функций по идентификации Клиента в соответствии с требованием
                    Федерального закона от 07.08.2001 N 115-ФЗ &quot;О противодействии легализации
                    (отмыванию) доходов, полученных преступным путем, и финансированию
                    терроризма&quot;; иные согласия, получение которых обусловлено требованиями
                    законодательства Российской Федерации (в т. ч. нормативными правовыми актами
                    в сфере противодействия легализации (отмыванию) доходов, полученных преступным
                    путем, и финансированию терроризма).
                  </p>
                  <p className={b('paragraph')}>
                    Перечень документов, указанный в настоящем пункте Соглашения, не является
                    исчерпывающим.
                  </p>
                  <p className={b('paragraph')}>
                    3.5. Клиент и Страховщик принимают на себя исполнение всех обязательств,
                    вытекающих из электронных документов, подписанных в соответствии с настоящим
                    Соглашением.
                  </p>
                  <p className={b('paragraph')}>
                    3.6. Страховщик обеспечивает Клиенту возможность обмена электронными документами
                    в порядке и способами, установленными в настоящем Соглашении.
                  </p>
                  <p className={b('paragraph')}>
                    3.7. Одной ПЭП могут быть подписаны несколько электронных документов (пакет
                    Электронных документов). При подписании ПЭП пакета Электронных документов
                    каждый из Электронных документов, входящих в этот пакет, считается подписанным
                    отдельно.
                  </p>
                  <p className={b('paragraph')}>
                    3.8. Для определения лица, подписавшего Электронный документ ПЭП, в состав
                    документа включаются сведения о фамилии, имени и отчестве (при наличии) Клиента
                    и/или номере мобильного телефона (маскированном номере мобильного телефона)
                    Клиента, на который одноразовый пароль в SMS-сообщении, также может быть указан
                    ключ проверки электронной подписи.
                  </p>
                  <p className={b('paragraph')}>
                    3.9. Копии электронного документа могут быть изготовлены (распечатаны) на
                    бумажном носителе и должны быть заверены собственноручной подписью
                    уполномоченного лица, являющегося Отправителем или Получателем. Копии
                    электронного документа на бумажном носителе должны соответствовать
                    требованиям действующего законодательства РФ, а также может содержать отметку
                    «Копия электронного документа». Информация, содержащаяся в копии на бумажном
                    носителе, должна соответствовать содержанию электронного документа.
                  </p>
                  <p className={b('paragraph')}>
                    3.10. Электронный документ может иметь неограниченное количество экземпляров,
                    в том числе выполненных на машиночитаемых носителях различного типа. Все
                    экземпляры электронного документа являются подлинниками данного электронного
                    документа. Электронный документ не может иметь копий в электронном виде.
                  </p>
                  <p className={b('head-paragraph')}>
                    4. Заключительные положения
                  </p>
                  <p className={b('paragraph')}>
                    4.1 Страховщик имеет право в одностороннем порядке вносить изменения в настоящее
                    Соглашение без предварительного уведомления Клиента. Уведомление Клиента об
                    изменении Соглашения не требуется.
                  </p>
                  <p className={b('paragraph')}>
                    4.2. Стороны признают, что электронные документы, предусмотренные настоящим
                    Соглашением, подписанные ПЭП Клиента, признаются равнозначными документам на
                    бумажном носителе, подписанным собственноручной подписью Клиента, порождают для
                    подписанта юридические последствия в виде установления, изменения и/или
                    прекращения прав и обязанностей, аналогичные документу на бумажном носителе, и
                    могут применяться в любых правоотношениях в соответствии с законодательством
                    Российской Федерации, в т.ч. служить доказательством наличия соответствующих
                    прав и обязанностей у Сторон Соглашения для любых третьих лиц (в т.ч. в качестве
                    доказательств в суде, а также при рассмотрении споров в досудебном порядке,
                    для любых государственных органов власти и пр.).
                  </p>
                  <p className={b('paragraph')}>
                    4.3. Признание какого-либо положения настоящего Соглашения недействительным или
                    не подлежащим исполнению не влечет недействительности или неисполнимости иных
                    положений Соглашения.
                  </p>
                  <p className={b('paragraph')}>
                    4.4. Прекращение действия настоящего Соглашения не влияет на юридическую силу
                    и действительность электронных документов, которыми стороны обменивались до
                    прекращения действия настоящего Соглашения.
                  </p>
                </p>
              </div>
            </div>
            )
          }
            <button
              className={b('submit-button', { disabled: !isAcceptedRequirements })}
              disabled={!isAcceptedRequirements}
              onClick={submitApplication}
            >
              {CONTINUE}
            </button>
          </div>
        </div>
      </div>
    );
  };


  return (
    <PopupContainer
      isCloseButton
      close={handleClose}
      children={acceptedRules()}
      isOpen={!isAcceptedAgreement}
    />
  );
};

export default AcceptedRulesPopup;
