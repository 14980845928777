import block from 'bem-cn';
import { CustomCheckBox, PopupContainer, InfoBlock } from 'components';
import { LANG_DICTIONARY } from 'consts';
import React from 'react';
import InputMask from 'react-input-mask';
import trashIcon from 'assets/svg/trashIcon.svg';
import './styles.scss';

const b = block('personal-info-popup');

const {
  PERSONAL_DATA_TEXT,
  SHORT_FULLNAME,
  PASSPORT_DATA,
  COUNTRY_PATRIOT,
  BIRTH_DATE,
  BIRTH_PLACE,
  LIVING_PLACE,
  CURRENT_LIVING_PLACE,
  CONTACTS_INFORMATION,
  SERIAL_PASSPORT,
  NUMBER_PASSPORT,
  GET_PASSPORT_DATE,
  GET_PASSPORT_BY,
  SUBDIVISION_CODE,
  MAIN_OR_DELETE,
} = LANG_DICTIONARY;

interface IProps {
  fullName: string;
  serial: string;
  passportNumber: string;
  passportDate: string;
  subdivisionCode: string;
  passportGetBy: string;
  country: string;
  birthDate: string;
  birthPlace: string;
  addressLiving: string;
  currentAddress: string;
  contactsEmails: IContacts[];
  contactsPhones: IContacts[];
  deleteContact: (id: number) => void;
  isOpen: boolean;
  closePopUp: () => void;
  info: string;
}

interface IContacts {
  id?: number;
  isDefault: boolean;
  type: string;
  value: string;
  isDelete: boolean;
  code: string;
}
const PersonalInfoPopUp: React.FC<IProps> = (
  {
    fullName,
    serial,
    passportNumber,
    passportDate,
    passportGetBy,
    subdivisionCode,
    country,
    birthDate,
    birthPlace,
    addressLiving,
    currentAddress,
    contactsEmails,
    contactsPhones,
    deleteContact,
    isOpen,
    closePopUp,
    info,
  },
) => {
  const headers = [
    SHORT_FULLNAME,
    PASSPORT_DATA,
    COUNTRY_PATRIOT,
    BIRTH_DATE,
    BIRTH_PLACE,
    LIVING_PLACE,
    CURRENT_LIVING_PLACE,
  ];
  const rebuildPassportData = () => (
    <div className={b('passport-data')}>
      <div className={b('first-row')}>
        <div className={b('first-passport-block')}>
          <div className={b('title')}>{SERIAL_PASSPORT}</div>
          <div className={b('passport-value')}>{serial}</div>
        </div>
        <div className={b('first-passport-block')}>
          <div className={b('title')}>{NUMBER_PASSPORT}</div>
          <div className={b('passport-value')}>{passportNumber}</div>
        </div>
      </div>
      <div className={b('passport-row')}>
        <div className={b('passport-block')}>
          <div className={b('title')}>{GET_PASSPORT_DATE}</div>
          <div className={b('passport-value')}>{passportDate}</div>
        </div>
      </div>
      <div className={b('passport-row')}>
        <div className={b('passport-block')}>
          <div className={b('title')}>{SUBDIVISION_CODE}</div>
          <div className={b('passport-value')}>{subdivisionCode}</div>
        </div>
      </div>
      <div className={b('passport-row')}>
        <div className={b('passport-block')}>
          <div className={b('title')}>{GET_PASSPORT_BY}</div>
          <div className={b('passport-value')}>{passportGetBy}</div>
        </div>
      </div>
    </div>
  );

  const rebuildPersonalData = [
    fullName,
    rebuildPassportData(),
    country,
    birthDate,
    birthPlace,
    addressLiving,
    currentAddress,
  ];

  const rebuildData = rebuildPersonalData.map((item, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <div className={b('personal-info-row')} key={i}>
      <div className={b('personal-title')}>
        {headers[i]}
      </div>
      <div className={b('personal-info-data')}>
        {item}
      </div>
    </div>
  ));

  const getMask = (codeValue: string) => {
    switch (codeValue) {
      case 'mobile': {
        return '+9 (999)-999-99-99';
      }

      case 'contact': {
        return '';
      }

      case 'work': {
        return '+9 (999)-999-99-99';
      }

      case 'home': {
        return '9 (999)-999-99-99';
      }

      default:
        return '';
    }
  };
  const rebuildContacts = (contacts: IContacts[]) => {
    if (contacts) {
      return contacts.map(({
        id,
        isDefault,
        type,
        value,
        isDelete,
        code,
      }) => (
        <div className={b('contacts-row')} key={value}>
          <div className={b('contacts-title')}>
            {type}
          </div>
          <div className={b('contacts-value')}>
            <InputMask
              className={b('contact')}
              value={value}
              mask={getMask(code)}
              disabled
            />
            <div className={b('contact-control')}>
              <CustomCheckBox active={isDefault} />
              {(id && isDelete)
                && (
                <div
                  className={b('delete-contact')}
                  onClick={() => deleteContact(id)}
                  role="button"
                  tabIndex={0}
                >
                  <img src={trashIcon} alt="trash" />
                </div>
                )}
            </div>
          </div>
        </div>
      ));
    }
    return null;
  };


  const finalForm = () => (
    <div className={b()}>
      <div className={b('header')}>
        {PERSONAL_DATA_TEXT}
      </div>
      <div className={b('personal-data')}>
        {rebuildData}
      </div>
      <div className={b('header')}>
        <div>{CONTACTS_INFORMATION}</div>
        <div className={b('delete-contact')}>{MAIN_OR_DELETE}</div>
      </div>
      <div className={b('contacts-data')}>
        {rebuildContacts(contactsPhones)}
        {rebuildContacts(contactsEmails)}
      </div>
      <InfoBlock message={info} />
    </div>
  );

  return (
    <PopupContainer
      isCloseButton
      close={closePopUp}
      children={finalForm()}
      isOpen={isOpen}
    />
  );
};

export default PersonalInfoPopUp;
