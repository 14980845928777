const SUPPORT_NUMBER = '8 (800) 500-50-21';
const SUPPORT_MAIL = 'info@bcslife.ru';
const ctsNumberFullLenght = 17;
const MAIN_BCS_LIFE = 'https://bcslife.ru/';
const MAX_LENGTH_INPUT = 255;
const MIN_LENGTH_INPUT = 5;
const TIME_TO_CHECK_IMPORT_MS = 120000;
const REGISTRATIONG_STAGES = {
  FIRST: 1,
  FOUR: 4,
  SECOND: 2,
  THIRD: 3,
  FINAL: 5,
  SIX: 6,
};
const MAX_LENGTH_SUPPORT_MESSAGE = 400;

const VK_BCS = 'http://vk.com/skbcslife';
const FB_BCS = 'http://facebook.com/skbcslife';
const INSTAGRAM_BCS = 'http://instagram.com/skbcslife';
const AGREEMENT_BCS = 'https://bcslife.ru/upload/iblock/b5b/b5b33846bcb6ebc51b4a995c743e08d4.pdf';

const ITEM_COUNT_ADMIN_TABLE_USERS = 10;
const ITEM_COUNT_ADMIN_ADMINS = 12;
const ITEM_COUNT_ADMIN_USER_CARD = 10;

const MINIMUM_PASSWORD_LENGTH = 8;

declare global {
    interface Window {
        REACT_APP_API_BASE_URL: string;
    }
}
const API_BASE_URL = window.REACT_APP_API_BASE_URL
  || process.env.REACT_APP_API_BASE_URL
  || 'http://localhost:4000';


export {
  ITEM_COUNT_ADMIN_USER_CARD,
  ITEM_COUNT_ADMIN_ADMINS,
  ITEM_COUNT_ADMIN_TABLE_USERS,
  MAX_LENGTH_SUPPORT_MESSAGE,
  MINIMUM_PASSWORD_LENGTH,
  VK_BCS,
  FB_BCS,
  INSTAGRAM_BCS,
  MAX_LENGTH_INPUT,
  MIN_LENGTH_INPUT,
  SUPPORT_NUMBER,
  SUPPORT_MAIL,
  API_BASE_URL,
  MAIN_BCS_LIFE,
  ctsNumberFullLenght,
  REGISTRATIONG_STAGES,
  TIME_TO_CHECK_IMPORT_MS,
  AGREEMENT_BCS,
};
