import {
  CLEAR_STORE_AUTH,
  LOG_OUT,
  SET_STORE_TOKEN,
  SIGN_IN,
  CHECK_USER,
  REQUEST_RESET_PASSWORD,
  VERIFY_RESET_PASSWORD_TOKEN,
  RESET_PASSWORD_CHANGE_PASSWORD,
  CLEAR_RESET_PASSWORD_STORE,
  GET_RECAPTCHA,
  CHANGE_LOGIN,
  ADD_PHONE_LOGIN_VERIFY,
  ADD_PHONE_LOGIN,
  ACCEPT_RULES,
  SET_IS_ACCEPTED_AGREEMENT,
} from '../types';
import { IAddPhoneLoginPayload, IAddPhoneLoginVerifyPayload } from '../../../interfaces/addPhoneLoginPayload';

export const changeLogin = (isPhone: boolean) => ({
  payload: isPhone,
  type: CHANGE_LOGIN,
});

export const getRecaptcha = (captchaId: string) => ({
  type: GET_RECAPTCHA.request,
  payload: { captchaId },
});

export const clearStoreAfterReset = () => ({
  type: CLEAR_RESET_PASSWORD_STORE,
});

export const changePasswordReset = (
  token: string,
  login: string,
  password: string,
  passwordConfirmation: string,
) => ({
  type: RESET_PASSWORD_CHANGE_PASSWORD.request,
  payload: {
    login,
    token,
    password,
    passwordConfirmation,
  },
});

export const verifyResetPasswordToken = (token: string) => ({
  type: VERIFY_RESET_PASSWORD_TOKEN.request,
  payload: token,
});

export const startResetPassword = (login: string) => ({
  type: REQUEST_RESET_PASSWORD.request,
  payload: login,
});

export const clearAuthStore = () => ({
  type: CLEAR_STORE_AUTH,
});

export const SignIn = (login: string, password: string, captchaId: string, captchaKey: string) => ({
  payload: {
    login,
    password,
    captchaId,
    captchaKey,
  },
  type: SIGN_IN.request,
});

export const AddPhoneLoginVerify = (payload: IAddPhoneLoginVerifyPayload) => ({
  payload,
  type: ADD_PHONE_LOGIN_VERIFY.request,
});

export const AddPhoneLogin = (payload: IAddPhoneLoginPayload) => ({
  payload,
  type: ADD_PHONE_LOGIN.request,
});

export const setToken = (token: string) => ({
  payload: token,
  type: SET_STORE_TOKEN,
});

export const logOut = () => ({
  type: LOG_OUT,
});

export const checkCurrentUser = (token: string) => ({
  type: CHECK_USER.request,
  payload: token,
});

export const acceptRules = (userId: number) => ({
  type: ACCEPT_RULES.request,
  payload: userId,
});

export const setIsAcceptedAgreement = (isAcceptedAgreement: boolean) => ({
  payload: isAcceptedAgreement,
  type: SET_IS_ACCEPTED_AGREEMENT,
});
