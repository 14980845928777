import React, { useState } from 'react';
import { block } from 'bem-cn';
import { Dropdown } from 'semantic-ui-react';
import { sendPrintForm } from 'redux/rootActions';
import { LANG_DICTIONARY } from 'consts';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { AdminMainButton, AdminSectionTitle } from '../../index';

const b = block('managment-admin-form');

interface IProps {
  printFormsOptions: [],
  sendChosePrintForm: typeof sendPrintForm,
  adminId: number,
}

const {
  UPLOAD_PRINTED_FORMS,
  SELECT_FILE_FOR_UPLOAD,
  PRINTED_FORM_TYPE,
  UPLOAD_PRINTED_FORM,
  RUN,
  CHOOSE_PRINT_FORM_TYPE,
  FILE_REQUIRES,
} = LANG_DICTIONARY;

const maxFileSize = 1000000;

const PrintedForms:React.FC<IProps> = (props) => {
  const {
    printFormsOptions,
    sendChosePrintForm,
    adminId,
  } = props;

  const [file, setFile] = useState<File | ''>('');
  const [type, setType] = useState('');
  const [isErr, setIsErr] = useState(false);

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { files } } = e;
    if (files && (files[0]?.size < maxFileSize)) {
      setFile(files[0]);
      setIsErr(false);
      return;
    }
    setIsErr(true);
  };

  const onChangeType = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const { value } = data;
    if (value) {
      setType(value as string);
    }
  };

  const sendForm = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('printFormTypeCode', type);
    formData.append('adminId', adminId.toString());
    sendChosePrintForm(formData);
  };

  const isDisabledRunButton = !file || !type || isErr;

  return (
    <div>
      <AdminSectionTitle title={UPLOAD_PRINTED_FORMS} />
      <section className={b('section')}>
        <p className={b('section-title')}>
          {SELECT_FILE_FOR_UPLOAD}
        </p>
        <input
          type="file"
          onChange={onChangeFile}
          className={b('input-file')}
          accept=".docx"
        />
      </section>
      {
        isErr && (
        <p className={b('error')}>{FILE_REQUIRES}</p>
        )
      }
      <section className={b('section')}>
        <p className={b('section-title')}>
          {PRINTED_FORM_TYPE}
        </p>
        <div className={b('select')}>
          <Dropdown
            placeholder={CHOOSE_PRINT_FORM_TYPE}
            onChange={(e, data) => onChangeType(e, data)}
            options={printFormsOptions}
          />
        </div>
      </section>
      <section className={b('section')}>
        <p className={b('section-title')}>
          {UPLOAD_PRINTED_FORM}
        </p>
        <AdminMainButton
          className={b('button')}
          disabled={isDisabledRunButton}
          text={RUN}
          onClick={sendForm}
        />
      </section>
    </div>
  );
};

export default PrintedForms;
