const generateContacts = (contacts: any) => {
  const phones = contacts.filter((item: any) => {
    if (item.code === 'mobile' || item.code === 'home' || item.code === 'work' || item.code === 'contact') {
      return ({
        ...item,
      });
    }
    return null;
  });
  const emails = contacts.filter((item: any) => {
    if (item.code === 'email') {
      return ({
        ...item,
      });
    }
    return null;
  });

  return {
    emails,
    phones,
  };
};

const generateFio = (lastName: string, firstName: string, middleName?: string) => `${lastName} ${firstName} ${middleName}`;
export { generateContacts, generateFio };
