import React, { ReactElement } from 'react';
import { block } from 'bem-cn';
import './styles.scss';

const b = block('checkbox');

const d = block('adminCheckbox');

interface IProps {
  name: string,
  checked?: boolean,
  onChange?: (...args: any[]) => any,
  text?: string | ReactElement,
  textLeft?: boolean,
  onClick?: (name: string) => void;
  disabled?: boolean;
  isNotAdmin?: boolean;
  isCursorDefault?: boolean;
}

const Checkbox: React.FC<IProps> = ({
  name,
  checked,
  onChange = () => null,
  text = '',
  textLeft = false,
  disabled = false,
  isNotAdmin,
  isCursorDefault,
}) => {
  const customCheckClass = `${b()} ${b({custom: true})} ${b({cursor: isCursorDefault})}`;

  return (
    <label htmlFor={name} className={isNotAdmin ? b('label') : d('label')}>
      {textLeft && text && (<span className={isNotAdmin ? b('text') : d('text')}>{text}</span>)}
      <input
        id={name}
        className={isNotAdmin ? b() : d()}
        name={name}
        type="checkbox"
        disabled={disabled}
        onChange={onChange}
        checked={checked}
      />
      <span className={customCheckClass} />
      {!textLeft && text && (<span className={b('text')}>{text}</span>)}
    </label>
  );
};

export default Checkbox;
