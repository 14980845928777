import { REGISTRATIONG_STAGES } from 'config';
import {
  CHANGE_FIELD,
  CHANGE_LOGIN,
  CLEAR_LOGIN,
  CLEAR_STORE,
  GET_ADDTIONAL_INFO_ACCOUNT_SUCCESS,
  GET_ADDTIONAL_INFO_CONTRACT_SUCCESS,
  GET_ADDTIONAL_INFO_PERSONAL_SUCCESS,
  SET_DATA_FIRST_FORM_FAIL,
  SET_DATA_FIRST_FORM_SUCCESS,
  SET_DATA_SECOND_FORM_FAIL,
  SET_DATA_SECOND_FORM_SUCCESS,
  SET_DATA_THIRD_FORM_SUCCESS,
} from '../types';

const INITIAL_STATE = {
  activeStage: 1,
  ctgtsFlId: 0,
  ctsNumber: '',
  dateSign: null,
  errorMessage: '',
  firstName: '',
  infoAccount: '',
  infoContract: '',
  infoMessage: '',
  infoPersonal: '',
  lastName: '',
  login: '',
  middleName: '',
  passportNumber: '',
  passportSerial: '',
  password: '',
  passwordConfirm: '',
  prevStage: 0,
  redirectLink: '',
  usersRegsId: 0,
  isPhone: true,
};

export default (state = INITIAL_STATE, { type, payload }: {type: string, payload: any}) => {
  switch (type) {
    case CLEAR_LOGIN: {
      return {
        ...state,
        login: '',
      };
    }

    case CHANGE_LOGIN: {
      return {
        ...state,
        isPhone: payload,
      };
    }

    case SET_DATA_SECOND_FORM_FAIL: {
      const { activeStage } = state;
      return {
        ...state,
        activeStage: REGISTRATIONG_STAGES.FOUR,
        errorMessage: payload,
        prevStage: activeStage,
      };
    }

    case SET_DATA_FIRST_FORM_FAIL: {
      const { activeStage } = state;
      return {
        ...state,
        activeStage: REGISTRATIONG_STAGES.FOUR,
        errorMessage: payload,
        prevStage: activeStage,
      };
    }

    case CHANGE_FIELD: {
      const { name, value } = payload;
      return {
        ...state,
        [name]: value,
      };
    }

    case CLEAR_STORE: {
      return {
        ...INITIAL_STATE,
      };
    }

    case GET_ADDTIONAL_INFO_ACCOUNT_SUCCESS: {
      return {
        ...state,
        infoAccount: payload,
      };
    }

    case GET_ADDTIONAL_INFO_CONTRACT_SUCCESS: {
      return {
        ...state,
        infoContract: payload,
      };
    }

    case GET_ADDTIONAL_INFO_PERSONAL_SUCCESS: {
      return {
        ...state,
        infoPersonal: payload,
      };
    }

    case SET_DATA_FIRST_FORM_SUCCESS: {
      const { infoMessage, usersRegsId } = payload;
      if (infoMessage) {
        return {
          ...state,
          activeStage: REGISTRATIONG_STAGES.FINAL,
          infoMessage: payload.infoMessage,
          redirectLink: payload.redirectLink,
        };
      }
      return {
        ...state,
        activeStage: REGISTRATIONG_STAGES.SECOND,
        usersRegsId,
      };
    }

    case SET_DATA_SECOND_FORM_SUCCESS: {
      return {
        ...state,
        activeStage: REGISTRATIONG_STAGES.THIRD,
        ctgtsFlId: payload,
      };
    }

    case SET_DATA_THIRD_FORM_SUCCESS: {
      return {
        ...state,
        activeStage: REGISTRATIONG_STAGES.SIX,
      };
    }

    default:
      return state;
  }
};
