import React, { useState } from 'react';
import { AdminSectionTitle, AdminMainButton } from 'components';
import { LANG_DICTIONARY } from 'consts';
import { getLastImportStatus, import1c } from 'redux/rootActions';
import { block } from 'bem-cn';

interface IProps {
  isProcessImport: boolean,
  importFrom1c: typeof import1c,
  getLastImport: typeof getLastImportStatus,
}

const {
  IMPORT_INTO_LK,
  SELECT_FILE_FOR_IMPORT,
  START_IMPORT,
  RUN,
  GET_LAST_IMPORT,
} = LANG_DICTIONARY;
const b = block('managment-admin-form');

const Import: React.FC<IProps> = ({isProcessImport, importFrom1c, getLastImport}) => {
  const [file, setFile] = useState(null);
  const onChangeFile = (e: any) => {
    const { target: { files } } = e;
    setFile(files[0]);
  };

  return (
    <>
      <AdminSectionTitle title={IMPORT_INTO_LK} />
      <section className={b('section')}>
        <p className={b('section-title')}>
          {SELECT_FILE_FOR_IMPORT}
        </p>
        <input
          type="file"
          onChange={onChangeFile}
          className={b('input-file')}
          accept=".xml"
        />
      </section>
      <section className={b('section')}>
        <p className={b('section-title')}>
          {START_IMPORT}
        </p>
        <AdminMainButton
          className={b('button')}
          text={RUN}
          onClick={() => importFrom1c(file)}
          isProcess={isProcessImport}
          disabled={!file || isProcessImport}
        />
      </section>
      <section className={b('section')}>
        <p className={b('section-title')}>
          {GET_LAST_IMPORT}
        </p>
        <AdminMainButton
          className={b('button')}
          text={RUN}
          onClick={getLastImport}
        />
      </section>
    </>
  );
};

export default Import;
