import { REGEXP, LANG_DICTIONARY } from 'consts';
import { MINIMUM_PASSWORD_LENGTH, MAX_LENGTH_INPUT, MAX_LENGTH_SUPPORT_MESSAGE } from 'config';
import { object, string } from 'yup';

const {
  CYRILLIC,
  EMAIL_CHECK,
  PASSWORD_CHECK,
} = REGEXP;

const {
  PASSWORD_CONFIRMATION_VALIDATION,
  PASSWORD_LANGUAGE,
  EMAIL_VALIDATION,
  INPUT_MAX_LENGTH,
  ACCESS_EMAIL_SYMBOLS,
  PASSWORD_RULES,
  MESSAGE_MAX_LENGTH,
} = LANG_DICTIONARY;

const checkPasswordSchema = (checkPassword: boolean, passwordConfirm?: string) => (
  checkPassword ? {
    oldPassword: string()
      .min(MINIMUM_PASSWORD_LENGTH, PASSWORD_RULES)
      .matches(PASSWORD_CHECK, PASSWORD_RULES)
      .test('', PASSWORD_LANGUAGE, (value) => !value.match(CYRILLIC)),
    password: string()
      .min(MINIMUM_PASSWORD_LENGTH, PASSWORD_RULES)
      .matches(PASSWORD_CHECK, PASSWORD_RULES)
      .test('', PASSWORD_LANGUAGE, (value) => !value.match(CYRILLIC)),
    passwordConfirm: string().test('', PASSWORD_CONFIRMATION_VALIDATION, (value) => value === passwordConfirm),
  } : {}
);

const checkSupportSchema = (checkSupport: boolean) => (
  checkSupport ? {
    email: string()
      .email(EMAIL_VALIDATION)
      .test('', PASSWORD_LANGUAGE, (value) => !value.match(CYRILLIC))
      .test('', ACCESS_EMAIL_SYMBOLS, (value) => !value.match(EMAIL_CHECK))
      .max(MAX_LENGTH_INPUT, INPUT_MAX_LENGTH),
    message: string()
      .max(MAX_LENGTH_SUPPORT_MESSAGE, MESSAGE_MAX_LENGTH),
  } : {}
);
const schema = (
  checkPassword: boolean,
  checkSupport: boolean,
  passwordConfirm?: string,
) => object().shape({
  ...checkPasswordSchema(checkPassword, passwordConfirm),
  ...checkSupportSchema(checkSupport),
});

export default schema;
