import { IPayloadAction } from 'interfaces/payloadAction';
import {
  simpleDate,
  generateFio,
  getCtgts,
  getContractData,
} from 'helpers';
import { DATE_FORMAT } from 'consts';
import {
  GET_USER_INFO,
  CLEAR_STORE,
  CONTROL_ACTION,
  GET_CONTACTS_CODES,
  GET_CONTR_AGENTS_LIST,
  DELETE_ACTION,
  GET_CONTR_AGENT_CARD,
  CLEAR_CTGT_CARD,
  UPDATE_CONTR_AGENTS_LIST,
  SHOW_TABLES_CONTROL,
  CHOOSE_CTGT,
  GET_CONTRACT_CARD,
  GET_CONTRACTS_LIST,
  UPDATE_CONTRACTS_LIST,
  CLEAR_CTS_CARD,
  RESET_CHANGES,
  CLEAR_SEARCH_CTS,
} from '../types';
import {
  IUserCard,
  IContrAgents,
  IContactsUserCard,
  IContractUserCard,
} from './interface';

export const ENUMS_SEND_STATUSES = {
  dontSend: 'none',
  toUpdate: 'toUpdate',
  toCreate: 'toCreate',
  toDelete: 'toDelete',
};

const INITIAL_STATE: IUserCard = {
  userPersonalCard: {
    userId: 0,
    regDateCreate: '',
    registrationFinishDate: '',
    isLocked: false,
    passwordChangeDate: '',
    userPassword: '',
    dataDateImport: '',
    dataCachePermanent: false,
    dateLock: '',
    dateUnlock: '',
    dateEndBan: '',
    ip: '',
    tempBan: null,
    contacts: [],
    contragents: [],
    contracts: [],
    ctgtsIdArray: [],
    needUpdatePassword: false,
    canUpdatePassword: false,
    hash: '',
    errorPassword: '',
  },
  initialData: {
    userId: 0,
    regDateCreate: '',
    registrationFinishDate: '',
    isLocked: false,
    passwordChangeDate: '',
    userPassword: '',
    dataDateImport: '',
    dataCachePermanent: false,
    dateLock: '',
    dateUnlock: '',
    dateEndBan: '',
    ip: '',
    tempBan: null,
    contacts: [],
    contragents: [],
    contracts: [],
    ctgtsIdArray: [],
    needUpdatePassword: false,
    canUpdatePassword: false,
    hash: '',
    errorPassword: '',
  },
  selectData: [],
  listOfCtgts: [],
  currentPageCtgts: 1,
  totalPagesCtgts: 1,
  toDelete: {
    contacts: [],
    contrAgents: [],
    contracts: [],
  },
  currentContrAgent: [],
  choosedCtgt: null,
  showTables: {
    showContragents: false,
    showContracts: false,
  },
  choosedCts: null,
  currentContractCard: [],
  listOfContracts: [],
  currentPageCts: 1,
  totalPagesCts: 1,
};

export default (state = INITIAL_STATE, { type, payload }: IPayloadAction) => {
  switch (type) {
    case CLEAR_SEARCH_CTS: {
      return {
        ...state,
        listOfContracts: [],
        currentPageCts: 1,
        totalPagesCts: 1,
      };
    }

    case RESET_CHANGES: {
      const { initialData } = state;
      return {
        ...state,
        userPersonalCard: initialData,
        choosedCts: null,
        listOfCtgts: [],
        currentPageCtgts: 1,
        totalPagesCtgts: 1,
        toDelete: {
          contacts: [],
          contrAgents: [],
          contracts: [],
        },
        currentContrAgent: [],
        choosedCtgt: null,
        showTables: {
          showContragents: false,
          showContracts: false,
        },
        currentContractCard: [],
        listOfContracts: [],
        currentPageCts: 1,
        totalPagesCts: 1,
      };
    }

    case CLEAR_CTS_CARD: {
      return {
        ...state,
        currentContractCard: [],
      };
    }

    case UPDATE_CONTRACTS_LIST.success: {
      const { listOfContracts } = state;
      const { data, rebuildData } = payload;
      return {
        ...state,
        listOfContracts: [...listOfContracts, ...rebuildData],
        currentPageCts: data.currentPage,
        totalPagesCts: data.totalPages,
      };
    }

    case GET_CONTRACTS_LIST.success: {
      const { data, rebuildData } = payload;
      return {
        ...state,
        listOfContracts: rebuildData,
        currentPageCts: data.currentPage,
        totalPagesCts: data.totalPages,
      };
    }

    case GET_CONTRACT_CARD.success: {
      const { data, ctsId } = payload;
      return {
        ...state,
        currentContractCard: getContractData(data),
        choosedCts: ctsId,
      };
    }

    case CHOOSE_CTGT: {
      return {
        ...state,
        choosedCtgt: payload,
      };
    }

    case SHOW_TABLES_CONTROL: {
      return {
        ...state,
        showTables: payload,
      };
    }

    case UPDATE_CONTR_AGENTS_LIST.success: {
      const { listOfCtgts } = state;
      const { rebuildData, data } = payload;
      return {
        ...state,
        listOfCtgts: [...listOfCtgts, ...rebuildData],
        currentPageCtgts: data.currentPage,
        totalPagesCtgts: data.totalPages,
      };
    }

    case CLEAR_CTGT_CARD: {
      return {
        ...state,
        currentContrAgent: [],
      };
    }

    case GET_CONTR_AGENT_CARD.success: {
      return {
        ...state,
        currentContrAgent: getCtgts(payload.data),
        choosedCtgt: payload.ctgtId,
      };
    }

    case GET_CONTR_AGENT_CARD.fail: {
      return {
        ...state,
        currentContrAgent: [],
      };
    }

    case DELETE_ACTION: {
      return {
        ...state,
        toDelete: payload,
      };
    }

    case GET_CONTR_AGENTS_LIST.success: {
      const { rebuildData, data } = payload;
      return {
        ...state,
        listOfCtgts: rebuildData,
        currentPageCtgts: data.currentPage,
        totalPagesCtgts: data.totalPages,
      };
    }

    case GET_CONTACTS_CODES.success: {
      return {
        ...state,
        selectData: payload,
      };
    }

    case CONTROL_ACTION: {
      return {
        ...state,
        userPersonalCard: payload,
      };
    }

    case GET_USER_INFO.success: {
      const {
        userId,
        regDateCreate,
        registrationFinishDate,
        isLocked,
        passwordChangeDate,
        userPassword,
        dataCachePermanent,
        dateLock,
        dateUnlock,
        dateEndBan,
        ip,
        tempBan,
        contacts,
        ctgts,
        cts,
        dataDateImport,
      } = payload;
      return {
        ...state,
        userPersonalCard: {
          userId,
          regDateCreate: simpleDate(regDateCreate, DATE_FORMAT.DATE),
          registrationFinishDate: simpleDate(registrationFinishDate, DATE_FORMAT.DATE),
          isLocked: Boolean(isLocked),
          passwordChangeDate,
          hash: userPassword,
          userPassword: '',
          dataCachePermanent,
          dateLock: simpleDate(dateLock, DATE_FORMAT.DATE),
          dataDateImport: simpleDate(dataDateImport, DATE_FORMAT.DATE_WITH_HOURS_MINUTES_SECOND),
          dateUnlock: simpleDate(dateUnlock, DATE_FORMAT.DATE),
          dateEndBan: simpleDate(dateEndBan, DATE_FORMAT.DATE),
          ip,
          tempBan,
          contacts: contacts.map((item: IContactsUserCard) => ({
            ...item,
            sendStatus: ENUMS_SEND_STATUSES.dontSend,
            popUpOpen: false,
          })),
          contragents: ctgts.map(({
            guid1c,
            firstName,
            middleName,
            lastName,
            ctgtsId,
            birthDate,
          }: IContrAgents) => ({
            guid1c,
            ctgtsId,
            birthDate,
            fio: generateFio(lastName, firstName, middleName),
            sendStatus: ENUMS_SEND_STATUSES.dontSend,
          })),
          contracts: cts.map((item: IContractUserCard) => ({
            ...item,
            sendStatus: ENUMS_SEND_STATUSES.dontSend,
          })),
          ctgtsIdArray: [],
          needUpdatePassword: false,
        },
        initialData: {
          userId,
          regDateCreate: simpleDate(regDateCreate, DATE_FORMAT.DATE),
          registrationFinishDate: simpleDate(registrationFinishDate, DATE_FORMAT.DATE),
          isLocked: Boolean(isLocked),
          passwordChangeDate,
          userPassword: '',
          hash: userPassword,
          dataCachePermanent,
          dateLock: simpleDate(dateLock, DATE_FORMAT.DATE),
          dataDateImport: simpleDate(dataDateImport, DATE_FORMAT.DATE),
          dateUnlock: simpleDate(dateUnlock, DATE_FORMAT.DATE),
          dateEndBan: simpleDate(dateEndBan, DATE_FORMAT.DATE),
          ip,
          tempBan,
          contacts: contacts.map((item: IContactsUserCard) => ({
            ...item,
            sendStatus: ENUMS_SEND_STATUSES.dontSend,
            popUpOpen: false,
          })),
          contragents: ctgts.map(({
            guid1c,
            firstName,
            middleName,
            lastName,
            ctgtsId,
            birthDate,
          }: IContrAgents) => ({
            guid1c,
            ctgtsId,
            birthDate,
            fio: generateFio(lastName, firstName, middleName),
            sendStatus: ENUMS_SEND_STATUSES.dontSend,
          })),
          contracts: cts.map((item: IContractUserCard) => ({
            ...item,
            sendStatus: ENUMS_SEND_STATUSES.dontSend,
          })),
          ctgtsIdArray: [],
          needUpdatePassword: false,
        },
      };
    }

    case CLEAR_STORE: {
      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};
