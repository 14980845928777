import { all } from 'redux-saga/effects';
import authorizationSaga from './authorization/sages';
import mainDataSaga from './mainData/sages';
import registrationgSaga from './registration/sages';
import adminAuthorizationSaga from './adminAuthorization/sages';
import commonSaga from './common/sages';
import adminManagmentSaga from './adminManagment/sages';
import contractSaga from './contracts/sages';
import adminUsersSaga from './adminUsers/sages';
import adminUserCardSaga from './adminUserCard/sages';
import descriptionsSaga from './clientDescriptions/sages';
import adminAdministratorsSaga from './adminAdministrators/sages';
import profitabilitySaga from './profitability/sages';
import applicationSaga from './applications/sages';

export default function* rootSaga() {
  yield all([
    adminUserCardSaga(),
    adminAdministratorsSaga(),
    descriptionsSaga(),
    adminUsersSaga(),
    authorizationSaga(),
    mainDataSaga(),
    registrationgSaga(),
    adminAuthorizationSaga(),
    commonSaga(),
    adminManagmentSaga(),
    contractSaga(),
    profitabilitySaga(),
    applicationSaga(),
  ]);
}
