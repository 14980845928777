import './styles.scss';

import React from 'react';
import { PopupContainer } from 'components';
import { block } from 'bem-cn';

interface IProps {
  isOpen: boolean,
  close: (...args: any[]) => any,
  children: React.ReactNode,
  isCloseButton?: boolean,
}

const b = block('admin-popup');

const AdminPopupContainer: React.FC<IProps> = ({
  isOpen,
  close,
  children,
  isCloseButton,
}) => (
  <PopupContainer
    close={close}
    isOpen={isOpen}
    isCloseButton={isCloseButton}
    className={b()}
    classNameVeil={b('veil')}
  >
    {children}
  </PopupContainer>
);

export default AdminPopupContainer;
