import React from 'react';
import { IInvestPeriodModel, IProfitabilityModel } from 'redux/profitability/reducers/interfaces';
import AccordionTitleOpen from '../AccordionTitleOpen';
import AccordionTitleClosed from '../AccordionTitleClosed';

interface ITitleProps {
  index: number;
}

interface IProps {
  activeIndex: number,
  index: number,
  number: number,
  isAutocalled: boolean,
  name: string,
  years: number,
  months: number,
  description: string,
  handleClick: (e : React.MouseEvent, titleProps: ITitleProps) => void,
  item: IInvestPeriodModel,
  isDisabledStrategy: boolean,
}

const AccordionTitle: React.FC<IProps> = (props) => {
  const {
    activeIndex,
    index,
    number,
    isAutocalled,
    name,
    years,
    months,
    description,
    handleClick,
    item,
    item: {isAllPaymentsExists},
  } = props;

  return (
    <>
      { isAllPaymentsExists && (index === activeIndex)
        ? (
          <AccordionTitleOpen
            activeIndex={activeIndex}
            index={index}
            number={number}
            isAutocalled={isAutocalled}
            name={name}
            years={years}
            months={months}
            description={description}
            handleClick={handleClick}
            item={item}
            isDisabledStrategy={!isAllPaymentsExists}
          />
        )
        : (
          <AccordionTitleClosed
            activeIndex={activeIndex}
            index={index}
            number={number}
            isAutocalled={isAutocalled}
            name={name}
            years={years}
            months={months}
            handleClick={handleClick}
            isDisabledStrategy={!isAllPaymentsExists}
          />
        )}
    </>
  );
};

export default AccordionTitle;
