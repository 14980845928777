import {
  GET_USERS_CONTRACTS_ISZ,
  GET_USERS_CONTRACTS_NSZ,
  SET_CONTRACT_TYPE,
  SET_SELECTED_TYPE_ID,
} from 'redux/contracts/types';

export const getUserContractsIsz = () => ({
  type: GET_USERS_CONTRACTS_ISZ.request,
});

export const getUserContractsNsz = () => ({
  type: GET_USERS_CONTRACTS_NSZ.request,
});

export const setContractType = (isIsz: boolean) => ({
  payload: isIsz,
  type: SET_CONTRACT_TYPE,
});

export const setSelectedTypeId = (id: number) => ({
  payload: id,
  type: SET_SELECTED_TYPE_ID,
});
