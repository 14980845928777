const GET_ADDTIONAL_INFO_CONTRACT_REQUEST = 'registration/GET_ADDTIONAL_INFO_CONTRACT_REQUEST';
const GET_ADDTIONAL_INFO_CONTRACT_FAIL = 'registration/GET_ADDTIONAL_INFO_CONTRACT_FAIL';
const GET_ADDTIONAL_INFO_CONTRACT_SUCCESS = 'registration/GET_ADDTIONAL_INFO_CONTRACT_SUCCESS';
const GET_ADDTIONAL_INFO_CONTRACT_START = 'registration/GET_ADDTIONAL_INFO_CONTRACT_START';

const GET_ADDTIONAL_INFO_PERSONAL_REQUEST = 'registration/GET_ADDTIONAL_INFO_PERSONAL_REQUEST';
const GET_ADDTIONAL_INFO_PERSONAL_START = 'registration/GET_ADDTIONAL_INFO_PERSONAL_START';
const GET_ADDTIONAL_INFO_PERSONAL_FAIL = 'registration/GET_ADDTIONAL_INFO_PERSONAL_FAIL';
const GET_ADDTIONAL_INFO_PERSONAL_SUCCESS = 'registration/GET_ADDTIONAL_INFO_PERSONAL_SUCCESS';

const GET_ADDTIONAL_INFO_ACCOUNT_REQUEST = 'registration/GET_ADDTIONAL_INFO_ACCOUNT_REQUEST';
const GET_ADDTIONAL_INFO_ACCOUNT_START = 'registration/GET_ADDTIONAL_INFO_ACCOUNT_START';
const GET_ADDTIONAL_INFO_ACCOUNT_FAIL = 'registration/GET_ADDTIONAL_INFO_ACCOUNT_FAIL';
const GET_ADDTIONAL_INFO_ACCOUNT_SUCCESS = 'registration/GET_ADDTIONAL_INFO_ACCOUNT_SUCCESS';

const SET_DATA_FIRST_FORM_REQUEST: any = 'registration/SET_DATA_FIRST_FORM_REQUEST';
const SET_DATA_FIRST_FORM_START = 'registration/SET_DATA_FIRST_FORM_START';
const SET_DATA_FIRST_FORM_SUCCESS = 'registration/SET_DATA_FIRST_FORM_SUCCESS';
const SET_DATA_FIRST_FORM_FAIL = 'registration/SET_DATA_FIRST_FORM_FAIL';

const SET_DATA_SECOND_FORM_REQUEST: any = 'registration/SET_DATA_SECOND_FORM_REQUEST';
const SET_DATA_SECOND_FORM_START = 'registration/SET_DATA_SECOND_FORM_START';
const SET_DATA_SECOND_FORM_SUCCESS = 'registration/SET_DATA_SECOND_FORM_SUCCESS';
const SET_DATA_SECOND_FORM_FAIL = 'registration/SET_DATA_SECOND_FORM_FAIL';

const SET_DATA_THIRD_FORM_REQUEST: any = 'registration/SET_DATA_THIRD_FORM_REQUEST';
const SET_DATA_THIRD_FORM_START = 'registration/SET_DATA_THIRD_FORM_START';
const SET_DATA_THIRD_FORM_SUCCESS = 'registration/SET_DATA_THIRD_FORM_SUCCESS';
const SET_DATA_THIRD_FORM_FAIL = 'registration/SET_DATA_THIRD_FORM_FAIL';

const CLEAR_STORE = 'registration/CLEAR_STORE';

const CHANGE_FIELD = 'registration/CHANGE_FIELD';

const CHANGE_LOGIN = 'registration/CHANGE_LOGIN';
const CLEAR_LOGIN = 'registration/CLEAR_LOGIN';

export {
  CLEAR_LOGIN,
  CHANGE_LOGIN,
  CHANGE_FIELD,
  CLEAR_STORE,
  GET_ADDTIONAL_INFO_CONTRACT_REQUEST,
  GET_ADDTIONAL_INFO_CONTRACT_START,
  GET_ADDTIONAL_INFO_CONTRACT_FAIL,
  GET_ADDTIONAL_INFO_CONTRACT_SUCCESS,
  GET_ADDTIONAL_INFO_PERSONAL_REQUEST,
  GET_ADDTIONAL_INFO_PERSONAL_START,
  GET_ADDTIONAL_INFO_PERSONAL_FAIL,
  GET_ADDTIONAL_INFO_PERSONAL_SUCCESS,
  GET_ADDTIONAL_INFO_ACCOUNT_REQUEST,
  GET_ADDTIONAL_INFO_ACCOUNT_START,
  GET_ADDTIONAL_INFO_ACCOUNT_FAIL,
  GET_ADDTIONAL_INFO_ACCOUNT_SUCCESS,
  SET_DATA_SECOND_FORM_REQUEST,
  SET_DATA_SECOND_FORM_SUCCESS,
  SET_DATA_SECOND_FORM_START,
  SET_DATA_SECOND_FORM_FAIL,
  SET_DATA_FIRST_FORM_REQUEST,
  SET_DATA_FIRST_FORM_SUCCESS,
  SET_DATA_FIRST_FORM_START,
  SET_DATA_FIRST_FORM_FAIL,
  SET_DATA_THIRD_FORM_REQUEST,
  SET_DATA_THIRD_FORM_SUCCESS,
  SET_DATA_THIRD_FORM_START,
  SET_DATA_THIRD_FORM_FAIL,
};
