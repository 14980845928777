import 'components/_client/_shared/Profitability/styles.scss';
import React, { useEffect } from 'react';
import block from 'bem-cn';
import { LANG_DICTIONARY } from 'consts/dictionary';
import { ISelectedStrategy } from 'redux/profitability/reducers/interfaces';
import TabSelect, { ITabSelectItem } from 'components/_client/_shared/TabSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeProfitabilitySelectedPeriod,
} from 'redux/profitability/actions';
import {
  getSelectedPeriod,
  getSelectedStrategy,
} from 'redux/profitability/selectors';
import SelectDate from '../SelectDate';

const b = block('profitability');

const REF_PAYMENT_END_OF_TERM = 5;

interface IProps {
  strategy: ISelectedStrategy,
}

const PeriodSelect: React.FC<IProps> = ({
  strategy,
}) => {
  const selectedPeriod = useSelector(getSelectedPeriod);
  const selectedStrategy = useSelector(getSelectedStrategy);
  const dispatch = useDispatch();

  const yearSelectItems: ITabSelectItem[] = strategy.periods.map((period, i) => {
    const isActive = !period.isDisabled;
    const isMarked = i + 1 === (selectedStrategy.defaultPeriod);
    return {
      label: `${i + 1}${LANG_DICTIONARY.YEAR_ORDER}`,
      value: i + 1,
      active: isActive,
      marked: isMarked,
      barrier: period?.barrierLevel,
      disabled: !isActive,
    };
  });

  useEffect(() => {
    const defaultSelectedPeriodIndex = strategy.periods
      .map((period) => period.isDisabled)
      .lastIndexOf(false);

    dispatch(changeProfitabilitySelectedPeriod(defaultSelectedPeriodIndex + 1));
  }, []);

  const onSelectPeriod = (year: number) => {
    dispatch(changeProfitabilitySelectedPeriod(year));
  };

  const isMarkedYear = yearSelectItems.find((item) => item.value === selectedPeriod)?.marked;

  return (
    <div>
      <div className={b('block-header')}>
        {LANG_DICTIONARY.POLICY_PERIOD}
      </div>
      {strategy.refPaymentTerm === REF_PAYMENT_END_OF_TERM
        ? (
          <>
            <div className={b('wrapper-tab')}>
              <p className={b('term-end')}>{LANG_DICTIONARY.END_OF_TERM}</p>
              <div className={b('border')} />
              <div className={b('barrier')}>
                <div className={b('barrier-percent')}>
                  {Boolean(strategy.periods[0].barrierLevel) && `${strategy.periods[0].barrierLevel}%`}
                </div>
                <div className={b('barrier-field')}>
                  {
                    Boolean(strategy.periods[0].barrierLevel) && LANG_DICTIONARY.BARRIER
                  }
                </div>
              </div>
            </div>
            <SelectDate
              selectedYear={selectedPeriod}
              isMarkedYear
            />
          </>
        )
        : (
          <>
            <div style={{marginBottom: 30}}>
              <TabSelect
                value={selectedPeriod}
                items={yearSelectItems}
                markTitle={LANG_DICTIONARY.CURRENT}
                onSelect={onSelectPeriod}
              />
            </div>
            <SelectDate
              selectedYear={selectedPeriod}
              isMarkedYear={isMarkedYear}
            />
          </>
        )}
    </div>
  );
};

export default PeriodSelect;
