import React from 'react';
import { block } from 'bem-cn';
import { withWindowDimensions } from 'HOC';
import RowTable from './row';
import './styles.scss';

const b = block('flex-table');

interface IProps {
  data: any[];
  minHeight: number;
  windowWidth: number;
}

const FlexTable: React.FC<IProps> = ({data, minHeight, windowWidth}) => (
  <div className={b()}>
    {data.map(({data: includeData, key}, index) => (
      <RowTable
        minHeight={minHeight}
        index={index}
        data={includeData}
        key={key}
        windowWidth={windowWidth}
      />
    ))}
  </div>
);

export default withWindowDimensions(FlexTable);
