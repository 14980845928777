import React from 'react';
import { generateKeys } from 'helpers';
import { IIValueWithKey } from 'helpers/generateKeys';

type TItemArray = string | number | Node;

interface IProps {
  data: TItemArray[][],
  headers?: TItemArray[],
  className: string,
  isFirstRowColor?: boolean,
  classNameTd?: string,
  classNameTr?: string,
  classNameTh?: string,
  classNameHeader?: string,
}

const TableGenerator: React.FC<IProps> = ({
  headers = [],
  data,
  className,
  classNameTd = '',
  classNameTr = '',
  classNameTh = '',
  classNameHeader = '',
}) => {
  const rebuildHeders = generateKeys(headers);
  const rebuildData = generateKeys(data);

  return (
    <table className={className}>
      {Boolean(rebuildHeders.length) && (
      <tr className={classNameHeader}>
        {rebuildHeders.map(({value, key}) => (
          <th
            key={key}
            className={classNameTh}
          >
            {value}
          </th>
        ))}
      </tr>
      )}
      <tbody>
        {rebuildData.map(({value: row, key}) => (
          <tr
            key={key}
            className={classNameTr}
          >
            {row.map(({value, key: subkey}: IIValueWithKey) => (
              <td
                key={subkey}
                className={classNameTd}
              >
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableGenerator;
