import './styles.scss';

import { block } from 'bem-cn';
import { ADMIN_SECTIONS, ROUTES, LANG_DICTIONARY } from 'consts';
import { IAdminSection } from 'consts/adminSections';
import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { RouteComponentProps } from 'react-router-dom';

interface IProps {
  isOpen: boolean,
  toggleMenu: (state: boolean) => void,
  actions: string[],
  history: RouteComponentProps['history'],
  adminUrl: string,
  signOut: () => void,
}

const b = block('admin-burger-menu');
const { EXIT } = LANG_DICTIONARY;

const BurgerMenu: React.FC<IProps> = ({
  isOpen,
  toggleMenu,
  actions,
  history,
  adminUrl,
  signOut,
}) => {
  const handleClick = (path: string) => {
    history.push(`${adminUrl}${path}`);
    toggleMenu(false);
  };

  const handleExit = () => {
    signOut();
    toggleMenu(false);
    history.push(`${adminUrl}${ROUTES.adminAuthorization}`);
  };

  return (
    <Menu
      className={b()}
      isOpen={isOpen}
      onStateChange={(state) => !state.isOpen && toggleMenu(!isOpen)}
      right
    >
      {
      ADMIN_SECTIONS.map(({
        path,
        role,
        name,
      }: IAdminSection) => {
        if (actions.includes(role)) {
          return (
            <button
              type="button"
              className={b('button')}
              onClick={() => handleClick(path)}
              key={path}
            >
              {name}
            </button>
          );
        }

        return null;
      })
     }
      <button
        type="button"
        className={b('button')}
        onClick={handleExit}
      >
        {EXIT}
      </button>
    </Menu>
  );
};

export default BurgerMenu;
