import compose from '@shopify/react-compose';
import { ADMIN_SECTIONS, ROUTES } from 'consts';
import { withAdministrationInterface } from 'HOC';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouterProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IRedux } from 'redux/interface';

interface IProps {
  actions: string[],
  adminUrl: string,
}

type TProps = IProps & RouterProps;

const RootAdminSection: React.FC<TProps> = ({
  actions,
  adminUrl,
}) => {
  const firstSection = actions.length
    ? ADMIN_SECTIONS.find(({role}) => role === actions[0])
    : { path: ROUTES.adminAuthorization };
  const redirectPath = `${adminUrl}${firstSection?.path}`;

  return (<Redirect to={redirectPath} />);
};

const mapStateToProps = ({
  common: { adminUrl },
  adminAuthorization: { actions },
}: IRedux) => ({ adminUrl, actions });

export default compose(
  withAdministrationInterface,
  withRouter,
  connect(mapStateToProps),
)(RootAdminSection);
