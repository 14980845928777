import { block } from 'bem-cn';
import { SUPPORT_NUMBER, MAIN_BCS_LIFE } from 'config';
import { LANG_DICTIONARY } from 'consts';
import { SocialIcons } from 'components';
import React from 'react';
import bcsLogo from 'assets/svg/bcsLogo.svg';
import footerPhone from 'assets/svg/footerPhone.svg';
import './styles.scss';

const b = block('footer');

const {
  INSURANCE_COMPANY,
  BCS_NAME,
  LICENSE_FIRST_ROW,
  LICENSE_SECOND_ROW,
  SOCIAL_WEB,
} = LANG_DICTIONARY;

interface IProps {
  currentYear: string;
}
const goToBcsOnline = () => {
  document.location.href = MAIN_BCS_LIFE;
};

const Footer: React.FC<IProps> = ({currentYear}) => (
  <div className={b()}>
    <div className={b('wrapper')}>
      <div className={b('left-side')}>
        <div className={b('logo')} onClick={goToBcsOnline} role="button" tabIndex={-1}>
          <img src={bcsLogo} alt="logo" />
        </div>
        <div>
          <div>{INSURANCE_COMPANY}</div>
          <div>{BCS_NAME}</div>
        </div>
      </div>
      <div className={b('right-side')}>
        <div>
          <div className={b('license-row')}>{LICENSE_FIRST_ROW(currentYear)}</div>
          <div className={b('license-row')}>{LICENSE_SECOND_ROW}</div>
          <div className={b('contact-info')}>
            <img src={footerPhone} alt="phone" className={b('phone-icon')} />
            <a href="tel:88005005021" className={b('phone-call')}>{SUPPORT_NUMBER}</a>
          </div>
        </div>
        <div className={b('social-network')}>
          <div className={b('social-text')}>{SOCIAL_WEB}</div>
          <SocialIcons />
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
