import { IRedux } from '../../interface';

export const getSelectedStrategy = (
  { profitability }: IRedux,
) => (profitability.profitabilityData.selectedStrategy);

export const getIsStrategyLoading = (
  { profitability }: IRedux,
) => (profitability.isStrategyLoading);

export const getIsPaymentsLoading = (
  { profitability }: IRedux,
) => (profitability.isPaymentsLoading);

export const getSelectedPeriod = (
  { profitability }: IRedux,
) => (profitability.profitabilityData.selectedPeriod);

export const getSelectedPeriodPayments = (
  { profitability }: IRedux,
) => (profitability.profitabilityData.selectedPeriodPayments);

export const getSelectedPayment = (
  { profitability }: IRedux,
) => (profitability.profitabilityData.selectedPayment);

export const getContractInfo = (
  { profitability }: IRedux,
) => (profitability.profitabilityData.contract);
