import './styles.scss';

import { block } from 'bem-cn';
import addButton from 'assets/images/addButton.png';
import React from 'react';

interface IProps {
  title: string,
  addButtonBoolean?: boolean,
}

const b = block('admin-section-title');

const SectionTitle: React.FC<IProps> = ({title, addButtonBoolean = false}) => (
  <div className={b()}>
    <p className={b('text-wrapper')}>{title}</p>
    {addButtonBoolean && (
    <img
      src={addButton}
      alt="add"
      className={b('control-btn')}
    />
    )}
  </div>
);

export default SectionTitle;
