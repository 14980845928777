import { API, LANG_DICTIONARY, ROUTES } from 'consts';
import { apiCall, getError, getGlobalHistory } from 'helpers';
import { TIME_TO_CHECK_IMPORT_MS } from 'config';
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import {
  CLEAR_CONNECTION_USERS_TO_CTGTS,
  CHANGE_GLOBAL_SETTINGS,
  CREATE_CONNECTION_USERS_TO_CTGTS,
  GET_GLOBAL_SETTINGS,
  IMPORT_1C,
  CHECK_IMPORT,
  GET_LAST_IMPORT_RESULT,
  GET_PRINTED_FORMS_TYPES,
  SEND_PRINT_FORMS,
} from '../types';
import { ICheckImportPayload, IGlobalSettings } from '../reducers/interface';

const { SAVED_SUCCESS } = LANG_DICTIONARY;

const delay = (time: number) => new Promise((resolve: any) => setTimeout(resolve, time));

function* import1c({ payload }: {payload: File}) {
  const { name } = payload;

  try {
    yield put({ type: IMPORT_1C.start });

    const formData = new FormData();
    formData.append('file', payload);

    const { data } = yield apiCall({
      body: formData,
      type: 'POST',
      url: API.IMPORT_1C,
      isAdminToken: true,
    });
    // const { importName } = data;
    // yield put({ type: CHECK_IMPORT, payload: { importName, name }});
    yield put({ type: IMPORT_1C.success, payload: { fileName: name }});
    toastr.success('', SAVED_SUCCESS);
  } catch (e) {
    yield put({ type: IMPORT_1C.fail, payload: { error: getError(e), fileName: name } });
  }
}

function* getLastImportResult() {
  try {
    yield put({ type: GET_LAST_IMPORT_RESULT.start });

    const { data } = yield apiCall({
      type: 'GET',
      url: API.LAST_IMPORT_RESULT,
      isAdminToken: true,
    });
    const { errors, fileName } = data;
    yield put({ type: GET_LAST_IMPORT_RESULT.success, payload: { errors, fileName }});
  } catch (e) {
    yield put({ type: GET_LAST_IMPORT_RESULT.fail, payload: { error: getError(e) } });
  }
}

function* import1cCheck({ payload }: { payload: ICheckImportPayload }) {
  const { importName, name } = payload;

  try {
    const { data } = yield apiCall({
      query: {
        importName,
      },
      type: 'GET',
      url: API.IMPORT_1C_CHECK,
      isAdminToken: true,
    });
    const { errors, isImporting } = data;
    if (isImporting) {
      yield call(delay, TIME_TO_CHECK_IMPORT_MS);
      yield put({ type: CHECK_IMPORT, payload: { importName, name }});
    } else {
      yield put({ type: IMPORT_1C.success, payload: { errors, fileName: name }});
    }
  } catch (e) {
    yield put({ type: IMPORT_1C.fail, payload: { error: getError(e), fileName: name } });
  }
}

function* createConnectionUsersToCtgts() {
  try {
    yield put({ type: CREATE_CONNECTION_USERS_TO_CTGTS.start });
    yield apiCall({
      type: 'POST',
      url: API.CONNECTION_USERS_TO_CTGTS,
      isAdminToken: true,
    });
    yield delay(1000);
    yield put({ type: CREATE_CONNECTION_USERS_TO_CTGTS.success });
  } catch (e) {
    yield put({ type: CREATE_CONNECTION_USERS_TO_CTGTS.fail, payload: getError(e) });
  }
}

function* clearConnectionUsersToCtgts() {
  try {
    yield put({ type: CLEAR_CONNECTION_USERS_TO_CTGTS.start });
    yield apiCall({
      type: 'DELETE',
      url: API.CONNECTION_USERS_TO_CTGTS,
      isAdminToken: true,
    });
    yield delay(1000);
    yield put({ type: CLEAR_CONNECTION_USERS_TO_CTGTS.success });
  } catch (e) {
    yield put({ type: CLEAR_CONNECTION_USERS_TO_CTGTS.fail, payload: getError(e) });
  }
}

function* getGlobalSettings() {
  try {
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GLOBAL_SETTINGS,
      isAdminToken: true,
    });
    yield put({ type: CHANGE_GLOBAL_SETTINGS.success, payload: data });
  } catch (e) {
    const { adminUrl } = yield select(({common}) => common);
    getGlobalHistory().push(`${adminUrl}${ROUTES.adminAuthorization}`);
    yield put({ type: GET_GLOBAL_SETTINGS.fail, payload: getError(e) });
  }
}

function* changeGlobalSettings({payload}: { payload: IGlobalSettings }) {
  try {
    yield apiCall({
      type: 'POST',
      url: API.GLOBAL_SETTINGS,
      body: payload,
      isAdminToken: true,
    });
    yield put({ type: CHANGE_GLOBAL_SETTINGS.success, payload });
  } catch (e) {
    yield put({ type: CHANGE_GLOBAL_SETTINGS.fail, payload: getError(e) });
  }
}

function* getPrintedFormsTypes() {
  try {
    yield put({ type: GET_PRINTED_FORMS_TYPES.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_PRINTED_FORMS_TYPES,
      isAdminToken: true,
    });
    const rebuildData = data.map(
      (item: { code: string, name: string, description: string, id: number }) => ({
        id: item.id,
        key: item.name,
        value: item.code,
        text: item.description,
      }),
    );
    yield put({ type: GET_PRINTED_FORMS_TYPES.success, payload: rebuildData });
  } catch (e) {
    yield put({ type: GET_PRINTED_FORMS_TYPES.fail });
  }
}

function* sendPrintForm({ payload }: { payload: FormData}) {
  try {
    yield put({ type: SEND_PRINT_FORMS.start });
    yield apiCall({
      type: 'POST',
      url: API.SEND_PRINT_FORM,
      body: payload,
      isAdminToken: true,
    });
    yield put({ type: SEND_PRINT_FORMS.success });
    toastr.success('', SAVED_SUCCESS);
  } catch (e) {
    yield put({ type: SEND_PRINT_FORMS.fail });
    toastr.error('', getError(e));
  }
}

export default function* adminManagmentSaga() {
  yield takeLatest<string, any>(
    CREATE_CONNECTION_USERS_TO_CTGTS.request,
    createConnectionUsersToCtgts,
  );
  yield takeLatest<string, any>(
    CLEAR_CONNECTION_USERS_TO_CTGTS.request,
    clearConnectionUsersToCtgts,
  );
  yield takeLatest<string, any>(IMPORT_1C.request, import1c);
  yield takeLatest<string, any>(GET_GLOBAL_SETTINGS.request, getGlobalSettings);
  yield takeLatest<string, any>(CHANGE_GLOBAL_SETTINGS.request, changeGlobalSettings);
  yield takeLatest<string, any>(CHECK_IMPORT, import1cCheck);
  yield takeLatest<string, any>(GET_LAST_IMPORT_RESULT.request, getLastImportResult);
  yield takeLatest<string, any>(GET_PRINTED_FORMS_TYPES.request, getPrintedFormsTypes);
  yield takeLatest<string, any>(SEND_PRINT_FORMS.request, sendPrintForm);
}
