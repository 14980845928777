import compose from '@shopify/react-compose';
import { UsersForm } from 'components';
import { withAdminFormInterface, withAdministrationInterface } from 'HOC';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IAdminUsersReducer } from 'redux/adminUsers/reducers/interface';
import { IRedux } from 'redux/interface';
import {
  getUsersList,
  signInByUserId,
  clearStoreUsers,
  changeSearchField,
} from 'redux/rootActions';

interface IStoreProps {
  getListOfUser: typeof getUsersList;
  signInByUser: typeof signInByUserId;
  clearStore: typeof clearStoreUsers,
  changeSearch: typeof changeSearchField,
}

type TProps = IStoreProps & RouteComponentProps & IAdminUsersReducer;

class AdminUsers extends React.Component <TProps> {
  componentDidMount = () => {
    const { getListOfUser, currentPage, filter } = this.props;
    getListOfUser(currentPage, filter);
  }

  handleUpPage = () => {
    const {
      getListOfUser,
      currentPage,
      filter,
      totalPages,
    } = this.props;
    if (currentPage !== totalPages) {
      getListOfUser(currentPage + 1, filter);
    }
  }

  handleDownPage = () => {
    const {
      getListOfUser,
      currentPage,
      filter,
    } = this.props;
    if (currentPage !== 1) {
      getListOfUser(currentPage - 1, filter);
    }
  }

  componentWillUnmount = () => {
    const { clearStore } = this.props;
    clearStore();
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      changeSearch,
      getListOfUser,
      currentPage,
    } = this.props;
    const { value } = e.target;
    changeSearch(value);
    getListOfUser(currentPage, value);
  }

  render() {
    const {
      users,
      currentPage,
      totalPages,
      signInByUser,
      filter,
      adminUrl,
    } = this.props;
    return (
      <UsersForm
        adminUrl={adminUrl}
        filter={filter}
        signInByUser={signInByUser}
        users={users}
        activePage={currentPage}
        totalPages={totalPages}
        handleUpPage={this.handleUpPage}
        handleDownPage={this.handleDownPage}
        handleChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = ({
  adminUsers,
  common: {
    adminUrl,
  },
}: IRedux) => ({...adminUsers, adminUrl});

const mapDispatchToProps = {
  getListOfUser: getUsersList,
  signInByUser: signInByUserId,
  clearStore: clearStoreUsers,
  changeSearch: changeSearchField,
};

export default compose(
  withAdministrationInterface,
  withAdminFormInterface({ maxWidth: false }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AdminUsers);
