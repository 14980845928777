import { ActionFactory } from 'helpers';

const factory = new ActionFactory('applications');

const SET_IS_PAYMENT_END = 'SET_IS_PAYMENT_END';
const SET_CHECKBOX_VALUE = 'SET_CHECKBOX_VALUE';
const SET_PASSED_FIRST_FORM = 'SET_PASSED_FIRST_FORM';
const TYPE_COMMENT = 'TYPE_COMMENT';
const CLEAR_STATE = 'CLEAR_STATE';
const CHANGE_FIELD = 'CHANGE_FIELD';
const SAVE_FORM_DATA = 'SAVE_FORM_DATA';
const DELETE_IMAGE = 'DELETE_IMAGE';
const SET_FOUND_BANK = 'SET_FOUND_BANK';
const SET_PIC_ERR = 'SET_PIC_ERR';
const DECREMENT_TIMER = 'DECREMENT_TIMER';


const GET_USER_CONTRACT = factory.createAsync('GET_USER_CONTRACT');
const SEND_APPLICATION = factory.createAsync('SEND_APPLICATION');
const SEND_REQ_DADATA = factory.createAsync('SEND_REQ_DADATA');
const SEND_REQ_FOR_SMS_CODE = factory.createAsync('SEND_REQ_FOR_SMS_CODE');
const CHECK_CODE_IS_VALID = factory.createAsync('CHECK_CODE_IS_VALID');
const GET_USER_BANKS_INN = factory.createAsync('GET_USER_BANKS_INN');

export {
  SET_IS_PAYMENT_END,
  GET_USER_CONTRACT,
  SET_CHECKBOX_VALUE,
  SET_PASSED_FIRST_FORM,
  TYPE_COMMENT,
  CLEAR_STATE,
  CHANGE_FIELD,
  SEND_APPLICATION,
  SAVE_FORM_DATA,
  DELETE_IMAGE,
  SEND_REQ_DADATA,
  SET_FOUND_BANK,
  SET_PIC_ERR,
  SEND_REQ_FOR_SMS_CODE,
  CHECK_CODE_IS_VALID,
  DECREMENT_TIMER,
  GET_USER_BANKS_INN,
};
