import React from 'react';
import { CheckBoxSvg } from 'components';
import block from 'bem-cn';
import './styles.scss';

const b = block('custom-checkbox');

interface IProps {
  active: boolean;
  onClick?: () => void;
}

const CustomCheckBox: React.FC<IProps> = ({active, onClick}) => (
  <div
    onClick={onClick}
    role="button"
    tabIndex={-1}
    className={b()}
  >
    <CheckBoxSvg active={active} />
  </div>
);

export default CustomCheckBox;
