import { ICommonReducer } from './interface';
import { GET_IP, GET_ADMIN_URL, TOGGLE_ADMIN_BURGER_MENU } from '../types';

const INITIAL_STATE: ICommonReducer = {
  ip: '',
  isFinishGettingAdminUrl: false,
  isOpenBurgerMenu: false,
  adminUrl: '',
};

interface IAcitonPayload {
  type: string,
  payload: any,
}

export default (state = INITIAL_STATE, { type, payload }: IAcitonPayload) => {
  switch (type) {
    case GET_IP.success:
      return {
        ...state,
        ip: payload,
      };

    case GET_ADMIN_URL.success:
      return {
        ...state,
        adminUrl: payload,
        isFinishGettingAdminUrl: true,
      };

    case GET_ADMIN_URL.fail:
      return {
        ...state,
        isFinishGettingAdminUrl: true,
      };

    case TOGGLE_ADMIN_BURGER_MENU:
      return {
        ...state,
        isOpenBurgerMenu: payload,
      };

    default:
      return state;
  }
};
