import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { setSelectedTypeId } from 'redux/contracts/actions';
import {ContractData, IPrintFormOptions} from 'redux/contracts/reducers/interface';
import { ArrowRecIcon } from '../../../index';
import './styles.scss';
import PopupStickyContainer from '../../../_shared/PopupStickyContainer';

const b = block('dropdown');

interface IProps {
    actions: IPrintFormOptions[];
    contract: ContractData;
}

const DropdownButton: React.FC<IProps> = ({
  actions,
  contract,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isActive, setActive] = useState<boolean>(false);

  const handleOnClick = useCallback(() => {
    setActive((state) => !state);
  }, [isActive]);

  const handleOnClose = useCallback(() => {
    setActive(false);
  }, [isActive]);

  const handleOnActionClick = (value: string, id: number) => {
    sessionStorage.setItem('selectedContractType', id.toString());
    dispatch(setSelectedTypeId(id));
    history.push(`/${value}/${contract.id}`);
  };

  return (
    <div className={b()}>
      <button
        className={b('btn')}
        onClick={handleOnClick}
      >
        {LANG_DICTIONARY.ACTIONS_WITH_CONTRACT}
        <ArrowRecIcon className={b('arrow-icon', { opened: isActive })} />
      </button>
      <PopupStickyContainer
        className={b('actions-container')}
        classNameVeil={b('actions-veil')}
        isOpen={isActive}
        close={handleOnClose}
      >
        <div className={b('actions')}>
          {actions?.map(({
            key, text, value, id,
          }) => (
            <button
              className={b('action-btn')}
              disabled={value === 'payout_end' && contract.ctsStatusName !== LANG_DICTIONARY.COMPLETED}
              onClick={() => handleOnActionClick(value, id)}
            >
              {key}
            </button>
          ))}
        </div>
      </PopupStickyContainer>
    </div>
  );
};

export default DropdownButton;
