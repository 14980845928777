import './styles.scss';
import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts/dictionary';
import { Loader } from 'semantic-ui-react';
import { IAssetModel } from 'redux/profitability/reducers/interfaces';
import ArrowProfitIcon from 'components/_svg/ArrowProfitIcon';
import ArrowLossIcon from 'components/_svg/ArrowLossIcon';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getSelectedPayment } from '../../../../../redux/profitability/selectors';

const b = block('assets-table');

interface IProps {
  data: IAssetModel[];
  barrier: number;
  getNoBarrier: () => any;
  isLoading: boolean;
  refOptionType: number;
}

const refOptionTypesWeight = [1, 2, 10, 11, 12, 16];
const refOptionTypesBarrier = [3, 7, 8, 9, 13, 14, 15, 18];

const ROUNDING_TO_ONE_DEC_PLACE = 1;

const getClassIconCouple = (
  assetPriceChange: number,
  barrier: number,
  assetPriceIncrease: number,
) => {
  let colorClass = '';
  let icon = null;

  if (assetPriceChange > 0) {
    icon = <ArrowProfitIcon className={b('arrow-icon')} />;
    colorClass = b('profit');
  } else if (assetPriceChange < 0) {
    colorClass = b('loss');
    icon = <ArrowLossIcon className={b('arrow-icon')} />;
  }

  if (assetPriceIncrease > barrier) {
    icon = <ArrowProfitIcon className={b('arrow-icon')} />;
    colorClass = b('profit');
  } else if (assetPriceIncrease < barrier) {
    colorClass = b('loss');
    icon = <ArrowLossIcon className={b('arrow-icon')} />;
  }

  return {
    colorClass,
    icon,
  };
};

const checkIsActiveWeight = (refOptionType: number) => refOptionTypesWeight
  .includes(refOptionType);

const checkIsBarrierPassed = (refOptionType: number) => refOptionTypesBarrier
  .includes(refOptionType);


const sortTicker = (a: IAssetModel, B: IAssetModel) => {
  if (a.ticker > B.ticker) {
    return 1;
  }
  return -1;
};

const AssetsTable: React.FC<IProps> = ({
  data,
  barrier,
  isLoading,
  getNoBarrier,
  refOptionType,
}) => {
  const selectedPayment = useSelector(getSelectedPayment);

  return (
    <div className={b('row')}>
      <div className={b('block')}>
        <div className={b('blocks-header')}>
          {LANG_DICTIONARY.ACTIVES}
        </div>
        {!isLoading && (
        <>
          <div className={b('current-date')}>
            {LANG_DICTIONARY.FOR_DATE(moment(selectedPayment.date).locale('ru').format('DD MMMM YYYY'))}
          </div>
          {data.find((item) => !!item)
            ? (
              <div style={{ display: 'flex' }}>
                <table className={b()}>
                  <thead>
                    <tr>
                      <th className="first" colSpan={2}>
                        {LANG_DICTIONARY.NAME}
                      </th>
                      <th colSpan={1}>
                        %&nbsp;
                        {LANG_DICTIONARY.FROM_START_PRICE}
                      </th>
                      {
                          checkIsActiveWeight(refOptionType)
                          && (
                            <th colSpan={1}>
                              {LANG_DICTIONARY.ACTIVE_WEIGHT}
                            </th>
                          )
                        }
                      {
                          checkIsBarrierPassed(refOptionType)
                          && (
                            <th colSpan={1}>
                              {LANG_DICTIONARY.BARRIER_PASSED}
                            </th>
                          )
                        }
                    </tr>
                  </thead>
                  <tbody>
                    {
                        data.sort(sortTicker).map((asset) => {
                          const classIconCouple = getClassIconCouple(asset?.assetPriceChange,
                            barrier,
                            asset?.assetPriceIncrease);
                          return (
                            <tr key={`ticker_${asset?.ticker}`}>
                              <td className="first" colSpan={2}>
                                {`${asset?.name} (${asset?.ticker})`}
                              </td>
                              <td colSpan={1} className={classIconCouple.colorClass}>
                                {`${Number(asset?.assetPriceIncrease).toFixed(ROUNDING_TO_ONE_DEC_PLACE)}%`}
                              </td>
                              {checkIsActiveWeight(refOptionType) && (
                                <td colSpan={1} className={classIconCouple.colorClass}>
                                  {`${Math.round(asset?.weight)}`}
                                </td>
                              )}
                              {checkIsBarrierPassed(refOptionType) && (
                                <td colSpan={1}>
                                  {classIconCouple.icon}
                                </td>
                              )}
                            </tr>
                          );
                        })
                      }
                  </tbody>
                </table>
                <div className={b('description')}>
                  <div className={b('border')} />
                  <div className={b('description-text')}>{getNoBarrier()}</div>
                </div>
              </div>
            )
            : (
              <h4>{LANG_DICTIONARY.NO_ASSETS}</h4>
            )}
        </>
        )}
      </div>
      {isLoading && (
        <div className={b('loader')}>
          <Loader active={isLoading} inline="centered" />
        </div>
      )}
    </div>
  );
};

export default AssetsTable;
