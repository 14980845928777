import React, { useEffect } from 'react';
import { block } from 'bem-cn';
import { useHistory, withRouter } from 'react-router-dom';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, SearchResultData } from 'semantic-ui-react';
import InsuranceContract from 'components/_client/_shared/InsuranceContract';
import WarningInfo from 'components/_client/_shared/WarningInfo';
import ApplicantsDetails from 'components/_client/_shared/ApplicantsDetails';
import BankRequisites from 'components/_client/_shared/BankRequisites';
import { AdminCheckbox } from 'components';
import {
  LANG_DICTIONARY,
  REGEXP,
  ROUTES,
} from 'consts';
import fatArrowLeft from 'assets/svg/fatArrowLeft.svg';
import exit from 'assets/svg/exit.svg';
import {
  getBanksData,
  getCheckboxesStates,
  getComment,
  getIsLoading,
  getIsPassedFirstForm,
  getSelectedContract,
} from 'redux/applications/selectors';
import {
  changeField,
  clearState,
  getUserContractIsz,
  sendReqDaData,
  setCheckboxValue,
  setPassedFirstForm,
  typeComment,
  setFoundBank,
  getBankInn,
} from 'redux/applications/actions';
import moment from 'moment';
import MainPageContainer from '../MainPage';

const b = block('paymentEnd');

const maxSymbolLength = 200;

const {
  MAKE_APPLICATION,
  PHONE_NUMBER_NOT_CHANGED,
  USE_PROVIDED_PASSPORT_DATA,
  AMOUNT_TO_REFUND,
  DECLARE_THE_CANCELLATION,
  CONTRACT_IS_TERMINATED,
  CONTRACT_IS_TERMINATED_PREMATURELY,
  APPLICATION_FOR_TERMINATION,
  SPECIFY_OVERPAYMENTS,
} = LANG_DICTIONARY;

const ApplicationForTermination = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const contract = useSelector(getSelectedContract);
  const {
    isSameData,
    isTelNumber,
  } = useSelector(getCheckboxesStates);
  const isPassedFirstForm = useSelector(getIsPassedFirstForm);
  const comment = useSelector(getComment);
  const isLoading = useSelector(getIsLoading);
  const banksData = useSelector(getBanksData);

  const selectedContractId = Number(history.location.pathname.split('/')[2]);

  useEffect(() => {
    dispatch(getUserContractIsz(selectedContractId));
    dispatch(getBankInn());
  }, []);

  const {
    id,
    productName,
    numFull,
    dateReg,
    premiumCur,
    contractTerm,
    ctsStatusName,
    symbol,
    dateCreate,
    coolOffPeriod,
    isApplicant,
    lastName,
    firstName,
    middleName,
    bic,
    curAcc,
    corAcc,
    bankName,
    inn,
    recipient,
    isPremiumCur,
  } = contract;

  const fio = {
    lastName,
    middleName,
    firstName,
  };

  const bankReq = {
    bic,
    curAcc,
    corAcc,
    bankName,
    inn,
    recipient,
  };

  const isAfterDuration = moment(dateCreate).add(coolOffPeriod, 'days').isAfter(moment())
      || moment(dateCreate).add(coolOffPeriod, 'days').isSame(moment());

  const insuranceData = {
    productName,
    numFull,
    dateReg,
    premiumCur,
    contractTerm,
    ctsStatusName,
    symbol,
  };

  const goBack = () => {
    dispatch(clearState());
    history.push(ROUTES.home);
  };

  const submitApplication = () => {
    dispatch(setPassedFirstForm(true));
    sessionStorage.setItem('curUrl', history.location.pathname);
    sessionStorage.setItem('applicationHeader', LANG_DICTIONARY.APPLICATION_FOR_TERMINATION);
    history.push(`${ROUTES.payoutEndUpload}/:${id}`, isPremiumCur ? premiumCur : null);
  };

  const goToSecondForm = () => {
    if (isPassedFirstForm && isAllFieldsField) {
      history.push(`${ROUTES.payoutEndUpload}/:${id}`, isPremiumCur ? premiumCur : null);
    }
  };

  const onClickCheckbox = (value: boolean, name: string) => {
    dispatch(setCheckboxValue(value, name));
  };

  const onChangeCommentField = (value: string) => {
    dispatch(typeComment(value));
  };

  const onChangeBankReqStrings = (value: string, name: string) => {
    dispatch(changeField(value, name));
  };

  const onChangeBankReq = (value: string, name: string) => {
    const rebuildValue = value.replace(REGEXP.NOT_DIGITS_CHECK_GREEDY, '');
    dispatch(changeField(rebuildValue, name));
  };

  const onChangeBic = (e: React.MouseEvent<HTMLElement>) => {
    const { value } = e.target as HTMLInputElement;
    const { name } = e.target as HTMLInputElement;
    onChangeBankReqStrings(value, name);
    dispatch(sendReqDaData(value));
  };

  const onResultSelect = (data: SearchResultData) => {
    const selectedItem = data.result.description;
    const foundBank = banksData.find((item: any) => item.description === selectedItem);
    const dataForSelectedBank = {
      bic: foundBank.data.bic,
      corAcc: foundBank.data.correspondent_account,
      bankName: foundBank.description,
      inn: foundBank.data.inn,
    };
    dispatch(setFoundBank(dataForSelectedBank));
  };

  const isAllFieldsField = isSameData && isTelNumber && comment
      && bic && curAcc && corAcc && bankName && inn && recipient;

  return (
    <MainPageContainer>
      <div className={b()}>
        <div className={b('loader', { disabled: !isLoading })}>
          <Loader active={isLoading} />
        </div>
        <div className={b('container')}>
          {
            !isLoading && (
            <>
              <button className={b('back-button')} onClick={goBack}><img src={exit} alt="exit" /></button>
              <button className={b('arrow-forward')} onClick={goToSecondForm}>
                <img
                  src={fatArrowLeft}
                  alt="fatArrowRight"
                  className={b('arrow', {right: true})}
                />
              </button>
              <div className={b('inner-container')}>
                <h1 className={b('header')}>{APPLICATION_FOR_TERMINATION}</h1>
                <InsuranceContract insuranceData={insuranceData} />
                <WarningInfo warningText={isAfterDuration
                  ? CONTRACT_IS_TERMINATED
                  : CONTRACT_IS_TERMINATED_PREMATURELY}
                />
                <div className={b('divider')} />
                <ApplicantsDetails isNotAdmin fullName={fio} checkboxLabel="Страхователь" isApplicant={isApplicant} />
                <div className={b('comment-block')}>
                  <p className={b('comment-label')}>{DECLARE_THE_CANCELLATION}</p>
                  <textarea
                    className={b('comment')}
                    placeholder={SPECIFY_OVERPAYMENTS}
                    maxLength={maxSymbolLength}
                    value={comment}
                    onChange={(e) => onChangeCommentField(e.target.value)}
                  />
                </div>
                {
                  isPremiumCur && (
                  <div className={b('back-sum')}>
                    <p className={b('text')}>{AMOUNT_TO_REFUND}</p>
                    <input value={`${premiumCur} ${symbol}`} className={b('field')} />
                  </div>
                  )
                }
                <BankRequisites
                  onChangeBic={onChangeBic}
                  bankReq={bankReq}
                  onChange={onChangeBankReq}
                  onChangeString={onChangeBankReqStrings}
                  banksData={banksData}
                  onResultSelect={onResultSelect}
                />
                <div className={b('checkbox')}>
                  <AdminCheckbox
                    isNotAdmin
                    name="isSameData"
                    checked={isSameData}
                    text={USE_PROVIDED_PASSPORT_DATA}
                    onChange={(e) => onClickCheckbox(!isSameData, e.target.name)}
                  />
                </div>
                <div className={b('checkbox')}>
                  <AdminCheckbox
                    isNotAdmin
                    name="isTelNumber"
                    checked={isTelNumber}
                    text={PHONE_NUMBER_NOT_CHANGED}
                    onChange={(e) => onClickCheckbox(!isTelNumber, e.target.name)}
                  />
                </div>
                <div className={b('button-container')}>
                  <button
                    className={b('button', { disabled: !isAllFieldsField })}
                    disabled={!isAllFieldsField}
                    onClick={submitApplication}
                  >
                    {MAKE_APPLICATION}
                  </button>
                </div>
              </div>
            </>
            )
          }
        </div>
      </div>
    </MainPageContainer>
  );
};

export default withRouter(ApplicationForTermination);
