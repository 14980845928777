import { ActionFactory } from 'helpers';

const factory = new ActionFactory('adminAdministrators');
const GET_ADMINISTRATORS = factory.createAsync('GET_ADMINISTRATORS');
const GET_ADMIN_CARD = factory.createAsync('GET_ADMIN_CARD');
const SAVE_CHANGES = factory.createAsync('SAVE_CHANGES');
const RESET_CHANGES = factory.create('RESET_CHANGES');
const CLEAR_FORM = factory.create('CLEAR_FORM');
const CHANGE_FIELD = factory.create('CHANGE_FIELD');
const DELETE_ADMIN = factory.create('DELETE_ADMIN');
const GET_CURRENT_CARD = factory.create('GET_CURRENT_CARD');
const SET_HAVE_CARD = factory.create('SET_HAVE_CARD');

export {
  SET_HAVE_CARD,
  DELETE_ADMIN,
  GET_ADMINISTRATORS,
  GET_ADMIN_CARD,
  SAVE_CHANGES,
  RESET_CHANGES,
  CLEAR_FORM,
  CHANGE_FIELD,
  GET_CURRENT_CARD,
};
