import { REGEXP, LANG_DICTIONARY } from 'consts';
import { object, string } from 'yup';
import { MAX_LENGTH_INPUT, MIN_LENGTH_INPUT } from 'config';

const {
  PHONEMOBILE,
  PHONE_HOME,
  CYRILLIC,
  EMAIL_CHECK,
} = REGEXP;

const {
  EMAIL_VALIDATION,
  PHONE_VALIDATION,
  INPUT_MAX_LENGTH,
  INPUT_MIN_LENGTH,
  EMAIL,
  PHONE_MOBILE,
  PASSWORD_LANGUAGE,
  ACCESS_EMAIL_SYMBOLS,
} = LANG_DICTIONARY;

const checkLogin = (check: string) => {
  if (check === EMAIL) {
    return {
      contact: string()
        .min(MIN_LENGTH_INPUT, INPUT_MIN_LENGTH)
        .max(MAX_LENGTH_INPUT, INPUT_MAX_LENGTH)
        .email(EMAIL_VALIDATION)
        .test('', PASSWORD_LANGUAGE, (value) => !value.match(CYRILLIC))
        .test('', ACCESS_EMAIL_SYMBOLS, (value) => !value.match(EMAIL_CHECK)),
    };
  } if (check === PHONE_MOBILE) {
    return {
      contact: string()
        .max(MAX_LENGTH_INPUT, INPUT_MAX_LENGTH)
        .min(MIN_LENGTH_INPUT, INPUT_MIN_LENGTH)
        .matches(PHONEMOBILE, PHONE_VALIDATION),
    };
  }
  return {
    contact: string()
      .max(MAX_LENGTH_INPUT, INPUT_MAX_LENGTH)
      .min(MIN_LENGTH_INPUT, INPUT_MIN_LENGTH)
      .matches(PHONE_HOME, PHONE_VALIDATION),
  };
};

const schema = (check: string) => object().shape({
  ...checkLogin(check),
});

export default schema;
