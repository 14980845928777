import { ResetPasswordForm } from 'components';
import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { IAuthreducer } from 'redux/authorization/reducers/interfaces';
import { IRedux } from 'redux/interface';
import {
  startResetPassword,
  verifyResetPasswordToken,
  clearStoreAfterReset,
  changePasswordReset,
} from 'redux/rootActions';
import { withUserInterface } from 'HOC';
import { ROUTES } from 'consts';

type TMapStateToPropsTypes = (reducer: IRedux) => IAuthreducer;

interface IMapDispatchToPropsTypes {
  startReset: typeof startResetPassword,
  verifyToken: typeof verifyResetPasswordToken
  clearStore: typeof clearStoreAfterReset
  resetPassword: typeof changePasswordReset
}

interface IProps {
  startReset: typeof startResetPassword,
  verifyToken: typeof verifyResetPasswordToken
  clearStore: typeof clearStoreAfterReset
  resetPassword: typeof changePasswordReset
  history: any;
  resetPasswordErrorMessage: string;
  loginType: string;
  loginForReset: string;
  passwordIsChanged: boolean;
}

const ResetPasswordContainer: React.FC<IProps> = ({
  clearStore,
  history,
  resetPasswordErrorMessage,
  startReset,
  verifyToken,
  loginType,
  loginForReset,
  resetPassword,
  passwordIsChanged,
}) => {
  const [resetToken, setToken] = useState('');
  const params = useParams();

  useEffect(() => {
    const { token } = params as any;

    if (token) {
      verifyToken(token);
      setToken(token);
    }
  }, []);

  useEffect(() => () => {
    clearStore();
  }, []);

  return (
    <ResetPasswordForm
      history={history}
      resetPasswordErrorMessage={resetPasswordErrorMessage}
      startReset={startReset}
      verifyToken={verifyToken}
      loginType={loginType}
      loginForReset={loginForReset}
      resetToken={resetToken}
      resetPassword={resetPassword}
      passwordIsChanged={passwordIsChanged}
    />
  );
};

const mapStateToProps: TMapStateToPropsTypes = ({
  authReducer,
}: IRedux) => ({
  ...authReducer,
});

const mapDispatchToProps: IMapDispatchToPropsTypes = {
  startReset: startResetPassword,
  verifyToken: verifyResetPasswordToken,
  clearStore: clearStoreAfterReset,
  resetPassword: changePasswordReset,
};

export default withUserInterface(withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ResetPasswordContainer),
));
