import { IContrAgentCard, IContractCard } from 'redux/adminUserCard/reducers/interface';
import { LANG_DICTIONARY, DATE_FORMAT } from 'consts';
import { generateFio, simpleDate, dateFormatter } from 'helpers';

const { DATE } = DATE_FORMAT;
const {
  FIO_INSURER,
  SEX,
  FIO_LATINIC,
  BIRTH_PLACE,
  BIRTH_DATE,
  DUL_WORD,
  COUNTRY_PATRIOT,
  SERIAL_PASSPORT,
  NUMBER_PASSPORT,
  GET_PASSPORT_DATE,
  DOC_AUTH,
  SUBDIVISION_CODE,
  VALID_TIME,
  ADRES_FACT,
  ADRES_REG,
  BIC_WORD,
  RS_WORDS,
  BANK_WORD,
  BANK_DATA,
  CTS_NUMBER,
  DATE_CREATE_CTS,
  PRODUCT,
  DATE_START_CTS,
  INSURANCE_AMMOUNT_CUR,
  FREQ_CONTRACT,
  PAYMENT_FREQ,
  INSURANCE_PREMIUM,
  LAST_PAYMENT_STATUS,
  DATE_END_CTS,
  RELATIONSHIP_FAMILY,
  SHARE_PROCENT,
  BENEFICIARI,
  INSURED,
} = LANG_DICTIONARY;
const getCtgts = (data: IContrAgentCard) => {
  const {
    firstName,
    lastName,
    middleName,
    latFirstName,
    latLastName,
    latMiddleName,
    birthPlaceFull,
    birthDate,
    docName,
    docCitienship,
    docSerial,
    docNumber,
    docDateIssue,
    docAuth,
    docAuthCode,
    docDateBeg,
    docDateEnd,
    adrFactFullText,
    adrRegFullText,
    contacts,
    requisites,
    sex,
  } = data;

  const getContactsCtgt = contacts.map((item) => ({
    title: item.contactTypeName,
    value: item.value,
    md: 4,
    sm: 12,
  }));
  return [
    {
      title: FIO_INSURER, value: generateFio(lastName, firstName, middleName), md: 6, sm: 12,
    },
    {
      title: SEX, value: sex, md: 2, sm: 12,
    },
    {
      title: FIO_LATINIC,
      value: generateFio(latLastName, latFirstName, latMiddleName),
      md: 4,
      sm: 12,
    },
    {
      title: BIRTH_PLACE, value: birthPlaceFull, md: 6, sm: 12,
    },
    {
      title: BIRTH_DATE, value: simpleDate(birthDate, DATE), md: 6, sm: 12,
    },
    {
      title: DUL_WORD, value: docName, md: 6, sm: 12,
    },
    {
      title: COUNTRY_PATRIOT, value: docCitienship, md: 6, sm: 12,
    },
    {
      title: SERIAL_PASSPORT, value: docSerial, md: 4, sm: 12,
    },
    {
      title: NUMBER_PASSPORT, value: docNumber, md: 4, sm: 12,
    },
    {
      title: GET_PASSPORT_DATE, value: simpleDate(docDateIssue, DATE), md: 4, sm: 12,
    },
    {
      title: DOC_AUTH, value: docAuth, md: 4, sm: 12,
    },
    {
      title: SUBDIVISION_CODE, value: docAuthCode, md: 4, sm: 12,
    },
    {
      title: VALID_TIME, value: `C: ${simpleDate(docDateBeg, DATE)} ПО: ${simpleDate(docDateEnd, DATE)}`, md: 4, sm: 12,
    },
    {
      title: ADRES_REG, value: adrRegFullText, md: 12, sm: 12,
    },
    {
      title: ADRES_FACT, value: adrFactFullText, md: 12, sm: 12,
    },
    [...getContactsCtgt],
    [
      {
        title: BANK_DATA, value: '', md: 12, sm: 12,
      },
      {
        title: BIC_WORD, value: requisites.kpp, md: 12, sm: 12,
      },
      {
        title: RS_WORDS, value: requisites.curAcc, md: 12, sm: 12,
      },
      {
        title: BANK_WORD, value: requisites.bankName, md: 12, sm: 12,
      },
    ],
  ];
};

const getContractData = (data: IContractCard) => {
  const {
    numFull,
    dateCreate,
    dateEnd,
    dateReg,
    term,
    insuranceAmmountCur,
    insureds,
    currencyName,
    paymentConditionName,
    paymentFreqName,
    premiumCur,
    beneficiaries,
    productName,
  } = data;

  const getInsureds = insureds.map((item) => ({
    title: INSURED,
    value: generateFio(item.lastName, item.firstName, item.middleName),
    md: 12,
    sm: 12,
  }));

  const getBenefeciaries = beneficiaries.map((item, i) => {
    const returnedArra = [];
    const title = {
      title: `${BENEFICIARI} ${i + 1}`,
      value: generateFio(item.lastName, item.firstName, item.middleName),
      md: 12,
      sm: 12,
    };
    returnedArra.push(title);
    const relationshipBenef = {
      title: RELATIONSHIP_FAMILY,
      value: item.realtionship,
      md: 6,
      sm: 12,
    };
    returnedArra.push(relationshipBenef);
    const sharedProcent = {
      title: SHARE_PROCENT,
      value: `${item.benPercent}%`,
      md: 6,
      sm: 12,
    };
    returnedArra.push(sharedProcent);
    return returnedArra;
  });
  return [
    {
      title: CTS_NUMBER, value: numFull, md: 6, sm: 12,
    },
    {
      title: DATE_CREATE_CTS, value: simpleDate(dateCreate, DATE), md: 6, sm: 12,
    },
    {
      title: VALID_TIME, value: dateFormatter(term), md: 6, sm: 12,
    },
    {
      title: PRODUCT, value: productName, md: 6, sm: 12,
    },
    {
      title: DATE_START_CTS, value: simpleDate(dateReg, DATE), md: 6, sm: 12,
    },
    {
      title: INSURANCE_AMMOUNT_CUR, value: insuranceAmmountCur, md: 6, sm: 12,
    },
    {
      title: FREQ_CONTRACT, value: currencyName, md: 6, sm: 12,
    },
    {
      title: PAYMENT_FREQ, value: paymentFreqName, md: 6, sm: 12,
    },
    {
      title: INSURANCE_PREMIUM, value: premiumCur, md: 6, sm: 12,
    },
    {
      title: LAST_PAYMENT_STATUS, value: paymentConditionName, md: 6, sm: 12,
    },
    {
      title: DATE_END_CTS, value: simpleDate(dateEnd, DATE), md: 12, sm: 12,
    },
    ...getInsureds,
    ...getBenefeciaries,
  ];
};

export { getCtgts, getContractData };
