import './styles.scss';
import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { generateFio } from 'helpers';
import { TransparentTable } from 'components';
import { IContragents } from 'redux/contracts/reducers/interface';

const b = block('isz-insureds-data');
const slider = block('main-slider');
const contentClass = b('content').mix(slider('content', { right: true }));
const {
  INSURED_INFORMATION,
  INSURER,
  BENEFICIARIES,
  INSUREDS,
  BENEFECIARY_BY_LAW,
} = LANG_DICTIONARY;

interface IProps {
  data: IContragents,
}

const ContrAgents: React.FC<IProps> = ({
  data: {
    insurer = [],
    insured = [],
    beneficiary = [],
  },
}) => {
  const insurerFirst = insurer[0] || '';

  const getInsurerName = () => `${generateFio(insurerFirst.lastName, insurerFirst.firstName, insurerFirst.middleName)}`;

  const beneficiaryBlock = (
    <div className={b('table')}>
      {beneficiary.map((item) => (
        [<div className={b('table-element')}>
          <div className={b('table-element-fio')}>
            {`${item.lastName} `}
            {`${item.firstName} `}
            {item.middleName}
          </div>
          <div className={b('table-element-params')}>
            <div>{item.familyRelationName}</div>
            <div>{`${item.benPercent}%`}</div>
          </div>
          <div />
        </div>,
        ]
      ))}
    </div>
  );

  const insuredsBlock = insured.map((item) => (
    [generateFio(item.lastName, item.firstName, item.middleName)]
  ));

  const haveBenefeciary = beneficiary.length;

  return (
    <div className={b.mix(slider)}>
      <div className={b('wrapper')}>
        <div className={contentClass}>
          <div className={b('header-form-text')}>{INSURED_INFORMATION}</div>
          <div className={b('insurer')}>
            <div className={b('form-title')}>
              {`${INSURER}:`}
            </div>
            <div className={b('insurer-name')}>{getInsurerName()}</div>
          </div>
          <div className={b('form-title', {second: true})}>
            {`${INSUREDS}:`}
          </div>
          <TransparentTable data={insuredsBlock} className={b('table-insurers')} />
          <div className={b('form-title', {second: true})}>
            {`${BENEFICIARIES}:`}
          </div>
          {haveBenefeciary
            ? <div>{beneficiaryBlock}</div>
            : <div className={b('beneficiary-by-low')}>{BENEFECIARY_BY_LAW}</div>}
          <div className={b('beneficiary')} />
        </div>
      </div>
    </div>
  );
};

export default ContrAgents;
