import { API } from 'consts';
import { apiCall } from 'helpers';
import { put, takeLatest } from 'redux-saga/effects';
import { GET_CLIENT_DESCRIPTIONS } from '../types';

function* getDescriptions() {
  try {
    yield put({ type: GET_CLIENT_DESCRIPTIONS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_FORM_DESCRIPTIONS,
    });
    yield put({ type: GET_CLIENT_DESCRIPTIONS.success, payload: data });
  } catch (e) {
    yield put({ type: GET_CLIENT_DESCRIPTIONS.fail });
  }
}

export default function* descriptionsSaga() {
  yield takeLatest(GET_CLIENT_DESCRIPTIONS.request, getDescriptions);
}
