const ROUTES = {
  home: '/',
  profitability: '/profitability',
  registration: '/registration',
  authorization: '/authorization',
  adminAuthorization: '/authorization',
  adminManagment: '/managment',
  adminUsers: '/users',
  adminUserCard: '/users',
  adminAdministrators: '/administrators',
  resetPassword: '/reset-password',
  resetPasswordWithToken: '/reset-password/:token',
  addEmailContactWithToken: '/add-email/:token',
  payoutEnd: '/payout_end',
  payoutEndUpload: '/payout_end/upload',
  payoutSuccess: '/payout-success',
  payoutCancel: '/cancel_contract',
};

export default ROUTES;
