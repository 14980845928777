import compose from '@shopify/react-compose';
import { withAdministrationInterface } from 'HOC';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IAdminAuthorization } from 'redux/adminAuthorization/reducers/interfaces';
import { IRedux } from 'redux/interface';
import { adminSignin, getRecaptchaAdmin } from 'redux/rootActions';
import { withFormik, FormikProps } from 'formik';
import { AdminAuthForm } from 'components';
import { formSettings, FormikValues } from './formik';

interface IDispatchToProps {
  signIn: typeof adminSignin,
  getRecaptcha: typeof getRecaptchaAdmin,
}

type TProps = IDispatchToProps &
  IAdminAuthorization &
  RouteComponentProps &
  FormikProps<FormikValues>;

const AdminAuthorization: React.FC<TProps> = ({
  values,
  handleChange,
  handleBlur,
  signIn,
  errors,
  touched,
  handleSubmit,
  errorMessage,
  getRecaptcha,
  captcha,
  captchaId,
}) => (
  <AdminAuthForm
    signIn={signIn}
    values={values}
    errors={errors}
    touched={touched}
    handleBlur={handleBlur}
    handleChange={handleChange}
    handleSubmit={handleSubmit}
    errorMessage={errorMessage}
    getRecaptcha={getRecaptcha}
    captcha={captcha}
    captchaId={captchaId}
  />
);

const mapStateToProps = ({adminAuthorization}: IRedux) => ({...adminAuthorization});
const mapDispatchToProps: IDispatchToProps = {
  signIn: adminSignin,
  getRecaptcha: getRecaptchaAdmin,
};

export default compose(
  withAdministrationInterface,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withFormik(formSettings),
)(AdminAuthorization);
