import { IGlobalSettings } from '../reducers/interface';
import {
  CHANGE_GLOBAL_SETTINGS,
  CREATE_CONNECTION_USERS_TO_CTGTS,
  GET_GLOBAL_SETTINGS,
  IMPORT_1C,
  SET_INIT_STORE_FOR_IMPORT,
  CLEAR_CONNECTION_USERS_TO_CTGTS,
  GET_LAST_IMPORT_RESULT,
  GET_PRINTED_FORMS_TYPES,
  SEND_PRINT_FORMS,
} from '../types';

export const import1c = (file: File | null) => ({
  type: IMPORT_1C.request,
  payload: file,
});

export const createConnectionUsersToCtgts = () => ({
  type: CREATE_CONNECTION_USERS_TO_CTGTS.request,
});

export const clearConnectionUsersToCtgts = () => ({
  type: CLEAR_CONNECTION_USERS_TO_CTGTS.request,
});

export const setInitStoreForImport = () => ({
  type: SET_INIT_STORE_FOR_IMPORT,
});

export const changeGlobalSettings = (payload: IGlobalSettings) => ({
  type: CHANGE_GLOBAL_SETTINGS.request,
  payload,
});

export const getGlobalSettings = () => ({
  type: GET_GLOBAL_SETTINGS.request,
});

export const getLastImportStatus = () => ({
  type: GET_LAST_IMPORT_RESULT.request,
});

export const getPrintedFormsTypes = () => ({
  type: GET_PRINTED_FORMS_TYPES.request,
});

export const sendPrintForm = (formData: FormData) => ({
  type: SEND_PRINT_FORMS.request,
  payload: formData,
});
