import './styles.scss';

import React from 'react';
import { block } from 'bem-cn';
import schemaIcon from 'assets/images/schema-icon.png';
import shieldIcon from 'assets/images/shield-icon.png';
import { LANG_DICTIONARY, DATE_FORMAT, ROUTES } from 'consts';
import {
  rebuildCurrency,
  dateFormatter,
  getGlobalHistory,
  monthFormatter,
} from 'helpers';
import {
  TransparentTable,
  ContractStatus,
  LogoForm,
  WhiteButton,
} from 'components';
import { ContractData, IContractData } from 'redux/contracts/reducers/interface';
import moment from 'moment';
import { Icon, Popup } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';

const b = block('isz-main-data');
const slider = block('main-slider');
const contentClass = b('content').mix(slider('content', { right: true }));
const {
  YOUR_CONTRACT_INVESTMENT_INSURANCE,
  YOUR_CONTRACT_FUNDED_INSURANCE,
  NUMBER_CONTRACT,
  BEGIN_DATE_CONTRACT,
  TERM_CONTRACT,
  FREQ_CONTRACT,
  PAYMENT_FREQ,
  INSURANCE_AMMOUNT_CUR,
  PRODUCT,
  INSURANCE_PREMIUM,
  FROM,
  OVERALL_RESULT_OF_THE_CONTRACT,
  PAYMENT,
  ADDITIONAL_INCOME,
  CUMULATIVE_AMOUNT,
  SURVIVAL_SUM_INSURED,
  CONTRACT_DESCRIPTION,
  INVESTMENT_DATE,
  ACCUMULATION_RESULT,
} = LANG_DICTIONARY;

interface IProps {
  data: IContractData,
  contract: ContractData,
  history: RouteComponentProps['history'],
  isIsz: boolean,
}

const MainIszData: React.FC<IProps> = ({
  data: {
    contract: {
      numFull,
      dateReg,
      currencyName,
      paymentFreqName,
      insuranceAmmountCur,
      productName,
      ctsStatusName,
      premiumCur,
      dateCreate,
      id,
      totalIncome,
      symbol,
      dateInvest,
      additionalIncomeSum,
      accumulatedSum,
      contractTerm: {
        years,
        months,
      },
      totalIncomeForAll,
    },
    isIsz,
  },
}) => {
  const dateBegin = dateReg && moment(dateReg).format(DATE_FORMAT.DATE);
  const dateFrom = dateCreate
    && `${numFull} ${FROM.toLowerCase()} ${moment(dateCreate).format(DATE_FORMAT.DATE)}`;
  const investDate = dateInvest && moment(dateInvest).format(DATE_FORMAT.DATE);
  const termContract = `${years ? dateFormatter(Number(years)) : ''} ${months ? monthFormatter(Number(months)) : ''}`;
  const iszData = [
    [NUMBER_CONTRACT, dateFrom],
    [BEGIN_DATE_CONTRACT, dateBegin],
    ...(investDate ? [[INVESTMENT_DATE, investDate]] : []),
    [TERM_CONTRACT, termContract],
    [FREQ_CONTRACT, currencyName],
    [PAYMENT_FREQ, paymentFreqName],
    [INSURANCE_PREMIUM, rebuildCurrency(String(premiumCur))],
    [INSURANCE_AMMOUNT_CUR, rebuildCurrency(String(insuranceAmmountCur))],
    [PRODUCT, productName],
    [OVERALL_RESULT_OF_THE_CONTRACT, totalIncomeForAll
      ? rebuildCurrency(String(totalIncomeForAll.toFixed(2)))
      : 0],
  ];

  const nszData = [
    [NUMBER_CONTRACT, dateFrom],
    [BEGIN_DATE_CONTRACT, dateBegin],
    [TERM_CONTRACT, termContract],
    [PAYMENT_FREQ, paymentFreqName],
    [SURVIVAL_SUM_INSURED, `${rebuildCurrency(insuranceAmmountCur.toFixed(2))} ${symbol}`],
    [PRODUCT, productName],
  ];

  const NszDataSavings = () => (
    <div className={b('additional-table')}>
      <div className={b('payments')}>
        <div>{PAYMENT}</div>
        <div>{`${rebuildCurrency(String(totalIncome))} ${symbol}`}</div>
      </div>
      <div className={b('additional')}>
        <div className={b('additional-income')}>
          <div className={b('additional-income-name')}>{ADDITIONAL_INCOME}</div>
          <Popup
            popperModifiers={{
              preventOverflow: {
                boundariesElement: 'offsetParent',
              },
            }}
            className={b('popup')}
            trigger={<Icon style={{cursor: 'pointer'}} name="question circle outline" />}
            content={<div style={{width: '400px'}}>{CONTRACT_DESCRIPTION}</div>}
            on="click"
            pinned
            size="large"
          />
        </div>
        <div>{`${rebuildCurrency(String(additionalIncomeSum))} ${symbol}`}</div>
      </div>
      <div className={b('cumulative-amount')}>
        <div>{CUMULATIVE_AMOUNT}</div>
        <div>{`${rebuildCurrency(String(accumulatedSum))} ${symbol}`}</div>
      </div>
    </div>
  );

  const goToProfitability = () => {
    getGlobalHistory().push(`${ROUTES.profitability}/${id}`);
  };

  return (
    <div className={b.mix(slider)}>
      <div className={b('wrapper')}>
        <div className={contentClass}>
          {
            isIsz
              ? (
                <LogoForm
                  image={schemaIcon}
                  title={YOUR_CONTRACT_INVESTMENT_INSURANCE}
                  className={b('logo')}
                />
              )
              : (
                <LogoForm
                  image={shieldIcon}
                  title={YOUR_CONTRACT_FUNDED_INSURANCE}
                  className={b('logo')}
                />
              )
          }
          <ContractStatus
            status={ctsStatusName}
            className={b('status')}
          />
          {
            isIsz
              ? (
                <>
                  <TransparentTable data={iszData} className={b('table')} />
                  <div className={b('button-container')}>
                    <WhiteButton
                      text={LANG_DICTIONARY.PROFITABILITY_BUTTON_TEXT}
                      onClick={goToProfitability}
                    />
                  </div>
                </>
              ) : (
                <>
                  <TransparentTable data={nszData} className={b('table')} />
                  <div className={b('saving')}>{ACCUMULATION_RESULT}</div>
                  <div className={b('profitability')}>
                    <NszDataSavings />
                  </div>
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default MainIszData;
