import compose from '@shopify/react-compose';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IRedux } from 'redux/interface';
import { getUserContractsIsz, getPrintedFormsTypes } from 'redux/rootActions';
import { IData, IPrintFormOptions } from 'redux/contracts/reducers/interface';
import { MyIszBlock } from 'components';

interface IProps {
  getContracts: typeof getUserContractsIsz,
  isz: IData[],
  isIsz: boolean,
  isLoading: boolean,
  getActions: typeof getPrintedFormsTypes,
  printFormsOptions: IPrintFormOptions[],
}

const MyIszData: React.FC<IProps> = ({
  getContracts,
  isz,
  isIsz,
  isLoading,
  getActions,
  printFormsOptions,
}) => {
  useEffect(() => {
    getContracts();
    getActions();
  }, [getContracts]);

  return (
    <MyIszBlock
      printFormsOptions={printFormsOptions}
      isIsz={isIsz}
      isz={isz}
      isLoading={isLoading}
    />
  );
};

const mapStateToProps = ({
  contracts: {
    isz, isIsz, isLoading, printFormsOptions,
  },
}: IRedux) => (
  {
    isz, isIsz, isLoading, printFormsOptions,
  }
);

const mapDispatchToProps = {
  getActions: getPrintedFormsTypes,
  getContracts: getUserContractsIsz,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(MyIszData);
