import {
  CLEAR_CONNECTION_USERS_TO_CTGTS,
  CREATE_CONNECTION_USERS_TO_CTGTS,
  IMPORT_1C, SET_INIT_STORE_FOR_IMPORT,
  CHANGE_GLOBAL_SETTINGS,
  GET_LAST_IMPORT_RESULT,
  GET_PRINTED_FORMS_TYPES,
} from '../types';

import { IAdminManagmetReducer } from './interface';

const INITIAL_STATE: IAdminManagmetReducer = {
  isProcessImport: false,
  isProcessCreatingConnection: false,
  isProcessCleaningConnection: false,
  fileName: '',
  statusImport: false,
  isFinishImport: false,
  errors: [],
  isService: false,
  isRegistration: false,
  isAuthorization: false,
  printFormsOptions: [],
};

export default (state = INITIAL_STATE, { type, payload }: {type: string, payload: any}) => {
  switch (type) {
    case IMPORT_1C.start:
      return {
        ...state,
        isProcessImport: true,
      };

    case IMPORT_1C.fail: {
      const { fileName, error } = payload;

      return {
        ...state,
        isProcessImport: false,
        isFinishImport: true,
        errors: [error],
        fileName,
      };
    }

    case GET_LAST_IMPORT_RESULT.success: {
      const { fileName, errors } = payload;
      const statusImport = !errors.length;

      return {
        ...state,
        statusImport,
        fileName,
        errors,
        isFinishImport: true,
      };
    }

    case IMPORT_1C.success: {
      const { fileName, errors } = payload;
      // const statusImport = !errors.length;

      return {
        ...state,
        // statusImport, сделано для того, чтобы не дожидаясь
        // импорта выводилось сообщение об успешном окончании
        statusImport: true,
        fileName,
        errors: [payload.errors],
        isProcessImport: false,
        isFinishImport: true,
      };
    }

    case SET_INIT_STORE_FOR_IMPORT:
      return {
        ...state,
        statusImport: false,
        fileName: '',
        isProcessImport: false,
        isFinishImport: false,
      };

    case CREATE_CONNECTION_USERS_TO_CTGTS.start:
      return {
        ...state,
        isProcessCreatingConnection: true,
      };

    case CREATE_CONNECTION_USERS_TO_CTGTS.success:
      return {
        ...state,
        isProcessCreatingConnection: false,
      };

    case CHANGE_GLOBAL_SETTINGS.success:
      return {
        ...state,
        ...payload,
      };

    case CLEAR_CONNECTION_USERS_TO_CTGTS.start:
      return {
        ...state,
        isProcessCleaningConnection: true,
      };

    case CLEAR_CONNECTION_USERS_TO_CTGTS.success:
      return {
        ...state,
        isProcessCleaningConnection: false,
      };

    case CLEAR_CONNECTION_USERS_TO_CTGTS.fail:
      return {
        ...state,
        isProcessCleaningConnection: false,
      };

    case GET_PRINTED_FORMS_TYPES.success:
      return {
        ...state,
        printFormsOptions: payload,
      };

    default:
      return state;
  }
};
