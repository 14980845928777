import './styles.scss';

import { block } from 'bem-cn';
import { AdminBurgerMenu, AdminFooterPanel, AdminHeadPanel } from 'components';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { adminSignOut, toggleAdminBurgerMenu } from 'redux/rootActions';

const b = block('admin-page-wrapper');

export default (Component) => {
  const AdminInterface = ({
    ip,
    login,
    fullName,
    token,
    isOpenBurgerMenu,
    toggleMenu,
    actions,
    history,
    adminUrl,
    signOut,
  }) => (
    <div className={b()}>
      {token && (
        <AdminBurgerMenu
          isOpen={isOpenBurgerMenu}
          toggleMenu={toggleMenu}
          actions={actions}
          history={history}
          adminUrl={adminUrl}
          signOut={signOut}
        />
      )}
      <AdminHeadPanel
        isOpen={isOpenBurgerMenu}
        toggleBurgerMenu={toggleMenu}
        login={login}
        fullName={fullName}
        token={token}
      />
      <div className={b('main')}>
        <Component />
      </div>
      <AdminFooterPanel ip={ip} />
    </div>
  );

  const mapStateToProps = ({
    common: {
      ip,
      isOpenBurgerMenu,
      adminUrl,
    },
    adminAuthorization: {
      fullName,
      login,
      token,
      actions,
    },
  }) => ({
    adminUrl,
    isOpenBurgerMenu,
    ip,
    fullName,
    login,
    token,
    actions,
  });

  const mapDispatchToProps = {
    toggleMenu: toggleAdminBurgerMenu,
    signOut: adminSignOut,
  };

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminInterface));
};
