import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import block from 'bem-cn';

const b = block('info-popup');

interface IProps {
  description: string
}

const InfoPopUp: React.FC<IProps> = ({description}) => (
  <Popup
    content={(
      <div className={b('popup')}>
        <div className={b('popup-border')} />
        <div className={b('popup-text')}>
          {description}
        </div>
      </div>
    )}
    on="click"
    hideOnScroll
    trigger={(
      <Icon
        style={{cursor: 'pointer', width: '40px'}}
        name="question circle outline"
      />
    )}
    position="top right"
  />
);

export default InfoPopUp;
