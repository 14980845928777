import {
  GET_IP,
  GET_ADMIN_URL,
  TOGGLE_ADMIN_BURGER_MENU,
} from '../types';

const getIp = () => ({
  type: GET_IP.request,
});

const getAdminUrl = () => ({
  type: GET_ADMIN_URL.request,
});

const toggleAdminBurgerMenu = (state: boolean) => ({
  type: TOGGLE_ADMIN_BURGER_MENU,
  payload: state,
});

export {
  getIp,
  getAdminUrl,
  toggleAdminBurgerMenu,
};
