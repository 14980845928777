import { LANG_DICTIONARY, ROUTES } from 'consts';
import {
  AdminAuthorization,
  AdminManagment,
  AdminUsers,
  AdminUserCard,
  AdminCard,
  RootAdminSection,
} from 'containers';
import { IRoute } from 'router/interfaces/route';

const {
  AUTHORIZATION,
  MANAGMENT_LK,
  USERS,
  USER_CARD,
  ADMINS_WORD,
} = LANG_DICTIONARY;

const getAdminRoutes = (adminUrl: string): IRoute[] => (adminUrl ? [
  {
    component: AdminAuthorization,
    path: `${adminUrl}${ROUTES.adminAuthorization}`,
    title: AUTHORIZATION,
    type: 'public',
  },
  {
    component: AdminManagment,
    path: `${adminUrl}${ROUTES.adminManagment}`,
    title: MANAGMENT_LK,
    type: 'admin',
  },
  {
    component: AdminUsers,
    path: `${adminUrl}${ROUTES.adminUsers}`,
    title: USERS,
    type: 'admin',
  },
  {
    component: AdminUserCard,
    path: `${adminUrl}${ROUTES.adminUserCard}/:id`,
    title: USER_CARD,
    type: 'admin',
  },
  {
    component: AdminCard,
    path: `${adminUrl}${ROUTES.adminAdministrators}`,
    title: ADMINS_WORD,
    type: 'admin',
  },
  {
    component: RootAdminSection,
    path: `${adminUrl}`,
    title: AUTHORIZATION,
    type: 'admin',
  },
] : []);

export default getAdminRoutes;
