import { block } from 'bem-cn';
import { GreenButton } from 'components';
import { LANG_DICTIONARY } from 'consts';
import React from 'react';
import errorIcon from 'assets/svg/errorIcon.svg';
import './styles.scss';

const b = block('stage-four-form');
const { ERROR_WORD, BACK_WORD } = LANG_DICTIONARY;
interface IProps {
  errorMessage: string;
  handleBack: () => void;
}
const StageFour: React.FC<IProps> = ({errorMessage, handleBack}) => (
  <div className={b()}>
    <div className={b('error')}>
      <div className={b('error-header')}>
        <div>{ERROR_WORD}</div>
        <div className={b('error-text')}>{errorMessage}</div>
      </div>
      <img src={errorIcon} className={b('error-icon')} alt="errorIcon" />
    </div>
    <div className={b('control-btn')}>
      <GreenButton text={BACK_WORD} onClick={handleBack} />
    </div>
  </div>
);

export default StageFour;
