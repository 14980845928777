import './styles.scss';

import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import {
  AdminMainButton,
  AdminCheckbox, InfoBlock,
} from 'components';
import React from 'react';
import { IPersonalCard } from 'redux/adminUserCard/reducers/interface';

const {
  STATUS,
  ACTIVE_WORD,
  BLOCKING_WORD,
  DATA_CACHING,
  TIME_CACHE,
  DATE_IMPORT,
  CACHE_IS_OLD,
  DATE_BLOCK,
  DATE_UNLOCK,
  LAST_IP,
  UNBAN_WORD,
  BLOCKED_STATUS,
  PERNAMENT_CACHE,
  TEMP_BAN,
  UNLOCK_TEMP_BAN,
  PASSWORD_CHANGED_DATE,
  HASH_PASSWORD,
  NEW_PASSWORD,
  SET_PASSWORD_IF_CHANGE,
} = LANG_DICTIONARY;

const b = block('user-information');

interface IProps {
  userPersonalCard: IPersonalCard;
  handleChangeValue: (name: string, value: string | boolean) => void;
  handleChangeCanUpdatePassword: () => void;
  validatePassword: (value: string) => void;
}

const UserInformation: React.FC<IProps> = ({
  userPersonalCard: {
    isLocked,
    userPassword,
    passwordChangeDate,
    dataDateImport,
    dataCachePermanent,
    dateEndBan,
    dateLock,
    dateUnlock,
    ip,
    hash,
    errorPassword,
  },
  handleChangeValue,
  validatePassword,
}) => (
  <div className={b()}>
    <div className={b('first-row')}>
      <p className={b('text-wrapper')}>{`${STATUS}: ${isLocked ? BLOCKED_STATUS : ACTIVE_WORD}`}</p>
      <AdminMainButton
        text={isLocked ? UNBAN_WORD : BLOCKING_WORD}
        onClick={() => handleChangeValue('isLocked', !isLocked)}
        className={b('ban-button')}
      />
    </div>
    <div className={b('prop-content')}>
      <p className={b('label-text')}>{`${HASH_PASSWORD}:`}</p>
      <p className={b('prop-text')}>{hash}</p>
    </div>
    <div className={b('prop-content')}>
      <p className={b('label-text')}>{`${PASSWORD_CHANGED_DATE}:`}</p>
      <p className={b('prop-text')}>{passwordChangeDate}</p>
    </div>
    <div className={b('prop-content')}>
      <p className={b('label-text')}>{`${NEW_PASSWORD}:`}</p>
      <div className={b('password-row')}>
        <input
          className={b('password-input')}
          name="userPassword"
          value={userPassword}
          onChange={({target: { value, name }}) => handleChangeValue(name, value)}
          onBlur={({target: { value, name }}) => validatePassword(value)}
          placeholder={SET_PASSWORD_IF_CHANGE}
        />
        <InfoBlock
          message={errorPassword}
          error
          isShow={Boolean(userPassword)}
        />
      </div>
    </div>
    <div className={b('third-row')}>
      <div className={b('caching-title')}>
        <p className={b('text-wrapper')}>{DATA_CACHING}</p>
        <p className={b('text-wrapper')}>{`${TIME_CACHE}:`}</p>
      </div>
      <div className={b('cache-data')}>
        <p className={b('text-wrapper')}>{`${DATE_IMPORT}: ${dataDateImport}`}</p>
        <div className={b('pernament-cache')}>
          {`${PERNAMENT_CACHE}:`}
          <AdminCheckbox
            checked={dataCachePermanent}
            disabled
            name="dataCachePermanent"
          />
        </div>
        <p className={b('text-wrapper', {lastChild: true})}>{`${CACHE_IS_OLD}:`}</p>
      </div>
    </div>
    <div className={b('four-row')}>
      <div className={b('lock-block')}>
        <div className={b('lock-block-row')}>
          <p className={b('text-wrapper', {controlBlockFirst: true})}>{`${DATE_BLOCK}: ${dateLock}`}</p>
          <p className={b('text-wrapper', {controlBlockFirst: true})}>{`${TEMP_BAN}: ${dateEndBan}`}</p>
        </div>
        <div className={b('lock-block-row')}>
          <p className={b('text-wrapper', {controlBlock: true})}>{`${DATE_UNLOCK}: ${dateUnlock}`}</p>
          <p className={b('text-wrapper', {controlBlock: true})}>{`${LAST_IP}: ${ip || ''}`}</p>
        </div>
      </div>
      <AdminMainButton
        text={UNLOCK_TEMP_BAN}
        name="tempBan"
        onClick={({target: {name}}) => handleChangeValue(name, false)}
        className={b('ban-button')}
      />
    </div>
  </div>
);

export default UserInformation;
