import * as _ from 'lodash';
import uuid from 'uuid/v1';

export interface IIValueWithKey {
  value: any,
  key: string,
}

export const generateKeys = (arr: any[]): IIValueWithKey[] => arr.map((item) => {
  const isArray = _.isArray(item);

  if (isArray) {
    return {
      value: generateKeys(item),
      key: uuid(),
    };
  }

  return {
    value: item,
    key: uuid(),
  };
});
