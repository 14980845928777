import './styles.scss';

import { block } from 'bem-cn';
import { AdminPopupContainer } from 'components';
import React from 'react';
import { LANG_DICTIONARY } from 'consts';

interface IProps {
  name: string,
  isOpen: boolean,
  close: (...args: any[]) => any,
}

const b = block('success-import-popup');
const { ATTENTION, IMPORT_FILE, COMPLETE_FINISH } = LANG_DICTIONARY;

const SuccessImportPopup: React.FC<IProps> = ({
  name,
  isOpen,
  close,
}) => (
  <AdminPopupContainer
    close={close}
    isOpen={isOpen}
    isCloseButton
  >
    <div className={b()}>
      <p className={b('title')}>{`${ATTENTION}!`}</p>
      <p className={b('text')}>
        {`${IMPORT_FILE} `}
        <b>{name}</b>
        {` ${COMPLETE_FINISH.toLowerCase()}`}
      </p>
    </div>
  </AdminPopupContainer>
);

export default SuccessImportPopup;
