import './styles.scss';
import phone from 'assets/svg/phone.svg';
import { block } from 'bem-cn';
import { MAIN_BCS_LIFE, SUPPORT_NUMBER } from 'config';
import { LANG_DICTIONARY } from 'consts';
import { BcsLogoSvg } from 'components';
import React from 'react';
import { logOut } from 'redux/rootActions';

const b = block('header-app');

const { EXIT } = LANG_DICTIONARY;

interface IMapDispatchToPropsTypes {
  exit: typeof logOut;
}

interface IProps {
  token: string;
}

const goToBcsOnline = () => {
  document.location.href = MAIN_BCS_LIFE;
};

type Props = IMapDispatchToPropsTypes & IProps;

const HeadPanel: React.FC<Props> = ({token, exit}) => (
  <div className={b()}>
    <div className={b('left-side')}>
      <div className={b('logo')} onClick={goToBcsOnline} role="button" tabIndex={-1}>
        <BcsLogoSvg />
      </div>
      <div className={b('contact-info')}>
        <img src={phone} alt="phone" className={b('phone-icon')} />
        <a href="tel:88005005021" className={b('phone-call')}>{SUPPORT_NUMBER}</a>
      </div>
    </div>
    {token
      && (
      <div className={b('exit')} onClick={() => exit()} role="button" tabIndex={-1}>
        {EXIT}
      </div>
      )}
  </div>
);

export default HeadPanel;
