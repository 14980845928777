import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import { Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';
import './styles.scss';

const b = block('captcha-block');
const { WRITE_CAPTCHA, DONT_SEE_CODE } = LANG_DICTIONARY;

interface IProps {
  captcha: string,
  handleRecaptcha: () => void
}

const CaptchaBlock: React.FC<IProps> = ({
  captcha,
  handleRecaptcha,
}) => (
  <div className={b()}>
    <p className={b('title')}>{WRITE_CAPTCHA}</p>
    <div className={b('content')}>
      <div className={b('image')}>
        <div dangerouslySetInnerHTML={{__html: captcha}} />
        <div
          onClick={handleRecaptcha}
          role="button"
          tabIndex={-1}
        >
          <div className={b('button-content')}>
            <p className={b('repeat-text')}>{DONT_SEE_CODE}</p>
            <Icon name="repeat" className={b('icon').toString()} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CaptchaBlock;
