const formatDate = (n: number, text: string) => `${n} ${text}`;

const declOfNum = (n: number, titles: any) => {
  const {
    few,
    one,
    two,
  } = titles;
  if (n % 10 === 1 && n % 100 !== 11) return formatDate(n, one);
  if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) return formatDate(n, two);
  return formatDate(n, few);
};

const declOfMonth = (n: number, titles: any) => {
  const {
    few,
    one,
    two,
  } = titles;
  if (n % 12 === 1) return formatDate(n, one);
  if (n % 12 > 1 && n % 12 < 5) return formatDate(n, two);
  return formatDate(n, few);
};

const dateFormatter = (n: number) => declOfNum(n, {
  few: 'лет',
  one: 'год',
  two: 'года',
});

const monthFormatter = (n: number) => declOfMonth(n, {
  few: 'месяцев',
  one: 'месяц',
  two: 'месяца',
});

const couponFormatter = (n: number) => declOfNum(n, {
  few: 'купонов',
  one: 'купон',
  two: 'купона',
});

export {
  declOfNum,
  dateFormatter,
  monthFormatter,
  couponFormatter,
};
