import { TOKEN_TYPE } from 'consts';
import axios from 'axios';
import { API_BASE_URL } from 'config';
import { toastr } from 'react-redux-toastr';
import { call } from 'redux-saga/effects';

const getToken = (isAdminToken: boolean) => {
  const tokenKey = isAdminToken ? TOKEN_TYPE.admin : TOKEN_TYPE.client;
  return `Bearer ${localStorage.getItem(tokenKey)}`;
};

const fetchJSON = (type: any) => (url: string, body: any, options: any) => axios({
  data: body,
  method: type,
  url,
  ...options,
});

const apiCall = ({
  type,
  body,
  headers,
  url,
  withoutToken,
  isBlob,
  query,
  host = API_BASE_URL,
  isAdminToken = false,
  ...rest
}: {
    type: string,
    body?: object,
    headers?: object,
    url: string,
    withoutToken?: boolean,
    isBlob?: boolean,
    query?: object,
    host?: string,
    isAdminToken?: boolean,
  }) => {
  const allHeaders = {
    'Content-Type': 'application/json',
    ...(!withoutToken && { Authorization: getToken(isAdminToken) }),
    ...(headers && headers),
  };
  const options = {
    headers: allHeaders,
    method: type,
    params: query,
    ...(isBlob && { responseType: 'blob' }),
    ...rest,
  };

  let URL = `${host}${url}`;
  const version = Number(new Date());
  if (type === 'GET') {
    URL = `${host}${url}?_ver=${version}`;
  }
  return call(fetchJSON(type), URL, body, options);
};

const getErrorText = (data: any, mute: any, validMessage?: string) => {
  try {
    const { message: { RU, EN, INFO_MESSAGE } } = data;
    const topMessage = validMessage && EN === 'Validation error' ? validMessage : RU;
    toastr.error('', topMessage);
    return INFO_MESSAGE || RU;
  } catch (error) {
    return null;
  }
};

const getError = (
  e: any,
  isAdmin?: boolean,
  errorIndex?: any,
  mute?: any,
  validMessage?: string,
) => {
  const data = e.response && e.response[errorIndex || 'data'];
  const isBlob = data instanceof Blob;
  if (isBlob) {
    const reader: any = new FileReader();
    reader.onload = () => {
      const res = JSON.parse(reader.result);
      return getErrorText(res, mute, validMessage);
    };
    reader.readAsText(data);
  } else {
    return getErrorText(data, mute, validMessage);
  }
  return null;
};

const getFileNameByHeaders = (name: string) => {
  const searchValue = 'filename="';
  const start = name.indexOf(searchValue) + searchValue.length;
  const end = name.length - 1;
  return name.slice(start, end);
};

const downloadFile = (response: any, fileName: string) => {
  const name = fileName || getFileNameByHeaders(response.headers['content-disposition']);
  if (!window.navigator.msSaveOrOpenBlob) {
    const newUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = newUrl;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  } else {
    window.navigator.msSaveOrOpenBlob(new Blob([response.data]), name);
  }
};

export { apiCall, getError, downloadFile };
