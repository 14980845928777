import { block } from 'bem-cn';
import { AdminSectionTitle, AdminMainButton } from 'components';
import { LANG_DICTIONARY } from 'consts';
import React from 'react';
import { clearConnectionUsersToCtgts, createConnectionUsersToCtgts } from 'redux/rootActions';

interface IProps {
  isProcessCleaningConnection: boolean,
  isProcessCreatingConnection: boolean,
  createConnection: typeof createConnectionUsersToCtgts,
  clearConnection: typeof clearConnectionUsersToCtgts,
}

const b = block('managment-admin-form');

const {
  CONNECTION_USERS_AND_LK,
  CLEAR_CONNECTION_CTGTS_AND_USERS,
  CREATE_CONNECTION_CTGTS_AND_USERS,
  RUN,
} = LANG_DICTIONARY;

const ConnectionUsers: React.FC<IProps> = ({
  isProcessCleaningConnection,
  clearConnection,
  isProcessCreatingConnection,
  createConnection,
}) => (
  <>
    <AdminSectionTitle title={CONNECTION_USERS_AND_LK} />
    <section className={b('section')}>
      <p className={b('section-title')}>
        {CLEAR_CONNECTION_CTGTS_AND_USERS}
      </p>
      <AdminMainButton
        className={b('button')}
        disabled={isProcessCleaningConnection}
        isProcess={isProcessCleaningConnection}
        text={RUN}
        onClick={clearConnection}
      />
    </section>
    <section className={b('section')}>
      <p className={b('section-title')}>
        {CREATE_CONNECTION_CTGTS_AND_USERS}
      </p>
      <AdminMainButton
        className={b('button')}
        text={RUN}
        onClick={createConnection}
        isProcess={isProcessCreatingConnection}
        disabled={isProcessCreatingConnection}
      />
    </section>
  </>
);

export default ConnectionUsers;
