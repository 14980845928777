
import { block } from 'bem-cn';
import { PopupContainer } from 'components';
import { LANG_DICTIONARY, REGEXP, ROUTES } from 'consts';
import { simpleDate, gerErrorFromSchema, getPasswordErrors } from 'helpers';
import { MAIN_BCS_LIFE, REGISTRATIONG_STAGES } from 'config';
import React from 'react';
import { IRegistartionReducer } from 'redux/registration/reducers/interfaces';
import { RouteComponentProps } from 'react-router-dom';
import schema from './schema';
import StageFinal from './StageFinal';
import StageFour from './StageFour';
import StageOne from './StageOne';
import StageSecond from './StageSecond';
import StageThird from './StageThird';
import './styles.scss';

const b = block('registration-form');

const { home } = ROUTES;

const {
  PHONE_MASK,
} = REGEXP;

const {
  CHECK_LOGIN,
} = LANG_DICTIONARY;

interface IProps {
  initialStore: () => void;
  fieldChange: (name: string, value: any) => void;
  sendFirstForm: (dateSign: string, login: string, ctsNumber: string) => void;
  sendSecondForm: (
      usersRegsId: number,
      firstName: string,
      lastName: string,
      middleName: string,
      passportNumber: string,
      passportSerial: string,
  ) => void;
  sendThirdForm: (
      usersRegsId: number,
      ctgtsFlId: number,
      password: string,
      passwordConfirmation: string,
      dateSign: string,
      ctsNumber: string,
  ) => void;
  getAccountInfo: () => void;
  getContractInfo: () => void;
  getPersonalInfo: () => void;
  history: RouteComponentProps['history'];
  changeRegLogin: (isPhone: boolean) => void;
  clearRegLogin: () => void;
}

type Props = IProps & IRegistartionReducer;

interface IState {
  errorLogin: string;
  errorPassportData: string;
  errorPassword: string;
  open: boolean;
  errorField: string;
}

class RegiStrationForm extends React.Component <Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorLogin: '',
      errorPassportData: '',
      errorPassword: '',
      open: true,
      errorField: '',
    };
  }


  public handleClosePopUp = () => {
    document.location.href = MAIN_BCS_LIFE;
  }

  public goToBCSOnline = () => {
    const { redirectLink } = this.props;
    document.location.href = redirectLink;
  }

  public componentWillUnmount = () => {
    const { initialStore } = this.props;
    initialStore();
  }

  public componentDidMount = () => {
    const { getAccountInfo, getContractInfo, getPersonalInfo } = this.props;
    getAccountInfo();
    getContractInfo();
    getPersonalInfo();
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      fieldChange,
    } = this.props;
    const { name } = e.target;
    const { value } = e.target;
    fieldChange(name, value);
  }

  public handleChangeDate = (date: Date) => {
    const { fieldChange } = this.props;
    fieldChange('dateSign', date);
  }

  public sendDataFirst = () => {
    const {
      sendFirstForm,
      login,
      dateSign,
      ctsNumber,
      isPhone,
    } = this.props;
    const date = simpleDate(dateSign);
    try {
      schema(false, false, isPhone).validateSync({
        login,
      });
      sendFirstForm(date, login.replace(PHONE_MASK, ''), ctsNumber);
    } catch (e) {
      this.setState({
        errorLogin: CHECK_LOGIN,
      });
    }
  }

  public sendDataSecond = () => {
    const {
      usersRegsId,
      firstName,
      lastName,
      middleName,
      passportSerial,
      passportNumber,
      sendSecondForm,
    } = this.props;

    try {
      schema(true, false, true).validateSync({
        firstName,
        lastName,
        middleName,
        passportNumber: passportNumber.replace(/\D/, ''),
        passportSerial: passportSerial.replace(/\D/, ''),
      });
      sendSecondForm(
        usersRegsId,
        firstName,
        lastName,
        middleName,
        passportSerial,
        passportNumber,
      );
    } catch (e) {
      const error = gerErrorFromSchema(e);
      this.setState({
        errorPassportData: error,
      });
    }
  }

  public sendDataThird = () => {
    const {
      usersRegsId,
      ctgtsFlId,
      password,
      passwordConfirm,
      dateSign,
      ctsNumber,
      sendThirdForm,
      login,
    } = this.props;

    const loginCheckForRegilar = login.split('');
    const phoneMobileLogin = loginCheckForRegilar.slice();
    phoneMobileLogin.shift();
    const goodLogin = loginCheckForRegilar[0] === '+' ? phoneMobileLogin.join('') : login;
    try {
      schema(false, true, true, password, goodLogin).validateSync({
        password,
        passwordConfirm,
      });
      const date = simpleDate(dateSign);
      sendThirdForm(
        usersRegsId,
        ctgtsFlId,
        password,
        passwordConfirm,
        date,
        ctsNumber,
      );
      this.setState({
        errorPassword: '',
      });
    } catch (e) {
      const { error, path } = getPasswordErrors(e);
      this.setState({
        errorPassword: error,
        errorField: path,
      });
    }
  }

  public handleBack = () => {
    const { fieldChange, prevStage} = this.props;
    fieldChange('activeStage', prevStage);
  }

  public getStage = () => {
    const {
      activeStage,
      login,
      dateSign,
      ctsNumber,
      infoContract,
      errorMessage,
      firstName,
      lastName,
      middleName,
      passportSerial,
      passportNumber,
      infoPersonal,
      password,
      passwordConfirm,
      infoAccount,
      infoMessage,
      history,
      isPhone,
      changeRegLogin,
      clearRegLogin,
    } = this.props;

    const {
      errorLogin,
      errorPassword,
      errorPassportData,
      errorField,
    } = this.state;

    const {
      FIRST,
      SECOND,
      THIRD,
      FOUR,
      FINAL,
      SIX,
    } = REGISTRATIONG_STAGES;

    switch (activeStage) {
      case FIRST: {
        return (
          <StageOne
            login={login}
            dateSign={dateSign}
            ctsNumber={ctsNumber}
            handleChange={this.handleChange}
            sendData={this.sendDataFirst}
            info={infoContract}
            handleChangeDate={this.handleChangeDate}
            errorLogin={errorLogin}
          />
        );
      }

      case SECOND: {
        return (
          <StageSecond
            firstName={firstName}
            lastName={lastName}
            middleName={middleName}
            passportSerial={passportSerial}
            passportNumber={passportNumber}
            handleChange={this.handleChange}
            info={infoPersonal}
            sendDataSecond={this.sendDataSecond}
            errorPassportData={errorPassportData}
          />
        );
      }

      case THIRD: {
        return (
          <StageThird
            password={password}
            passwordConfirm={passwordConfirm}
            sendData={this.sendDataThird}
            info={infoAccount}
            handleChange={this.handleChange}
            errorPassword={errorPassword}
            errorField={errorField}
          />
        );
      }

      case FOUR: {
        return (
          <StageFour
            errorMessage={errorMessage}
            handleBack={this.handleBack}
          />
        );
      }

      case FINAL: {
        return (
          <StageFinal
            infoMessage={infoMessage}
            onClick={this.goToBCSOnline}
          />
        );
      }

      case SIX: {
        history.push(home);
        return null;
      }

      default: {
        return null;
      }
    }
  }

  public render() {
    const { open } = this.state;
    return (
      <div className={b()}>
        <PopupContainer
          isOpen={open}
          children={this.getStage()}
          close={this.handleClosePopUp}
          isCloseButton
        />
      </div>
    );
  }
}

export default RegiStrationForm;
