import React, {useEffect} from 'react';
import { block } from 'bem-cn';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import {
  getSelectedContractTypeId,
  getBankReqs,
  getCheckboxesStates,
  getNumFull,
  getPassportPhoto,
  getUserPhone,
  getUserId,
  getPicErrors,
  getComment,
  getSmsCode,
  getIsValid,
  getUserEmail,
} from 'redux/rootSelectors';
import {
  clearState,
  sendApplication,
  setCheckboxValue,
  saveFormData,
  deleteImage,
  setPicErr,
  sendReqForSmsCode,
  changeField,
  checkCodeIsValid,
} from 'redux/applications/actions';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import WarningInfo from 'components/_client/_shared/WarningInfo';
import { AdminCheckbox } from 'components';
import fatArrowLeft from 'assets/svg/fatArrowLeft.svg';
import exit from 'assets/svg/exit.svg';
import PhotoUploadDesc from 'components/_client/_shared/PhotoUploadDesc';
import SmsBlock from 'components/_client/_shared/SmsBlock';
import { AGREEMENT_BCS } from 'config';
import { getSelectedContract } from 'redux/applications/selectors';
import MainPageContainer from '../MainPage';

const b = block('paymentUploads');

const maxFileSize = 5000000;
const codeLifetime = 240;

const {
  ATTACH_PHOTO_PAGE,
  ATTACH_REG_PAGE,
  ATTACH_FILES,
  SMS_TEMPLATE_MESSAGE,
  WARNING_PASSPORT_PAGE_VISIBLE,
} = LANG_DICTIONARY;

const ApplicationPaymentUploads = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const checkboxesStates = useSelector(getCheckboxesStates);
  const mobilePhone = useSelector(getUserPhone);
  const bankReqs = useSelector(getBankReqs);
  const userId = useSelector(getUserId);
  const contractNumFull = useSelector(getNumFull);
  const passportPhoto = useSelector(getPassportPhoto);
  const picErrors = useSelector(getPicErrors);
  const comment = useSelector(getComment);
  const phone = useSelector(getUserPhone);
  const code = useSelector(getSmsCode);
  const isValid = useSelector(getIsValid);
  const mail = useSelector(getUserEmail);
  const selectedContract = useSelector(getSelectedContract);

  const insuranceAmountCur = history.location.state || 0;

  const prevUrl = sessionStorage.getItem('curUrl');
  const header = sessionStorage.getItem('applicationHeader');
  const selectedTypeId = Number(sessionStorage.getItem('selectedContractType'));

  const {
    photoErr,
    regErr,
  } = picErrors;

  const {
    photo,
    reg,
  } = passportPhoto;

  const { isAgree, isSmsSent } = checkboxesStates;

  const goBack = () => {
    dispatch(clearState());
    history.push(ROUTES.home);
  };

  const goToFirstForm = () => {
    history.goBack();
  };

  const submitApplication = () => {
    dispatch(checkCodeIsValid(code, phone, userId, selectedTypeId, contractNumFull));
  };

  useEffect(() => {
    if (Object.keys(selectedContract).length === 0) {
      history.push(`${prevUrl}`);
    }
  });

  useEffect(() => {
    if (isValid) {
      const formData = new FormData();
      formData.append('photo', photo);
      formData.append('reg', reg);
      Object.entries(bankReqs).map(
        (item) => formData.append(item[0], item[1]),
      );
      formData.append('contractNumFull', contractNumFull);
      formData.append('selectedTypeId', selectedTypeId?.toString());
      formData.append('comment', comment);
      if (insuranceAmountCur) {
        formData.append('insuranceAmmountCur', insuranceAmountCur?.toString());
      }
      formData.append('mail', mail);
      formData.append('phone', phone);
      dispatch(sendApplication({userId, formData}));
      history.push(ROUTES.payoutSuccess);
    }
  }, [isValid]);

  const onClickCheckbox = (value: boolean, name: string) => {
    dispatch(setCheckboxValue(value, name));
  };

  const sendSms = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { name } = e.target as HTMLButtonElement;
    const body = {
      message: SMS_TEMPLATE_MESSAGE,
      phone,
      ttl: codeLifetime,
    };

    dispatch(setCheckboxValue(true, name));
    dispatch(sendReqForSmsCode(body));
  };
  const setScanPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files, name } = e.target as HTMLInputElement;
    if (files && files[0].size < maxFileSize) {
      dispatch(setPicErr(`${name}Err`, false));
      return dispatch(saveFormData(name, files[0]));
    }
    return dispatch(setPicErr(`${name}Err`, true));
  };
  const delImage = (name: string) => {
    (document.getElementById(name) as HTMLInputElement).value = '';
    dispatch(deleteImage(name));
  };

  const typeCode = (name: string, value: string) => {
    dispatch(changeField(name, value));
  };

  const agreementText = () => (
    <div>
      Даю
      {' '}
      <a
        target="_blank"
        rel="noreferrer noopener"
        href={AGREEMENT_BCS}
      >
        согласие
      </a>
      {' '}
      на обработку персональных данных
    </div>
  );

  const isAllFieldsFilled = isAgree && photo && reg && Boolean(phone);

  return (
    <MainPageContainer>
      <div className={b()}>
        <div className={b('container')}>
          <button className={b('arrow-back')} onClick={goToFirstForm}>
            <img
              src={fatArrowLeft}
              alt="fatArrowLeft"
              className={b('arrow', { left: true })}
            />
          </button>
          <button className={b('back-button')} onClick={goBack}><img src={exit} alt="exit" /></button>
          <div className={b('inner-container')}>
            <h1 className={b('header')}>{header}</h1>
            <p className={b('upload-txt')}>
              {ATTACH_FILES}
            </p>
            <PhotoUploadDesc
              passportPhoto={passportPhoto}
              passportPhotoFile={passportPhoto?.photo}
              setScanPhoto={setScanPhoto}
              delImage={delImage}
              name="photo"
              desc={ATTACH_PHOTO_PAGE}
              isPicErr={photoErr}
            />
            <PhotoUploadDesc
              passportPhoto={passportPhoto}
              passportPhotoFile={passportPhoto?.reg}
              setScanPhoto={setScanPhoto}
              delImage={delImage}
              name="reg"
              desc={ATTACH_REG_PAGE}
              isPicErr={regErr}
            />
            <WarningInfo warningText={WARNING_PASSPORT_PAGE_VISIBLE} />
            <div className={b('checkbox-container')}>
              <AdminCheckbox
                isNotAdmin
                name="isAgree"
                checked={isAgree}
                text={agreementText()}
                onChange={(e) => onClickCheckbox(!isAgree, e.target.name)}
              />
            </div>
            <SmsBlock
              isSmsSent={isSmsSent}
              isAllFieldsFilled={isAllFieldsFilled}
              submitApplication={submitApplication}
              sendSms={sendSms}
              mobilePhone={mobilePhone}
              typeCode={typeCode}
              code={code}
              isValid={isValid}
              setCheckboxValue={setCheckboxValue}
            />
          </div>
        </div>
      </div>
    </MainPageContainer>
  );
};

export default withRouter(ApplicationPaymentUploads);
