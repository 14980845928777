import { IRedux } from 'redux/interface';

const getUserEmail = ({ mainDataReducer: { email }}: IRedux) => email;

const getUserPhone = (
  { mainDataReducer }: IRedux,
) => (mainDataReducer.mobile);

export {
  getUserEmail,
  getUserPhone,
};
