import { ActionFactory } from 'helpers';

const factory = new ActionFactory('authorization');

export const SET_STORE_TOKEN = factory.create('SET_STORE_TOKEN');
export const LOG_OUT = factory.create('LOG_OUT');
export const CLEAR_STORE_AUTH = factory.create('CLEAR_STORE_AUTH');
export const CHECK_USER = factory.createAsync('CHECK_USER');
export const SIGN_IN = factory.createAsync('SIGN_IN');
export const REQUEST_RESET_PASSWORD = factory.createAsync('REQUEST_RESET_PASSWORD');
export const VERIFY_RESET_PASSWORD_TOKEN = factory.createAsync('VERIFY_RESET_PASSWORD_TOKEN');
export const RESET_PASSWORD_CHANGE_PASSWORD = factory.createAsync('RESET_PASSWORD_CHANGE_PASSWORD');
export const ADD_PHONE_LOGIN_VERIFY = factory.createAsync('ADD_PHONE_LOGIN_VERIFY');
export const ADD_PHONE_LOGIN = factory.createAsync('ADD_PHONE_LOGIN');
export const CLEAR_RESET_PASSWORD_STORE = factory.create('CLEAR_RESET_PASSWORD_STORE');
export const GET_CAPTCHA = factory.createAsync('GET_CAPTCHA');
export const GET_RECAPTCHA = factory.createAsync('GET_RECAPTCHA');
export const CHANGE_LOGIN = 'registration/CHANGE_LOGIN';
export const ACCEPT_RULES = factory.createAsync('ACCEPT_RULES');
export const SET_IS_ACCEPTED_AGREEMENT = 'mainData/SET_IS_ACCEPTED_AGREEMENT';
