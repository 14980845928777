// @ts-nocheck
import { updateArray } from 'helpers';
import { IPayloadAction } from 'interfaces/payloadAction';
import {
  GET_PAYMENTS,
  GET_REDEMPTION,
  GET_RISKS,
  GET_STRATEGY,
  GET_USERS_CONTRACTS_ISZ,
  GET_CONTRAGENTS,
  GET_ASSETS,
  GET_INVSTMENT_INCOME,
  GET_ADDITIONAL_SERVICES,
  SET_CONTRACT_TYPE,
  GET_USERS_CONTRACTS_NSZ,
  GET_EARNED,
  SET_SELECTED_TYPE_ID,
} from '../types';
import { IContracts, IData } from './interface';
import { GET_PRINTED_FORMS_TYPES } from '../../adminManagment/types';

const INITIAL_STATE: IContracts = {
  isIsz: true,
  isz: [],
  nsz: [],
  isLoading: true,
};
const INITIAL_ISZ_DATA = {
  risks: {},
  redemptions: [],
  payments: [],
  strategy: {},
  assets: [],
  services: [],
  contragents: {
    insurer: [],
    insured: [],
    beneficiary: [],
  },
  investmentIncome: [],
};

const INITIAL_NSZ_DATA = {
  risks: {},
  redemptions: [],
  payments: [],
  strategy: {},
  assets: [],
  services: [],
  contragents: {
    insurer: [],
    insured: [],
    beneficiary: [],
  },
  investmentIncome: [],
};

export default (state = INITIAL_STATE, { type, payload }: IPayloadAction) => {
  switch (type) {
    case GET_USERS_CONTRACTS_ISZ.success: {
      const { isz } = payload;
      const rebuildDataIsz = isz.map((contract: IData) => ({
        contract,
        ...INITIAL_ISZ_DATA,
      }));

      return {
        ...state,
        isz: rebuildDataIsz,
        isLoading: false,
      };
    }

    case GET_USERS_CONTRACTS_ISZ.start: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_USERS_CONTRACTS_ISZ.fail: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case GET_USERS_CONTRACTS_NSZ.success: {
      const { nsz } = payload;
      const rebuildDataNsz = nsz.map((contract: IData) => ({
        contract,
        ...INITIAL_NSZ_DATA,
      }));

      return {
        ...state,
        nsz: rebuildDataNsz,
        isLoading: false,
      };
    }

    case GET_USERS_CONTRACTS_NSZ.start: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_USERS_CONTRACTS_NSZ.fail: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case GET_RISKS.success: {
      const { data, index, contractType } = payload;
      const list = updateArray(state[contractType], index, {
        ...state[contractType][index],
        risks: data,
      });

      return {
        ...state,
        [contractType]: list,
      };
    }

    case GET_EARNED.success: {
      const { data, contractType, index } = payload;
      const list = updateArray(state[contractType], index, {
        ...state[contractType][index],
        contract: { ...state[contractType][index].contract, ...data },
      });

      return {
        ...state,
        [contractType]: list,
      };
    }

    case GET_ADDITIONAL_SERVICES.success: {
      const { data, index, contractType } = payload;
      const list = updateArray(state[contractType], index, {
        ...state[contractType][index],
        services: data,
      });

      return {
        ...state,
        [contractType]: list,
      };
    }

    case GET_REDEMPTION.success: {
      const { data, index, contractType } = payload;
      const list = updateArray(state[contractType], index, {
        ...state[contractType][index],
        redemptions: data,
      });

      return {
        ...state,
        [contractType]: list,
      };
    }

    case GET_PAYMENTS.success: {
      const { data, index, contractType } = payload;
      const list = updateArray(state[contractType], index, {
        ...state[contractType][index],
        payments: data,
      });

      return {
        ...state,
        [contractType]: list,
      };
    }

    case GET_STRATEGY.success: {
      const { data, index, contractType } = payload;
      const list = updateArray(state[contractType], index, {
        ...state[contractType][index],
        strategy: data,
      });

      return {
        ...state,
        [contractType]: list,
      };
    }

    case GET_CONTRAGENTS.success: {
      const { data, index, contractType } = payload;
      const list = updateArray(state[contractType], index, {
        ...state[contractType][index],
        contragents: data,
      });

      return {
        ...state,
        [contractType]: list,
      };
    }

    case GET_ASSETS.success: {
      const { data, index, contractType } = payload;
      const list = updateArray(state[contractType], index, {
        ...state[contractType][index],
        assets: data,
      });

      return {
        ...state,
        [contractType]: list,
      };
    }

    case GET_INVSTMENT_INCOME.success: {
      const { data, index, contractType } = payload;
      const list = updateArray(state[contractType], index, {
        ...state[contractType][index],
        investmentIncome: data,
      });

      return {
        ...state,
        [contractType]: list,
      };
    }

    case SET_CONTRACT_TYPE: {
      return {
        ...state,
        isIsz: payload,
      };
    }

    case SET_SELECTED_TYPE_ID: {
      return {
        ...state,
        selectedContractTypeId: payload,
      };
    }

    case GET_PRINTED_FORMS_TYPES.success:
      return {
        ...state,
        printFormsOptions: payload,
      };

    default:
      return state;
  }
};
