import React from 'react';
import { RowProduct } from 'components';
import { Row, Col } from 'reactstrap';
import { combineRows, generateKeys } from 'helpers';
import { block } from 'bem-cn';
import './styles.scss';

const b = block('info-table');

const InfoTable = (props: any) => {
  const { data } = props;
  const formatData = combineRows(data);
  const rebuildFormatData = generateKeys(formatData);
  return (
    <Row className={b()}>
      {rebuildFormatData.map(({value: item, key: index}) => {
        if (item.header) {
          return (
            <Col {...item} key={`header${index}`}>
              <p className={b('header-block')}>{item.header}</p>
            </Col>
          );
        }
        if (Array.isArray(item)) {
          const rebuildIncludedArray = generateKeys(item);
          return (

            rebuildIncludedArray.map(({value: {value: subItem, key: subIndex}}) => (
              <Col
                {...subItem}
                className="info-table__custom-row"
                key={`${subIndex}subswithch`}
              >
                <RowProduct
                  {...subItem}
                  key={`${index}${subIndex}includeSwitch`}
                  title={`${subItem.title}:`}
                />
              </Col>

            ))

          );
        }
        return (
          <React.Fragment key={`${index}${item.title}`}>
            <Col {...item} key={item.title} className="info-table__custom-row">
              {item.title && <RowProduct {...item} title={`${item.title}:`} />}
            </Col>
          </React.Fragment>
        );
      })}
    </Row>
  );
};

export default InfoTable;
