import './styles.scss';

import { block } from 'bem-cn';
import { AdminWarningIcon, AdminPopupContainer } from 'components';
import { LANG_DICTIONARY } from 'consts';
import React from 'react';

interface IProps {
  isOpen: boolean,
  close: (...args: any[]) => any,
  errors: string[],
  name: string,
}

const b = block('error-import-popup');
const { ATTENTION, OCCURRED_ERRORS, ON_IMPORT_FILE } = LANG_DICTIONARY;
const errorIconSettings = { width: 20, height: 20, fill: 'red' };

const ErrorImportPopup: React.FC<IProps> = ({
  errors,
  isOpen,
  name,
  close,
}) => (
  <AdminPopupContainer
    close={close}
    isOpen={isOpen}
    isCloseButton
  >
    <div className={b()}>
      <p className={b('title')}>{`${ATTENTION}!`}</p>
      <p className={b('text')}>
        {`${ON_IMPORT_FILE} `}
        <b>{name}</b>
        {` ${OCCURRED_ERRORS.toLowerCase()}:`}
      </p>
      <div className={b('error-window')}>
        {errors.map((error) => (
          <div
            className={b('error')}
            key={error}
          >
            <AdminWarningIcon {...errorIconSettings} />
            <p className={b('error-text')}>{error}</p>
          </div>

        ))}
      </div>
    </div>
  </AdminPopupContainer>
);

export default ErrorImportPopup;
