import React, { useEffect } from 'react';
import classNames from 'classnames';
import {
  Accordion,
  Icon,
  Loader,
  Popup,
} from 'semantic-ui-react';
import block from 'bem-cn';
import { IInvestPeriodModel } from 'redux/profitability/reducers/interfaces';
import { dateFormatter, monthFormatter } from 'helpers';
import { LANG_DICTIONARY } from 'consts';
import { useDispatch, useSelector } from 'react-redux';
import AttentionPopUp from '../../../_popups/AttentionPopUp';
import PeriodSelect from '../Period';
import {
  getProfitabilityStrategy,
} from '../../../../../redux/profitability/actions';
import { getIsStrategyLoading, getSelectedStrategy } from '../../../../../redux/profitability/selectors';

const b = block('profitability');

interface ITitleProps {
    index: number;
}

interface IProps {
    activeIndex: number,
    index: number,
    number: number,
    isAutocalled: boolean,
    name: string,
    years: number,
    months: number,
    description: string,
    handleClick: (e : React.MouseEvent, titleProps: ITitleProps) => void,
    isDisabledStrategy: boolean,
    item: IInvestPeriodModel,
}

const ACCORDION_BORDER_COLOR = '#D0D1D3';

const AccordionTitleOpen: React.FC<IProps> = (props) => {
  const {
    activeIndex,
    index,
    number,
    isAutocalled,
    name,
    years,
    isDisabledStrategy,
    months,
    description,
    handleClick,
    item,
  } = props;

  const dispatch = useDispatch();

  const selectedStrategy = useSelector(getSelectedStrategy);
  const isStrategyLoading = useSelector(getIsStrategyLoading);

  useEffect(() => {
    dispatch(getProfitabilityStrategy(item.id));
  }, []);

  return (
    <Accordion.Title
      active={!isDisabledStrategy && (activeIndex === index)}
      index={index}
      style={{borderRadius: '5px', border: `1px solid ${ACCORDION_BORDER_COLOR}`, background: 'white'}}
    >
      <div className={b('accordion')}>
        <div
          className={classNames({
            [b('accordion-strategy-open')]: true,
            [b('accordion-strategy')]: true,
          })}
        >
          {`${LANG_DICTIONARY.STRATEGY_NUMBER}${number}`}
        </div>
        {isAutocalled && <div className={b('accordion-strategy-open')}><AttentionPopUp /></div>}
        <div className={b('accordion-strategy-info')}>
          <div className={b('accordion-strategy-info-content')}>
            <div>
              <span className={b('info-content-head')}>{`${LANG_DICTIONARY.STRATEGY_TYPE}: `}</span>
              <span>{name}</span>
            </div>
            <div>
              <span className={b('info-content-head')}>{`${LANG_DICTIONARY.STRATEGY_TERM}: `}</span>
              <span>
                {years
                  ? dateFormatter(Number(years))
                  : <div />}
                {months
                  ? (
                    <div>
                      {monthFormatter(Number(months))}
                    </div>
                  )
                  : <div />}
              </span>
            </div>
            <div>
              {`${LANG_DICTIONARY.HOW_IT_WORK} `}
              <Popup
                content={(
                  <>
                    <div className={b('popup')}>
                      <div className={b('popup-question-icon')}>
                        ?
                      </div>
                      <div className={b('popup-border')} />
                      <div className={b('popup-text')}>
                        {description}
                      </div>
                    </div>
                  </>
                )}
                on="click"
                hideOnScroll
                trigger={<Icon style={{ cursor: 'pointer' }} name="question circle outline" />}
                position="top right"
              />
            </div>
          </div>
          <Icon size="large" onClick={handleClick} name="chevron down" />
        </div>
      </div>
      {
        isStrategyLoading
          ? (
            <div className={b('strategy_loader')}>
              <Loader inline="centered" active={isStrategyLoading} />
            </div>
          )
          : (
            <div className={b('block')}>
              {selectedStrategy.id && (
                <PeriodSelect
                  strategy={selectedStrategy}
                />
              )}
            </div>
          )
      }

    </Accordion.Title>
  );
};

export default AccordionTitleOpen;
