import React from 'react';
import block from 'bem-cn';
import {LANG_DICTIONARY} from 'consts';

const b = block('profitability');

interface IProps {
    goBack: () => void;
    errorMessage: string;
}

const ProfitabilityError: React.FC<IProps> = (props) => {
  const {
    goBack,
    errorMessage,
  } = props;

  return (
    <div className={b('main-block')}>
      <button className={b('back-button', { error: true })} onClick={goBack}>{LANG_DICTIONARY.BACK_WORD}</button>
      <div className={b('block', { error: true })}>
        <h1 className={b('page-title')}>{errorMessage}</h1>
      </div>
    </div>
  );
};

export default ProfitabilityError;
