import { ActionFactory } from 'helpers';

const factory = new ActionFactory('adminAuthorization');
const CHECK_ADMIN = factory.createAsync('CHECK_ADMIN');
const SIGN_IN = factory.createAsync('SIGN_IN');
const GET_CAPTCHA_ADMIN = factory.createAsync('GET_CAPTCHA_ADMIN');
const GET_RECAPTCHA_ADMIN = factory.createAsync('GET_RECAPTCHA_ADMIN');
const SIGN_OUT = factory.create('SIGN_OUT');

export {
  SIGN_OUT,
  SIGN_IN,
  CHECK_ADMIN,
  GET_RECAPTCHA_ADMIN,
  GET_CAPTCHA_ADMIN,
};
