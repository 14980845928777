import {
  ADMIN_SECTIONS,
  API,
  LANG_DICTIONARY,
  ROUTES,
} from 'consts';
import { apiCall, getError, getGlobalHistory } from 'helpers';
import {
  put, select, takeLatest,
} from 'redux-saga/effects';
import { getAdminUrlSelector } from 'redux/rootSelectors';
import {toastr} from 'react-redux-toastr';
import {
  CHECK_ADMIN,
  SIGN_IN,
  GET_RECAPTCHA_ADMIN,
  GET_CAPTCHA_ADMIN,
} from '../types';
import { IAdminSigninPayload } from './interfaces';

const { NO_ROLES_ADMIN } = LANG_DICTIONARY;

function* checkRoles(data: any, isRedirectFirst: boolean) {
  const adminUrl = yield select(getAdminUrlSelector);
  if (!data.actions.length) {
    toastr.error('', NO_ROLES_ADMIN);
    getGlobalHistory().push(`${adminUrl}${ROUTES.adminAuthorization}`);
    return;
  }
  const sortActions = data.actions.sort((a: string, b: string) => (b < a ? 1 : -1));
  const section = ADMIN_SECTIONS.find((item: any) => item.role === sortActions[0]);

  if (section && isRedirectFirst) {
    getGlobalHistory().push(`${adminUrl}${section.path}`);
    return;
  }

  if (isRedirectFirst) {
    toastr.error('', NO_ROLES_ADMIN);
    getGlobalHistory().push(`${adminUrl}${ROUTES.adminAuthorization}`);
  }
}

function* recaptcha({payload: {captchaId: id}}: { payload: { captchaId: string } }) {
  try {
    yield put({ type: GET_CAPTCHA_ADMIN.start });
    const { data: captchaId } = yield apiCall({
      type: 'POST',
      url: API.RECAPTCHA,
      body: {
        captchaId: id,
      },
    });
    yield getCaptcha({payload: { captchaId }});
  } catch (e) {
    yield put({ type: GET_CAPTCHA_ADMIN.fail, payload: getError(e)});
  }
}

function* getCaptcha({payload: { captchaId }}: { payload: { captchaId: string } }) {
  const { CAPTCHA } = API;
  try {
    yield put({ type: GET_CAPTCHA_ADMIN.start });
    const { data: { captcha } } = yield apiCall({
      type: 'POST',
      url: CAPTCHA,
      body: {
        captchaId,
      },
    });
    yield put({ type: GET_CAPTCHA_ADMIN.success, payload: { captcha, captchaId } });
  } catch (e) {
    yield put({ type: GET_CAPTCHA_ADMIN.fail, payload: getError(e) });
  }
}

function* signinAdmin({ payload }: { payload: IAdminSigninPayload }) {
  try {
    const {
      login,
      password,
      captchaId,
      captchaKey,
    } = payload;

    yield put({ type: SIGN_IN.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.SIGN_IN_ADMIN,
      body: {
        email: login,
        password,
        ...(captchaKey ? { captchaId, captchaKey } : {}),
      },
    });
    yield put({ type: SIGN_IN.success, payload: { token: payload, ...data }});
    yield checkRoles(data, true);
  } catch (e) {
    const { response: { data: { message: { captchaId: id }}}} = e;
    yield put({ type: SIGN_IN.fail, payload: getError(e) });
    if (id) {
      yield getCaptcha({ payload: { captchaId: id }});
    }
  }
}

function* checkAdmin({ payload }: { payload: string }) {
  try {
    yield put({ type: CHECK_ADMIN.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.CHECK_ADMIN,
      isAdminToken: true,
    });
    yield put({ type: CHECK_ADMIN.success, payload: { token: payload, ...data }});
    yield checkRoles(data, false);
  } catch (e) {
    const adminUrl = yield select(getAdminUrlSelector);
    getGlobalHistory().push(`${adminUrl}${ROUTES.adminAuthorization}`);
    yield put({ type: SIGN_IN.fail, payload: getError(e) });
  }
}

export default function* authorizationSaga() {
  yield takeLatest<string, any>(CHECK_ADMIN.request, checkAdmin);
  yield takeLatest<string, any>(SIGN_IN.request, signinAdmin);
  yield takeLatest<string, any>(GET_RECAPTCHA_ADMIN.request, recaptcha);
  yield takeLatest<string, any>(GET_CAPTCHA_ADMIN.request, getCaptcha);
}
