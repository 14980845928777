import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withUserInterface } from 'HOC';
import { IRedux } from '../../../redux/interface';
import { IProfitabilityReducer } from '../../../redux/profitability/reducers/interfaces';
import {
  clearProfitabilityPage,
  getProfitability,
  getProfitabilityReport,
  getProfitabilityContractInfo,
  getProfitabilityStrategies,
  getProfitabilityStrategy,
} from '../../../redux/rootActions';
import MainPageContainer from '../MainPage';
import ProfitabilityInfo from '../../../components/_client/_shared/Profitability';

interface IState {
}

type IMapStateToPropsTypes = (state: IRedux) => IProfitabilityReducer;

interface IMapDispatchToPropsTypes {
  loadProfitabilityData: typeof getProfitability;
  loadProfitabilityContractInfo: typeof getProfitabilityContractInfo;
  loadProfitabilityStrategies: typeof getProfitabilityStrategies;
  loadProfitabilityStrategy: typeof getProfitabilityStrategy;
  getReport: typeof getProfitabilityReport;
  clearPage: typeof clearProfitabilityPage;
}

interface IRouteParams {
  contractNumber: string;
}

type TProps = IMapDispatchToPropsTypes & IProfitabilityReducer & RouteComponentProps<IRouteParams>;

class ProfitabilityPageContainer extends React.Component<TProps, IState> {
  componentDidMount() {
    const {
      loadProfitabilityContractInfo,
      loadProfitabilityStrategies,
      match: { params: { contractNumber } },
    } = this.props;
    loadProfitabilityContractInfo(contractNumber);
    loadProfitabilityStrategies(contractNumber);
  }

  componentWillUnmount() {
    const { clearPage } = this.props;
    clearPage();
  }

  goBack = () => {
    const {
      history,
    } = this.props;
    history.goBack();
  }

  render() {
    const {
      profitabilityData,
      getReport,
      errorMessage,
      isLoading,
      isStrategyLoading,
      isPaymentsLoading,
      isPaymentInfoLoading,
      isLoadingReport,
      loadProfitabilityStrategy,
      match: { params: { contractNumber } },
    } = this.props;

    return (
      <MainPageContainer>
        <ProfitabilityInfo
          loadProfitabilityStrategy={loadProfitabilityStrategy}
          contract={profitabilityData.contract}
          data={profitabilityData}
          getReport={getReport}
          errorMessage={errorMessage}
          goBack={this.goBack}
          isLoading={isLoading}
          isStrategyLoading={isStrategyLoading}
          isPaymentInfoLoading={isPaymentInfoLoading}
          isPaymentsLoading={isPaymentsLoading}
          isLoadingReport={isLoadingReport}
          contractNumber={contractNumber}
          selectedStrategy={profitabilityData.selectedStrategy}
          selectedPeriod={profitabilityData.selectedPeriod}
          selectedPayment={profitabilityData.selectedPayment}
        />
      </MainPageContainer>
    );
  }
}

const mapStateToProps: IMapStateToPropsTypes = ({ profitability }) => ({
  ...profitability,
});

const mapDispatchToProps: IMapDispatchToPropsTypes = {
  loadProfitabilityContractInfo: getProfitabilityContractInfo,
  loadProfitabilityStrategies: getProfitabilityStrategies,
  loadProfitabilityStrategy: getProfitabilityStrategy,
  loadProfitabilityData: getProfitability,
  getReport: getProfitabilityReport,
  clearPage: clearProfitabilityPage,
};

export default withUserInterface(withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfitabilityPageContainer),
));
