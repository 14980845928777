import compose from '@shopify/react-compose';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IRedux } from 'redux/interface';
import { getUserContractsNsz } from 'redux/rootActions';
import { IData, IPrintFormOptions } from 'redux/contracts/reducers/interface';
import { MyNszBlock } from 'components';

interface IProps {
    getContracts: typeof getUserContractsNsz,
    nsz: IData[],
    isIsz: boolean,
    isLoading: boolean,
    printFormsOptions: IPrintFormOptions[],
}

const MyNszData: React.FC<IProps> = ({
  getContracts,
  nsz,
  isIsz,
  isLoading,
  printFormsOptions,
}) => {
  useEffect(() => {
    getContracts();
  }, [getContracts]);

  return (
    <MyNszBlock
      isIsz={isIsz}
      nsz={nsz}
      isLoading={isLoading}
      printFormsOptions={printFormsOptions}
    />
  );
};

const mapStateToProps = ({
  contracts: {
    nsz, isIsz, isLoading, printFormsOptions,
  },
}: IRedux) => (
  {
    nsz, isIsz, isLoading, printFormsOptions,
  });

const mapDispatchToProps = {
  getContracts: getUserContractsNsz,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(MyNszData);
