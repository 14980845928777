import './styles.scss';

import { block } from 'bem-cn';
import { LANG_DICTIONARY, DATE_FORMAT } from 'consts';
import { AdminMainButton, InfoBlock } from 'components';
import { simpleDate } from 'helpers';
import React from 'react';
import { IAdminPersonalCard } from 'redux/adminAdministrators/reducers/interfaces';
import { AdminCheckbox } from 'components/_administration';

const b = block('admin-personal-card');

const {
  STATUS,
  ACTIVED_WORD,
  BANNED_WORD,
  UNBAN_WORD,
  BAN_WORD,
  PASSWORD_CHANGED_DATE,
  ROLE_WORD,
  CREATED,
  HASH_PASSWORD,
  SET_PASSWORD_IF_CHANGE,
  NEW_PASSWORD,
} = LANG_DICTIONARY;

interface IProps {
  handleChangeValue: (name: string, value: any) => void;
  personalCard: IAdminPersonalCard
  handleChangeCheckbox: (id: number | string, roleId: number) => void;
  canUpdatePassword: boolean;
  checkDisabled: (id: number | string) => boolean;
  checkboxPassword: (id: number | string) => void;
  checkPasswordValidation: (password: string) => void;
}

const PersonalCard: React.FC<IProps> = ({
  handleChangeCheckbox,
  handleChangeValue,
  personalCard,
  checkDisabled,
  checkPasswordValidation,
}) => {
  const dateCreate = simpleDate(
    personalCard.dateCreate,
    DATE_FORMAT.DATE_WITH_HOURS_MINUTES_SECOND,
  );
  const changePasswordDate = simpleDate(personalCard.passwordChangeDate,
    DATE_FORMAT.DATE_WITH_HOURS_MINUTES_SECOND);

  const getBanStatus = personalCard.status ? BANNED_WORD : ACTIVED_WORD;

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('header-block')}>
          <p className={b('header-block-title')}>{`${STATUS}:`}</p>
          <p className={b('text-wrapper')}>{getBanStatus}</p>
        </div>
        <div className={b('header-block')}>
          <p className={b('header-block-title')}>{`${CREATED}:`}</p>
          <p className={b('text-wrapper')}>{dateCreate}</p>
        </div>
        <AdminMainButton
          text={personalCard.status ? UNBAN_WORD : BAN_WORD}
          onClick={() => handleChangeValue('status', !personalCard.status)}
        />
      </div>
      <div className={b('content')}>
        <div className={b('prop-content')}>
          <p className={b('label-text')}>{`${HASH_PASSWORD}:`}</p>
          <p className={b('prop-text')}>{personalCard.hash}</p>
        </div>
        <div className={b('prop-content')}>
          <p className={b('label-text')}>{`${PASSWORD_CHANGED_DATE}:`}</p>
          <p className={b('prop-text')}>{changePasswordDate}</p>
        </div>
        <div className={b('prop-content')}>
          <p className={b('label-text')}>{`${NEW_PASSWORD}:`}</p>
          <div className={b('password-row')}>
            <input
              className={b('password-input')}
              placeholder={SET_PASSWORD_IF_CHANGE}
              value={personalCard.password}
              name="password"
              onChange={({ target: { name, value } }) => handleChangeValue(name, value)}
              onBlur={({ target: { value } }) => checkPasswordValidation(value)}
            />
            <InfoBlock
              message={personalCard.errorPassword}
              error
              isShow={Boolean(personalCard.password)}
            />
          </div>
        </div>
        <div className={b('roles-row')}>
          <p className={b('roles-title')}>{`${ROLE_WORD}:`}</p>
          <div className={b('roles-list')}>
            {personalCard.roles.map((item) => (
              <div className={b('role')} key={item.id}>
                <p className={b('role-title')}>{item.name}</p>
                <AdminCheckbox
                  checked={item.checked}
                  onChange={() => handleChangeCheckbox(personalCard.id, item.id)}
                  name={item.code}
                  disabled={checkDisabled(personalCard.id)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalCard;
