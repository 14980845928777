import {
  GET_USERS,
  SIGN_IN_BY_USER,
  CHANGE_SEARCH_FIELD,
  CLEAR_STORE,
} from '../types';

export const getUsersList = (page: number, filter: string) => ({
  type: GET_USERS.request,
  payload: { page, filter },
});

export const signInByUserId = (id: number) => ({
  type: SIGN_IN_BY_USER.request,
  payload: id,
});

export const changeSearchField = (value: string) => ({
  type: CHANGE_SEARCH_FIELD,
  payload: value,
});

export const clearStoreUsers = () => ({
  type: CLEAR_STORE,
});
