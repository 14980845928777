import { GET_USERS, CHANGE_SEARCH_FIELD, CLEAR_STORE } from '../types';
import { IAdminUsersReducer } from './interface';

const INITIAL_STATE: IAdminUsersReducer = {
  users: [],
  totalPages: 1,
  currentPage: 1,
  filter: '',
  adminUrl: '',
};

export default (state = INITIAL_STATE, { type, payload }: {type: string, payload: any}) => {
  switch (type) {
    case GET_USERS.success: {
      return {
        ...state,
        users: payload.users,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage,
      };
    }

    case CHANGE_SEARCH_FIELD: {
      return {
        ...state,
        filter: payload,
      };
    }

    case CLEAR_STORE: {
      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};
