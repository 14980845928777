import { IRedux } from '../../interface';

const getSelectedContract = (
  { applications }: IRedux,
) => (applications.selectedContract);

const getBankReqs = (
  { applications }: IRedux,
) => ({
  bic: applications.selectedContract.bic,
  curAcc: applications.selectedContract.curAcc,
  corAcc: applications.selectedContract.corAcc,
  bankName: applications.selectedContract.bankName,
  inn: applications.selectedContract.inn,
  recipient: applications.selectedContract.recipient,
});

const getCheckboxesStates = (
  { applications }: IRedux,
) => ({
  isSameData: applications.isSameData,
  isTelNumber: applications.isTelNumber,
  isAgree: applications.isAgree,
  isSmsSent: applications.isSmsSent,
});

const getIsPassedFirstForm = (
  { applications }: IRedux,
) => (applications.isPassedFirstForm);

const getSysDate = (
  { applications }: IRedux,
) => (applications.sysDate);

const getComment = (
  { applications }: IRedux,
) => (applications.selectedContract.comment);

const getIsLoading = (
  { applications }: IRedux,
) => (applications.isLoading);

const getNumFull = (
  { applications }: IRedux,
) => (applications.selectedContract.numFull);

const getPassportPhoto = (
  { applications }: IRedux,
) => ({ photo: applications.selectedContract.photo, reg: applications.selectedContract.reg });

const getBanksData = (
  { applications }: IRedux,
) => (applications.selectedContract.banksData);

const getPicErrors = (
  { applications }: IRedux,
) => ({
  photoErr: applications.selectedContract.photoErr,
  regErr: applications.selectedContract.regErr,
});

const getInsuranceAmmountCur = (
  { applications }: IRedux,
) => (
  applications.selectedContract.insuranceAmmountCur
);

const getSmsCode = (
  { applications }: IRedux,
) => (
  applications.selectedContract.code
);

const getIsValid = (
  { applications }: IRedux,
) => (
  applications.selectedContract.isValid
);

export {
  getSelectedContract,
  getCheckboxesStates,
  getIsPassedFirstForm,
  getSysDate,
  getComment,
  getIsLoading,
  getBankReqs,
  getNumFull,
  getPassportPhoto,
  getBanksData,
  getPicErrors,
  getInsuranceAmmountCur,
  getSmsCode,
  getIsValid,
};
