interface IActionsFactory {
  request: string,
  success: string,
  fail: string,
  start: string,
}


class AsyncActionFactory {
  private readonly REQUEST = '_REQUEST';

  private readonly SUCCESS = '_SUCCESS';

  private readonly FAIL = '_FAIL';

  private readonly START = '_START';

  constructor(
    private readonly reducerType: string,
  ) { }

  public createAsync(action: string): IActionsFactory {
    return {
      success: this.getAction(action, this.SUCCESS),
      request: this.getAction(action, this.REQUEST),
      fail: this.getAction(action, this.FAIL),
      start: this.getAction(action, this.START),
    };
  }

  public create(action: string): string {
    return this.getAction(action);
  }

  private getAction(action: string, type: string = ''): string {
    return `${this.reducerType}/${action}${type}`;
  }
}

export default AsyncActionFactory;
