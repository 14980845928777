import {
  CHANGE_FIELD,
  CLEAR_STORE,
  GET_ADDTIONAL_INFO_ACCOUNT_REQUEST,
  GET_ADDTIONAL_INFO_CONTRACT_REQUEST,
  GET_ADDTIONAL_INFO_PERSONAL_REQUEST,
  SET_DATA_FIRST_FORM_REQUEST,
  SET_DATA_SECOND_FORM_REQUEST,
  SET_DATA_THIRD_FORM_REQUEST,
  CHANGE_LOGIN, CLEAR_LOGIN,
} from '../types';

const clearLogin = () => ({
  type: CLEAR_LOGIN,
});

const changeLogin = (isPhone: boolean) => ({
  payload: isPhone,
  type: CHANGE_LOGIN,
});

const getInfoContract = () => ({
  type: GET_ADDTIONAL_INFO_CONTRACT_REQUEST,
});

const getInfoPersonal = () => ({
  type: GET_ADDTIONAL_INFO_PERSONAL_REQUEST,
});

const getInfoAccount = () => ({
  type: GET_ADDTIONAL_INFO_ACCOUNT_REQUEST,
});

const setDataFirstForm = (dateSign: string, login: string, ctsNumber: string) => ({
  payload: {
    ctsNumber,
    dateSign,
    login,
  },
  type: SET_DATA_FIRST_FORM_REQUEST,
});

const setDataSecondForm = (
  usersRegsId: number,
  firstName: string,
  lastName: string,
  middleName: string,
  passportSerial: string,
  passportNumber: string,
) => ({
  payload: {
    firstName,
    lastName,
    middleName,
    passportNumber,
    passportSerial,
    usersRegsId,
  },
  type: SET_DATA_SECOND_FORM_REQUEST,
});

const setDataThirdForm = (
  usersRegsId: number,
  ctgtsFlId: number,
  password: string,
  passwordConfirmation: string,
  dateSign: string,
  ctsNumber: string,
) => ({
  payload: {
    ctgtsFlId,
    ctsNumber,
    dateSign,
    password,
    passwordConfirmation,
    usersRegsId,
  },
  type: SET_DATA_THIRD_FORM_REQUEST,
});

const changeField = (name: string, value: any) => ({
  payload: {
    name,
    value,
  },
  type: CHANGE_FIELD,
});

const clearStore = () => ({
  type: CLEAR_STORE,
});

export {
  changeLogin,
  clearStore,
  changeField,
  setDataFirstForm,
  setDataSecondForm,
  setDataThirdForm,
  getInfoAccount,
  getInfoContract,
  getInfoPersonal,
  clearLogin,
};
