const MASKS = {
  ctsNumberMask: '999999_9999999999',
  dateSignMask: '9999-99-99',
  numberPassportMask: '999999',
  serialPassportMask: '9999',
  dateWithDots: '99.99.9999',
  phoneNumberMask: '+7 (999) 999 99 99',
  smsCodeMask: '9 9 9 9 9 9',
  emailCodeMask: '9 9 9 9 9 9 9 9',
};

export default MASKS;
