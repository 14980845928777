import './styles.scss';

import { block } from 'bem-cn';
import { Location } from 'history';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface ITab {
  name: string,
  role: string,
  path: string,
}
interface IProps {
  tabs: {
    name: string,
    role: string,
    path: string,
  }[],
  history: RouteComponentProps['history'],
  actions: string[],
  location: Location,
  adminUrl: string,
}

const b = block('switcher-admin-form');

const SwitcherForm: React.FC<IProps> = ({
  tabs,
  location,
  actions,
  history,
  adminUrl,
}) => {
  const checkCurrentTab = (currentPath: string) => {
    const currentAdminPath = `${adminUrl}${currentPath}`;

    return location.pathname.includes(currentAdminPath);
  };

  return (
    <div className={b()}>
      {tabs.map(({
        name,
        role,
        path,
      }: ITab, index) => {
        if (!actions.includes(role)) {
          return null;
        }

        return (
          <div
            className={b('tab', { active: checkCurrentTab(path) })}
            role="button"
            key={path}
            tabIndex={index}
            onClick={() => history.push(`${adminUrl}${path}`)}
          >
            <p className={b('tab-text')}>{name}</p>
            <div className={b('arrow', { down: checkCurrentTab(path) })} />
          </div>
        );
      })}
    </div>
  );
};

export default SwitcherForm;
