import { REGEXP } from 'consts';
import { IDescription } from '../redux/clientDescriptions/reducers/interface';

const rebuildDescriptions = (
  descriptions: IDescription[],
) => descriptions.reduce((acc, current) => {
  const { code } = current;
  return {
    ...acc,
    [code]: current.description,
  };
}, {});

const rebuildCurrency = (str: string) => str.replace(REGEXP.CURRENCY_REG, '$1 ');
export { rebuildDescriptions, rebuildCurrency };
