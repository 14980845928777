import React, { Component } from 'react';

export interface IWindowDimensions {
  windowWidth: number,
  windowHeight: number,
}

interface IState {
  height: number,
  width: number,
}

export const withWindowDimensions = (
  WrappedComponent: React.FC<any> | React.ComponentClass<any, any>,
) => {
  class WindowDimensions extends Component<any, IState> {
    constructor(props: null) {
      super(props);

      this.state = {
        width: 0,
        height: 0,
      };
    }

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
      const { width, height } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          windowWidth={width}
          windowHeight={height}
        />
      );
    }
  }

  return WindowDimensions;
};
