import React from 'react';
import {block} from 'bem-cn';
import {Icon} from 'semantic-ui-react';
import './styles.scss';

const b = block('pagination-interface');

interface IProps {
  active: number;
  total: number;
  handleUpPage: () => void;
  handleDownPage: () => void;
}

const FooterPagination: React.FC<IProps> = ({
  active = 1,
  handleUpPage,
  handleDownPage,
  total = 1,
}) => (
  <section className={b()}>
    <div className={b('pagination-control')}>
      <div
        className={b('icon-caret')}
        tabIndex={0}
        role="button"
        onClick={handleDownPage}
      >
        <Icon name="caret left" />
      </div>
      <div className={b('pages')}><p>{`${active} из ${total}`}</p></div>
      <div
        className={b('icon-caret')}
        tabIndex={0}
        role="button"
        onClick={handleUpPage}
      >
        <Icon name="caret right" />
      </div>
    </div>
  </section>
);

export default FooterPagination;
