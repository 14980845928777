import './styles.scss';

import { block } from 'bem-cn';
import React from 'react';
import { AdminLoader } from 'components';

interface IProps {
  onClick: (...args: any[]) => any,
  text: string,
  className?: string,
  disabled?: boolean,
  isProcess?: boolean,
  name?: string,
}
const b = block('admin-main-button');

const MainButton: React.FC<IProps> = ({
  onClick,
  text,
  className = '',
  disabled = false,
  isProcess = false,
  name,
}) => (
  <div className={b()}>
    <AdminLoader isActive={isProcess} />
    <button
      type="button"
      onClick={onClick}
      className={`${b('button', {disabled})} ${className}`}
      disabled={disabled}
      name={name}
    >
      {text}
    </button>
  </div>
);

export default MainButton;
