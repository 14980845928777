import React from 'react';

interface IProps {
  width: number,
  height: number,
  fill: string,
}

const WarningIcon: React.FC<IProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 512 512.923"
  >
    <path
      fill={fill}
      d="M433.348 512.922H80.449A80 80 0 018.9 397.164L185.331 44.25C198.875 17.133 226.586 0 256.898 0s58.024 17.133 71.567 44.25l176.433 352.914a80 80 0 01-71.55 115.758zm0 0"
    />
    <g fill="#fff">
      <path d="M256.898 128.203c8.836 0 16 7.164 16 16v192c0 8.836-7.164 16-16 16s-16-7.164-16-16v-192c0-8.836 7.165-16 16-16zm0 0M240.898 384.203h32v32h-32zm0 0" />
    </g>
  </svg>
);

export default WarningIcon;
