import './styles.scss';

import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import React from 'react';
import ReactDOM from 'react-dom';

const b = block('custom-select-mine');

const { DEFAULT_PLACEHOLDER } = LANG_DICTIONARY;

interface IProps {
  onChange: (...args: any[]) => any,
  onSelect: (...args: any[]) => any,
  options: any[],
  value: string,
  placeholder?: string;
  onScroll: () => void;
  onBlur?: () => void;
  button?: boolean;
  name?: string;
}

interface IStore {
  isOpen: boolean;
  top: number;
  left: number;
  bottom: number;
  right: number;
}

class CustomSelect extends React.Component<IProps, IStore> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpen: false,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', this.closeList);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.closeList);
  }

  openList = (target: HTMLInputElement) => {
    const position = target.getBoundingClientRect();
    const {
      top,
      bottom,
      left,
      right,
    } = position;
    this.setState({
      isOpen: true,
      top,
      left,
      bottom,
      right,
    });
  }

  closeList = () => {
    this.setState({
      isOpen: false,
    });
  }

  getCurrentValue = () => {
    const { options, value: key } = this.props;
    const currentValue = options.find((item) => item.key === key);
    if (currentValue) return currentValue.text;
    return '';
  }

  handleChange = (value: any) => {
    const { onSelect, name } = this.props;
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
    onSelect(value, name);
  }

  trackScrolling = () => {
    const { onScroll } = this.props;
    const wrappedElement: any = document.getElementById('selectList');
    const bodyHeight = wrappedElement.offsetHeight;
    const scrollHeight = wrappedElement.scrollTop;
    const bodyScrollHeight = wrappedElement.scrollHeight;
    const distanceToBottom = bodyScrollHeight <= bodyHeight + scrollHeight;

    if (distanceToBottom) {
      onScroll();
    }
  }

  popUpControl = (target: any) => {
    const { isOpen } = this.state;
    const position = target.getBoundingClientRect();
    const {
      top,
      bottom,
      left,
      right,
    } = position;
    this.setState({
      isOpen: !isOpen,
      top,
      left,
      bottom,
      right,
    });
  }


  render() {
    const {
      options,
      placeholder = DEFAULT_PLACEHOLDER,
      value,
      onChange,
      button = false,
      name,
    } = this.props;
    const root: any = document.getElementById('root');
    const {
      isOpen,
      top,
      left,
      bottom,
      right,
    } = this.state;

    const portalStyles = {
      top: `${top + 33.55}px`,
      left: `${left}px`,
      bottom: `${bottom}px`,
      right: `${right}px`,
    };

    const inputType = button ? 'button' : 'text';

    return (
      <div className={b()} onMouseLeave={this.closeList}>
        <input
          value={value}
          type={inputType}
          name={name}
          onClick={({target}) => (inputType === 'button' ? this.popUpControl(target) : null)}
          className={b('input', {open: isOpen})}
          onChange={({target: { value: inpuValue, name: inputName }, target}) => {
            if (inpuValue.length) {
              this.openList(target);
            } else {
              this.closeList();
            }
            onChange(inpuValue, inputName);
          }}
          placeholder={placeholder}
        />
        {isOpen && ReactDOM.createPortal(
          <>
            <div
              className={b('list')}
              onScroll={this.trackScrolling}
              id="selectList"
              style={portalStyles}
            >
              {options.map((item) => (
                <div
                  className={b('list-item')}
                  onClick={() => this.handleChange(item.value)}
                  role="button"
                  tabIndex={0}
                  key={item.key}
                >
                  {item.text}
                </div>
              ))}
            </div>
          </>, root,
        )}
      </div>
    );
  }
}

export default CustomSelect;
