import { block } from 'bem-cn';
import React from 'react';
import './styles.scss';

const b = block('info-block-mini');

interface IProps {
  message: string;
  error?: boolean;
  isShow?: boolean,
}

const InfoBlock: React.FC<IProps> = ({
  message,
  error,
  isShow = true,
}) => (
  <>
    {isShow && (
      <div className={b({errorMessage: error})}>{message}</div>
    )}
  </>
);

export default InfoBlock;
