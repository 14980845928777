import React from 'react';
import { AdminCheckbox, AdminSectionTitle} from 'components';
import { LANG_DICTIONARY } from 'consts';
import { changeGlobalSettings } from 'redux/rootActions';
import { block } from 'bem-cn';

interface IProps {
  isRegistration: boolean,
  isAuthorization: boolean,
  isService: boolean,
  changeSettings: typeof changeGlobalSettings,
}

const {
  REGISTRATION_AVAILABLE,
  AUTHORIZATION_AVAILABLE,
  DISABLE_SERVICE_MODE,
  MANAGMENT_LK,
} = LANG_DICTIONARY;

const b = block('admin-global-settings');

const GlobalSettings: React.FC<IProps> = ({
  isAuthorization,
  isRegistration,
  isService,
  changeSettings,
}) => {
  const checkboxes = [
    {
      name: 'isAuthorization',
      checked: isAuthorization,
      text: AUTHORIZATION_AVAILABLE,
    },
    {
      name: 'isRegistration',
      checked: isRegistration,
      text: REGISTRATION_AVAILABLE,
    },
    {
      name: 'isService',
      checked: isService,
      text: DISABLE_SERVICE_MODE,
    },
  ];

  const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { name, checked } } = e;

    changeSettings({
      isAuthorization,
      isRegistration,
      isService,
      [name]: checked,
    });
  };

  return (
    <div className={b()}>
      <AdminSectionTitle
        title={MANAGMENT_LK}
      />
      <div className={b('content')}>
        {checkboxes.map((item) => (
          <AdminCheckbox
            key={item.name}
            {...item}
            onChange={handleChangeCheckbox}
            textLeft
            isNotAdmin={false}
          />
        ))}
      </div>
    </div>
  );
};

export default GlobalSettings;
