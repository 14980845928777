import { REGEXP, LANG_DICTIONARY } from 'consts';
import { object, string } from 'yup';
import { MAX_LENGTH_INPUT, MINIMUM_PASSWORD_LENGTH } from 'config';
import { validatePersonalInfo } from 'helpers';

const {
  PHONE_HOME,
  CYRILLIC,
  EMAIL_CHECK,
  MOBILE_PHONE,
  PASSWORD_CHECK,
} = REGEXP;

const {
  PHONE_VALIDATION,
  INPUT_MAX_LENGTH,
  MIN_PASSPORT_NUMBER,
  MIN_PASSPORT_SERIAL,
  PERSONAL_NAME_VALIDATION,
  PASSWORD_CONFIRMATION_VALIDATION,
  PASSWORD_LOGIN_VALIDATION,
  PASSWORD_LANGUAGE,
  ACCESS_EMAIL_SYMBOLS,
  PASSWORD_RULES,
} = LANG_DICTIONARY;

const checkLogin = (check: boolean) => {
  if (!check) {
    return {
      login: string()
        .email()
        .test('', PASSWORD_LANGUAGE, (value) => !value.match(CYRILLIC))
        .test('', ACCESS_EMAIL_SYMBOLS, (value) => !value.match(EMAIL_CHECK))
        .max(MAX_LENGTH_INPUT),
    };
  }
  if (check) {
    return {
      login: string().matches(MOBILE_PHONE).max(MAX_LENGTH_INPUT),
    };
  }
  return {
    login: string().matches(PHONE_HOME, PHONE_VALIDATION).max(MAX_LENGTH_INPUT, INPUT_MAX_LENGTH),
  };
};

const checkPassportData = (checkPassport: boolean) => (
  checkPassport ? {
    firstName: string().test('', PERSONAL_NAME_VALIDATION, (value) => validatePersonalInfo(value)).required(),
    lastName: string().test('', PERSONAL_NAME_VALIDATION, (value) => validatePersonalInfo(value)).required(),
    middleName: string().notRequired().test('ifEmpty', PERSONAL_NAME_VALIDATION, (value) => {
      if (value) {
        return validatePersonalInfo(value);
      }
      return true;
    }),
    passportNumber: string().min(6, MIN_PASSPORT_NUMBER).required(),
    passportSerial: string().min(4, MIN_PASSPORT_SERIAL).required(),
  } : {}
);


const checkPasswordSchema = (checkPassword: boolean, passwordConfirm?: string, login?: string) => (
  checkPassword ? {
    password: string()
      .min(MINIMUM_PASSWORD_LENGTH, PASSWORD_RULES)
      .matches(PASSWORD_CHECK, PASSWORD_RULES)
      .test('', PASSWORD_LANGUAGE, (value) => !value.match(CYRILLIC))
      .test('', PASSWORD_LOGIN_VALIDATION, (value) => !value.match(login)),
    passwordConfirm: string().test('', PASSWORD_CONFIRMATION_VALIDATION, (value) => value === passwordConfirm),
  } : {}
);

const schema = (
  checkPassport: boolean,
  checkPassword: boolean,
  check?: any,
  passwordConfirm?: string,
  login?: string,
) => object().shape({
  ...checkLogin(check),
  ...checkPassportData(checkPassport),
  ...checkPasswordSchema(checkPassword, passwordConfirm, login),
});

export default schema;
