import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Switch, withRouter } from 'react-router-dom';
import { IAuthreducer } from 'redux/authorization/reducers/interfaces';
import { IDescription } from 'redux/clientDescriptions/reducers/interface';
import { IRedux } from 'redux/interface';
import { getAdminUrl, getIp } from 'redux/rootActions';
import { checkStartRender, getAdminUrlSelector } from 'redux/rootSelectors';
import { Route } from 'router/components';
import { IRoute } from 'router/interfaces/route';
import {
  authorizationRoutes,
  getAdminRoutes,
  mainRoutes,
  registrationRoutes,
} from 'router/routes';

interface IStoreProps extends IAuthreducer {
  getUserIp: typeof getIp,
  getAdmininstrationUrl: typeof getAdminUrl,
  ip: string,
  isRender: boolean,
  adminUrl: string,
  descriptions: IDescription[]
}

type TProps = IStoreProps & RouteComponentProps;

class Router extends React.Component<TProps> {
  public componentDidMount() {
    const {
      getUserIp,
      getAdmininstrationUrl,
    } = this.props;
    getUserIp();
    getAdmininstrationUrl();
  }

  public render() {
    const {
      isRender,
      adminUrl,
    } = this.props;

    const pathes: IRoute[] = [
      ...getAdminRoutes(adminUrl),
      ...authorizationRoutes,
      ...mainRoutes,
      ...registrationRoutes,
    ];


    if (!isRender) {
      return null;
    }

    return (
      <Switch>
        {pathes.map(({
          component,
          path,
          title,
          type,
          exact,
        }: IRoute) => (
          <Route
            component={component}
            path={path}
            title={title}
            type={type}
            exact={exact}
            key={path}
          />
        ))}
      </Switch>
    );
  }
}

const mapStateToProps = (state: IRedux) => ({
  ...state.authReducer,
  ...state.descriptions,
  ip: state.common.ip,
  isRender: checkStartRender(state),
  adminUrl: getAdminUrlSelector(state),
});

const mapDispatchToProps = {
  getUserIp: getIp,
  getAdmininstrationUrl: getAdminUrl,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Router),
);
