import React from 'react';
import { block } from 'bem-cn';
import { Row, Col } from 'reactstrap';
import { assignIdCustomTableRow } from 'helpers';
import './styles.scss';

const b = block('custom-table-row');

interface IPros {
  data: any[];
  minHeight: number;
  index: number;
  windowWidth: number;
}

interface IHeaders {
  text: string;
  id: number;
  style?: object;
}
const getStyles = (height: number, countColumn: number) => (
  {
    minHeight: height / countColumn,
    height: `${(1 / countColumn) * 100}%`,
  });

const FlexTableRow = ({
  data = [],
  minHeight = 80,
  index = 0,
  windowWidth = 1000,
}: IPros) => (
  <div className={b()}>
    <Row>
      {assignIdCustomTableRow({data, prefix: 1, startId: 1}).map(({
        data: includeData,
        headers,
        md,
        id,
        xs,
        sm,
      }) => (
        <Col md={md} key={id} xs={xs} sm={sm}>
          <div className={b('headers')}>
            {headers && headers.map(({text, id: includeId}: IHeaders) => (
              <div
                style={getStyles(minHeight, headers.length)}
                className={b('item', {header: true})}
                key={includeId}
              >
                {text}
              </div>
            ))}
          </div>
          {includeData && (
          <div
            className={b('data')}
            style={{
              height: index === 0 || windowWidth < 767
                ? `calc(100% - ${minHeight}px)` : '100%',
            }}
            key={`${windowWidth}windowwkey${index}`}
          >
            {includeData.map(({text, id: includeId, style}: IHeaders) => (
              <div
                style={{...getStyles(minHeight, includeData.length), ...style}}
                className={b('item', {second: index % 2 === 1})}
                key={includeId}
              >
                {text}
              </div>
            ))}
          </div>
          )}
        </Col>
      ))}
    </Row>
  </div>
);

export default FlexTableRow;
