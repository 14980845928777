import { ROUTES, TOKEN_TYPE } from 'consts';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRedux } from 'redux/interface';
import { checkCurrentUser } from 'redux/rootActions';

interface IProps {
  component: React.FC | React.ComponentClass,
}
interface IStoreProps {
  token: string,
  checkUser: typeof checkCurrentUser
}
type TProps = IProps & IStoreProps & RouteComponentProps;

class ClientContainer extends React.Component<TProps> {
  componentDidMount() {
    const {
      checkUser,
      history,
      token: tokenFromStore,
    } = this.props;
    if (tokenFromStore) {
      return null;
    }
    const tokenFromLocalStore = localStorage.getItem(TOKEN_TYPE.client);
    if (tokenFromLocalStore) {
      checkUser(tokenFromLocalStore);
    } else {
      history.push(ROUTES.authorization);
    }
    return null;
  }

  render() {
    const { component: Component, token } = this.props;

    if (!token) {
      return null;
    }

    return (<Component />);
  }
}

const mapStateToProps = ({ authReducer: { token }}: IRedux) => ({
  token,
});

const mapDispatchToProps = {
  checkUser: checkCurrentUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientContainer));
