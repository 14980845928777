import './styles.scss';
import moment from 'moment';
import React from 'react';
import { block } from 'bem-cn';
import WhiteButton from 'components/_shared/WhiteButton';
import { IContractModel } from 'redux/profitability/reducers/interfaces';
import { simpleDate } from 'helpers/dateUtils';
import { rebuildCurrency } from 'helpers/rebuild';
import dateFormats from 'consts/dateFormats';
import { LANG_DICTIONARY } from 'consts/dictionary';
import { DATE_FORMAT } from 'consts';
import { dateFormatter } from '../../../../../helpers';

const b = block('profitability-contract');

interface IProps {
  contract: IContractModel,
  isHaveActiveStrategy:boolean,
  isAutocalledStrategy: boolean,
  getProfitabilityReport: () => void,
  isLoadingReport: boolean,
}

interface IValueProps {
  labels: string[];
  values: string[];
}

const ContractValueCouple: React.FC<IValueProps> = ({ labels, values }) => (
  <div className={b('couple')}>
    {
        labels.map((x) => (
          <div key={x} className={b('label')}>
            {x}
          </div>
        ))
      }
    {
        values.map((x) => (
          <div key={x} className={b('value')}>
            {x}
          </div>
        ))
      }
  </div>
);

const ContractData: React.FC<IProps> = ({
  contract,
  isAutocalledStrategy,
  getProfitabilityReport,
  isHaveActiveStrategy,
  isLoadingReport,
}) => {
  const {
    ctsNumber,
    startDate,
    endDate,
    productName,
    insuranceSum,
    allYears,
    totalIncome,
    dateInvest,
    isAutocalled,
    nameShort,
    dateOfPrice,
  } = contract;

  const status = new Date() > new Date(endDate) ? LANG_DICTIONARY.EXPIRED : LANG_DICTIONARY.ACTUAL;

  return (
    <>
      <div className={b('wrapper')}>
        {
          isAutocalled && isAutocalledStrategy
            ? (
              <div className={b('autocall')}>
                <div>{`${LANG_DICTIONARY.ATTENTION}!`}</div>
                <div>{`${LANG_DICTIONARY.AUTOCALL}`}</div>
                <div className={b('autocall-border')} />
              </div>
            )
            : <div />
        }
        <ContractValueCouple
          labels={[LANG_DICTIONARY.NUMBER_CONTRACT]}
          values={[ctsNumber]}
        />
        <ContractValueCouple
          labels={[LANG_DICTIONARY.VALID_TIME]}
          values={[
            dateFormatter(Number(allYears)),
            `${LANG_DICTIONARY.FROM_S} ${simpleDate(startDate, dateFormats.DATE)} ${LANG_DICTIONARY.TO} ${simpleDate(endDate, dateFormats.DATE)}`,
          ]}
        />
        {
          dateInvest
            ? (
              <ContractValueCouple
                labels={[LANG_DICTIONARY.INVESTMENT_DATE]}
                values={[simpleDate(dateInvest, dateFormats.DATE)]}
              />
            )
            : <div />
        }
        <ContractValueCouple
          labels={[LANG_DICTIONARY.STATUS]}
          values={[status]}
        />
        <ContractValueCouple
          labels={[LANG_DICTIONARY.PROGRAM]}
          values={[productName]}
        />

        {insuranceSum && (
          <ContractValueCouple
            labels={[LANG_DICTIONARY.INSURANCE_AMMOUNT_CUR]}
            values={[`${rebuildCurrency(insuranceSum.toString())} ${nameShort}`]}
          />
        )}
        {isHaveActiveStrategy && (
          <ContractValueCouple
            labels={[
              LANG_DICTIONARY.TOTAL_INCOME_CURRENT_DATE(
                moment(dateOfPrice).format(DATE_FORMAT.DATE),
              )]}
            values={[`${totalIncome} ${nameShort}`]}
          />
        )}
      </div>
      <div style={{textAlign: 'right'}}>
        <WhiteButton
          hasBorder
          onClick={getProfitabilityReport}
          disabled={isLoadingReport || !isHaveActiveStrategy}
          text={isLoadingReport ? LANG_DICTIONARY.DOWNLOADING : LANG_DICTIONARY.DOWNLOAD_REPORT}
        />
      </div>
    </>
  );
};

export default ContractData;
