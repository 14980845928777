import './styles.scss';

import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import {
  AdminSectionTitle,
  FlexTableUsers,
  FooterPagination,
} from 'components';
import React from 'react';
import { IUsers } from 'redux/adminUsers/reducers/interface';
import { signInByUserId } from 'redux/rootActions';


const b = block('users-form');

const {
  LIST_OF_USERS,
  SHORT_FULLNAME,
  LOGIN,
  CONTRACT,
  FILTER_WORD,
} = LANG_DICTIONARY;

interface IProps {
  users: IUsers[];
  activePage: number;
  totalPages: number;
  handleUpPage: () => void;
  handleDownPage: () => void;
  signInByUser: typeof signInByUserId;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filter: string;
  adminUrl: string;
}

const UsersForm: React.FC<IProps> = ({
  users,
  activePage,
  totalPages,
  handleUpPage,
  handleDownPage,
  signInByUser,
  handleChange,
  filter,
  adminUrl,
}) => (
  <div className={b()}>
    <div>
      <div className={b('title')}>
        <AdminSectionTitle title={LIST_OF_USERS} />
      </div>
      <div className={b('search')}>
        <div className={b('filter')}>
          {`${FILTER_WORD}:`}
        </div>
        <input
          placeholder={`${LOGIN}/${SHORT_FULLNAME}/${CONTRACT}`}
          className={b('search-input')}
          onChange={handleChange}
          value={filter}
        />
      </div>
      <FlexTableUsers users={users} signInByUser={signInByUser} adminUrl={adminUrl} />
    </div>
    <FooterPagination
      active={activePage}
      total={totalPages}
      handleDownPage={handleDownPage}
      handleUpPage={handleUpPage}
    />
  </div>
);

export default UsersForm;
