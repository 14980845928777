import React from 'react';

const CheckBoxSvg = ({active}: {active: boolean}) => {
  if (active) {
    return (
      <svg width="24" height="40" viewBox="20 5 24 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <rect x="20" y="13" width="24" height="24" rx="6" fill="#06722C" />
        </g>
        <path d="M35.4937 21.7584C35.8383 21.4139 36.397 21.4139 36.7416 21.7584C37.0861 22.103 37.0861 22.6617 36.7416 23.0063L31.4474 28.3004C31.1029 28.645 30.5442 28.645 30.1996 28.3004L27.2584 25.3592C26.9139 25.0146 26.9139 24.456 27.2584 24.1114C27.603 23.7668 28.1617 23.7668 28.5063 24.1114L30.8235 26.4286L35.4937 21.7584Z" fill="white" />
        <defs>
          <filter id="filter0_d" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="7" />
            <feGaussianBlur stdDeviation="10" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>

    );
  }
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="24" height="24" rx="6" fill="#EFF1F5" stroke="#D9DCE5" />
    </svg>
  );
};

export default CheckBoxSvg;
