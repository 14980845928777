import './styles.scss';
import React from 'react';
import { block } from 'bem-cn';
import { IAdditionalServices } from 'redux/contracts/reducers/interface';
import { DATE_FORMAT, LANG_DICTIONARY } from 'consts';
import { TransparentTable } from 'components';
import moment from 'moment';

const b = block('isz-additional-services-data');
const slider = block('main-slider');
const contentClass = b('content').mix(slider('content', { right: true }));

const {
  ADDITIONAL_SERVICES,
  DESCRIPTION,
  STATUS,
  ABLED_DATE,
  ACTIVE,
  DISABLED,
} = LANG_DICTIONARY;

interface IProps {
    data: IAdditionalServices;
}

const AdditionalServices: React.FC<IProps> = ({data}) => {
  const getDate = (date: string) => date && moment(date).format(DATE_FORMAT.DATE);

  const headers = [
    DESCRIPTION,
    STATUS,
    ABLED_DATE,
  ];

  const rebuildData = data.additionalServices?.length && data.additionalServices.map((item) => ([
    <div className={b('service-name')}>{item.serviceName}</div>,
    data.sysdate >= item.dateBegin && data.sysdate <= item.dateEnd ? ACTIVE : DISABLED,
    <div>{`до ${getDate(item.dateEnd)}`}</div>,
    <p className={b('desc')}>{item.description}</p>,
  ]));

  return (
    <div className={b.mix(slider)}>
      <div className={b('wrapper')}>
        <div className={contentClass}>
          <div className={b('header-form-text')}>{ADDITIONAL_SERVICES}</div>
          {rebuildData
           && (
           <TransparentTable
             data={rebuildData}
             headers={headers}
             className={b('table')}
           />
           )}
        </div>
      </div>
    </div>
  );
};

export default AdditionalServices;
