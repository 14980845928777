import './styles.scss';

import { block } from 'bem-cn';
import {
  AdminPreviewInput, CaptchaBlock, FormInput, GreenButton,
} from 'components';
import { FormikValues } from 'containers/_administration/Authorization/formik';
import { GenericFormikProps } from 'helpers';
import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import {adminSignin, getRecaptchaAdmin} from 'redux/rootActions';

interface IProps {
  signIn: typeof adminSignin;
  errorMessage: string;
  getRecaptcha: typeof getRecaptchaAdmin;
  captcha: string,
  captchaId: string,
}

type TFormikProps = GenericFormikProps<FormikValues,
  'handleChange' |
  'handleBlur' |
  'values' |
  'errors' |
  'touched' |
  'handleSubmit'
  >
type TProps = IProps & TFormikProps;

const b = block('admin-auth-form');
const {
  SIGN_INF_WORD,
  LOGIN,
  PASSWORD,
  CAPTCHA_CODE,
} = LANG_DICTIONARY;

const AuthForm: React.FC<TProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  errorMessage,
  captcha,
  getRecaptcha,
  captchaId,
}) => {
  const getProps = (nameInput: 'login' | 'password') => ({
    value: values[nameInput],
    error: errors[nameInput],
    touch: touched[nameInput],
    onChange: handleChange,
    onBlur: handleBlur,
    name: nameInput,
  });

  return (
    <form className={b()}>
      <p className={b('error-message')}>{errorMessage}</p>
      <AdminPreviewInput
        {...getProps('login')}
        placeholder={LOGIN}
        autoComplete="username"
      />
      <AdminPreviewInput
        {...getProps('password')}
        type="password"
        placeholder={PASSWORD}
        autoComplete="current-password"
      />
      {captcha && (
        <>
          <CaptchaBlock
            captcha={captcha}
            handleRecaptcha={() => getRecaptcha(captchaId)}
          />
          <div className={b('captcha-wrapper')}>
            <div className={b('captcha-input-wrapper')}>
              <FormInput
                placeholder={CAPTCHA_CODE}
                value={values.captchaKey}
                onChange={handleChange}
                name="captchaKey"
                mask=""
              />
            </div>
          </div>
        </>
      )}
      <GreenButton
        text={SIGN_INF_WORD}
        onClick={handleSubmit}
        className={b('submit-button')}
        type="submit"
      />
    </form>
  );
};

export default AuthForm;
