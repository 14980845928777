import './styles.scss';

import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import {
  FlexTableContracts,
  AdminSectionTitle,
  InfoTable,
} from 'components';
import React from 'react';
import { IContractUserCard } from 'redux/adminUserCard/reducers/interface';
import { ENUMS } from 'containers/_administration/UserCard';

const { CONTRACTS, CONTRACT_DATA } = LANG_DICTIONARY;

const b = block('user-card-contracts');

type TAction = 'contacts' | 'contragents' | 'contracts';

interface IProps {
  addAction: (type: TAction) => void;
  deleteAction: (type: TAction, id: number | string) => void;
  contracts: IContractUserCard[];
  getContractCard: (id: number | string) => void;
  updateContractList: (filter: string) => void;
  listOfContracts: any[];
  currentContractCard: any;
  showTablesControl: (type: string) => void;
  showTable: boolean;
  clearCtsList: () => void;
  handleChangeValueContract: (id: number | string, name: string, value: string) => void;
  handleSetContract: (id: number | string, value: any) => void;
}

const UserContractsForm: React.FC<IProps> = ({
  addAction,
  deleteAction,
  contracts,
  getContractCard,
  updateContractList,
  listOfContracts,
  currentContractCard,
  showTablesControl,
  showTable,
  clearCtsList,
  handleChangeValueContract,
  handleSetContract,
}) => (
  <div className={b()}>
    <button
      onClick={() => showTablesControl(ENUMS.contracts)}
      type="button"
      tabIndex={0}
      className={b('control-btn')}
    >
      <AdminSectionTitle title={CONTRACTS} addButtonBoolean />
    </button>
    {showTable && (
      <div className={b('contracts-block')}>
        <div className={b('table')}>
          <FlexTableContracts
            addAction={addAction}
            deleteAction={deleteAction}
            contracts={contracts}
            getContractCard={getContractCard}
            listOfContracts={listOfContracts}
            updateContractList={updateContractList}
            clearCtsList={clearCtsList}
            handleChangeValueContract={handleChangeValueContract}
            handleSetContract={handleSetContract}
          />
        </div>
        {Boolean(currentContractCard.length) && <AdminSectionTitle title={CONTRACT_DATA} />}
        <InfoTable
          data={currentContractCard}
        />
      </div>
    )}
  </div>
);

export default UserContractsForm;
