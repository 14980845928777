import { block } from 'bem-cn';
import React from 'react';
import './styles.scss';

const b = block('green-button');

interface IProps {
  onClick: () => void,
  text: string,
  className?: string,
  disabled?: boolean,
  type?: 'submit' | 'button',
}
const GreenButton: React.FC<IProps> = ({
  onClick,
  text,
  className,
  disabled,
  type = 'button',
}) => (
  <button
    className={`${b()} ${className}`}
    onClick={onClick}
    disabled={disabled}
    type={type || 'button'}
  >
    {text}
  </button>
);

export default GreenButton;
