import './styles.scss';

import { block } from 'bem-cn';
import { PopupContainer, WhiteTable, InfoBlock } from 'components';
import { DATE_FORMAT, LANG_DICTIONARY } from 'consts';
import moment from 'moment';
import React from 'react';
import { IInvestmentIncome } from 'redux/contracts/reducers/interface';

const { INVESTMENT_INCOME_MONITORING_DATES } = LANG_DICTIONARY;
const b = block('investment-income-popup');

interface IProps {
  data: IInvestmentIncome[],
  isOpen: boolean,
  close: (...args: any[]) => any,
  description: string,
}

const InvestmentIncomePopup: React.FC<IProps> = ({
  data,
  isOpen,
  close,
  description,
}) => {
  const getDate = (date: string) => (date ? moment(date).format(DATE_FORMAT.DATE) : null);
  const rebuildData = data.map((item) => [getDate(item.didDate)]);
  const headers = [INVESTMENT_INCOME_MONITORING_DATES];

  return (
    <PopupContainer
      isOpen={isOpen}
      close={close}
      isCloseButton
      className={b()}
    >
      <WhiteTable
        data={rebuildData}
        headers={headers}
        className={b('table')}
      />
      <InfoBlock message={description} />
    </PopupContainer>
  );
};

export default InvestmentIncomePopup;
